import React, { Component } from 'react';
//import {Header, Footer} from '../generic/headFoot';

export default class PageNotFound extends Component{
  render() {
    return(
      <div>
          <div className="container text-center">
            <label className="fontlarge">This page you are trying to visit does not exist.</label>
          </div>
      </div>
    )
  }
}

// export default class PageNotFound extends Component{
//   render() {
//     return(
//       <div>
//         <Header navigator={false} location={this.props.location}/>
//           <div className="container text-center">
//             <label className="fontlarge">This page you are trying to visit does not exist.</label>
//           </div>
//         <Footer />
//       </div>
//     )
//   }
// }