import {delDb} from '../lib/db/common';
import Net from '../lib/net.js';
import { browserHistory } from 'react-router';
import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker,TrafficLayer,Polyline } from "react-google-maps";
import {jsonToCSVConvertor} from './jsonUtils';
const crmhostname = require('../config').config.crmhostname;
var markerA = require('../images/greenMarker_s.png');
var markerB = require('../images/redMarker_s.png');

export function actionOnLogout(kick) {
  global.gWebSocket = null;
  global.wsOnline = null;
  global.bikeDetails = null;
  if (global.userId) global.userId = null;
  if(!kick) delDb('Glite');
  if (document.referrer !== '/webapp/') {
    window.history.pushState(null, null, '/webapp/');
    window.addEventListener('popstate', () => {
      window.history.pushState(null, null, '/webapp/');
    });
    window.removeEventListener('popstate', (e) => {
    });
  }

}

export function formatCurrency(amount, currency = 'INR', format = 'en-IN') {
  var formatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2
  });
  return (formatter.format(amount));
}

export function compareTwoArrays(arr1, arr2){
  var diff = false ;
  if(arr1.length === arr2.length) {
    arr1.forEach((v) => {
      if(!arr2.includes(v)){
        diff = true;
      }
    });
  }else diff = true;
  return diff;
}

export function logout(kick) {
  var url = crmhostname + "/crm/users/logout";
  Net.PostRequest(url, "", (resp) => {
    if(resp.status === 200){
      if (global.gWebSocket) {
        global.gWebSocket.closeWS();
      }
    }
    actionOnLogout(kick);
    browserHistory.push("/webapp/");
  });
}

export const MapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap zoom={props.zoom} center={props.center} onZoomChanged={props.onZoomChanged}
    ref={props.referance} onIdle={props.onMapIdle} onMapMounted={props.onMapMounted}
    options={{ scaleControl: true, streetViewControl: false }}>

    <Marker position={props.regionA} icon={{ url: markerA, anchor: { x: 20, y: 40 } }} onClick={() => props._onChange} />

    <Marker position={props.regionB} icon={{ url: markerB, anchor: { x: 20, y: 40 } }} onClick={() => props._onChange} />

    {props.trafficCheck ? <TrafficLayer autoUpdate /> : null}

    {props.pathTrail && props.pathTrail.length
      ? <Polyline path={props.pathTrail} options={{ strokeColor: '#217dbb', geodesic: true }} />
      : null}

  </GoogleMap>
))

export function isVehValid(vehicles, vidArr) {
  var valid = false;
  if (vidArr && vidArr.length) {
    vehicles.forEach(vid => {
      if (vidArr.includes(vid.idx)) {
        valid = true;
      }
    });
    return valid;
  }
}

export function _setNewUrl(loc, vehArr, from, to) {
  var newUrl = loc.pathname;
  if (vehArr) {
    vehArr.forEach((veh, i) => {
      if (i === 0) newUrl += "?vid=" + veh;
      else newUrl += "&vid=" + veh;
    });
    if (from && to) newUrl += "&from=" + from + "&to=" + to;
  } else {
    if (from && to) newUrl += "?from=" + from + "&to=" + to;
  }
  return newUrl;
}

export function getVidArray(vids) {
  //function accept vid from url and return vid array
  var vidArr = [];
  if (!Array.isArray(vids)) {
    vidArr.push(parseInt(vids));
  } else {
    vids.forEach(v => {
      vidArr.push(parseInt(v));
    });
  }
  return (vidArr);
}

export function getDevices(vehicles ) {
  /* 
  Fucntion get all vehicles as input,
  return length and boolean value for device availablity
 */
  var devices = [];
  if (vehicles && vehicles.length) {
    vehicles.forEach(veh => {
      if (veh.dev && Object.keys(veh.dev).length) devices.push(veh.dev);
    });
    return devices;
  } else return devices;
}

export function downloadRidesCSV(pingArr, title, heading){
  var excelData = pingArr.map((data)=>{
    var obj = {};
    obj['TIMESTAMP'] = data.ts;
    obj['LAT'] = data.lat;
    obj['LNG'] = data.lng;
    obj['ALT'] = data.alt;
    obj['SPEED'] = data.spd;
    obj['BRG'] = data.brg;
    obj['HEPE'] = data.hepe;
    obj['VEPE'] = data.vepe;
    obj['ANGLE'] = data.ang;
    obj['TEMP'] = data.temp;
    obj['MOTION'] = data.motion;
    obj['IGNITION'] = data.ignition;
    obj['MAINPOWER'] = data.mainPower;
    obj['RELAYSTATE'] = data.relayState;
    return obj;
  })
  jsonToCSVConvertor(excelData, title + Date.now(), true, heading);
}

export function isValidFromTS(qsFrom, fromLimit) {
  if (qsFrom < fromLimit) return false;
  else return true;
}

export function isValidToTS(qsTo, toLimit) {
  if (qsTo > toLimit) return false;
  else return true;
}