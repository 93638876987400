/**
 * This module handles api related to seller panel's service data back and forth
 */

const NET = require('../lib/net.js');
const crmhostname = require('../config.js').config.crmhostname;

/**
 * @function GetServiceListCount
 * @returns An object containing list of vids classified by service type
 */
export function GetServiceListCount() {
  let mt = new Date().setHours(0, 0, 0, 0);
  let nt = new Date().setHours(23, 59, 59, 999);
  const url = crmhostname + '/dealership/service/getServiceListCount?mt=' + mt + '&nt=' + nt;
  return new Promise((resolve, reject) => {
    NET.GetRequest(url, (resp) => {
      let data = JSON.parse(resp.response).data;
      if (resp.status !== 200) reject(data);
      else resolve(data);
    });
  });
}

/**
 * @function GetServiceList
 * @param {array} vidArr Array of vids
 * @returns A list a Users registered to the Seller
 */
export function GetServiceList(vidArr) {
  const url = crmhostname + '/dealership/service/getServiceList';
  return new Promise((resolve, reject) => {
    NET.PostRequest(url, { vid: vidArr }, (resp) => {
      let data = JSON.parse(resp.response).data;
      if (resp.status !== 200) reject(data);
      else resolve(data);
    });
  });
}

/**
 * @function AddServiceData
 * @param {*} serviceData Object containing service data
 * @returns Success or Failure Strings
 */
export function AddServiceData(serviceData) {
  const url = crmhostname + '/dealership/service/addServiceData';
  return new Promise((resolve, reject) => {
    NET.PostRequest(url, serviceData, (resp) => {
      let data = JSON.parse(resp.response).data;
      if (resp.status !== 200) reject(data);
      else resolve(data);
    });
  });
}

/**
 * @function MarkServiceDone
 * @param {number} vid Vehicle Id
 * @returns Success or Failure Strings
 */
export function MarkServiceDone(vid) {
  const url = crmhostname + '/dealership/service/markServiceDone';
  return new Promise((resolve, reject) => {
    NET.PostRequest(url, vid, (resp) => {
      let data = JSON.parse(resp.response).data;
      if (resp.status !== 200) reject(data);
      else resolve(data);
    });
  });
}

/**
 * @function ChangeLoginPassword
 * @param {string} oldPwd Old Password
 * @param {string} newPwd New Password
 * @returns Success () or Failure (string)
 */
export function ChangeLoginPassword({ oldPwd, newPwd }) {
  const url = crmhostname + '/dealership/service/changeLoginPassword';
  return new Promise((resolve, reject) => {
    NET.PostRequest(url, { oldPwd, newPwd }, (resp) => {
      let data = JSON.parse(resp.response).data;
      if (resp.status !== 200) reject(data);
      else resolve();
    });
  });
}
