import React, { Component} from 'react';
import {StampTODate, timeFormat, parseMSIntoReadableTime} from '../../lib/dtconv';
import {DailyRide ,RidesModal, CheckBox} from '../../generic/input.js';
import {Col, Row, ListGroupItem, Table, Button} from 'react-bootstrap/lib';
import {getRidesStat} from './dailyRideTiles';
import {clone } from '../../lib/jsonUtils';
import {getDayData,getStatForRides, getPathForRides} from '../../lib/db/rides';
import { LoadingScreen } from '../../generic/input';
import { DAY, MENUPATH } from '../../lib/enums';
const SPEED_THRESHOLD = 250;
const {MapComponent, downloadRidesCSV} = require('../../lib/common');

export default class Rides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rides: [],
      selectedDayRide: "",
      errMsg: "",
      showModal: false,
      selectedRidesIds: [],
      disabledOk: false,
      center: { lat: 20.5837, lng: 78.9629 },
      visibleRides: [],
      totalRides: []
    }
    this.ridePings = [];
    this.path = [];
    this.angArr = [];
    this.dailyRideTiles = [];
    this.eachRideTiles = [];
    this.selectedDayRides = "";
    this.rids = [];
    this.mapMounted = false;
    this.stats = [];
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.RIDE_DATA, this.onRideData);
    global.EE.addEventListener(global.EE.EVENT_TYPES.ON_NEW_RIDE, this.onNewRides);
  }
  componentDidMount() {
    this.getDataForDay();
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.DAILY_RIDES);
  }

  getDataForDay() {
    if (this.props.location.query.veh && this.props.location.query.ts) {
      getDayData(parseInt(this.props.location.query.veh), parseInt(this.props.location.query.ts), (data) => {
        this.selectedDayRides = data;
        this.rids = clone(data.rids);
        this.setState({
          selectedDayRide: this.selectedDayRides,
          selectedRidesIds: this.rids,
          totalRides: clone(this.rids),
          visibleRides: clone(this.rids)
        });
        getPathForRides(parseInt(this.props.location.query.veh), this.rids, (pathArr) => {
          if (pathArr.length) {
            this.path = pathArr;
            getStatForRides(parseInt(this.props.location.query.veh), this.rids, (statsArr) => {
              if (statsArr) this.getStartAndEnd(statsArr);
            });
          }
          else {
            this.lastRid = this.rids[this.rids.length - 1];
            global.EE.emitEvent(global.EE.EVENT_TYPES.GETPATH, this.rids);
          }
        });
      });
    } else {
      var newUrl = "/webapp/dailyRidesPage";
      this.props.router.replace(newUrl);
    }
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.RIDE_DATA, this.onRideData);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.ON_NEW_RIDE, this.onNewRides);
  }

  onNewRides = (todayNewStat) => {
    this.getDataForDay();
  }

  onRideData = (details) => {
    if (details.path) this.path = details.path;
    if (details.stats) this.getStartAndEnd(details.stats);
    if (this.path.length && this.path.length > 2)
      this.setState({
        center: { lat: this.path[Math.round(this.path.length / 2)].lat, lng: this.path[Math.round(this.path.length / 2)].lng }
      });
  }

  getStartAndEnd(statsArr) {
    var sdt = null, edt = null;
    if (statsArr && statsArr.length) {
      statsArr.forEach(sts => {
        if (!sdt) sdt = sts.sdt;
        edt = sts.edt;
      })
      let selectedDayRide = this.state.selectedDayRide;
      if (selectedDayRide) {
        if (sdt) selectedDayRide.sdt = sdt;
        if (edt) selectedDayRide.edt = edt;
        this.setState({
          selectedDayRide: selectedDayRide
        });
      }
    }
  }

  downloadCSV = ()=>{
    downloadRidesCSV(this.path, "daily_ride_" + Date.now(), "ajjas_");
  }

  showRides(totalRids, ride, selectedRids) {
    var dt, midnight, maxAngle;
    if (ride.ts) dt = new Date(ride.ts);
    else dt = new Date(ride.sdt * 1000);
    midnight = new Date().setHours(0, 0, 0, 0);
    maxAngle = this.angArr && this.angArr.length ? Math.max(...this.angArr) : ride.mang;
    return (
      <div className="col-sm-12 col-xs-12">
        <div className="font-size16px" style={{ marginTop: "2%", marginBottom: "3%" }}>{dt.getTime() < midnight ? StampTODate(dt) + " " + DAY[dt.getDay()] : "Today"}</div>
        <div>
          {DailyRide("Rides", totalRids && totalRids.length > 1 ? <span>{totalRids.length}<p style={{ margin: "0px" }}>{this.path.length ? <Button bsSize="xsmall" style={{ color: '#00a8e2' }} onClick={() => this.onRidesClick()}>View rides</Button>: null}</p></span> : totalRids.length, null, "col-lg-2 col-md-4 col-sm-4 col-xs-4")}
          {DailyRide("Distance", ride.dst ? ((ride.dst) / 1000).toFixed(1) + " Km" : "-")}
          {DailyRide("Duration", ride.dur ? parseMSIntoReadableTime(ride.dur * 1000) : "-")}
          {DailyRide("Start", ride.sdt ? timeFormat(ride.sdt * 1000) : "")}
          {DailyRide("End", ride.edt ? timeFormat(ride.edt * 1000) : "-")}
          {DailyRide("AVG Speed", ride.avs && ride.avs < SPEED_THRESHOLD ? ride.avs.toFixed(2) + " Km/h" : SPEED_THRESHOLD + " Km/h")}
          {DailyRide("Top Speed", ride.tps && ride.tps < SPEED_THRESHOLD ? ride.tps.toFixed(2) + " Km/h" : SPEED_THRESHOLD + " Km/h","", "col-lg-2 col-md-4 col-sm-4 col-xs-4 margin-bottom11" )}
          {maxAngle ? DailyRide("Max Lean Angle", maxAngle ? maxAngle.toFixed(2) + "°" : "-") : ""}
          {
            selectedRids && selectedRids.length ?
              DailyRide("Selected Rides", selectedRids.length)
              : null
          }
        { DailyRide("Engine On Duration", ride.ignOnDur ? parseMSIntoReadableTime(ride.ignOnDur * 1000) : "-", null)}
        {this.path && this.path.length && global.userId ? 
          DailyRide("Download",
            <button className="btn-primary" style={{ borderRadius: "4px", padding: "1px" }} title="Download CSV" onClick={this.downloadCSV}>
              <i className="ajjas-cloud" style={{ fontSize: "24px" }}/>
            </button>
          ) : null
        }
        </div>
      </div>
    )
  }

  createEachRide(rides) {
    this.eachRideTiles = [];
    if (rides && rides.length) {
      rides.forEach((ride) => {
        if (ride.sdt) var dt = new Date(ride.sdt * 1000);
        this.eachRideTiles.push({
          id: ride, data:
            <div>
              <div className="font-size16px">{dt ? StampTODate(dt) + " " + DAY[dt.getDay()] : null}</div>
              <Table style={{ borderTop: "hidden", marginTop: "2%", marginBottom: "0px" }}>
                <tbody>
                  <tr>
                    <td align="center" className="color-9b9b9b">Start <div style={{ color: "black", fonSize: "18px" }}>{ride.sdt ? timeFormat(ride.sdt * 1000) : "-"}</div></td>
                    <td align="center" className="color-9b9b9b">Distance<div style={{ color: "black", fonSize: "18px" }}>{ride.dst ? (ride.dst / 1000).toFixed(1) + " Km" : "-"}</div></td>
                    <td align="center" className="color-9b9b9b">Duration<div style={{ color: "black", fonSize: "18px" }}>{ride.dur ? parseMSIntoReadableTime(ride.dur * 1000) : "-"}</div></td>
                  </tr>
                </tbody>
              </Table>
            </div>
        })
      });
    }
  }

  onRidesClick() {
    getStatForRides(parseInt(this.props.location.query.veh), this.state.totalRides, (statArr) => {
      if(statArr && statArr.length){
        statArr = statArr.sort((a, b) => a.sdt - b.sdt);
        this.createEachRide(statArr);
        this.setState({
          showModal: true,
        });
      }
    });
  }

  closeModal = () => {
    if (this.state.selectedRidesIds && this.state.selectedRidesIds.length < 1) {
      this.setState({
        disabledOk: false,
      });
    }
    this.setState({
      showModal: false,
      selectedRidesIds: Array.from(this.state.visibleRides),
      errMsg: ""
    })
  }

  handleChange(e) {
    var rideId = parseInt(e.target.value);
    if (e && e.target.checked) {
      this.state.selectedRidesIds.push(rideId);
      this.setState({
        disabledOk: false,
        errMsg: ""
      })
    }
    else {
      for (var i = 0; i < this.state.selectedRidesIds.length; i++) {
        if (this.state.selectedRidesIds[i] === rideId) this.state.selectedRidesIds.splice(i, 1);
        if (this.state.selectedRidesIds && this.state.selectedRidesIds.length < 1) {
          this.setState({
            errMsg: "Please select the ride ",
            disabledOk: true
          });
        }
      }
    }
  }

  onSubmit() {
    var stats = {};
    this.state.selectedRidesIds.sort((a, b) => a - b);
    getStatForRides(parseInt(this.props.location.query.veh), this.state.selectedRidesIds, (statArr) => {
      if (statArr && statArr.length) stats = getRidesStat(statArr);
      getPathForRides(parseInt(this.props.location.query.veh), this.state.selectedRidesIds, (pathArr) => {
        if (pathArr && pathArr.length) this.path = pathArr;
        this.mapMounted = false;
        this.handleMapMounted();
        this.setState({
          showModal: false,
          selectedDayRide: stats,
          errMsg: "",
          visibleRides: clone(this.state.selectedRidesIds)
        });
      });
    });
  }

  handleMapMounted = (map) => {
    if (this.mapMounted) return;
    var bounds = new window.google.maps.LatLngBounds();
    this.path.forEach((path) => {
      bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
    })
    this._ref.fitBounds(bounds);
    this.mapMounted = true;
  }

  _onChange(latlng, zoom) {
    this.setState({
      zoom: zoom,
      center: this._ref.getCenter()
    })
  }

  mapDisplay() {
    var latlng = { lat: this.path[0].lat, lng: this.path[0].lng };
    return (
      <div className="margin-bottom15 map">
        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAZZ1EgiGO1AVPgChSEqS9iQwVZl2Xfdw8&v=3.exp&libraries=drawing,places"
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '50vh' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          center={this.state.center}
          zoom={this.state.zoom}
          onZoomChanged={() => this._onChange(latlng, this.state.zoom)}
          trafficCheck={this.state.trafficCheck}
          pathTrail={this.path}
          regionA={latlng}
          regionB={{ lat: this.path[this.path.length - 1].lat, lng: this.path[this.path.length - 1].lng }}
          onMapIdle={this.handleMapMounted}
          referance={(ref) => this._ref = ref}
        />
      </div>
    )
  }

  render() {
    return (
      <Col lg={10} md={9} sm={12} xs={12} className="border-gray" style={{ paddingLeft: "0%", paddingRight: "0%", paddingBottom: "15px" }}>
        {this.state.selectedDayRide && this.state.totalRides ? this.showRides(this.state.totalRides, this.state.selectedDayRide, this.state.selectedRidesIds) : null}
        {this.path.length > 0 ?
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 leftRight_padding" >{this.mapDisplay()}</div> : <LoadingScreen/>
        }
        {
          this.state.showModal ?
            <Row>
              <RidesModal show={this.state.showModal} title="Total Rides" close={this.closeModal} errMsg={this.state.errMsg} disabledOk={this.state.disabledOk} onSubmit={() => this.onSubmit()}>
                {
                  this.eachRideTiles && this.eachRideTiles.length ?
                    this.eachRideTiles.map((ride, i) => {
                      if (this.state.selectedRidesIds && this.state.selectedRidesIds.indexOf(ride.id.idx) > -1) {
                        return (
                          <div key={i.toString()} style={{ paddingBottom: "15px" }}>
                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1" style={{ paddingLeft: "0px" }}>
                              <CheckBox id={ride.id.idx.toString()} style={{ marginTop: '2vh' }} value={ride.id.idx} onClick={(e) => this.handleChange(e)} defaultChecked={true} />
                            </div>
                            <ListGroupItem className="col-lg-11 col-md-11 col-sm-11 col-xs-11" style={{ position: "initial", marginTop: "1%", borderRadius: "4px" }} header={ride.data} key={i}></ListGroupItem>
                          </div>
                        )
                      }
                      else {
                        return (
                          <div key={i.toString()} style={{ paddingBottom: "15px" }}>
                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1" style={{ paddingLeft: "0px" }}>
                              <CheckBox id={ride.id.idx.toString()} style={{ marginTop: '2vh' }} value={ride.id.idx} onClick={(e) => this.handleChange(e)} defaultChecked={false} />
                            </div>
                            <ListGroupItem className="col-lg-11 col-md-11 col-sm-11 col-xs-11" style={{ position: "initial", marginTop: "1%", borderRadius: "4px" }} header={ride.data} key={i}></ListGroupItem>
                          </div>
                        )
                      }
                    })
                    : null
                }
              </RidesModal>
            </Row>
            : null
        }
      </Col>
    )
  }
}
