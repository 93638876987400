import React, { Component} from 'react';
import {Col, Row, Alert} from 'react-bootstrap/lib';
import { VehSingleDropDown, LoadingScreen, DateInput, DailyRide } from '../generic/input';
import { parseMSIntoReadableTime } from '../lib/dtconv';
import { MENUPATH } from '../lib/enums';
const computeStats = require('../lib/geoDistCal').computeStats;
const SPEED_THRESHOLD = 250;
const {MapComponent, downloadRidesCSV} = require('../lib/common');

export default class GetRideLog extends Component {
  constructor(props){
    super(props);
    this.state = {
      errMsg: "",
      title: "Vehicle Filter",
      showVehDrop: true,
      from: "",
      to: "",
      toEndTS: "",
      selectedDayRide: "",
      center: { lat: 20.5837, lng: 78.9629 },
      isLoading: false,
      toEndMin:  ""
    }
    this.singleRide = [];
    this.angArr = [];
    this.mapMounted = false;
  }

  componentWillMount() {
    this.singleRide = [];
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.addEventListener(global.EE.EVENT_TYPES.GET_RIDE, this.onRidesPings);
  }

  componentDidMount() {
    if (global.wsOnline) this.onBikes(global.bikeDetails);
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.RIDE_LOG);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.GET_RIDE, this.onRidesPings);
    global.EE.emitEvent(global.EE.EVENT_TYPES.CHANGE_GET_RIDE_EVENT, true);
  }

  onRidesPings = (pings) => {
    if (pings.obj.ts !== 0){
      let ts = pings.obj.ts * 1000;
      if((ts >= this.state.from) && (ts <= this.state.to)) this.singleRide.push(pings.obj);
    }
    else {
      if (this.tsArray && this.tsArray.length) {
        if (this.tsIndex !== this.lastTsIndex) {
          this.tsIndex += 1;
          if (this.tsArray && this.tsArray[this.tsIndex]) this.SendReqForPings(this.tsArray[this.tsIndex], (this.tsArray[this.tsIndex + 1] - 1000));
        } else this.computeStatistics();
      } else this.computeStatistics();
    }
  }

  computeStatistics() {
    var stats, path = [], angArr = [];

    global.EE.emitEvent(global.EE.EVENT_TYPES.CHANGE_GET_RIDE_EVENT, true);
    stats = computeStats(this.singleRide.sort((a, b) => a.ts - b.ts), true);
    if (stats) {
      stats.avs = ((stats.dst / stats.ignOnCnt) * (18 / 5)) || 0;
      stats.idx = this.singleRide[0].rideid;
    }
    if(this.singleRide.length){
      this.singleRide.sort((a, b) => a.ts - b.ts);
      this.singleRide.forEach((p) => {
        path.push({ lat: p.lat, lng: p.lng });
        angArr.push(p.ang);
      });
    }
    var rideData = {};
    rideData.path = path;
    rideData.stats = stats;
    rideData.ang = angArr;
    this.showData(rideData);
  }

  showData = (details) =>{
    if(details.path && details.path.length){
      if (details.path) this.path = details.path;
      if (details.stats) this.getStartAndEnd(details.stats);
      if (this.path.length && this.path.length > 2)
        this.setState({
          center: { lat: this.path[Math.round(this.path.length / 2)].lat, lng: this.path[Math.round(this.path.length / 2)].lng }
        });
    }
    else this.setState({  isLoading: false, errMsg: "No Rides present between selected date range", selectedDayRide: ""})
  }

  getStartAndEnd(statsArr) {
    this.setState({
      selectedDayRide: statsArr, isLoading: false, errMsg: ""
    });
  }

  onBikes = (bikes) => {
    global.bikeDetails = bikes;
    if (bikes && bikes.length) {
      bikes = bikes.filter(veh => {return veh.did;});
      this.vehicles = bikes;
      if(this.props.location && this.props.location.query){
        var loc = this.props.location
        if(loc.query.did && loc.query.start && loc.query.end){
          var fromDate = parseInt(loc.query.start, 10);
          var toDate = parseInt(loc.query.end, 10);
          var bike = bikes.find(d=> d.did === parseInt(loc.query.did, 10));
          var endDate = parseInt(loc.query.start, 10) + (86400000 * 7);//
          if(endDate > Date.now()) endDate = Date.now();
          if(bike.did && toDate > fromDate && toDate <= endDate){
            this.selectedDev = bike;
            this.setState({
              title: (bike.vmk ? bike.vmk : "") + " " + (bike.mo ? bike.mo : "") + " " +(bike.reg ? bike.reg : bike.treg),
              from: fromDate,
              toEndTS: endDate,
              to: toDate}, ()=>{
              this.getData();
            });
          }
        }
      }
      this.forceUpdate();
    }
    else this.setState({ errMsg: "This feature is only available for Plus device users. Add a vehicle with Plus HW."})
  }

  onClickVehicle = (bike) =>{
    var selectedTitle = (bike.vmk ? bike.vmk : "") + " " + (bike.mo ? bike.mo : "") + " " +(bike.reg ? bike.reg : bike.treg);
    if(selectedTitle){
      this.setState({ showVehDrop: false, from: "", to: "", selectedDayRide: ""}, ()=>{
        this.selectedDev = bike;
        this.setState({ title : selectedTitle, showVehDrop: true });
      });
    }
  }

  onChangeFrom = (e, StartDate) =>{
    this.setState({ from: ""},()=>{
      let endDate = e[0].getTime() + (86400000 * 7);
      let toEndMin = this.state.toEndMin;
      if(endDate > Date.now()) endDate = Date.now();
      else toEndMin = new Date(e[0].getTime()).getMinutes()
      this.setState({ from : e[0].getTime(), toEndTS: endDate, errMsg: "", toEndMin: toEndMin});
    })
  } 

  onChangeTo = (e) =>{
   this.setState({ to : e[0].getTime(), errMsg: ""});
  }

  getStartTsArr (start, end) {
    var arr = [], dt = new Date(start), endDate = new Date(end);
    while (dt <= endDate) {
      arr.push(dt.getTime());
      dt.setDate(dt.getDate() + 1);
    }
    if (arr && arr.length && arr[arr.length - 1] < end) arr.push(end); 
    return arr;
  }

  getData = () => {
    if(this.state.from > this.state.to) this.setState({errMsg: "Start time and end time cannot be same"});
    else {
      var tsDiff = (this.state.to - this.state.from) / 3600000;
      this.path = [];
      this.singleRide = [];
      this.mapMounted = false;
      this.setState({ isLoading: true });
      global.EE.emitEvent(global.EE.EVENT_TYPES.CHANGE_GET_RIDE_EVENT, false);
      if (tsDiff > 24) {
        this.tsArray = this.getStartTsArr(this.state.from, this.state.to);
        this.tsIndex = 0;
        this.lastTsIndex= this.tsArray.length - 2;
        this.SendReqForPings(this.tsArray[this.tsIndex], (this.tsArray[this.tsIndex + 1] - 1000));
      } else {
        this.SendReqForPings(this.state.from, this.state.to);
      }      
    }

    var newUrl = this.props.location.pathname;
    newUrl += "?did=" + this.selectedDev.did + "&start=" + this.state.from + "&end=" + this.state.to;
    this.props.router.replace(newUrl)
  }

  SendReqForPings = (tsFrom, tsTo) => {
    var device = {
      devid: this.selectedDev.did,
      rideid: null,
      count: null,
      offset: null,
      tsFr: Math.round(tsFrom / 1000),
      tsTo: Math.round(tsTo / 1000)
    }
    var data = { a: "getEventByTs", d: { dev: device } };
    global.gWebSocket.SendRequest({
      data: data,
    });
  }

  handleMapMounted = (map) => {
    if (this.mapMounted) return;
    var bounds = new window.google.maps.LatLngBounds();
    this.path.forEach((path) => {
      bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
    })
    this._ref.fitBounds(bounds);
    this.mapMounted = true;
  }

  _onChange(latlng, zoom) {
    this.setState({
      zoom: zoom,
      center: this._ref.getCenter()
    })
  }

  mapDisplay() {
    var latlng = { lat: this.path[0].lat, lng: this.path[0].lng };
    return (
      <div className="margin-bottom15 map">
        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAZZ1EgiGO1AVPgChSEqS9iQwVZl2Xfdw8&v=3.exp&libraries=drawing,places"
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '50vh' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          center={this.state.center}
          zoom={this.state.zoom}
          onZoomChanged={() => this._onChange(latlng, this.state.zoom)}
          trafficCheck={this.state.trafficCheck}
          pathTrail={this.path}
          regionA={latlng}
          regionB={{ lat: this.path[this.path.length - 1].lat, lng: this.path[this.path.length - 1].lng }}
          onMapIdle={this.handleMapMounted}
          referance={(ref) => this._ref = ref}
        />
      </div>
    )
  }

  showRides = (ride, selectedRids) => {
    var maxAngle;
    var start = new Date(this.state.from).toString().split('GMT')[0];
    var end = new Date(this.state.to).toString().split('GMT')[0];
    maxAngle = this.angArr && this.angArr.length ? Math.max(...this.angArr) : ride.mang;
    var style = "col-lg-2 col-md-4 col-sm-4 col-xs-4 margin-bottom20px";
    return (
      <div className="col-sm-12 col-xs-12">
        <div className="font-size16px font-bold" style={{ marginTop: "1%", marginBottom: "1%" }}>{start + " -- " + end }</div>
        <div >
          {DailyRide("Distance", ride.dst ? ((ride.dst) / 1000).toFixed(1) + " Km" : "-", "", style)}
          {DailyRide("Avg Speed", ride.avs && ride.avs < SPEED_THRESHOLD ? ride.avs.toFixed(2) + " Km/h" : SPEED_THRESHOLD + " Km/h", "", style)}
          {DailyRide("Top Speed", ride.tps && ride.tps < SPEED_THRESHOLD ? ride.tps.toFixed(2) + " Km/h" : SPEED_THRESHOLD + " Km/h", "", style)}
          {maxAngle ? DailyRide("Max Lean Angle", maxAngle ? maxAngle.toFixed(2) + "°" : "-", "", style) : ""}
          {DailyRide("Engine On Duration", ride.ignOnCnt ? parseMSIntoReadableTime(ride.ignOnCnt * 1000) : "-", null, style)}
          {DailyRide("Start", ride.sdt ? new Date(ride.sdt * 1000).toString().split('GMT')[0] : "", "", style)}
          {DailyRide("End", ride.edt ? new Date(ride.edt * 1000).toString().split('GMT')[0] : "-", "", style)}
        </div>
      </div>
    )
  }

  downloadCSV = ()=>{
    downloadRidesCSV(this.singleRide, "ride_log_" + Date.now(), "ajjas_");
  }

  render(){
    return(
      this.vehicles && this.vehicles.length > 0 && this.state.showVehDrop ?
        <Col>
          <Row>
            <Col lg={4} md={4} sm={6} xs={12} style={{marginTop: "0%", marginBottom: "-2px"}}>
              <p className="color-e1e1e1 font-size12px color-b5b5b5">Select Vehicle</p>
              <VehSingleDropDown vehicles={this.vehicles} onClick={(e)=> this.onClickVehicle(e)} title={this.state.title}/>
            </Col>
            <Col lg={3} md={4} sm={6} xs={12} >
              <p className="color-e1e1e1 font-size12px color-b5b5b5">Time From: </p>
              <DateInput defaultdate={this.state.from ? this.state.from : ""} enableTime={true} dateFormat={true} mindate={1546281000000} maxdate={new Date().setHours(23, 59, 59, 999)} onChange={(e) => this.onChangeFrom(e)}></DateInput>
            </Col>
            {this.state.from ?
              <Col lg={3} md={4} sm={6} xs={12} >
                <p className="color-e1e1e1 font-size12px color-b5b5b5">Time To: </p>
                <DateInput defaultdate={this.state.to ? this.state.to : ""} defaultMinute={this.state.toEndMin ? this.state.toEndMin : ""} enableTime={true} dateFormat={true} mindate={this.state.from} maxdate={this.state.toEndTS} onChange={(e) => this.onChangeTo(e)}></DateInput>
              </Col>
              : null
            }
          </Row>
          <Row className="margin-top2">
            {
              this.selectedDev && this.state.from && this.state.to ?
                <Col lg={1} md={2} sm={4} xs={4} style={{ marginTop: "0%" }}>
                  <input type="button" className="btn btn-primary" value="Search" onClick={this.getData} />
                </Col>
                : null
            }
            {
              this.path && this.path.length && global.userId ?
                <Col lg={1} md={2} sm={4} xs={4} style={{ marginTop: "0%" }}>
                  <input type="button" className="btn btn-primary" value="Download" onClick={this.downloadCSV}/>
                </Col>
                : null
            }
          </Row>
          <Row className="margin-top2">
            {this.state.errMsg ?
              <Col lg={10} md={9} sm={12} xs={12}><Alert style={{ paddingLeft: "10px" }} bsStyle="danger">{this.state.errMsg}</Alert></Col>
              : ""}
          </Row>
          {this.state.isLoading ? <LoadingScreen /> : null}
          {
            this.state.selectedDayRide ?
              <Col lg={12} md={12} sm={12} xs={12} className="border-gray" style={{ paddingLeft: "0%", paddingBottom: "15px", marginTop: "1%" }}>
                {this.showRides(this.state.selectedDayRide, this.state.selectedRidesIds)}
                {this.path.length > 0 ?
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ paddingRight: "0px" }} >{this.mapDisplay()}</div> : null
                }
              </Col>
              : null
          }
        </Col>
        : <Col lg={10} md={9} sm={12} xs={12}>
            <Alert bsStyle="warning"> Ajjas device is not installed</Alert>
          </Col> 
    )
  }
}