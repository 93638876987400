import React, { Component} from 'react';
import { saveNewTriType } from '../../lib/db/triggers';
import {Col, Alert, FormControl, SplitButton, MenuItem, Button, Checkbox} from 'react-bootstrap/lib';
import { GeoFenceTextBox } from '../../generic/input';
const { GeoFenceMap } = require('./GeoFenceMap');
const { sphericalCosinus} = require('../../lib/geoDistCal')

export class GeoFenceInput extends Component{
  constructor(props){
    super(props);
    this.state = {
      errMsg: "",
      title: "Select type",
      nameErr: false,
      geoErr: false,
      vehErr: false,
      addType: false,
      selectedVehId: [],
    }
    this.newGFName = "";
    this.newGFType = "";
    this.desc = "";
    this.view = "";
    this.selectedData = {}
  }

  componentDidMount(){
    if(this.props.triggerIdData){
      this.newGFName = this.props.triggerIdData.title;
      this.desc = this.props.triggerIdData.desc;
      this.selectedData = { r: this.props.triggerIdData.r, clat: this.props.triggerIdData.clat, clong: this.props.triggerIdData.clong}
      if(this.props.triggerIdData.gfTyp){
        var selectedType = this.props.gFTypArr.filter((d)=> {return d.idx === this.props.triggerIdData.gfTyp});
        if(selectedType) this.selectedType = selectedType[0].idx;
      }
      if( this.props.triggerIdData.vehIdArr) this.setState({ selectedVehId: this.props.triggerIdData.vehIdArr});
      if(this.props.gfType) this.setState({ title: this.props.gfType});
    }
  }

  onChangeFence(e){
    this.selectedData = { r: e.radius, clat: e.center.lat(), clong: e.center.lng()}
  }

  onCircleComplete1(data){
    this.selectedShape = data;
    this.forceUpdate();
  }

  onClear(){
    this.selectedData = {};
    if(this.selectedShape){
      this.selectedShape.setOptions({drawingControlOptions: {position: 3, drawingModes: ['circle'] }});
      this.selectedShape.e.overlay.setMap(null);
      this.selectedShape = null;
    }
    if(this.props.viewMap) {
      this.view = true
    }
    this.forceUpdate();
  }

  mapDisplay() {
    return(
      <GeoFenceMap
        onCircleComplete= {(key)=> {this.onCircleComplete1(key)}}
        onClear ={ ()=> this.onClear()}
        center = {this.props.addMap || this.view ? { lat: 20.5837, lng: 78.9629 } : {lat: this.props.triggerIdData.clat, lng: this.props.triggerIdData.clong} }
        zoom = {8}
        onRadiusChanged = {this.props.addMap || this.view ? null : (key)=> {this.onChangeFence(key)}}
        onCenterChanged = {this.props.addMap || this.view ? null : (key)=> {this.onChangeFence(key)}}
        radius = {this.props.addMap || this.view ? null : this.props.triggerIdData.r}
        draw = { this.props.addMap || this.view ? true : null}

      />
    )
  }

  onGFTypeSelect(e){
    var selectedType = this.props.gFTypArr.filter((d)=> {return d.idx ===  parseInt(e.target.id)});
    if(selectedType && selectedType.length){
      this.selectedType = selectedType[0].idx;
      this.setState({ title: selectedType[0].nm });
    }
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSaveGFNewType()
    }
  }

  onSaveGFNewType(){
    if(!this.newGFType){
      this.setState({ errMsg: "Please Enter Geo Fence Type" });
      return;
    }
    this.setState({
      addType: false,
      title: this.newGFType,
      errMsg: ""
    });
    document.getElementById('applybutton').disabled = true;
    saveNewTriType({ nm: this.newGFType} ,2,(err, response) =>{
      if(err) this.setState({ errMsg: err });
      else {
        this.selectedType = response.idx;
        this.forceUpdate();
      }
    });
  }

  onSelectAllVehicle(e){
    var vehID = [];
    var items = document.getElementsByName('vehicle');
    for (var i = 0; i < items.length; i++) {
      if (e.target.checked && items[i].type === 'checkbox') {
        vehID.push(parseInt(items[i].id));
        items[i].checked = true;
      }
      else items[i].checked = false;
    }
    this.setState({ selectedVehId: vehID });
  }

  onVehicleSelect(e){
    var items = document.getElementById('selectAll'); items.checked = false;
    var vehId = parseInt(e.target.id);
    if(e && e.target.checked){
      this.state.selectedVehId.push(vehId);
    }
    else {
      for(var i = 0 ; i < this.state.selectedVehId.length ; i++) {
        if(this.state.selectedVehId[i] === vehId) {
          this.state.selectedVehId.splice(i, 1);
        }
      }
    }
  }

  checkOverLap(newLat, newLong, radius){
    var overLapArr = [];
    if(this.props.triggerData && this.props.triggerData.length){
      this.props.triggerData.forEach(d=>{
        if(this.props.triggerIdData && this.props.triggerIdData.idx === d.idx) return;
        var distance = sphericalCosinus(d.clat, d.clong, newLat, newLong);
        var TotalDist = d.r + radius;
        if((distance - TotalDist) < 0){
          overLapArr.push(d)
        }
      });
    }
    if(overLapArr && overLapArr.length){
      var save = "";
      if(overLapArr.length === 1) save = window.confirm("Geo-fence overlapping with " + overLapArr[0].title + ". Are you sure you want to save this geo-fence? Press OK to save it anyway.");
      else save = window.confirm("Geo-fence overlapping with more than one geo-fences. Are you sure you want to save this geo-fence? Press OK to save it anyway.");
      if(save)return true;
    }
    else return true;
  }

  validateInputs = (newData) => {
    if(!this.newGFName) {
      this.setState({
        nameErr: "Enter geo-fence name",
        errMsg: "Mandatory fields are missing. Please check your inputs and try again",
        geoErr: "",
        vehErr: ""
      });
    }
    else if(this.state.selectedVehId && this.state.selectedVehId.length === 0){
      this.setState({
        vehErr: "Select Vehicle",
        geoErr: "",
        nameErr: "",
        errMsg: "Mandatory fields are missing. Please check your inputs and try again"
      });
    }
    else if(this.props.triggerIdData && !this.view){
      if(Object.keys(this.selectedData).length === 0){
        this.setState({
          geoErr: "Create geo-fence area ",
          nameErr: "",
          vehErr: "",
          errMsg: "Mandatory fields are missing. Please check your inputs and try again"
        });
      }
      else return true;
    }
    else if((this.view || this.props.addMap) && !this.selectedShape){
      this.setState({
        geoErr: "Create geo-fence area ",
        nameErr: "",
        vehErr: "",
        errMsg: "Mandatory fields are missing. Please check your inputs and try again"
      });
    }
    else return true;
  }

  sendData(){
    var sendSelectedData = {};
    if(this.selectedShape){
      sendSelectedData.r = this.selectedShape.e.overlay.radius;
      sendSelectedData.clat = this.selectedShape.e.overlay.center.lat();
      sendSelectedData.clong = this.selectedShape.e.overlay.center.lng();
    }
    else sendSelectedData =  this.selectedData
    var data = {
      newGFName: this.newGFName,
      selectedShape: sendSelectedData,
      selectedVehId:  this.state.selectedVehId,
      selectedType: this.selectedType,
      desc: this.desc,
      view: this.view
    }
    this.props.onSaveGeoFence(data);
  }

  onSaveGeoFence(){
    let validated = this.validateInputs();
    if(validated){
      var confirm = "";
      if(this.props.triggerIdData && !this.view){
        if(this.props.triggerIdData.r !== this.selectedData.r || this.props.triggerIdData.clat !== this.selectedData.clat ){
          confirm = this.checkOverLap(this.selectedData.clat, this.selectedData.clong, this.selectedData.r)
          if(confirm) this.sendData();
        }
        else this.sendData();
      }
      else {
        confirm = this.checkOverLap(this.selectedShape.e.overlay.center.lat(), this.selectedShape.e.overlay.center.lng() ,this.selectedShape.e.overlay.radius) //add
        if(confirm) this.sendData();
      }
    }
  }

  GeoFenceDescription = (data) =>{
    return(
      <Col>
        <span style={{marginLeft: "4%", marginTop:"5%", fontSize: "12px"}}>Fence Description (Optional)</span>
        <FormControl style={{ marginLeft: "4%", width: "90%", fontSize:"12px", height:"120px"}}
          type="text"
          value={this.state.value}
          componentClass="textarea"
          defaultValue= {data && data.desc ? data.desc : " "}
          onChange={(e)=>{ this.desc = e.target.value} }
        />
      </Col>
    )
  }

  GeoFenceOption = () =>{
    return(
      <Col>
        <p style={{fontSize: "12px", marginTop:"5%"}}>Geo-fence Type (Optional)</p>
        <SplitButton title={this.state.title} className="addGfType" bsSize="large" style={{color: "#b5b5b5", fontSize: "14px", height:"46px"}} id="split-button-pull-right">
          <ul id="positionMe" className="addGfType1"></ul>
          {this.props.gFTypArr ?
            this.props.gFTypArr.map((d, i)=>{
              return (
                <MenuItem key={i} id ={d.idx} onClick={(e)=>this.onGFTypeSelect(e)}><span style={{ color: "#b5b5b5" }}>{d.nm}</span></MenuItem>
              )
            })
           :
            null}
        </SplitButton>
      </Col>
    )
  }

  showMap = () =>{
    return(
      <Col>

        <Col style={{marginTop:"3%"}}>
            <span  style={{marginLeft: "4%", marginTop:"3%", fontSize: "12px"}}>Add Location On The Map</span>
            {
              // <Button style={{float: "right", marginRight:"6%", width: "15%", marginBottom: "1%"}} id="clear" bsSize="small" onClick={() => this.onClear()} ><span style={{ fontSize: "12px", color: "red" }} >Clear</span></Button>
            }
        </Col>
        <Col className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginLeft: "2%", paddingRight:"8%",paddingBottom: "5%"}}>{this.mapDisplay()}</Col>
      </Col>
    )
  }

  VehicleOption = () =>{
    return (
      <Col>
        <p style={{ marginLeft: "4%", fontSize: "15px", color: "#9b9b9b"}}>Select Vehicle To Get Alert</p>
        {this.state.vehErr ? <p style={{marginLeft: "4%", marginTop:"1%", fontSize: "12px"}}><span style ={{color: "red" }}>{this.state.vehErr}</span></p> : null}
        <Checkbox style={{ marginLeft: "4%", marginTop: "5%", color:"#4a4a4a"}} id="selectAll" onClick={(e) => this.onSelectAllVehicle(e)}>Select for all</Checkbox>
        <Col style={{ overflowY: "auto", maxHeight: "230px",border: "1px solid #e1e1e1" }}>
          {
            this.props.bikeDetails && this.props.bikeDetails.map((veh, i) => {
              return (
                <Col key={i}>
                {veh.did ?
                  <Col  className={"col-lg-4 col-md-4 col-sm-12 col-xs-12 "} style={{ paddingLeft: "0px", color: "#4a4a4a",paddingRight: "0px", paddingTop: "0px"}}>
                    <Col style={{border: "1px solid #e1e1e1" }}>
                        <Checkbox defaultChecked={veh.checked ? veh.checked : false} onClick={(e) => this.onVehicleSelect(e)} id={veh.idx} value={veh.did}
                        name="vehicle" style={{marginLeft: "12%", marginTop:"6%", marginBottom:"6%"}} >
                        <Col>
                          <span style={{color: "#788995"}}>{veh.mo}<br />{veh.reg ? veh.reg : veh.treg}</span>
                          <span style={{color:"#cdcdcd",marginLeft: '15px', marginTop:"-4%", fontSize:"12px"}}>{veh.did ? "PLUS" : "PRO"}</span>
                        </Col>
                      </Checkbox>
                    </Col>
                  </Col>
                : null}
                </Col>
              )
            })
          }
        </Col>
      </Col>
    )
  }

  saveButton = () =>{
    return(
      <Col>
        <Col style={{ width: "90%", marginTop: "2%", marginLeft: "4%", height: "60px"}}>{this.props.errMsg || this.state.errMsg? <Alert  style={{paddingLeft: "10px"}} bsStyle="danger">{this.props.errMsg ? this.props.errMsg : this.state.errMsg}</Alert> : ""}</Col>
        <button id="save" style={{marginLeft: "4%", backgroundColor:"#00a8e2", width:"90%", marginTop: "20px", height:"45px"}} disabled={this.props.disabled} onClick={() => this.onSaveGeoFence()}><span style={{ fontSize: "14px", color:"#ffffff" }} >Save Geo-fence</span></button>
      </Col>
    )
  }

  render(){
    var data =  this.props.triggerIdData;
    return(
      <Col style={{ overflowY: "auto", height: "85vh", color: "#b5b5b5", paddingLeft:"0%", paddingBottom:"2px", paddingRight:"0%", border: "1px solid #e1e1e1"}}>
        <p className ="font-size16px" style={{marginLeft: "4%", marginTop: "2%", color:"#4a4a4a"}}>{this.props.header}</p>
        <hr/>
        <GeoFenceTextBox
          nm="Name"
          placeholder="Enter Name of the Geo-fence"
          style={{marginLeft: "4%", width: "90%", fontSize:"12px", height:"45px", marginTop: "2%"}}
          value={this.state.value}
          title={ data && data.title ? data.title : null}
          onChange={(e)=>{ this.newGFName = e.target.value}}
        />
        {this.state.nameErr ? <p style={{marginLeft: "4%", marginTop:"1%", fontSize: "12px"}}><span style ={{color: "red" }}>{this.state.nameErr}</span></p> : null}
        {this.showMap()}
        {this.state.geoErr ? <p style={{marginLeft: "4%", marginTop:"1%", fontSize: "12px"}}><span style ={{color: "red" }}>{this.state.geoErr}</span></p> : null}
        { this.GeoFenceDescription(data)}
        <Col style={{marginLeft: "4%"}}>{ this.GeoFenceOption()}</Col>
        { this.state.addType ?
          <Col>
            <GeoFenceTextBox
              className="newGfType1"
              placeholder="Add geofence type"
              style={{ marginLeft: "4%", fontSize:"12px", height:"45px", marginTop: "20px"}}
              value={this.state.value}
              onChange={(e)=>{ this.newGFType = e.target.value}}
              onKeyPress={this.handleKeyPress}
            />
            <Button id="applybutton" className="newGfType" style={{ backgroundColor:"#00a8e2"}} onClick={() => this.onSaveGFNewType()} ><span style={{ fontSize: "14px", color:"#ffffff" }} >Save</span></Button>
          </Col>
          : null
        }
        <hr style={{marginLeft: "0%"}}/>
        { this.VehicleOption()}
        { this.saveButton()}
      </Col>
    )
  }
}

