import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Col } from 'react-bootstrap/lib';
import { StampTODate, timeFormat } from "../lib/dtconv";
const { VEH_SHIP_STATS } = require('../lib/enums');
var staticMarker = require('../images/staticMarker.png');
var greyMarker = require('../images/greyMarker.png');
var greenMarker = require('../images/greenMarker.png');
var greyArrow = require('../images/grey-arrow.png');
var greenArrow = require('../images/green-arrow.png');
var blueArrow = require('../images/blue-arrow.png');

export default class TabularFormat extends React.Component{
  constructor(props) {
    super(props);
    this.lastSeen = {};
  }
  componentDidMount() {
    this.acceptLastSeen(this.props.lastSeen);
  }

  acceptLastSeen(lastSeen) {
    this._filterSelectedVehLastSeenData(lastSeen);
  }

  _filterSelectedVehLastSeenData(lastSeen) {
    this.lastSeen = {};
    if (this.props.vehicles && this.props.vehicles.length) {
      this.props.vehicles.forEach(veh => {
        if (veh.did && lastSeen && lastSeen[veh.did]) {
          this.lastSeen[veh.did] = lastSeen[veh.did];
        }
      });
      this.forceUpdate();
    }
  }

  showDetails(vehicles){
    return (
      <ReactTable
        data={vehicles}
        columns={this._getCustomersColumns()}
        showPageSizeOptions={true}
        defaultPageSize={10}
        getTdProps={this._getCustomCellStyle}
        sortable={true}
        resizable={true}
        defaultSorted={[
          {
            id: "vmk",
          }
        ]}
      />
    )
  }
 
  ignitionState = (d) => {
    if(this.lastSeen && d.did && this.lastSeen[d.did]){
      var ignition = this.lastSeen[d.did].ignition;
      if(ignition) return "ON";
      else if(ignition === false) return "OFF";
      else return "--"
    }
    else return "--";
  }
  currentSpeed(d){
    var key = d.did || d.idx;
    if (this.lastSeen && key && this.lastSeen[key]) {
      var spd = this.lastSeen[key].spd;
      return (spd.toFixed(2));
    }
    else return "";
  }
  mainPower(d){
    if(this.lastSeen && d.did && this.lastSeen[d.did]){
      var mainPower = this.lastSeen[d.did].mainPower;
      if(mainPower) return "ON";
      else if(mainPower === false) return "OFF";
      else return "--";
    }
    else return "--";
  }
  motion(d){
    if(this.lastSeen && d.did && this.lastSeen[d.did]){
      var motion = this.lastSeen[d.did].motion;
      if(motion) return "YES";
      else if(motion === false) return "NO";
      else return "--";
    }
    else return "--";
  }
  lastSeenTS = (row)=>{
    if(row && row.original && this.lastSeen){
      let key = row.original.did || row.original.idx;
      return this.lastSeen[key] && this.lastSeen[key].ts ? StampTODate(this.lastSeen[key].ts * 1000) + " " + timeFormat(this.lastSeen[key].ts * 1000,true) : "--";
    }
    else return "--";
  }
  trips = (row)=>{
    if(this.lastSeen){
      var sid = this.props.vehInShipment[row.idx];
      if(sid){
        return this.props.journeys[sid];
      }
      else return "--";
    }
    else return "--";
  }
  setIcon(image){
     return (<div><img src={image} alt=""/></div>)
  }

  getIcon = (row)=>{
    if(this.lastSeen && row.original){
      if(row.original.did){
        var lastseen = this.lastSeen[row.original.did];
        if(lastseen){
          var did = lastseen.devid;
          var vid = this.props.deviceHashmap[did];
          var status = this.props.vehicleStatus[vid];
          if(status){
            var sendIcon = "";
            if(status === VEH_SHIP_STATS.AT_SRC_START || status === VEH_SHIP_STATS.AT_SRC_END){
              if(lastseen.ignition) sendIcon = this.setIcon(greyArrow);
              else sendIcon = this.setIcon(greyMarker);
            }
            else if(status === VEH_SHIP_STATS.ENROUTE_DEST || status === VEH_SHIP_STATS.AT_DEST){
              if(lastseen.ignition) sendIcon = this.setIcon(greenArrow);
              else sendIcon = this.setIcon(greenMarker);
            }
            else if(status === VEH_SHIP_STATS.ENROUTE_SRC){
              if(lastseen.ignition) sendIcon = this.setIcon(blueArrow);
              else sendIcon = this.setIcon(staticMarker);
            }
            return sendIcon;
          }
          else {
            if(lastseen.ignition) return this.setIcon(greyArrow);
            else return this.setIcon(greyMarker);
          }
        }
      }
      else return "--";//this.setIcon(greyMarker);
    }
    return "--";
  }
  _getCustomersColumns = () => {
    var tableStyle = { borderRight: '1px solid rgba(0,0,0,0.09)', borderBottom: '1px solid rgba(0,0,0,0.09)'};
    var headerStyle = { boxShadow: "none", fontSize: "10px"};
    return [
      {
        Header: 'Total Vehicles (Count : ' + this.props.vehicles.length + ')', headerStyle: { marginTop: '4px'},
        columns: [
          { Header: 'MAKE & MODEL', minWidth: 100, id: 'vmk', filterable: true, filterMethod: this._containsFilter, accessor: (d)=> (d.vmk || "") + " " + (d.mo || ""), style: tableStyle, headerStyle: headerStyle },
          { Header: 'REG. NUMBER', minWidth: 100, id: 'reg', filterable: true, filterMethod: this._containsFilter, accessor: (d) => (d.treg || d.reg), style: tableStyle, headerStyle: headerStyle  },
          { Header: 'ON ROUTE',minWidth: 120, id: 'trips', filterable: true, filterMethod: this._containsFilter, accessor: (d) => this.trips(d), style: tableStyle, headerStyle: headerStyle},
          { Header: 'IGNITION',minWidth: 80, id: 'ign',  filterable: true, filterMethod: this._containsFilter, accessor: (d)=> this.ignitionState(d), style: tableStyle, headerStyle: headerStyle},
          {
            Header: 'CURRENT SPEED', minWidth: 80, id: 'spd1', filterable: true, filterMethod: this._containsFilter, accessor: (d) => this.currentSpeed(d), style: tableStyle, headerStyle: headerStyle,
            Cell: props => <div> {this.currentSpeed(props.original) ? this.currentSpeed(props.original) + " km/hr" : "--"}</div>,
          },
          { Header: 'LAST SEEN',minWidth: 120, id: 'lstseen', filterable: true, filterMethod: this._containsFilter,
            accessor: (d)=> {return this.lastSeen ? this.readableTs(d.did || d.idx) : "0"}, Cell: this.lastSeenTS, style: tableStyle, headerStyle: headerStyle},
          { Header: 'STATUS',minWidth: 40, id: 'status', filterable: false, filterMethod: this._containsFilter, Cell: this.getIcon , style: tableStyle, headerStyle: headerStyle},
        ]
      }
    ]
  }

  readableTs(key){
    if(this.lastSeen && key && this.lastSeen[key] && this.lastSeen[key].ts){
      return StampTODate(this.lastSeen[key].ts * 1000) + " " + timeFormat(this.lastSeen[key].ts * 1000,true)
    }
    else return 0;
  }

  _containsFilter(filter, row) {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? (String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) : true;
  }
  _getCustomCellStyle() {
    return {
      'style': {
        'textAlign': 'center',
        'whiteSpace': 'pre-line'
      }
    }
  }
  render(){
    return(
      <Col lg={12} xs={12} md={12} >
        { this.props.vehicles ? this.showDetails(this.props.vehicles) : null }
      </Col>
    )
  }
}