import React from 'react'
import { browserHistory } from 'react-router';
import { logout } from '../../lib/common';

export function _changeUrl(vehicles, selectedMenu, loc) {
  var newUrl;
  if (vehicles && vehicles.length) {
    newUrl = selectedMenu.link;
    if (loc.query && loc.query.vid) {
      if (Array.isArray(loc.query.vid)) {
        loc.query.vid.forEach((d, i) => {
          if (i === 0) newUrl += "?vid=" + d;
          else newUrl += "&vid=" + d;
        });
      } else newUrl += "?vid=" + loc.query.vid;

      if ((selectedMenu.nm === "Daily_Rides_Drives" || selectedMenu.nm === "Statistics" || selectedMenu.nm === "Alerts")
        && loc.query && loc.query.from && loc.query.to) {
        newUrl += "&from=" + loc.query.from + "&to=" + loc.query.to;
      }
    }
    else {
      if ((selectedMenu.nm === "Daily_Rides_Drives" || selectedMenu.nm === "Statistics" || selectedMenu.nm === "Alerts")
        && loc.query && loc.query.from && loc.query.to) {
        newUrl += "?from=" + loc.query.from + "&to=" + loc.query.to;
      }
    }
  }
  else newUrl = selectedMenu.link;
  return newUrl;
}

export function menuClick(length, menu, selNmArr, loc, cb) {
  if (menu && menu.submenu) {
    if (selNmArr.includes(menu.nm)) {
      selNmArr = selNmArr.filter(nm => nm !== menu.nm);
      length -= menu.submenu.screens.length;

      if (menu.submenu.screens && menu.submenu.screens.length) {
        menu.submenu.screens.forEach(sub => {
          if (selNmArr.includes(sub.nm)) {
            if (sub.submenu && sub.submenu.screens && sub.submenu.screens.length) {
              selNmArr = selNmArr.filter(nm => nm !== sub.nm);
              length -= sub.submenu.screens.length;
            }
          }
        })
      }
    }
    else {
      selNmArr.push(menu.nm);
      length += menu.submenu.screens.length;
    }
    cb(selNmArr, false, length);
  } else {
    if (loc) {
      var newUrl = _changeUrl(global.bikeDetails, menu, loc);
      if (menu.nm === "Signout") logout();
      if (global.bikeDetails.length) browserHistory.push(newUrl);  
      cb(null, true, length);
    } else {
      if (menu.link) {
        if (global.bikeDetails.length) browserHistory.push(menu.link);
      }
      cb(null, true, length);
    }
  }
}

export function calManuLength(screens, selNmArr, length) {
  if (screens && screens.length) {
    var rootMenu = screens.find(m => selNmArr.includes(m.nm));
    if (rootMenu && rootMenu.submenu && rootMenu.submenu.screens && rootMenu.submenu.screens.length) {
      length += rootMenu.submenu.screens.length;
      return calManuLength(rootMenu.submenu.screens, selNmArr, length);
    } else return length;
  }
}

export const DrawerToggleButton = props => (
  <button className="toggle-button" onClick={() => props.click()}>
    <div className="toggle-button__line" />
    <div className="toggle-button__line" />
    <div className="toggle-button__line" />
  </button>
)

