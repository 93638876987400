import Request from '../lib/net';
import { browserHistory } from 'react-router';
const conf = require("../config").config;
const AvroCodec = require('avroschema');
const crmhostname = require('../config').config.crmhostname;
const glHostName = require('../config').config.glHostName;
const logout = require('./common').logout;

const SHORT_PING_DUR = 5000;
const LONG_PING_DUR = 30000;

function arrayBufferToString(buffer){
  var arr = new Uint8Array(buffer);
  var str = String.fromCharCode.apply(String, arr);
  if(/[\u0080-\uffff]/.test(str)){
    throw new Error("this string seems to contain (still encoded) multibytes");
  }
  return str;
}

export class WebSocketClient {
  constructor(){
    this.autoReconnectInterval = 2*1000; // 2 sec
    this.retryJob = null;
    this.actionMap = {};
    this.isInitialised = false;
    this.pingCounter = 0;
    this.resetPing = this.resetPing.bind(this);
    this.checkIsLoggedIn()
    .then(this.checkUserSettings)
    .then(this.onConnect)
    .catch((err) => console.log(err));
    this.onWSRequest = this.onWSRequest.bind(this);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.WS_REQ, this.onWSRequest);
    global.EE.addEventListener(global.EE.EVENT_TYPES.WS_REQ, this.onWSRequest);
  }

  onConnect=()=>{
    clearTimeout(this.retryJob);
    this.pingCounter = 0;
    var url = conf.wshost +'/wss?cookie=' + this.GetCookie() + "&fromWeb=" + true + "&reqver=" + 12.21;
    if(this.isTPDevice) url += "&isTP=true";
    this.ws = new WebSocket(url);
    this.ws.binaryType = 'arraybuffer';
    this.ws.onmessage = (evt)=>{
      this.resetPing();
      var resp;
      var isString = typeof evt.data === 'string';
      if(isString){
        if(evt.data.length === 1){
          this.startPingPong(LONG_PING_DUR);
          return;
        }
        try{
          resp = JSON.parse(evt.data);
        }
        catch(e){
          console.log('error',e);
        }
        var callback;
        if(!resp.schema){
          if(resp.a === "ready"){
            global.wsOnline = true;
            global.EE.emitEvent(global.EE.EVENT_TYPES.WS_ONLINE_STATE, true);
          }
          else if(resp.a === "bikes") {
            global.bikeDetails = resp.d;
            global.EE.emitEvent(global.EE.EVENT_TYPES.BIKES, resp.d);
          }
          else if(resp.a === "liveRides") {
            global.EE.emitEvent(global.EE.EVENT_TYPES.LIVE_RIDES, resp);
            callback = this.actionMap['getLiveRides'];
          }
          // else if(resp.a === "startRide") global.EE.emitEvent(global.EE.EVENT_TYPES.START_RIDES, resp.d);
          // else if(resp.a === "stopRide")  global.EE.emitEvent(global.EE.EVENT_TYPES.STOP_RIDES, resp.d);
          else if(resp.a === "notif")  {
            global.EE.emitEvent(global.EE.EVENT_TYPES.NOTIF, resp.d);
          }
          else if(resp.a === "tpdevices") {
            global.EE.emitEvent(global.EE.EVENT_TYPES.TP_DEVICES, resp.d);
            global.tpDevDetails = resp.d;
          }
          else if(resp.a === "tp_rt_evt") callback = this.actionMap['subtpdev'];
          else if(resp.a === "tplastSeen") callback = this.actionMap['getLastSeenForTPDev'];
          else if(resp.a === "lastSeen") callback = this.actionMap['getLastSeen'];
          else if(resp.a === "kick") this.actionOnKick();
          else if(resp.a === "jrnyStatChange") global.EE.emitEvent(global.EE.EVENT_TYPES.VEHSTAT, resp.d);
          if(callback) callback(resp);
        }
      }
      else{
        resp = AvroCodec.Decode(evt.data);
        switch(resp.schema){
          case AvroCodec.SCHEMA.A_EVENT_REAL_TIME:
            callback = this.actionMap['subdev'];
            if(callback) callback(resp);
            global.EE.emitEvent(global.EE.EVENT_TYPES.SUB_DEV, resp);
            break;
          case AvroCodec.SCHEMA.A_RES_FILTER:
          case AvroCodec.SCHEMA.ALB_RES_FILTER:
            callback = this.actionMap["custRideEvents"];
            if(callback){
              callback(resp);
              break;
            }
            global.EE.emitEvent(global.EE.EVENT_TYPES.GET_RIDE, resp);
            break;
          case AvroCodec.SCHEMA.ALB_EVENT_REAL_TIME:
            callback = this.actionMap["getProRunningRides"];
            if(callback) callback(resp);
            break;
          default: console.log("Unexpected schema:" + resp.schema);
        }

        if(!resp){
          var str = arrayBufferToString(evt.data);
          console.log('got text in arraybuffer', str);
          resp = JSON.parse(evt.data);
        }
      }
    };
    this.startPingPong(SHORT_PING_DUR);
    this.ws.onclose = (e)=>{
      switch (e.code){
        case 1000:  // CLOSE_NORMAL
          console.log("WebSocket: closed");
          break;
        default:  // Abnormal closure
          this.reconnect(e);
          break;
      }
    };
  }

  actionOnKick(){
    if(global.gWebSocket) global.gWebSocket.closeWS();
    logout(true);
    browserHistory.push('/webapp/');
  }

  resetPing(){
    this.gotPong = true;
    this.pingCounter = 0;
  }

  startPingPong(duration){
    clearTimeout(this.pingPongTimeout);
    this.pingPongTimeout = setTimeout(()=>{
      this.pingCounter += 1;
      if(this.pingCounter === 5){
        this.ws.close();
      }
      else{
        if(this.gotPong) this.startPingPong(duration);
        else this.startPingPong(SHORT_PING_DUR);
        if(this.ws && this.ws.readyState === 1){
          this.ws.send('p');
          this.gotPong = false;
        }
      }
    },duration);
  }

  GetCookie(){
    var value = "; " + document.cookie;
    var parts = value.split("connect.sid=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  SendRequest = (obj) =>{
    this.actionMap[obj.data.a] = obj.cb;
    if(this.ws && this.ws.readyState === 1){
      this.ws.send(JSON.stringify(obj.data));
      return true;
    }
    else return false;
  }

  closeWS(){
    clearTimeout(this.retryJob);
    this.ws.onclose = function () {};
    this.ws.close();
    global.EE.removeEventListener(global.EE.EVENT_TYPES.WS_REQ, this.onWSRequest);
  }

  onWSRequest(obj){
    if(obj instanceof ArrayBuffer && this.ws.readyState === 1){
      this.ws.send(obj);
    }
  }
  checkIsLoggedIn = ()=>{
    return new Promise((resolve, reject) => {
      var url = crmhostname + "/crm/users/isloggedin";
      Request.GetRequest(url, (resp)=>{
        if(resp.status === 204 || resp.status === 401) this.isInitialised = false;
        else this.isInitialised = true;
        resolve();
      });
    });
  }

  checkUserSettings = ()=>{
    return new Promise((resolve, reject) => {
      var url = glHostName + "/gl/users/users/getusersett";
      Request.GetRequest(url, (resp)=>{
        var data = JSON.parse(resp.response).data;
        if(resp.status === 200){
          if(data && data.tpdev){
            this.isTPDevice = true;
          }
        }
        resolve();
      });
    });
  }

  reconnect(e){
    if(!this.isInitialised) return;
    console.log("WebSocketClient: reconnecting...");
    clearTimeout(this.retryJob);
    this.retryJob = setTimeout(()=>{
      this.onConnect();
    }, this.autoReconnectInterval);
  }
}

// module.exports = {
//   WebSocketClient: WebSocketClient
// };
