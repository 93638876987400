import { Component } from 'react';
import PropTypes from 'prop-types';
import {connectIndexedDB} from '../lib/db/common';

export default class HomePage extends Component {
  constructor(props){
    super(props);
    connectIndexedDB((e, d) => {});
  }
  render() {
    return (
      this.props.children
    )
  }
}

HomePage.propTypes = {
  children: PropTypes.element.isRequired
}
