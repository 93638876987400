import React, { Component } from 'react';
import Unauthorized from './unauthorized';
import Request from '../lib/net';
import EventEmitter from '../lib/eventEmitter';
import PropTypes from 'prop-types';
import Authorized from './authorized';
var crmhostname = require('../config').config.crmhostname;

export default class ChkUnauthorized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authfail: null
    }
    if (!global.EE) {
      global.EE = new EventEmitter();
    }
  }
  componentDidMount() {
    var url = crmhostname + "/crm/users/isloggedin";
    Request.GetRequest(url, (resp)=>{
      if(resp.status === 204 || resp.status === 401){
        this.setState({
          authfail: true
        });
      }
      else {
        this.setState({
          authfail: false
        });
      }
    })
  }
  render() {
    return (
      this.state.authfail ? <Unauthorized location={this.props.location} router={this.props.router} /> :
        this.state.authfail === null ? null :
          <Authorized router={this.props.router} location={this.props.location} children={this.props.children} />
    )
  }
}
ChkUnauthorized.propTypes = {
  children: PropTypes.element.isRequired
}