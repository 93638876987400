const NET = require('../lib/net.js');
const crmhostname = require('../config.js').config.crmhostname;

export function GetAPIKey() {
  const url = crmhostname + '/payments/razorpay/get_key';
  return new Promise((resolve, reject) => {
    NET.GetRequest(url, (resp) => {
      let data = resp.response ? JSON.parse(resp.response) : null;
      if (resp.status !== 200) reject(data);
      else resolve(data && data.data ? data.data : null);
    });
  });
}

export function MyAccountDetails() {
  const url = crmhostname + '/crm/users/users/getUserDetails';
  return new Promise((resolve, reject) => {
    NET.GetRequest(url, (resp) => {
      let data = resp.response ? JSON.parse(resp.response) : null;
      if (resp.status !== 200) reject(data);
      else resolve(data && data.data ? data.data : null);
    });
  });
}

export function RenewSubscription(key, userDetls, veh ,subs, cb) {
  let url = crmhostname + "/payments/razorpay/initiateMobileTransaction";
  var updusrdtl = userDetls;
  if(veh)
    updusrdtl.veh = veh;
  if(subs){
    updusrdtl.subs = subs;
    updusrdtl.selectedPlan = subs.selectedPlan;
  }

  NET.PostRequest(url, updusrdtl, (resp) => {
    let data = JSON.parse(resp.response).data;
    if (resp.status !== 200) cb(data, null);
    else {
      // Goto manual checkout page
      if(key && resp.status === 200){
        // var resp = JSON.parse(xhttp.responseText).data;
        if(data.order){
          ManualRazorPayCheckout(key, data, userDetls, veh, subs, cb);
        }else{
          cb("Sorry there seems to be some problem, please try again later.", null);
        }
      }
    }
  });
}


function ManualRazorPayCheckout(key, resp, userDetls, veh, subs, cb){
  try{
    var options = {
      key: key,
      protocol: 'https',
      hostname: 'api.razorpay.com',
      name: 'AJJAS',
      handler: function (response){
        // POST Request
        let url = crmhostname + "/payments/razorpay/verifyTransaction";
        var tr_data = {
          resp: response,
          tr: resp.tr
        };

        NET.PostRequest(url, tr_data, (resp) => {
          let data = JSON.parse(resp.response).data;
          if (resp.status !== 200) cb(data, null);
          else {
            if(resp.status === 200){
              cb(null, "Payment Done successfully. Your Razorpay Referrence transaction id is " + tr_data.resp.razorpay_payment_id + " and Ajjas transaction id is " + tr_data.tr.tr_id);
            }else{
              cb("There is some issue with processing your payment", null);
            }
          }
        });
      },
      order_id: resp.order.id,
      modal: {
        escape: false,
        backdropclose: false,
        ondismiss: function(){
          // POST Request
          let url = crmhostname + "/payments/razorpay/cancelTransaction";

          NET.PostRequest(url, resp.tr, (resp) => {
            let data = JSON.parse(resp.response).data;
            if (resp.status !== 200) cb(data, null);
            else {
              if(resp.status === 200){
                cb("You've cancelled the transaction", null);
              }
            }
          });
        }
      },
      prefill: {
        name: (userDetls.fn || ""),
        email: (userDetls.eml || ""),
        contact: (userDetls.cmob || "")
      },
      notes: {
        tr_id: resp.tr.tr_id,
        nm: (userDetls.fn || ""),
        eml: (userDetls.eml || "")
      },
      theme: {
        color: '#0497e0',
        emi_mode: false
      },
      callback_url: crmhostname + "/payments/razorpay/verifyMobileTransaction?tr_id=" + resp.tr.tr_id
    };
    var rzpay = new window.Razorpay(options);
    rzpay.open();
  }catch(e){
    cb(e, null)
  }
}
