import React from 'react';
import Net from '../lib/net';
import { User } from '../generic/genericFunctions';
import { withScriptjs, withGoogleMap, GoogleMap, Marker,TrafficLayer,Polyline } from "react-google-maps";
import { parseMSIntoReadableTime } from '../lib/dtconv';
import EventEmitter from '../lib/eventEmitter';
const glHostName = require('../config').config.glHostName;
const computeStats = require('../lib/geoDistCal').computeStats;
const markerA = require('../images/greenMarker_s.png');
const markerB = require('../images/redMarker_s.png');

export default class ShowRideDetails extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      respText: ""
    }
    this.path = [];
    this.lastPing = null;
    if(!global.EE) global.EE = new EventEmitter();
  }
  componentDidMount(){
    if(this.props.location && this.props.location.query && this.props.location.query.idx){
      var url = glHostName + "/gl/users/rides/getcustomrides?idx=" + this.props.location.query.idx;
      Net.GetRequest(url, (resp)=>{
        var response = JSON.parse(resp.response).data;
        if(resp.status !== 200){
          this.setState({
            respText: response
          })
          return;
        }
        if(response && response[0]){
          this.rideDetails = response[0];
          this.getRideData(this.rideDetails);
        }
        else this.setState({ respText: "No ride found"})
      })
    }
    else{
      this.setState({
        respText: "Mandatory fields are missing"
      })
    }
  }
  getRideData(){
    if(global.wsOnline){
      this.onWSReady(true);
    }
    else if(global.EE) global.EE.addEventListener(global.EE.EVENT_TYPES.WS_ONLINE_STATE, this.onWSReady);
  }
  componentWillUnmount(){
    global.EE.removeEventListener(global.EE.EVENT_TYPES.WS_ONLINE_STATE, this.onWSReady); 
  }
  onWSReady = (state, str)=>{
    if(!state) return;
    this.path = [];
    global.gWebSocket.SendRequest({
      data: { a: "custRideEvents" , d: { idx: this.rideDetails.idx}},
      cb: (d)=> {
        if(d.obj && d.obj.ts){
          this.path.push(d.obj);
        }
        else{
          this.lastPing = d.obj;
          var stats = computeStats(this.path);
          if(stats){
            stats.dur = stats.edt - stats.sdt;
            stats.avs = ((stats.dst / stats.dur) * (18 / 5)) || 0;
          }
          this.rideDetails.stats = stats;
          this.path = this.path.sort((a,b)=> { return a.ts - b.ts});
          this.forceUpdate();
        }
      }
    })
  }
  _onChange(latlng, zoom){
    this.setState({
      zoom: zoom,
      center: this._ref.getCenter()
    })
  }
  handleMapMounted = (map)=>{
    if(this.mapMounted) return;
    var bounds = new window.google.maps.LatLngBounds();
    this.path.forEach((path)=>{
      bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
    })
    this._ref.fitBounds(bounds);
    this.mapMounted = true;
  }
  mapDisplay(){
    var latlng = {lat: this.path[0].lat, lng: this.path[0].lng};
    return(
      <div className="margin-bottom15 horz-line-bottom map">
        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCVvRjaFS47dyaTJZKagTFZS_hUmIbrwaI"
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '600px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          center={this.state.center}
          zoom={this.state.zoom}
          onZoomChanged={()=>this._onChange(latlng,this.state.zoom)}
          pathTrail={this.path}
          regionA={latlng}
          regionB={{lat: this.path[this.path.length - 1].lat, lng:this.path[this.path.length - 1].lng}}
          onMapIdle={this.handleMapMounted}
          referance={(ref)=> this._ref = ref}
        />
        <div className="clear"/>
      </div>
    )
  }
  viewRideDetails(heading, details){
    return(
      <div className="margin-bottom30">
        <h3 className="text-center font-size20">{heading}</h3>
        {User("Total Distance",details.stats && details.stats.dst ? (details.stats.dst / 1000).toFixed(2) + " km" : details.stats && details.stats.dst === 0 ? "0 km" : "-","searchlabel")}
        {User("Total Duration",details.stats && details.stats.dur ? parseMSIntoReadableTime(details.stats.dur * 1000) + " mins" : details.stats && details.stats.dur === 0 ? "0 mins" : "-","searchlabel")}
        {User("Top Speed",details.stats && details.stats.tps ? details.stats.tps.toFixed(2) + " km/hr" : details.stats && details.stats.tps === 0 ? "0 km/hr" : "-","searchlabel")}
        {User("Max lean-angle",details.stats && details.stats.mang ? details.stats.mang.toFixed(2) + "°" : "0°","searchlabel")}
        {User("Average Speed",details.stats && details.stats.avs ? details.stats.avs.toFixed(2) + " km/hr" : "-","searchlabel")}
        <div className="clear"/>
        {User("Ride Start Time",details.stats && details.stats.sdt ? new Date(details.stats.sdt * 1000).toString().split('GMT')[0] : "-","searchlabel")}
        {User("Ride End Time",details.stats && details.stats.edt ? new Date(details.stats.edt * 1000).toString().split('GMT')[0] :  "-","searchlabel")}
        <div className="clear"/>
      </div>
    )
  }
  render(){
    return(
      <div className="col-lg-12">
        <div className="padding-bottom15">
          {this.rideDetails && this.path.length ? this.mapDisplay() : null}
          {this.rideDetails && this.path.length ? this.viewRideDetails('Statistics', this.rideDetails) : null}
          {!this.path.length && this.lastPing ? <h3 className='text-center'>No Data to show for this ride</h3> : null}
          {this.state.respText ? <h3 className='text-center'>{this.state.respText} </h3> : null}
        </div>
      </div>
    )
  }
}

const MapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    zoom={props.zoom}
    center={props.center}
    onZoomChanged={props.onZoomChanged}
    ref={props.referance}
    onIdle={props.onMapIdle}
    onMapMounted={props.onMapMounted}
    options={{scaleControl:true}}
  >
    <Marker position={props.regionA} icon={{url:markerA,anchor:{x:20,y:40}}} onClick={()=>props._onChange}/>
    <Marker position={props.regionB} icon={{url:markerB,anchor:{x:20,y:40}}} onClick={()=>props._onChange}/>
    {props.trafficCheck ? <TrafficLayer autoUpdate /> : null}
    {props.pathTrail && props.pathTrail.length ? <Polyline path={props.pathTrail} options={{strokeColor: '#217dbb', geodesic: true }} /> : null}
  </GoogleMap>
))