/**
 * This component exports various customized text boxes to be used in forms as inputs
 * TODO: Convert to react-bootstrap elements
 */
import React from 'react';

export class GenericTextBox extends React.PureComponent {
  render() {
    return (
      <div>
        <label> {this.props.label || 'Text'} : </label>
        <input className='form-control' {...this.props} type='text' />
      </div>
    )
  }
}

export class EmailTextBox extends React.PureComponent {
  render() {
    return (
      <div className='row'>
        <div className={this.props.labelclass || 'col-md-12'}>
          <label> {this.props.label || 'Email'}: </label>
        </div>
        <div className={this.props.inputclass || 'col-md-12'}>
          <input className='form-control' {...this.props} type='email' />
        </div>
      </div>
    )
  }
}

export class PasswordTextBox extends React.PureComponent {
  render() {
    return (
      <div className='row margin-bottom15'>
        <div className={this.props.labelclass || 'col-md-12'}>
          <label> {this.props.label || 'Password'}: </label>
        </div>
        <div className={this.props.inputclass || 'col-md-12'}>
          <input className='form-control' {...this.props} type='password' />
        </div>
      </div>
    )
  }
}

export class NumberTextBox extends React.PureComponent {
  render() {
    return (
      <div className='row'>
        <label> {this.props.label || 'Number'}: </label>
        <input className='form-control' {...this.props} type='number' />
      </div>
    )
  }
}
