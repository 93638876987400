
function sphericalCosinus(lat1, lon1, lat2, lon2){
  var radius = 6371e3; // meters
  var dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);
  var distance = Math.acos(Math.sin(lat1) * Math.sin(lat2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.cos(dLon)) * radius;
  return isNaN(distance) ? 0 : distance;
}

function toRad(n) { return n * Math.PI / 180; }

function computeStats(data, accumuloData){
  try{
    if(!data || !data.length) return;
    data = data.sort((a, b) => {
      return a.ts - b.ts;
    });
    var initialLat = data[0].lat
    , initialLon = data[0].lng
    , totalDis = 0
    var tps = 0;
    var mang = 0;
    var engOnTs = 0;
    var ignOnDur = 0;
    var ignOnCnt = 0;
    var i = 0;
    for(i = 1; i < data.length; i++){
      var newlat = data[i].lat;
      var newlon = data[i].lng;
      totalDis += sphericalCosinus(initialLat, initialLon, newlat, newlon);
      initialLat = newlat;
      initialLon = newlon;

      if(tps < data[i].spd)
        tps = data[i].spd;

      if(data[i].ang < 45 && mang < data[i].ang)
        mang = data[i].ang;

      if(data[i].ignition && data[i - 1].ignition){
        if((data[i].ts - data[i - 1].ts) < 120) ignOnCnt += (data[i].ts - data[i - 1].ts);
      }

      if(data[i].ignition && i !== data.length - 1){
        if(!engOnTs) engOnTs = data[i].ts;
      }
      else if(engOnTs) {
        ignOnDur += (data[i].ts - engOnTs);
        engOnTs = 0;
      }
    }
    var sendData = {
      tps: tps,
      sdt: data[0].ts,
      mang: mang,
      dst: totalDis,
      edt: data[i - 1].ts,
      flt: data[0].lat,
      tlt: data[i - 1].lat,
      fln: data[0].lng,
      tln: data[i - 1].lng,
    }
    if(accumuloData) {
      sendData.ignOnDur = ignOnDur;
      sendData.ignOnCnt = ignOnCnt;
    }
    return sendData;
  }
  catch(e){
    console.log("Error in computeStats: ", e);
  }
}

module.exports = {
  computeStats: computeStats,
  sphericalCosinus: sphericalCosinus
};