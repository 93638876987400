import localforage from "localforage";
var dbconst = {};

export function connectIndexedDB(cb) {
  localforage.config({
    driver: [
      localforage.INDEXEDDB,
      localforage.WEBSQL,
      localforage.LOCALSTORAGE
    ],
    name: 'Glite',
    version: 1
  });
  localforage.ready().then(function () {
    cb(null, localforage.driver());
  }).catch((e) => {
    cb(e, null);
  });
}

export function createStore(name) {
  dbconst[name] = localforage.createInstance({
    name: 'Glite',
    storeName: name,
    version: 1
  });
}

export function setGenericVal(key, value) {
  if (dbconst.hasOwnProperty().length < 0) return Promise.reject();
  return localforage.setItem(key, value);
}

export function getGenericVal(key) {
  if (dbconst.hasOwnProperty().length < 0) return Promise.reject();
  return localforage.getItem(key);
}

export function getFromStore(store, key) {
  if (!dbconst[store]) return Promise.reject();
  return dbconst[store].getItem(key)
}

export function getAllkeys(store) {
  if (!dbconst[store]) return Promise.reject();
  return dbconst[store].keys();
}

export function iterateOverAll(store, cb) {
  return dbconst[store].iterate(cb);
}

export function setToStore(store, key, value) {
  if (!dbconst[store]) return Promise.reject();
  return dbconst[store].setItem(key, value);
}

export function deleteAllInstances() {
  var instArr = [];
  if (!dbconst) {
    return;
  }
  instArr = Object.keys(dbconst);
  instArr.forEach(inst => {
    localforage.dropInstance({
      name: "Glite",
      storeName: inst
    }).then(() => {
    });
  });
}

export function delInstance(dbName, storeName) {
  if (!dbName) {
    return;
  }
  var query = { name: dbName };
  if (storeName) query.storeName = storeName;
  localforage.dropInstance(query).then(() => { });
}

export function delDb(name) {
  localforage.dropInstance({
    name: name
  }).then(() => { dbconst = {} }).catch();
}