import React, { Component} from 'react';
import Net from '../lib/net';
import { setToStore ,createStore} from '../lib/db/common';
import {LandingImage,MobileLogin, EnterPassword, OTP} from './childComponent';
// import  AddVehicle  from './addVehicle';
import { Col,Row}  from 'react-bootstrap/lib';
import Validation from '../lib/validation';
// import EventEmitter from '../lib/eventEmitter';
// import {LandingImage,MobileLogin, EnterDetails, EnterPassword, OTP ,TermAndConditions} from './childComponent';

// var WebSocketClient = require('../lib/ws').WebSocketClient;
var crmhostname = require('../config').config.crmhostname;

export default class LoginPage extends Component {
  constructor(props){
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      errorMsg: "",
      success: "",
      password: false,
      login: true,
      usernm: "",
      otp: false,
      mob: "",
      pwderrorMsg:"",
      fullNameError: " ",
      pwdError: " ",
      otpErrorMsg:"",
      userHandleError: " ",
      countDown: false,
      terms: false,
      joinData: "",
      bikeLength: 0,
      details: false,
      disLoginBtn : false,
      disNextBtn : false,
      disOptlogin : false
    }
    this.fullName = "";
    this.pwd = "";
    this.userHandle = "";
    this.componentStates = [];
  }
  componentWillMount() {
    var url = crmhostname + "/crm/users/isloggedin";
    Net.GetRequest(url,(resp)=>{
      if(resp.status === 200){
        resp = JSON.parse(resp.response);
        if(resp.data === "isLoggedIn") {
          if(this.props.location && this.props.location.query && this.props.location.query.redirect){
            var url = "";
            Object.keys(this.props.location.query).forEach((key) => {
              if(key === 'redirect') url += this.props.location.query[key];
              else url += "&" + key + "=" + this.props.location.query[key];
            });
            this.props.router.replace(url);
          }
          else this.props.router.replace("/webapp/dashboard");
        }
        else this.props.router.replace("/webapp/");
      }
    });
  }
  
  stopInterval(){
    for (var i = 1; i < 999; i++) window.clearInterval(i);
  }

  isNormalInteger(mob){
    return mob !== Infinity && String(mob) === mob && mob >= 0;
  }
  onSubmit(e){
    if( !e || !Validation.MobVal(e)){
      this.setState({ errorMsg: "Invalid Mobile Number" });
      return;
    }
    else{
      this.componentStates.pop();
      this.setState({ errorMsg: "" , disLoginBtn : true});
      var params = { cmob: "+91" + e ,isd: "+91"};
      var url = crmhostname + "/crm/users/users/verifwithmob";
      Net.PostRequest(url, params, (resp) => {
        var data = JSON.parse(resp.response).data;
        if (resp.status === 200) {
          if(data && data.mbv === true && data.pwd === true){
            
            // Set user type based on prod
            let prod = data.prod || [];
            if (prod.includes(3)) window.localStorage.setItem("usrTyp", "3");
            else window.localStorage.setItem("usrTyp", "1");
            this.componentStates.push({login: true, mbv: false });
            
            this.setState({
              login: false,
              password: data.pwd,
              usernm: data.fn,
              mbv: true,
              mob:e,
              details:false,
              disLoginBtn : false
            });
          }
          else if(data && data.mbv === false){
            this.setState({
              errorMsg: "Mobile number is not verified please signup from App",
              disLoginBtn: false
            })
            // this.componentStates.push({login: true, otp: false,errorMsg:""});
            // this.setState({
            //   login: false,
            //   otp: true,
            //   mob: e,
            //   mbv: data.mbv,
            //   password: data.pwd,
            //   countDown: true,
            //   terms: false
            // });
          }
        }
        else {
          this.setState({ errorMsg: data.msg },()=>{
            setTimeout(()=>{
              this.setState({
                errorMsg: "",
                disLoginBtn : false
              })
            },5000)
          });
        }
      });
    }
  }
  //========OTP function==========
  handleNext(otp){
    if(otp.length === 6){
      this.setState({
        disOptlogin : true
      });
      var params = { cmob: "+91" + this.state.mob , otp: otp, isWeb: true};
      var url = crmhostname + "/crm/users/login"; //change api call
      Net.PostRequest(url, params, (resp) => {
        var data = JSON.parse(resp.response).data;
        if (resp.status === 200) {
          this.setState({
            disOptlogin : false
          });
          createStore('user');
          setToStore('user','name',data.fn + " " + data.ln).then(() =>{
            if(this.props.location && this.props.location.query && this.props.location.query.redirect){
              var url = "";
              Object.keys(this.props.location.query).forEach((key) => {
                if(key === 'redirect') url += this.props.location.query[key];
                else url += "&" + key + "=" + this.props.location.query[key];
              });
              this.props.router.replace(url);
            }else this.props.router.replace("/webapp/dashboard");
            this.stopInterval();
          }).catch(() => {
            return;
          });
        }
        //   // if(data.mbv === true){
        //   //   clearInterval(interval);
        //   //   this.componentStates.push({details: false, login: true, terms: false });
        //   //   this.setState({
        //   //     otp: false,
        //   //     terms: true,
        //   //     errorMsg: ""
        //   //   });
        //   // }
        // }
        else{
          this.setState({ errorMsg: data },()=>{
            setTimeout(()=>{
              this.setState({
                errorMsg: "",
                disOptlogin : false
              })
            },5000)
          });
        }
      })
    }else{
      var errorMsg = "OTP is not Valid"
      if(this.state.otpErrorMsg) errorMsg = this.state.otpErrorMsg;
      this.setState({ errorMsg: errorMsg });
    }
  }


  /* =========Enter Details Function==============
  handleSignupPress(e){
    if(!e.fullName || !Validation.FullNameVal(e.fullName)){
      this.setState({fullNameError: "* Enter Full Name", buttonDisabled: false, apiError: ""});
      return;
    }
    else if(!e.pwd || !Validation.PassVal(e.pwd)){
      this.setState({pwdError: "* Password should be minimum 6 digit", fullNameError: " ", buttonDisabled: false, apiError: ""});
      return;
    }
    else if(!e.userHandle || !Validation.IsUserHandle(e.userHandle)){
      this.isUsrHndleInvalid = true
      this.setState({userHandleError: "*Invalid username ", fullNameError: " ", pwdError: " ", buttonDisabled: false, apiError: ""});
      return;
    }
    else this.setState({fullNameError: " ", pwdError: " ", userHandleError:" "})
    var params = {
      cmob: "+91" + this.state.mob ,
      nm: e.fullName,
      uh: e.userHandle,
      pwd: e.pwd,
      isWeb: true
    };
    var url = crmhostname + "/crm/users/users/signUp";
    Net.PostRequest(url, params, (resp) => {
      var data = JSON.parse(resp.response).data;
      if(data.msg){
        this.setState({ errorMsg: data.msg },()=>{
          setTimeout(()=>{
            this.setState({
              errorMsg: ""
            })
          },5000)
        })
      }
      else{
        window.localStorage.setItem("name", e.fullName)
        this.componentStates.push({password: false, details: true });
        this.setState({
          joinData: data,
          login: false,
          password: false,
          otp: false,
          details: false,
          mbv: true,
          errorMsg: ""
        },()=>{
          browserHistory.push("/addvehicle");
        });
      }
    });
    //api call
  }
  */

  goToLink(e){
    // var te mp = "";
    if(e === "onOTP") {
      this.componentStates.push({password: true, otp: false,countDown: false});
      this.setState({
        password: false,
        otp: true,
        countDown: true
      },()=>{
        var url = crmhostname + "/crm/users/users/sendotpwithoutlogin?cmob=" + encodeURIComponent("+91" + this.state.mob);
        Net.GetRequest(url, (resp) => {
          var data = JSON.parse(resp.response).data;
          if (!resp.status === 200) {
            this.setState({
              countDown: false,
              errorMsg: data
            })
          }
        });
      });
    }
    else if(e === "onFPassword") {
      var params = { cmob: "+91" + this.state.mob};
      var url = crmhostname + "/crm/users/users/forgotmobpassword";
      Net.PostRequest(url, params, (resp) => {
        var data = JSON.parse(resp.response).data;
        if (resp.status === 200) {
          this.setState({
            success: data + " to +91" + this.state.mob,
            errorMsg: ""
          },()=>{
            setTimeout(()=>{
              this.setState({
                success: ""
              })
            },5000)
          })
        }
        else{
          this.setState({
            errorMsg: data,
            success: ""
          },()=>{
            setTimeout(()=>{
              this.setState({
                errorMsg: ""
              })
            },5000)
          });
        }
      });
    }
    else if(e === "onLogin") {
      this.setState({
        password: false,
        login: true
      });
    }else if(e === "resendOtp"){
      this.setState({
        password: false,
        otp: true
      });
      params = { cmob: "+91" + this.state.mob , isd: "+91"};
      url = crmhostname + "/crm/users/users/resendotp";
      Net.PostRequest(url, params, (resp) => {
        var data = JSON.parse(resp.response).data;
        if (resp.status === 200) {
          this.setState({
            countDown: true,
            errorMsg: ""
          })
        }
        else{
          this.setState({
            errorMsg: data.msg,
            countDown: false,
            infoMsg: "If problem persists please contact us in +91 8080364547 or mail us at contact@hpslabdesigns.com."
          });
        }
      });
    }else if(e === "editMob"){
      this.setState({
        otp: false,
        login: true
      });
    }
  }

  handleBackPress(){
    var temp = this.componentStates.pop();
    if(temp) this.setState(temp);
  }

  handleAgree(){
    this.setState({
      terms: false,
      details: true
    });
  }

  onSubmitClick(e){
    this.setState({
      disNextBtn : true
    });
    Net.PostRequest(crmhostname + "/crm/users/login", {
      cmob: "+91" + this.state.mob,
      pwd: e,
      isWeb: true
    }, (resp)=> {
      var data = JSON.parse(resp.response).data;
      if(resp.status === 200){
        this.setState({
          disNextBtn : false
        });
        createStore('user');
        setToStore('user','name', data.fn + " " + (data.ln || "")).then(()=>{
          if(this.props.location && this.props.location.query && this.props.location.query.redirect){
            var url = "";
            Object.keys(this.props.location.query).forEach((key) => {
              if(key === 'redirect') url += this.props.location.query[key];
              else url += "&" + key + "=" + this.props.location.query[key];
            })
            this.props.router.replace(url);
          }else this.props.router.replace("/webapp/dashboard");
        }).catch(() => {
          return;
        });
      }
      else
        this.setState({ pwderrorMsg: data ,disNextBtn : false});
    });
  }

  // onWSReady(wsState){
  //   if(wsState) global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  // }

  // onBikes(bikes){
  //   if(bikes && bikes.length) {
  //     window.localStorage.setItem("bikes", JSON.stringify(bikes));
  //     browserHistory.push("/webapp/dashboard");
  //   }
  //   // else browserHistory.push("/addvehicle");
  // }

  render() {
    return (
      <Row>
        <Col className="hidden-xs hidden-sm hidden-md visible-lg" lg={8} md={8} style={{paddingRight: "0"}}>
          <LandingImage/>
        </Col>
        <Col lg={4} style={{paddingLeft: "0", paddingRight: "0"}}>
          { //=========otp page called==========
            this.state.otp ?
            <OTP mob={this.state.mob ? this.state.mob : null}  errorMsg={this.state.errorMsg ? this.state.errorMsg : null}
            infoMsg={this.state.infoMsg ? this.state.infoMsg : null} disOptlogin={this.state.disOptlogin} onBackPress={()=>this.handleBackPress()}
            handleOtpNext={(e) => this.handleNext(e)} onClickLink={(e) => this.goToLink(e)}
            countDown={this.state.countDown} />
            : null
          }
          {this.state.login ? <MobileLogin disLoginBtn={this.state.disLoginBtn} errorMsg={this.state.errorMsg} onSubmit={(e) => this.onSubmit(e)} />: null}
          {
            this.state.password && this.state.mbv ? <EnterPassword goToLink={(e)=> this.goToLink(e)} disNextBtn={this.state.disNextBtn} errorMsg={this.state.errorMsg}
            pwderrorMsg={this.state.pwderrorMsg} success={this.state.success} getName={this.state.usernm} onSubmitClick={(e)=>this.onSubmitClick(e)}
            onBackPress={()=>this.handleBackPress()}/>: null
          }
          { //=======enter details page============
            /*this.state.details ?
            <EnterDetails  handleSignupPress={(e) => this.handleSignupPress(e)} onBackPress={()=>this.handleBackPress()}
              errorMsg={this.state.errorMsg} fullNameError={this.state.fullNameError ? this.state.fullNameError : null}
              pwdError={this.state.pwdError ? this.state.pwdError : null}
              userHandleError={this.state.userHandleError ? this.state.userHandleError : null} />
            : null*/
          }
          { //=========terms &condition page=============
            //this.state.terms ? <TermAndConditions handleAgree={() => this.handleAgree()} onBackPress={()=>this.handleBackPress()}/>: null
          }
        </Col>
      </Row>
    );
  }
}
