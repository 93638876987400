import React from 'react';
import { browserHistory } from 'react-router';
import { Row, Col, Jumbotron } from 'react-bootstrap/lib';

import { PasswordTextBox } from '../components/inputs/TextBox';
import { SubmitButton, GlyphButton } from '../components/inputs/Buttons';

import { ChangeLoginPassword } from '../services/apiFunnel'

export default class ProfileSettings extends React.Component {

  state = { msg: '' };

  handleChange = (e) => {
    this[e.target.id] = e.target.value;
  }

  handleSubmit = () => {
    this.setState({ msg: 'Processing...' }, () => {

      let err = this._validatePasswordInputs();
      if (err) return this.setState({ msg: err });

      ChangeLoginPassword({ oldPwd: this.oldPwd, newPwd: this.newPwd })
        .then(() => this.setState({ msg: 'Password updated successfully, will apply the next time you login' }))
        .catch((err) => this.setState({ msg: err }));

    });
  }
  _validatePasswordInputs = () => {
    if (!this.oldPwd) return ('Please enter old password');
    if (!this.newPwd) return ('Please enter new password');
    if (!this.newPwdVerify) return ('Please enter new password again for verification');
    if (this.oldPwd === this.newPwd) return ('Old and new passwords cannot be same');
    if (this.newPwd.length < 6) return ('Password must be atleast 6 characters long');
    if (this.newPwd !== this.newPwdVerify) return ('New passwords fields do not match');
    return (0);
  }

  render() {
    return (
      <div>
        <Row>
          <Col sm={4}>
            <GlyphButton glyphicon='menu-left' onClick={() => browserHistory.goBack()}></GlyphButton>
          </Col>
        </Row>
        <br />

        {/* Change Password Block Start */}
        <Jumbotron>
          <h2> Change Password </h2>
          <hr />
          <Row>
            <Col xs={12} md={6}>
              <PasswordTextBox id='oldPwd' placeholder='Current Password' label='Enter Current Password' onChange={this.handleChange} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <PasswordTextBox id='newPwd' placeholder='New Password' label='Enter New Password' onChange={this.handleChange} />
            </Col>
            <Col xs={12} md={6}>
              <PasswordTextBox id='newPwdVerify' placeholder='New Password' label='Enter New Password Again (Verification)' onChange={this.handleChange} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12} md={3}>
              <SubmitButton value='Enter' onClick={this.handleSubmit} />
            </Col>
            <Col sm={12} md={4} mdPull={2}>
              <small>{this.state.msg}</small>
            </Col>
          </Row>
        </Jumbotron>
        {/* Change Password Block End */}
      </div>
    )
  }
}