import React from 'react';
import { Row, Col, Table } from 'react-bootstrap/lib';
import { StampTODate, timeFormat } from '../../lib/dtconv';
import { InfoModal } from '../../generic/input.js';
import { GetVehicleStatus } from "../../lib/db/journeys";

const VEH_SHIP_STAT = {
  "1": "At Source",
  "2": "Enroute to Destination",
  "3": "At Destination",
  "4": "Enroute to Source",
  "5": "At Source",
  "6": "Aborted"
};

export class ViewTrip extends React.Component {

  state = { msg: "", vehicleList: [] };

  componentDidMount() { this.getJourneyPanel(this.props.journeys); }

  onViewVehicle(journeyId) {
    journeyId = parseInt(journeyId, 10);
    let vidArr = this.props.journeys.find(journey => journey.idx === journeyId).vidArr;

    GetVehicleStatus(vidArr, (err, vehicleStats) => {

      if (err) return this.setState({ msg: err });

      let vehicleDetails = this.props.bikes.filter(bike => vidArr.includes(bike.idx));

      let vehicleList = vidArr.map((vid) => { // Data to be shown in modal from details & stats

        let detailsObj = vehicleDetails.find(v => v.idx === vid);
        let statsObj = vehicleStats[vid];

        let vehicleObj = {
          idx: vid,
          reg: detailsObj.reg,
          stat: VEH_SHIP_STAT[statsObj.stat],
          ts: VEH_SHIP_STAT[statsObj.ts]
        };

        return vehicleObj;

      });

      this.setState({ vehicleList });

    });

  }

  getJourneyPanel = (journey) => { //// Generalize for other journey types here

    const rowStyle = { border: "1px solid lightgrey", borderRadius: "5px", margin: "15px", paddingBottom: "15px" };
    const headerClass = "color-b5b5b5 font-size14px margin-top7 font-weight600";
    const dataClass = "color-4a4a4a font-size16px margin-top4 font-weight400";
    return (
      <Row key={journey.idx} style={rowStyle}>
        <Col lg={3} md={4} sm={6} xs={6} >
          <Col xs={12} className={headerClass} >Trip Name</Col>
          <Col xs={12} className={dataClass}>{journey.nm || "--"}</Col>
        </Col>
        <Col lg={3} md={4} sm={6} xs={6} >
          <Col xs={12} className={headerClass}>Route  Name</Col>
          <Col xs={12} className={dataClass}>{journey.idx && this.props.route ? this.props.route[journey.routeId] : "--"}</Col>
        </Col>
        {/* <Col lg={4} md={4} sm={6} xs={6} >
          <Col xs={12} className={headerClass}>Vehicles</Col>
          <Col xs={12}>
            <Button onClick={() => this.onViewVehicle(journey.idx)}>
              <span className="color-00a8e2">View vehicles List</span>
            </Button>
          </Col>
        </Col> */}
        {
          // <Col md={4} xs={6} >
          //   <Col xs={12} className={headerClass}>DISTANCE</Col>
          //   <Col xs={12} className={dataClass}>{journey.desc ? journey.desc : "--"}</Col>
          // </Col>
        }
        <Col lg={3} md={4} sm={6} xs={6} >
          <Col xs={12} className={headerClass}>Start Date</Col>
          <Col xs={12} className={dataClass}>{journey.stdt ? StampTODate(journey.stdt) + " " + timeFormat(journey.stdt) : "--"}</Col>
        </Col>

        <Col lg={3} md={4} sm={6} xs={6} >
          <Col xs={12} className={headerClass}>End Date</Col>
          <Col xs={12} className={dataClass}>{journey.eddt ? StampTODate(journey.eddt) + " " + timeFormat(journey.eddt) : "--"}</Col>
        </Col>

      </Row>
    );

  }

  getVehicleRow(vehicle) {
    return (
      <tr key={vehicle.idx}>
        <td xs={6}>{vehicle.reg}</td>
        <td xs={6}>{vehicle.stat}</td>
        <td xs={6}>{vehicle.ts ? StampTODate(vehicle.ts) + " " + timeFormat(vehicle.ts) : "--"}</td>
      </tr>
    )
  }


  render() {
    return (
      <div>
        {
          this.props.journeys &&
          this.props.journeys.length > 0 &&
          this.props.journeys.map(this.getJourneyPanel)
        }
        <InfoModal show={this.state.vehicleList.length > 0} title="Vehicle Status Logs"
          close={() => this.setState({ vehicleList: [] })}>
          {
            this.state.vehicleList.length > 0 &&
            <Table bordered>
              <thead>
                <tr>
                  <th>Registration Number</th>
                  <th>Status</th>
                  <th>Updated Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {this.state.vehicleList.map(this.getVehicleRow)}
              </tbody>
            </Table>
          }
        </InfoModal>
      </div>
    )
  }

}