/**
 * TODO: Add Search options, add multidropdown
 */
import React from 'react';

/**
 * @class GenericDropdown
 * @requires options Array of options
 * @requires optionkey Key to identify option a value
 * @requires optionval Value to be shown in the list
 * @exports Dropdown A simple list view dropdown
 */
export class GenericDropdown extends React.PureComponent {
  render() {
    return (
      <select {...this.props} className='selectpicker form-control col-sm-4'>
        <option value={this.props.defaultValue} disabled>{this.props.defaultoption}</option>
        {
          this.props.options.map((e, i) => {
            return (
              <option value={e[this.props.optionkey]} key={i}>
                {e[this.props.optionval]}
              </option>
            )
          })
        }
      </select>
    )
  }
}

// For selecting multiple values from a list
// export class MultiDropdown extends React.PureComponent {
//   render() {
//     return (
//     )
//   }
// }
