// Generic
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Row, Col, Alert, ProgressBar, Breadcrumb, Button } from 'react-bootstrap/lib';
import { MENUPATH } from '../lib/enums';
import { LoadingScreen } from '../generic/input';

// Components
import PanelView from '../components/panel/PanelView';
import PanelSection from '../components/panel/PanelSection';
import { StampTODate } from '../lib/dtconv';

// Services
import { GetAPIKey, MyAccountDetails, RenewSubscription } from '../services/apiFunnel';
const PAST_90_DAY_TS = Date.now() - (90 * 86400000);

export default class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.autoOpenRenewal = false;
  }


  componentDidMount() {
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.ACCOUNT_DETAILS);
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    this.setState({
      isLoading: true
    })
    GetAPIKey()
      .then((key) =>
        MyAccountDetails()
          .then((subsdata) => {
            this.subs_plan = subsdata.ajjas_sub_details;
            this.yearlyPlan = this.subs_plan.find(p => p.month === 12 || p.month === 13);
            this.setState({ key, subsdata, isLoading: false })
            if(this.props.location.query.vid && this.props.location.query.selPkg) {
              subsdata.userSettings.glsubs.forEach(d => {
                let veh = subsdata.userSettings.vd.find(vd => { return (d.did && (vd.did === d.did)) });
                if(veh && veh.idx === Number(this.props.location.query.vid)) this.openRenewal(veh, d);
              });
            };
          })
          .catch((e) => { this.setState({ err: e, isLoading: false }) })
      )
      .catch((e) => this.setState({ err: e, isLoading: false }));
  }

  onMenuSelect = (menu) => {
    this.props.router.replace(menu.link);
  }
  openRenewal = (veh, subs) => {
    let updusrdtl = Object.assign({}, this.state.subsdata);
    delete updusrdtl.userSettings;
    RenewSubscription(this.state.key, updusrdtl, veh,subs, (e,d)=>{
      if(e){
        this.autoOpenRenewal = false;
        this.setState({err: e, paydone: null});
      }else{
        this.setState({err: null, paydone: d});
      }
    });
  }

  onPackageSelect = (selPlan, sub) => {
    this.selPlan = selPlan;
    var allBtns = document.getElementsByClassName('renewBtn');
    let allSubs = this.state.subsdata.userSettings.glsubs;
    if (this.yearlyPlan && allBtns.length) {
      for (let i = 0; i < allBtns.length; i++) {
        allSubs[i].selectedPlan = this.yearlyPlan.idx;
        allBtns[i].innerHTML = 'Renew Subscription at ₹ ' + this.yearlyPlan.amt + '/- for ' + this.yearlyPlan.month + ' months';
      }
    }
    document.getElementById(sub.idx).innerHTML = 'Renew Subscription at ₹ ' + selPlan.amt + '/- for ' + selPlan.month + (selPlan.month === 1 ? ' month' : ' months');
    sub.selectedPlan = selPlan.idx;
  }

  getSelectedPlan = (userDets, getAmount, getMonth) => {
    let selPlan = userDets.ajjas_sub_details.find(plan => plan.idx === Number(this.props.location.query.selPkg))
    if(getAmount) {
      if(selPlan) return selPlan.amt;
      else return (userDets.ajjas_sub_details[userDets.ajjas_sub_details.length - 1]).amt;
    } else if(getMonth) {
      if(selPlan) return selPlan.month;
      else return (userDets.ajjas_sub_details[userDets.ajjas_sub_details.length - 1]).month;
    } else {
      if(selPlan) return selPlan.idx;
      else return (userDets.ajjas_sub_details[userDets.ajjas_sub_details.length - 1]).idx;
    }
  }

  render() {
    let s = this.state.subsdata;
    return (
      <Row>
        {this.state.isLoading ? <LoadingScreen /> : null}
        <Col xs={12} md={10}>
          <Breadcrumb>
            <Breadcrumb.Item componentClass='span'><Link to='/webapp/dashboard'>Dashboard</Link></Breadcrumb.Item>
            <Breadcrumb.Item active>Account Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs={12} md={10}>
          {
            (s && s.userSettings && (s.userSettings.atyp === 3)) ?
              ((s.userSettings) && (s.userSettings.vd))
                ?
                <PanelView bsStyle='success' heading='Account Details'>
                  <PanelSection heading='My Subscriptions'>
                    <Row>
                      {
                        s.userSettings.glsubs && s.userSettings.glsubs.length ?
                          s.userSettings.glsubs.map((d, i) => {
                            let vehicle = s.userSettings.vd.find(vd => { return (d.did && (vd.did === d.did)) });
                            var device = s.userSettings.gldev.find(dev => { return (d.did && (dev.idx === d.did)) });
                            d.selectedPlan = ((vehicle && Number(this.props.location.query.vid) === vehicle.idx) ? this.getSelectedPlan(s) : this.yearlyPlan.idx);
                            return (
                              vehicle ?
                                <Col key={i} xs={12} md={12} lg={12}>
                                  <Col xs={12} md={12} lg={12} className="bold">
                                    <p>
                                      <b>{vehicle.vmk + (vehicle.mo ? (", " + vehicle.mo) : "") + (vehicle.reg ? (", " + vehicle.reg) : "")}</b><br />
                                    </p>

                                    {
                                      d.sed ?
                                        <div>
                                          {
                                            (d.sed - Date.now()) > -1 ?
                                              <div>
                                                <div> Your Ajjas Device Subscription has <b> {(Math.floor((d.sed - Date.now()) / (1000 * 60 * 60 * 24)))}</b> Days Remaining.</div>
                                                <div> Device Subscription Renewal Date <b> {StampTODate(d.sed)}</b></div>
                                              </div>
                                              :
                                              <div>
                                                <div> Your Ajjas Device Subscription has expired <b>{(Math.floor((Date.now() - d.sed) / (1000 * 60 * 60 * 24)))}</b>  Days Ago.</div>
                                                <div> Device Subscription Expiry Date <b>{StampTODate(d.sed)}</b></div>
                                              </div>
                                          }

                                          {
                                            device && device.blocked === 2 && device.dcTs && device.dcTs <= PAST_90_DAY_TS ?
                                              <a href="tel:9826428400">
                                                <Button variant="primary" className="btn btn-success" size="lg">
                                                  Call us : +91 982 642 8400
                                                </Button>
                                              </a>
                                              :

                                              this.subs_plan && this.subs_plan.length ?
                                                <div>
                                                  <div><h4>Select Subcription Plan</h4>

                                                    {
                                                      this.subs_plan.map(p => {
                                                        return (
                                                          <h5 key={p.idx}>
                                                            <input type="radio" value={p.idx} defaultChecked={((Number(this.props.location.query.vid) === vehicle.idx) && (Number(this.props.location.query.selPkg) === p.idx)) ? true : false} name="sub_plan" onChange={(e) => this.onPackageSelect(p, d)} />
                                                            {" " + p.nm + " ( ₹" + p.amt + "/- )"}
                                                          </h5>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                  <button className='btn btn-large btn-primary renewBtn' id={d.idx} type='button' data={this.yearlyPlan} onClick={(e) => this.openRenewal(vehicle, d)}>
                                                    Renew Subscription at ₹ {(Number(this.props.location.query.vid) === vehicle.idx) ? this.getSelectedPlan(s, true) : this.yearlyPlan.amt}/- for {(Number(this.props.location.query.vid) === vehicle.idx) ? this.getSelectedPlan(s, false, true) : this.yearlyPlan.month} months
                                                  </button>
                                                  { i < s.userSettings.glsubs.length - 1 ? <hr/> : null }
                                                </div>
                                                : null
                                          }
                                        </div>
                                        : null
                                    }
                                  </Col>
                                </Col>
                                : null
                            )
                          })
                          : null
                      }
                    </Row>
                  </PanelSection>
                </PanelView>
                :
                this.state.err
                  ? <Alert className='text-center font-bold' bsSize='medium' bsStyle='danger'>{this.state.err}</Alert>
                  : <ProgressBar active now={100} label='Loading, Please wait...' />
              :null
          }
          <Col xs={12} md={12}>
            {
              this.state.err
                ? <Alert className='text-center font-bold' bsSize='medium' bsStyle='danger'>{this.state.err}</Alert>
                : null
            }
          </Col>
          <Col xs={12} md={12}>
            {
              this.state.paydone
                ? <Alert className='text-center font-bold' bsSize='medium' bsStyle='success'>{this.state.paydone}</Alert>
                : null
            }
          </Col>
        </Col>
      </Row>
    );
  }
}
AccountDetails.propTypes = {
  router: PropTypes.object
}
