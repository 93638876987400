import React, { Component } from 'react';
import DailyRidesTiles from './dailyRideTiles';
import { Col, Row } from 'react-bootstrap/lib';
import { VehMultiDropDown } from '../inputs/vehMultiSelect';
import { LoadingScreen, DateInput } from '../../generic/input';
import { GetDateFromNow } from '../../lib/dtconv';
import { ONE_DAY_TS, MENUPATH } from '../../lib/enums';
import { isVehValid, _setNewUrl, getVidArray, isValidFromTS, isValidToTS } from '../../lib/common';
const LAST_DAY_TS = Date.now() - (31 * ONE_DAY_TS);

export class DailyRidesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tileClick: false,
      isLoading: true,
      from: "",
      to: "",
    }
    this.vehMap = {};
    this.proVehs = false;
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.addEventListener(global.EE.EVENT_TYPES.TP_DEVICES, this.onTPDevices);
  }

  componentDidMount() {
    if (global.wsOnline) this.onBikes(global.bikeDetails);
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.DAILY_RIDES);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.TP_DEVICES, this.onTPDevices);
  }

  setDateInURL() {
    var newUrl ;
    if (this.props.location) {
      var loc = this.props.location;
      this.setState({
        from: loc.query && loc.query.from && isValidFromTS(parseInt(loc.query.from), LAST_DAY_TS) ? parseInt(loc.query.from) : GetDateFromNow(4) * 1000,
        to: loc.query && loc.query.to && isValidToTS(parseInt(loc.query.to), new Date().setHours(23, 59, 59, 999)) ? parseInt(loc.query.to) : new Date().setHours(23, 59, 59, 999)
      }, () => {
        if (loc.query && loc.query.vid) {
          var vidArr = getVidArray(loc.query.vid);
          if (this.vehicles && vidArr && vidArr.length) this.validVeh = isVehValid(this.vehicles, vidArr);
          if (this.validVeh) {
            newUrl = _setNewUrl(loc, vidArr, this.state.from, this.state.to);
            this.props.router.replace(newUrl)
          }
        } else if(this.vehicles && this.vehicles.length){
          newUrl = _setNewUrl(loc, null, this.state.from, this.state.to);
          this.props.router.replace(newUrl)
        }
      });
    }
  }

  onTPDevices = (devices) => {
    global.tpDevDetails = devices;
    this.forceUpdate();
  }

  onBikes = (bikes) => {
    this.vehicles = bikes;
    if (bikes && bikes.length) {
      bikes = bikes.filter(veh => {
        this.vehMap[veh.idx] = veh.mo + " " + (veh.treg || veh.reg);
        return veh;
      });
    }
    this.setDateInURL();
  }

  clearAll = (vehs) => {
    var loc = this.props.location
    var newUrl = loc.pathname;
    if (loc && loc.query && loc.query.from && loc.query.to) newUrl += "?from=" + loc.query.from + "&to=" + loc.query.to;
    this.props.router.replace(newUrl);
  }

  onClickApplyButton = (selectedIds) => {
    var vids = [],selIds = [];
    selectedIds.forEach(veh => {
      if (veh && veh.checked) {
        vids.push(veh.idx);
        selIds.push(veh);
      }
    });

    if (selIds && selIds.length) {
      this.validVeh = isVehValid(this.vehicles, vids);
      var newUrl = _setNewUrl(this.props.location, vids, this.state.from, this.state.to);
      this.props.router.replace(newUrl);
    }
  }

  onTileClick(e) {
    this.setState({
      tileClick: e
    });
  }

  isLoaded = (e) => {
    this.setState({
      isLoading: e
    });
  }

  dateChange = (e) => {
    var newUrl,vidArr = [];
    if (e.length > 1) {
      this.setState({
        from: e[0].getTime(),
        to: e[1].setHours(23, 59, 59, 999),
        isLoading: true
      }, () => {
        if (this.props.location && this.props.location.query && this.props.location.query.vid){
          if (Array.isArray(this.props.location.query.vid) === false) {
            vidArr.push(parseInt(this.props.location.query.vid));
          } else {
            this.props.location.query.vid.forEach(v => {
              vidArr.push(parseInt(v));
            });
          }
          newUrl =_setNewUrl(this.props.location, vidArr, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
        }
        else {
          newUrl =_setNewUrl(this.props.location,null, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
        }
      });
    }
  }

  showRidesTile() {
    var loc = this.props.location;
    const dailyRideTiles = (paramObj) => {
      return (
        <Col lg={12} md={12} sm={12} xs={12} style={{ paddingRight: "0px",paddingTop: "2%"  }}>
          {this.state.isLoading ? <LoadingScreen /> : null}
          <DailyRidesTiles args={paramObj} onTileClick={(e) => this.onTileClick(e)} {...this.props} vehMap={this.vehMap} loading={(e) => { this.isLoaded(e) }} />
        </Col>
      );
    }
    if(this.vehicles && this.vehicles.length && this.state.from && this.state.to) {
      var paramObj = {}, vidArr = [];
      if (loc.query && loc.query.vid) {
        if (!Array.isArray(loc.query.vid)) {
          vidArr.push(parseInt(loc.query.vid));
        }
        else {
          loc.query.vid.forEach(v => {
            vidArr.push(parseInt(v));
          });
        }
      } else {
        this.vehicles.forEach((veh) => {
          if(veh) vidArr.push(veh.idx);
        });
      }
      paramObj.vids = vidArr;
      paramObj.from = this.state.from;
      paramObj.to = this.state.to;
      return dailyRideTiles(paramObj);
    }
  }

  render() {
    return (
      <Col>
        <Row>
          <Col lg={4} lgOffset={5} md={6} mdOffset={2} sm={6} xs={12} className="vehicle-padding">
            {
              this.vehicles && this.vehicles.length > 1 ?
                <VehMultiDropDown
                  headerName="Vehicle Filter"
                  headingStyle ={{ fontSize: "15px", border: "1px solid #ccc", borderRadius: "5px", width: "100%" }}
                  clearStyle={{ color: "#d6001a", fontSize: "14px" }}
                  selectedVehs={this.props.location.query && this.props.location.query.vid ? (Array.isArray(this.props.location.query.vid) ? this.props.location.query.vid : [this.props.location.query.vid]) : 0}
                  options={this.vehicles}
                  onClear={(vehs) => this.clearAll(vehs)}
                  onApply={(selectedIds) => this.onClickApplyButton(selectedIds)}/>
                : null
            }
          </Col>
          {
            this.state.from && this.state.to && this.vehicles && this.vehicles.length ?
              <Col lg={3} md={4} sm={6} xs={12} >
                <DateInput defaultdate={[this.state.from, this.state.to]} mode={true} mindate={GetDateFromNow(30) * 1000} maxdate={new Date().setHours(23, 59, 59, 999)} onChange={(e) => this.dateChange(e)}></DateInput>
              </Col>
            : null
          }
        </Row>
        <Row>
          <Col style={{ paddingBottom: "15px"}}>
            {this.showRidesTile()}
          </Col>
        </Row>
      </Col>
    )
  }
}