import React, { Component } from 'react';
import { Button, Col, Table } from 'react-bootstrap/lib';
import { clone } from '../../lib/jsonUtils';
export class VehMultiDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: clone(this.props.options),
      tickAll: false
    }
    this.vehicles = this.props.options;
    this.vehiclesToShow = this.props.options;
    this.filteredVehicles = [];
    this.selVehLength = this.props.selectedVehs ? this.props.selectedVehs.length : 0;
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this._handleClickOutside = this._handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  componentWillReceiveProps(newProps) {
    this.vehicles = newProps.options;
    this.vehiclesToShow = newProps.options;
    if(newProps.selectedVehs && newProps.selectedVehs.length){
      this.selVehsLength = newProps.selectedVehs.length;
      this.vehiclesToShow = this.vehiclesToShow.filter(veh => {
        if(newProps.selectedVehs.indexOf(veh.idx.toString()) > -1){
          veh.checked = true;
        }
        else veh.checked = false;
        return veh;
      });
    }else{
      this.vehiclesToShow.forEach(veh => {
        veh.checked = false;
      });
    }

    this.setState({
      options: clone(newProps.options),
      // tickAll : (newProps.selectedVehs && newProps.selectedVehs.length) === (newProps.options && newProps.options.length) ? true :  false
    });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  _handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.dropDownClose();
    }
  }

  onClickCheckbox(e) {
    let clickedId = +e.target.id;
    let options = clone(this.vehiclesToShow);
    for (let i = 0; i < options.length; i++) {
      if (options[i].idx === clickedId) {
        options[i].checked = e.target.checked;
        break;
      }
    }

    for (let i = 0; i < this.vehicles.length; i++) {
      if (this.vehicles[i].idx === clickedId) {
        this.vehicles[i].checked = e.target.checked;
        break;
      }
    }
    this.vehiclesToShow = options;
    this.setState({
      tickAll: false,
      options: this.vehicles
    });
  }

  onSelectAll = (e) => {
    if (e.target.checked) {
      this.vehiclesToShow.forEach(veh => { veh.checked = true; });
    } else {
      this.vehiclesToShow.forEach(veh => {
        veh.checked = false;
      });
    }
    this.setState({
      tickAll: e.target.checked,
      options: this.vehiclesToShow
    });
  }

  dropDownClose() {
    // Hide dropdown from view
    var items = document.getElementById('items');
    var anchor = document.getElementById('anchor');
    if (items.classList.contains('visible')) {
      items.classList.remove('visible');
      items.style.display = "none";
      anchor.className = "anchorOpen";
    }
  }

  changevisible() {
    var items = document.getElementById('items');
    var anchor = document.getElementById('anchor');
    if (items.classList.contains('visible')) {
      items.classList.remove('visible');
      items.style.display = "none";
      anchor.className = "anchorOpen";
    }
    else {
      items.classList.add('visible');
      items.style.display = "block";
      anchor.className = "anchorClose";
    }
    items.onblur = function (evt) {
      items.classList.remove('visible');
    }
  }

  onApply() {
    // Filter out selected Ids
    let selectedIds = [];
    for (let i = 0; i < this.state.options.length; i++) {
      if (this.state.options[i].checked) {
        selectedIds.push(this.state.options[i]);
      }
    }
    this.selVehsLength = selectedIds ? selectedIds.length : 0
    this.props.onApply(this.state.options);
    this.dropDownClose();
  }

  clearAll = () => {
    let vehicles = this.state.options;
    for (let i = 0; i < vehicles.length; i++) {
      vehicles[i].checked = false;
    }
    this.selVehsLength = 0;
    this.props.onClear(vehicles);
    this.vehiclesToShow = vehicles;
    document.getElementById("search").value = "";
    this.setState({
      tickAll: false
    });
  }

  filterList = (event) => {
    var inputText = event.target.value;
    if (inputText) {
      this.filteredVehicles.length = 0;
      inputText = inputText.toLowerCase();
      this.vehicles.forEach(veh => {
        if ((veh.vmk && veh.vmk.toLowerCase().indexOf(inputText) !== -1) || ( veh.mo && veh.mo.toLowerCase().indexOf(inputText) !== -1) || (veh.reg && veh.reg.toLowerCase().indexOf(inputText) !== -1))
          this.filteredVehicles.push(veh);
      });
      this.vehiclesToShow = this.filteredVehicles;
    }
    else this.vehiclesToShow = this.vehicles;
    this.forceUpdate();
  }

  render() {
    var icon = "";
    return (
      <div ref={this.setWrapperRef}>
        <Col className="dropdown-veh-list" style={{ position: "relative", backgroundColor: 'white' }}>
          <span id="anchor" className="anchorOpen" style={this.props.headingStyle} onClick={(e) => this.changevisible()}>{this.props.headerName + (this.selVehsLength ? " (" + this.selVehsLength + ")" : "")}</span>
          <ul id="items" className="items veh-dropDown" style={{ width: "100%" }}>
            <li style={{ paddingTop: "5px", paddingBottom: "6px", borderBottom: "1px solid #ccc" }}>
              <div className="col-lg-10 col-md-9 col-sm-10 col-xs-9" style={{ paddingTop: "5px" }}>
                <button id="clear" onClick={this.clearAll}><span style={this.props.clearStyle} >Clear All</span></button>
              </div>
              {
                <Button id="applybutton" bsSize="small" className="" style={{ paddingTop: "4px", backgroundColor: "#0094de", borderColor: "#0094de", color: "#fff" }} onClick={() => this.onApply()}><span style={{ fontSize: "12px" }} >Apply</span></Button>
              }
            </li>
            <li style={{ borderBottom: "1px solid #ccc" }}>
              <input type="text" id="search" className="form-control " placeholder="Search" onChange={this.filterList} style={{ border: "none", boxShadow: "none",height: "35px"}} />
            </li>
            {this.vehiclesToShow.length ?
              <li style={{ borderBottom: "1px solid #ccc" }}>
                <Table style={{ borderTop: "hidden", borderLeft: "hidden", marginBottom: "0px" }}>
                  <tbody>
                    <tr style={{ padding: "0px" }}>
                      <td style={{ width: "15%", borderRight: "1px solid white" }}></td>
                      <td style={{ borderRight: "1px solid white", width: "70%" }} className="color-4a4a4a">
                        <span className="font-bold col-lg-offset-8 col-lg-4 col-md-4  col-xs-offset-4 col-xs-4"
                          style={{ padding: "0px", marginLeft: "75%" }}>Select All</span></td>
                      <td align="center">
                        <input type="checkbox" name="allsel" id={-1} checked={this.state.tickAll} onChange={(e) => this.onSelectAll(e)} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </li>
              : null
            }
            <div style={{ overflowY: "auto", maxHeight: "250px" }}>
              {this.vehicles && this.vehicles.length ?
                !this.vehiclesToShow.length ? <li style={{marginLeft:"1%",padding:"2%"}}>Match not found</li> :
                  this.vehiclesToShow.map((bike, i) => {
                    if (bike.typ === 1) icon = "bike";
                    else if (bike.typ === 2) icon = "car";
                    else if (bike.typ === 3) icon = "auto";
                    else if (bike.typ === 4) icon = "truck";
                    return (
                      <li key={i}>
                        <Table style={{ borderTop: "hidden", borderLeft: "hidden", marginBottom: "0px" }}>
                          <tbody>
                            <tr style={{ padding: "0px" }}>
                              <td style={{ width: "15%", borderRight: "1px solid white" }}><i className={"icon ajjas-" + icon + "icon font-size22px"} style={{color: bike.disabled ? "#9b9b9b" : ""}} /></td>
                              <td style={{ borderRight: "1px solid white", width: "70%" }} className={ "color-4a4a4a"}>
                                <span className="font-bold">{(bike.vmk ? bike.vmk : "") + " " + (bike.mo ? bike.mo : "") + "  "}</span>
                                <span style={{ fontSize: "13px" }}>{(bike.reg ? bike.reg : bike.treg) + "  "}</span>
                                <span style={{ color: "gray", fontSize: "12px" }}>{bike.did ? "" : "UNMAPPED"}</span>
                              </td>
                              <td align="center">
                                {
                                  <input type="checkbox" checked={bike.checked || false} name="check" id={bike.idx}
                                    onChange={(e) => this.onClickCheckbox(e)} />
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </li>
                    )
                  })
                : null
              }
            </div>
          </ul>
        </Col>
      </div>
    )
  }
}
