const { GetRequest } = require('../lib/net.js');
const crmhostname = require('../config.js').config.crmhostname;

/* APIs */
export function GetSalesPanelCount() {
  return new Promise((resolve, reject) => {
    let url = crmhostname + '/dealership/sales/getSalesPanelCount';
    return Get(url, resolve, reject);
  });
}

/* Internal Functions */
function Get(url, resolve, reject) {
  GetRequest(url, (resp) => {
    try {
      let data = JSON.parse(resp.response).data;
      if (resp.status !== 200) return reject(data);
      resolve(data);
    } catch (err) {
      console.error(err);
      reject('Network error has occured');
    }
  });
}