import React, { Component} from 'react';
import {browserHistory} from 'react-router';
import {Col, Row, Alert, ListGroupItem} from 'react-bootstrap/lib';
import {getTriggersData, getTriggerTypes ,deleteTrigger} from  '../../lib/db/triggers';
import {InfoModal,LoadingScreen} from '../../generic/input.js';
import { MENUPATH } from '../../lib/enums';
const { GeoFenceInput } = require('./gfinputs');
const TRIGGER_TYP = 2;

export default class ViewGeoFence extends Component{
  constructor(props){
    super(props);
    this.state = {
      gfData: [],
      infoMsg: "",
      showVehicle: false,
      viewClicked: false,
      triggerData: "",
      bikes: "",
      disabled: false,
      isLoading: true
    }
    this.gfTypeMap = {};
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentDidMount(){
    if(global.wsOnline){
      if(global.bikeDetails) this.onBikes(global.bikeDetails);
    }
    
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.VIEW_GF);
    getTriggersData(TRIGGER_TYP, (err, gfData) => {
      if(err){
        this.setState({
          infoMsg: err,
          isLoading : false
        });
        return;
      }
      if(gfData && gfData.length === 0){
        this.setState({ infoMsg: "Geo-fence list is empty. Add first geo-fence to view list here." });
      }
      var GeofenceArr = gfData.filter(d=> !d.src)
      getTriggerTypes(TRIGGER_TYP , (e, triTyp) => {
        if(triTyp && triTyp.length) {
          triTyp.forEach((d)=>{
            this.gfTypeMap[d.idx] = d.nm;
          });
        }
        var sortedData = GeofenceArr.sort((a,b) => b.ts - a.ts)
        this.createGeoFenceData(sortedData);
        this.setState({
          gfData: sortedData,
          gFTypArr: GeofenceArr.type,
          isLoading : false
        });
      });
    });
  }

  onBikes = (bikes) => {
    global.bikeDetails = bikes;
    this.forceUpdate();
  }

  onViewVehicle(e){
    var vehArr = this.dailyGFTiles.filter(d=> d.id.idx === parseInt(e.target.value)) //vehArr[0].id.vehIdArr
    this.vehicleDetails = global.bikeDetails.filter(d=> vehArr[0].id.vehIdArr.includes(d.idx))
    this.setState({ showVehicle: true });
  }

  onVehicleClose=()=>{
    this.setState({ showVehicle: false });
  }

  onDeleteGeoFence(e){
    var del = window.confirm("Are you sure you want to delete?");
    if(del){
      var index = "";
      var vidArr = [];
      this.dailyGFTiles.forEach((x,i) => {
        if(x.id.idx === parseInt(e.target.id)){
          index = i;
          vidArr = x.id.vehIdArr
        }
      });
      if(vidArr && vidArr.length){
        deleteTrigger({ vehIdArr: vidArr, idx: parseInt(e.target.id)} , TRIGGER_TYP ,(err, response) =>{
          if(err) this.setState({ errMsg: err });
          else {
            this.dailyGFTiles.splice(index, 1);
            this.forceUpdate();
          }
        });
      }
    }
  }

  onViewGeoFence(e){
    var newUrl = "/webapp/gf/edit?tid=" + e.idx;
    browserHistory.push(newUrl);
  }

  createGeoFenceData(data){
    this.dailyGFTiles = [];
    if(data && data.length){
      data.forEach(d=>{
        this.dailyGFTiles.push({
          id: d ,data:
          <div>
            <Row>
              <Col md={4} xs={6} >
                <Col xs={12} style={{fontSize: "14px", color: "#b5b5b5", marginTop:"7%"}}>Geo-fenced Zone</Col>
                <Col xs={12} style={{fontSize: "16px", color: "#788995", marginTop:"4%"}}>{d.title ? d.title : "-"}</Col>
              </Col>
              <Col md={4} xs={6} >
                <Col xs={12} style={{ fontSize: "14px", color: "#b5b5b5", marginTop:"7%"}}>Geo-fenced Type</Col>
                <Col xs={12} style={{fontSize: "16px", color: "#788995", marginTop:"4%"}}>{d.gfTyp ? this.gfTypeMap[d.gfTyp] : "-"}</Col>
              </Col>
              <Col md={4} xs={6} >
                <Col xs={12} style={{ fontSize: "14px", color: "#b5b5b5", marginTop:"7%"}}>Geo-fenced Description</Col>
                <Col xs={12} style={{fontSize: "16px", color: "#788995", marginTop:"4%"}}>{d.desc ? d.desc : "-"}</Col>
              </Col>
            </Row>
            <div style={{background: "#f5f5f5", marginLeft: "-15px", marginRight: "-15px", marginTop:" 19px", marginBottom: "-15px", height: "40px"}}>
              <button value={d.idx} style={{paddingTop:"10px",  paddingLeft:" 25px", color: "#0094de", fontSize:"14px"}} onClick={(e)=>this.onViewVehicle(e)}>View Assigned vehicles</button>
              <button  style={{float:"right", paddingRight:"20px", paddingTop:"10px"}}><i className="icon ajjas-delete"  id={d.idx}  onClick={(e)=>this.onDeleteGeoFence(e)} style={{color: "#0094de"}}/></button>
              <button value={d.idx} style={{float:"right", paddingRight:"30px", paddingTop:"10px"}}><i className="icon ajjas-edit" onClick={(e)=>this.onViewGeoFence(d)} style={{color: "#0094de"}}/></button>
            </div>
          </div>
        });
      })
    }
  }

  render(){
    return (
      <div>
        <Col style={{ paddingBottom:"15px"}}>
          {
            this.state.infoMsg || (this.dailyGFTiles && this.dailyGFTiles.length === 0) ?
              <Col><Alert bsStyle="warning">{this.state.infoMsg ? this.state.infoMsg : "Geo-fence list is empty. Add first geo-fence to view list here." }</Alert></Col>
            : null
          }
          {
            this.state.gfData && this.state.gfData.length && this.dailyGFTiles.length && !this.state.viewClicked?
              <Col className="height-83vh overflowY-auto" >
                {
                  this.dailyGFTiles.map((d,i)=>{
                    return (
                      <ListGroupItem style={{position: "initial" ,marginBottom: "2%",  borderRadius:"4px"}} header={d.data} key={i}></ListGroupItem>
                    )
                  })
                }
              </Col>
            : this.state.isLoading ? <LoadingScreen/> : null
          }
          {
            <InfoModal show={this.state.showVehicle} modalSize="small" close={this.onVehicleClose} bodyStyle={{paddingBottom: "0px"}} formStyle={{marginTop: "-12px"}}>
            {
              this.vehicleDetails
              ? this.vehicleDetails.map((veh, i)=>{
                return(
                  <div key={i.toString()} style={{marginTop:"5%", borderBottom: "1px solid #e1e1e1", marginLeft: "-12px", marginRight: "-12px", textAlign: "center", fontSize:" 14px", height:"60px"}}>
                    <span style={{color: "#788995"}}>{veh.mo}<br />{veh.reg ? veh.reg : veh.treg}</span>
                    <span style={{color:"#cdcdcd",marginLeft: '15px', marginTop:"-4%", fontSize:"12px"}}>{veh.did ? "PLUS" : "PRO"}</span>
                  </div>
                )
              })
              : "No vehicle Present"
            }
            </InfoModal>
          }

        </Col>
        <Col style={{paddingLeft:"0%", paddingBottom:"2px"}}>
        {
          this.state.viewClicked ?
            <GeoFenceInput
              header="Edit geo-fence zone"
              drawingManagerOptions={this.drawingManagerOptions}
              gFTypArr={this.state.gFTypArr}
              disabled = {this.state.disabled}
              onSaveGeoFence={ (e) => this.onSaveGeoFence(e)}
              gfType={this.state.triggerData && this.state.triggerData.gfTyp ? this.gfTypeMap[this.state.triggerData.gfTyp] : null}
              bikeDetails = {this.state.bikes}
              triggerData = {this.state.triggerData}
              nameErr = { this.state.nameErr ?  this.state.nameErr : null}
              vehErr = { this.state.vehErr ?  this.state.vehErr : null}
              geoErr = { this.state.geoErr ?  this.state.geoErr : null}
              errMsg = { this.state.errMsg ?  this.state.errMsg : null}
              viewMap = {true}
            />
          : null
          }
        </Col>
      </div>
    )
  }
}
