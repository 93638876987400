import React, { Component } from 'react';

export default class Unauthorized extends Component{
  componentWillMount(){
    var redirect = "/webapp";
    var url = redirect + "?redirect=" + this.props.location.pathname + (this.props.location.search ? this.props.location.search : "");
    window.location.href = url;
  }
  render() {
    return(
      <div className="container">
        <label className="fontlarge">Redirecting....
        </label>
      </div>
    )
  }
}