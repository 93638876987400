import React, { Component } from 'react';
import { Image, Button ,Alert}  from 'react-bootstrap/lib';
import { Col,Row}  from 'react-bootstrap/lib';
import {Link} from 'react-router';

export class LandingImage extends Component{
  render(){
    return (
      <Row className='landing-image'>
        <Row lg="12" className='bottom5 position-absolute padding-left5'>
          <h1 className='font-family-OpenSans color-white'><b>Bike & Car Anti Theft &<br/>Accident Alert GPS device</b></h1>
          <h4 className='color-white margintop2 font-style-normal font-family-OpenSans' >
            Absolute freedom from fear of fall and Two-wheeler theft
          </h4>
        </Row>
      </Row>
    );
  }
}

export class MobileLogin extends Component {
  constructor(props){
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.mob = "";
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount(){
    window.location.hash = "";
  }

  onSubmit(){
    this.props.onSubmit(this.mob);
  }

  handleKeyPress(e) {
    if (e.key === 'Enter' && !this.props.disLoginBtn) {
      this.mob = e.target.value;
      this.onSubmit()
    }
  }

  render(){
    var getDate = new Date();
    var copyRightYear = getDate.getFullYear();
    return (
      <Row align="center" className='login' style={{marginLeft:"0px"}}>
        <Col>
          <Image
            className='width35 height10 margin-top10 margin-right10 margin-bottom20 margin-left15' src={require('../images/logo-lat.png')}>
          </Image>
        </Col>
        <Col className='height260px'>
          <p className='height20 width310px color-9b9b9b font-size18px margin-top25 font-family-roboto'>
            Manage your vehicles review ride history, and more.
          </p>
          <Col className="input-group mb-3 width310px margin-top5 margin-bottom7">
            <Col className="input-group-addon">
              <span className="input-group-text" id="basic-addon1">+91</span>
            </Col>
            <input className="form-control" type="number" style={{fontFamily: "Roboto"}}
              onChange={(e) => this.mob = e.target.value } onKeyPress={this.handleKeyPress} placeholder="Enter Mobile Number" autoFocus />
          </Col>
          <button  className= 'btn-info bottom-suc' disabled={this.props.disLoginBtn} onClick={this.onSubmit}>
            Login <i className="icon ajjas-arrright float-right margin-right5"/>
          </button>
          <Col className="clear"/>
          { this.props.errorMsg ? <span className='color-red'>{this.props.errorMsg}</span> : null}
        </Col>
        <Col className='font-family-OpenSans'>
          <p className='font-size15px color-9b9b9b'>© {copyRightYear}, All rights reserved by HPS Lab Designs</p>
        </Col>
      </Row>
    )
  }
}

export class EnterDetails extends Component{
  constructor(props){
    super(props);
    this.state = {
      eye: false,
    }
  }

  handleSignupPress(){
    var signupData = {}
    if(this.fullName) signupData.fullName = this.fullName;
    if(this.pwd) signupData.pwd = this.pwd;
    if(this.userHandle) signupData.userHandle =this.userHandle;
    if(signupData){
      this.props.handleSignupPress(signupData);
    }
  }

  visiblePassword(){
    this.setState({eye: true});
    var temp;
    var x = document.getElementById("Input");
    if (x.type === "password") {
        x.type = "text";
        temp = true;
    } else {
        x.type = "password";
        temp = false;
    }
    this.setState({ eye: temp })
  }

  render(){
    return(
      <Row className=' width100 padding-top10 padding-left10'>
        <button type="button" className='border-none border-outline backgroundColor-grey'
          onClick={this.props.onBackPress}><i className="icon ajjas-arrleft"/>
        </button>
        <Col className="clear"/>
        <Col className='padding-right25 height450px padding-top10'>
          <Col className='font-family-roboto'>
            <span className='line-input-title'>What’s your name?</span>
            <input type="text" className='line-input gray-bb-focus' placeholder="Enter Full Name" onChange={(e) => this.fullName = e.target.value}></input>
            { this.props.fullNameError ? <span className='line-input-error'>{this.props.fullNameError}</span> : null}
          </Col>
          <Col>
            <span className='line-input-title'>Create profile password</span>
            <Col className='border-bottom margin-left11 margin-top5 width100'>
              <input className='border-none border-outline backgroundColor-grey font-size13px width85' type="password" id="Input"
                aria-hidden="true" placeholder="Password" onChange={(e) => this.pwd = e.target.value}>
              </input>
              <button type="button" className='border-none border-outline backgroundColor-grey padding-right0' onClick={() => this.visiblePassword()}>
                {this.state.eye ? <i className="icon ajjas-pwvisible"/> : <i className="icon ajjas-pwhidden"/>}
              </button>
            </Col>
            { this.props.pwdError ? <span className='line-input-error'>{this.props.pwdError}</span> : null}
          </Col>
          <Col className="clear"/>
          <Col className="height30">
            <span className="line-input-title">Create unique user name</span>
            <input type="text" className="line-input gray-bb-focus"
              placeholder="User name" onChange={(e) => this.userHandle = e.target.value}>
            </input>
            { this.props.userHandleError ? <span className='line-input-error' >{this.props.userHandleError}</span> : ""}
          </Col>
          <Col className='height30 width100'>
            <span className='color-red font-size14px margin-left15'>{this.props.errorMsg}</span>
            <Col >
              <button className= 'btn-info height30px width100 margin-left11' onClick={() => this.handleSignupPress()} >Join
                <i className="icon ajjas-arrright float-right margin-right5"/>
              </button>
            </Col>
          </Col>
        </Col>
         <Col align="center" className='height48px'>
          <p className='color-9b9b9b font-size15px margin-top50'>© 2018, All rights reserved by HPS Lab Designs</p>
        </Col>
      </Row>
    )
  }
}

export class EnterPassword extends Component{
  constructor(props){
    super(props);
    this.state = {
      eye: false,
    }
    this.visiblePassword = this.visiblePassword.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
  }

  visiblePassword(){
    var temp;
    var x = document.getElementById("Input");
    if (x.type === "password") {
        x.type = "text";
        temp = true;
    } else {
        x.type = "password";
        temp = false;
    }
    this.setState({ eye: temp })
  }

  goToLinks(e){
    if(e) this.props.goToLink(e);
  }

  onSubmitClick(){
    this.props.onSubmitClick(this.pwd);
  }
  render(){
    return(
      <form onSubmit={(e)=>{
        e.preventDefault();
        this.onSubmitClick();
      }}>
        <Row className='login' style={{paddingTop:"10%", paddingLeft:"5%"}}>
          <button type="button"  className='border-none border-outline padding-left5 backgroundColor-grey'
            onClick={this.props.onBackPress}>
            <i className="icon ajjas-arrleft"/>
          </button>
          <Col className="clear"/>
          <Col className='padding-left10 padding-top20 height480px font-family-roboto' >
            <span className='width80 font-size15px font-family-roboto height10 letter-space'>
              Welcome back {this.props.getName }, please sign in to continue
            </span>
            <Col className='border-bottom margin-top20px width92'>
              <input autoFocus className='border-none border-outline backgroundColor-grey font-size13px width85' type="password" id="Input"
                aria-hidden="true" placeholder="Enter your Password" onChange={(e) => this.pwd = e.target.value}>
              </input>
              <button type="button" className='border-none border-outline backgroundColor-grey' onClick={() => this.visiblePassword()}>
                {this.state.eye ? <i className="icon ajjas-pwvisible"/> : <i className="icon ajjas-pwhidden"/>}
              </button>
            </Col>
            <Col className='height30 width100'>
              <Col className='height10'>
                {this.props.pwderrorMsg ? <span className='color-red font-size14px'>{this.props.pwderrorMsg}</span> : null}
              </Col>
              <Col className='padding-top10'>
                <button className='btn-info height30px width92 margin-left5 ' disabled={this.props.disNextBtn}>
                 {/* onClick={()=>this.onSubmitClick()}> */}
                  Next
                  <i className="icon ajjas-arrright float-right margin-right5"/>
                </button>
              </Col>
            </Col>
            <Col className='padding-top10 font-family-roboto font-size14px'><a href="#onOTP" id="onOTP"
              onClick={(e)=> this.goToLinks(e.target.id)} >Login with OTP</a>
            </Col><br/>
            <Col className='padding-top1 font-family-roboto font-size14px' ><a href="#onFPassword" id="onFPassword"
              onClick={(e)=> this.goToLinks(e.target.id)} >I forgot my password</a>
            </Col><br/>
            <Col className='padding-top1 font-family-roboto font-size14px'><button className="link-button" id="onLogin"
              onClick={(e)=> this.goToLinks(e.target.id)} >Not { this.props.getName }? Change account</button>
            </Col>
            <Col className="clear"/>
            <Col className='margin-top10 font-size13px'>
              { this.props.errorMsg ? <scam className='color-red'>{this.props.errorMsg}</scam> : null}
              { this.props.success ? <scam className='color-red'>{this.props.success}</scam> : null}
            </Col>
          </Col>
          <Col align="center" className='height48px'>
            <p className='color-9b9b9b font-size15px margin-top50'>© 2018, All rights reserved by HPS Lab Designs</p>
          </Col>
        </Row>
      </form>
    )
  }
}

export class OTP extends Component {
  constructor(props){
    super(props);
    this.state = {
      num : "",
      errorMsg: "",
    }
    this.otpObj = {};
    this.interval = "";
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount(){
    this.startCountDown();
  }

  startCountDown(){
    var i = 60;
    this.interval = setInterval(increment, 1000);
    var self = this;
    function increment(){
      if(i > 0){
        i = i % 360 - 1;
        self.setState({
          num: i
        })
      }
    }
  }

  handleNext(){
    if( this.otp && this.otp.length === 6) this.props.handleOtpNext(this.otp);
    else this.setState({ errorMsg : "Invalid OTP"})
  }

  onClickLink(e){
    if(e){
      if(e === "resendOtp") {
        this.startCountDown();
      }else {
        clearInterval(this.interval);
      }
      this.props.onClickLink(e);
    }
  }

  onOTPEnter(e){
    var id = e.target.id;
    this.otpObj[id] = e.target.value;
    this.otp = (Object.values(this.otpObj)).join('');
    id = parseInt(id);
    if(!e.target.value) id -=1;
    if(!e.target.value && this.refs["otp_" + (id)]) this.refs["otp_" + (id)].focus();
    else if(this.refs["otp_" + (id + 1)]) this.refs["otp_" + (id + 1)].focus();
  }

    handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleNext(e);
    }
  }

  showOTPFields(){
    var inputs = [];
    for(var i = 0; i< 6; i++){
      inputs.push(
        <input key={i} className="form-control" type='text' maxLength="1" style={{width: "12%", display: "inline", marginRight: '4%'}}
          id={i} ref={'otp_' + i} onKeyPress={i === 5 ? this.handleKeyPress : null} onChange={(e)=> this.onOTPEnter(e)}/>
      );
    }
    return inputs;
  }

  onBackPress(){
    this.props.onBackPress();
    clearInterval(this.interval);
  }

  render(){
    return (
      <Row className='backgroundColor-grey height100vh width100 padding-top10 padding-left10'>
        <button type="button" className='border-none border-outline padding-left2 backgroundColor-grey' onClick={() => this.onBackPress()}>
          <i className="icon ajjas-arrleft"/>
        </button>
        <Col className='padding-left10 padding-top5 height450px font-family-roboto'>
          <p className='color-9b9b9b font-size15px height10 width72'>We have send a 6-digit code to +91 {this.props.mob}.</p>
          <Col className='padding-top10'>{this.showOTPFields()}</Col>
          { this.state.num === 0 || !this.props.countDown ?
            <p className='padding-top10 height10 width60'><a className='color-0094de' href="#resendOtp"
              id="resendOtp" onClick={(e) => this.onClickLink(e.target.id)}> Resend OTP</a>
            </p>
            : <p className='color-9b9b9b font-size15px height10 width60 padding-top10'>Resend code in 00:{this.state.num}</p>
          }
          <Col className='padding-top10'>
            <Button className= 'btn-info height30px width90 margin-left5' disabled={this.props.disOptlogin} onClick={() => this.handleNext()}>
              Login
              <i className="icon ajjas-arrright float-right margin-right2"/>
            </Button>
          </Col>
          <Col className='height10 padding-top10'>
            <a className='color-0094de' href="#editMob" id="editMob" onClick={(e) => this.onClickLink(e.target.id)}> Edit my mobile number</a>
          </Col>
          <Col className='height10 padding-top10'>
            {
              (this.props.errorMsg && !this.props.infoMsg)  || this.state.errorMsg ?
                <label className='color-red'>{this.props.errorMsg ? this.props.errorMsg : this.state.errorMsg}</label>
              : ""
            }
          </Col>
          <Col className='padding-right10 height40 padding-top10'>
          { this.props.infoMsg ?
            <Alert bsStyle="danger">
              <strong>{this.props.errorMsg}</strong><br/>{this.props.infoMsg ? this.props.infoMsg : " "}
            </Alert> : null
          }
          </Col>
        </Col>
        <Col align="center" className='height48px'>
          <p className='color-9b9b9b font-size15px margin-top50'>© 2018, All rights reserved by HPS Lab Designs</p>
        </Col>
      </Row>
    )
  }
}

export class TermAndConditions extends Component {
  constructor(props){
    super(props);
    this.handleAgree = this.handleAgree.bind(this);
  }

  handleAgree(){
    this.props.handleAgree();
  }

  render(){
    return (
      <Row className='backgroundColor-grey width100 height100vh padding-top10 padding-left10 font-family-roboto'>
        <button type="button" className='border-none border-outline padding-left5 backgroundColor-grey'
          onClick={this.props.onBackPress}>
          <i className="icon ajjas-arrleft"/>
        </button>
        <Col className='padding-left10 padding-top20 height480px'>
          <p className='color-9b9b9b font-size15px height10 width80'> Agree to Ajjas’s Terms of Use and Privacy Policy to proceed.</p>
          <p className='color-9b9b9b font-size15px height10 width92 '> To learn more, see our
            <Link className='color-0094de' to="https://ajjas.com/terms.html" target="_blank"> Terms &amp; Conditions </Link>&amp;
            <Link className='color-0094de' to="https://ajjas.com/privacypolicy.html" target="_blank"> Privacy policy</Link>
          </p>
          <Col className='padding-top20'>
            <button className= 'btn-info height30px width80 margin-left5' onClick={() => this.handleAgree()}>
               Agree
              <i className="icon ajjas-arrright float-right margin-right5"/>
            </button>
          </Col>
        </Col>
        <Col align="center" className='height48px'>
          <p className='color-9b9b9b font-size15px margin-top50'>© 2018, All rights reserved by HPS Lab Designs</p>
        </Col>
      </Row>
    )
  }
}

















