export default class EventEmitter{
  constructor(){
    this.listeners = {};
    this.EVENT_TYPES = {
      WS_REQ: 'wsrq',
      WS_ONLINE_STATE: 'wso',
      BIKES: 'bikes',
      LAST_SEEN: 'lastSeen',
      // START_RIDES: "startRide",
      // STOP_RIDES: "stopRide",
      GET_RIDE : 'getride',
      TP_DEVICES: 'tpdevices',
      ON_NEW_RIDE : 'onnewride',
      SUB_DEV : 'subdev',
      LIVE_RIDES : 'liveRides',
      NOTIF : 'notif',
      GETPATH : "getPath",
      RIDE_DATA : 'rideData',
      ON_NEW_NOTIF: "newNotif",
      VEHSTAT: "vehstat",
      ON_CHANGE_VEHSTAT: "ChangeVehStat",
      CHANGE_GET_RIDE_EVENT: "changeGetRideEvent"
    }
  }

  addEventListener(eventName, func){
    if(typeof func !== 'function') return;

    if(!this.listeners[eventName]){
      this.listeners[eventName] = [];
    }
    else if(this.listeners[eventName].indexOf(func) >= 0)
      return;
    this.listeners[eventName].push(func);
  }

  removeEventListener(eventName, func){
    if(typeof func !== 'function') return;

    if(func && this.listeners[eventName]){
      var index = this.listeners[eventName].indexOf(func);
      if(index >= 0) this.listeners[eventName].splice(index, 1);
    }
  }

  removeAllListeners(eventName){
    delete this.listeners[eventName]
  }

  emitEvent(eventName, ...args){
    if(this.listeners[eventName])
      this.listeners[eventName].forEach((el)=>{
        el(...args);
      });
  }
}
