import { GetRequest, PostRequest } from '../net';
import { getFromStore, setToStore, getAllkeys } from './common';
import { getTriggersData } from './triggers'
const glHostName = require('../../config').config.glHostName;

const JOURNEY_URL = glHostName + "/gl/users/journeys";


export function AddJourney(shipment, cb) {
  getFromStore('journeys', "shipment").then(tt => {
    if (tt && tt.length) {
      var exist = tt.find((typ) => { return typ.nm === shipment.nm });
      if (exist) {
        cb("Shipment name already exist", null);
        return;
      }
    }
    if (!tt) tt = [];
    var url = glHostName + '/gl/users/journeys';
    PostRequest(url, shipment, (resp) => {
      var data = JSON.parse(resp.response).data;
      if (resp.status !== 200) {
        cb(data, null);
      }
      else {
        if(data){
          tt.push( data );
          var insertVidStatus = [];
          setToStore('journeys', "shipment", tt).then(() =>{
            setToStore('triggers', 2 , []).then(() =>{
              getTriggersData(2, ()=>{
                getAllkeys('vehicleStatus').then((vids) => {
                  if(vids && vids.length) {
                    vids = vids.map(d => parseInt(d))
                    shipment.vidArr.forEach((d)=>{
                      var temp = vids.includes(parseInt(d));
                      if(!temp) {
                        insertVidStatus.push(d);
                      }
                    });
                    if(insertVidStatus && insertVidStatus.length){
                      insertVidStatus.forEach((d, i) => {
                        setToStore('vehicleStatus', d,  {stat: 1 , journeyId: data.idx, ts: Date.now()} ).then(() => {
                          if ((insertVidStatus.length - 1) === i) cb(null, data);
                        }).catch((e) => {
                          return cb(e, null);
                        });
                      });
                    }
                    else {
                      cb(null, data);
                    }
                  }
                  else{
                    setToStore('vehicleStatus', shipment.vidArr[0],  {stat: 1 , journeyId: data.idx, ts: Date.now()} ).then(() => {
                      cb(null, data);
                    })
                  }
                });
              })
            });
          });
        }
      }
    });
  });
}

export function GetJourney(cb){
  getFromStore('journeys', "shipment").then(tt => {
    if (tt && tt.length) cb(null, tt)
    if (!tt) tt = [];
    var url = glHostName + '/gl/users/journeys';
    GetRequest(url, (resp) => {
      var data = JSON.parse(resp.response).data;
      if (resp.status !== 200) {
        cb(data, null);
      }
      else {
        if(data){
          setToStore('journeys', "shipment", data).then(()=>{
            cb(null, data);
          });
        }
      }
    });
  });
}

export function GetShipments() {
  return new Promise((resolve, reject) => {

    getFromStore('journeys', 'shipment')
      .then(data => {

        if (data) resolve(data);

        GetRequest(JOURNEY_URL, (res) => {
          let data = JSON.parse(res.response).data;
          if (res.status !== 200) reject(data);
          else {
            resolve(data);
            setToStore('journeys', 'shipments', data)
              .catch(console.error);
          }
        });

      })
      .catch(reject);

  });
}

export function GetVehicleStatus(vidArr, cb) {
  var vehStatus = {};

  getAllkeys('vehicleStatus')
    .then((vids) => {
      if (vids && vids.length) {
        vidArr.forEach((d, i) => {
          getFromStore('vehicleStatus', parseInt(d, 10))
            .then(status => {
              if (status) vehStatus[d] = status
              if ((vidArr.length - 1) === i) cb(null, vehStatus)
            })
            .catch(cb);
        });
      } else {
        var query = "";
        vidArr.forEach((d, i) => { if (i > 0) query += "&vid=" + d });
        var url = glHostName + '/gl/users/journeys/journeyStatLogs?vid=' + vidArr[0] + query;
        GetRequest(url, (resp) => {
          var data = JSON.parse(resp.response).data;
          if (resp.status !== 200) cb(data, null);
          else {
            if (data && Array.isArray(data) && data.length) {
              data.forEach((d, i) => {
                setToStore('vehicleStatus', d.vid, { stat: d.stat, journeyId: d.journeyId, ts: d.ts }).then(() => {
                  if ((data.length - 1) === i) cb(null, data);
                }).catch(cb);
              });
            }
          }
        });
      }
    });
}

export function SetNewVehStat(vehStat, cb){
  if (vehStat && Object.keys(vehStat).length > 1) {
    getFromStore('vehicleStatus', vehStat.vid).then(stat => {
      if (!stat) stat = {};
      stat = {stat: vehStat.stat === 5 ? 5 : vehStat.stat, journeyId: vehStat.journeyId, ts: vehStat.ts};
      setToStore('vehicleStatus', vehStat.vid, stat).then(()=>{
        cb(null, vehStat);
      })
      .catch((e)=>{
        cb(e, null);
      });
    });
  }
}



