import Net from '../net';
import { getFromStore, setToStore } from './common';
const glHostName = require('../../config').config.glHostName;

export function saveRoute(route, cb) {
  getFromStore('journeys', "routes").then(tt => {
    if (!tt) tt = [];
    var url = glHostName + '/gl/users/routes/addRoute';
    Net.PostRequest(url, route, (resp) => {
      var data = JSON.parse(resp.response).data;
      if (resp.status !== 200) {
        cb(data, null);
      }
      else {
        tt.push( data );
        setToStore('journeys', "routes", tt);
        cb(null, data);
      }
    });
  });
}


export function getRouteData(cb){
  getFromStore('journeys', "routes").then(tt => {
    if (tt && tt.length) {
        cb(null, tt);
        return;
    }
    if (!tt) tt = [];
    var url = glHostName + '/gl/users/routes/getAllRoutes';
    Net.GetRequest(url, (resp) => {
      var data = JSON.parse(resp.response).data;
      if (resp.status !== 200) {
        cb(data, null);
      }
      else {
        tt = data;
        setToStore('journeys', "routes", tt);
        cb(null, data);
      }
    });
  });
}
