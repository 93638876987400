import React from 'react';
import StarRating from './starRating';
import Net from '../../lib/net';
import { Col, Image, Alert } from 'react-bootstrap/lib';
const crmhostname = require('../../config.js').config.crmhostname;

export default class IssueFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issue: null,
      err: ""
    }
    this.getIssueData();
  }
  getIssueData = () => {
    if (this.props.location && this.props.routeParams && this.props.routeParams.id ) {
      var url = crmhostname + "/crm/issues/issues/getIssue?fblinkid=" + this.props.routeParams.id;
      Net.GetRequest(url, (resp) => {
        var data = JSON.parse(resp.response).data;
        if (resp.status === 200) {
          if (data && data.length) {
            this.setState({
              issue: data[0]
            });
          } else {
            this.setState({
              err: "Something went wrong, Please try again in some time."
            });
          }
        }
      });
    }
  }
  sendFeedback() {
    if (this.rating && this.state.issue) {
      var url = crmhostname + "/crm/issues/issues/addIssueFeedback";
      var params = { idx: this.state.issue.idx, nps: this.rating, ts: Date.now() };
      if (this.com) params.com = this.com;

      Net.PostRequest(url, params, (resp) => {
        var data = JSON.parse(resp.response).data;
        if (resp.status === 200) {
          if (data.nModified === 1) {
            let issue = this.state.issue;
            issue.feedback = { rating: params.rating, ts: params.ts };
            if (params.com) issue.feedback.com = params.com;
            this.setState({
              issue
            });
          }
        } else {
          this.setState({
            err: data
          });
        }
      });

    }
  }
  render() {
    return (
      <div className="container">
        <div className="page-header">
          <div align="center">
            <Image className="toolbar_image" src={require("../../images/aj-logo.png")} />
          </div>
        </div>
        {
          this.state.issue && !this.state.err ?
            <Col className="jumbotron" lg={8} lgOffset={2} md={10} mdOffset={1} sm={10} smOffset={1} xs={10} xsOffset={1}>
              {
                !this.state.issue.feedback ?
                  <div>
                    <div className="panel__title"><b>How happy were you with our service?</b></div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-left0">
                      <label className="sf__label">{"Your Issue Id : " + this.state.issue.idx}</label>
                      <div>{this.state.issue.desc}</div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-top7 padding-left0">
                      <label className="sf__label">How likely are you to recommend Ajjas GPS based on the support you received?</label>
                      <div align="center">
                        <StarRating id={"issue"} checkValue={(e) => { this.rating = e; this.forceUpdate(); }} />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-top7 padding-left0">
                      <label className="sf__label">Additional feedback</label>
                      <textarea name='comBody' className='form-control' placeholder="Add your comment here" onChange={(e) => this.com = e.target.value} />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-top7 padding-left0">
                      <button className="form-control" id="save" style={{ backgroundColor: "#0094de", width: "100%", height: "50px", opacity: (this.rating ? "unset" : "0.5") }} disabled={this.rating ? false : true}
                        onClick={() => this.sendFeedback()}><strong style={{ fontSize: "14px", color: "#ffffff" }}>Send Feedback</strong>
                      </button>
                    </div>
                  </div>
                  :
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-left0">
                    <label className="sf__label">{"Your Issue Id : " + this.state.issue.idx}</label>
                    <div>{this.state.issue.desc}</div>
                    <label className="sf__label margin-top5">Thank you for your feedback</label>
                  </div>
              }
            </Col>
            :
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <Alert bsStyle="danger">Something went wrong please try again</Alert>
            </div>
        }
      </div>
    )
  }
}