import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap/lib';
import { SubmitButton, CloseButton } from './inputs/Buttons';

/** 
 * @class EditModal
 * @requires id String
 * @requires show Boolean
 * @exports Modal A modal with form body for user input and submission
 * 
 * usage: 
 * <EditModal show={boolean} close={func}> {custom html} </EditModal>
 */
export class EditModal extends React.PureComponent {

  // When submit button is clicked
  onSubmit = () => {

    // Package all data of the form inside modal
    let data = {};
    let _counter = 0;

    // form inputs (includes dropdowns and textarea)
    let inputs = document.getElementById(this.props.id).elements;
    while (_counter < inputs.length) {
      data[inputs[_counter].name] = inputs[_counter].value;
      _counter++;
    }

    // date input (not included in default inputs since we are using flatpickr)
    let dateInputs = document.getElementById(this.props.id).getElementsByClassName('flatpickr-input');
    _counter = 0;
    while (_counter < dateInputs.length) {
      // NOTE : flatpicker gives o/p in seconds since Unix Epoch, we convert it to a timestamp here
      // hidden and placeholder inputs should be segregated
      if (dateInputs[_counter].name) data[dateInputs[_counter].name] = dateInputs[_counter].value * 1000;
      _counter++;
    }

    // Send it back to parent element
    this.props.submit(data);
  }

  render() {
    return (
      <Modal bsSize={this.props.modalSize} show={this.props.show} onHide={this.props.close}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id={this.props.id}>
            {this.props.children}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <small><b>{this.props.msg}</b>&nbsp;&nbsp;</small>
          <SubmitButton id='update' onClick={this.onSubmit} disabled={this.props.submitBtnDisabled || false} />
          <CloseButton id='close' onClick={this.props.close} />
        </Modal.Footer>
      </Modal>
    );
  }

}
EditModal.propTypes = {
  modalSize: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  msg: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  modalClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  onHide: PropTypes.func
}

/** 
 * @class InfoModal
 * @requires id String
 * @requires show Boolean
 * @exports Modal A modal with form body for user input and submission
 * 
 * usage: 
 * <InfoModal show={boolean} clase={func}> {custom html} </InfoModal>
 */
export class InfoModal extends React.PureComponent {
  render() {
    return (
      <Modal bsSize={this.props.modalSize} id={this.props.id} show={this.props.show} onHide={this.props.close}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {this.props.children}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton id='close' onClick={this.props.close} />
        </Modal.Footer>
      </Modal>
    )
  }
}
InfoModal.propTypes = {
  modalSize: PropTypes.string,
  id: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
  msg: PropTypes.string,
  close: PropTypes.func.isRequired
}