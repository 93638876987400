import React from 'react';
import PropTypes from 'prop-types';

export default class StarRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
    }
    this.id = this.props.id || "";
  }
  componentDidMount() {
    if (this.props.defValue) {
      this.setState({rating: this.props.defValue})
    }
  }

  getRatingLabel = () => {
    if (this.state.rating >= 0 && this.state.rating <= 3) {
      return 'Not at all likely';
    } else if (this.state.rating >= 4 && this.state.rating <= 7) {
      return 'Neutral';
    } else {
      return 'Extremely likely';
    }
  };

  handleRatingChange(value) {
    this.setState({rating: value})
    this.props.checkValue(parseInt(value, 10));
  }

  render() {
    return (
      <>
        <div  className='rate-area'>
          <div className='rate-scale'>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
              <button
                key={val}
                onClick={() => this.handleRatingChange(val)}
                style={{ background: val <= this.state.rating ? '#00A8E2' : 'lightgray', }}
              >
                {val}
              </button>
            ))}
          </div>
          <p className='rate-area-label'>{this.getRatingLabel()}</p>
        </div>
      </>
    )
  }
}
StarRating.propTypes = {
  id: PropTypes.string,
  defValue: PropTypes.number,
  checkValue: PropTypes.func
}