import React from 'react';
import { Link } from 'react-router';
import { InputField } from '../generic/genericFunctions';
import {changeTo12HrFormat ,timeFormat,GetTSForGivenDays} from '../lib/dtconv';
import { DropDown ,DateInput} from '../generic/input';
import Net from '../lib/net';
import { Modal, Button} from 'react-bootstrap/lib';
const glHostName = require('../config').config.glHostName;
const sharingHostName = require('../config').config.sharingHostName;

export default class CustomRides extends React.Component{
  constructor(props){
    super(props);
    this.maxEndTime = null;
    this.isEdit = false;
    this.state = {
      labelMsg: null,
      selectedVehicle: -1,
      showEndDate: false,
      showInputModal : false,
      showInput : false,
      startDt : "",
      endDt : "",
      createClick : false
    }
    this.customRides = [];
    this.bikes = [];
  }
  componentDidMount(){
    if(global.wsOnline){
      this.onWSReady(true);
    }
    else if(global.EE) global.EE.addEventListener(global.EE.EVENT_TYPES.WS_ONLINE_STATE, this.onWSReady);

    this.getCustomRides();
  }
  componentWillUnmount(){
    global.EE.removeEventListener(global.EE.EVENT_TYPES.WS_ONLINE_STATE, this.onWSReady);
  }
  getCustomRides(){
    var url = glHostName + "/gl/users/rides/getcustomrides";
    Net.GetRequest(url, (resp=>{
      try{
        var response = JSON.parse(resp.response).data;
        if(resp.status !== 200){
          this.setState({
            labelMsg: response
          })
          return;
        }
        this.customRides = response;
        this.forceUpdate();
      }
      catch(e){
        console.log(e);
      }
    }))
  }
  onWSReady = (state)=>{
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }
  onBikes = (bikes)=>{
    bikes.forEach(bike=>{
      bike.nm = bike.mo + " (" + (bike.reg ? bike.reg : bike.treg) + ")";
    })
    if(bikes && bikes.length && bikes.length === 1){
      this.setState({
        selectedVehicle: bikes[0].idx
      })
    }
    this.bikes = bikes;
    this.forceUpdate();
  }

  getRides = () => {
    if(this.state.selectedVehicle === -1){
      this.setState({
        labelMsg: "Please select your vehicle"
      })
    }
    if(!this.state.startDt){
      this.setState({
        labelMsg: "Please select start date and time"
      })
      return;
    }
    if(!this.state.endDt){
      this.setState({
        labelMsg: "Please select end date and time"
      })
      return;
    }
    this.validateRideInsertion();

    this.setState({
      labelMsg: ""
    })
    let veh = this.bikes.find(bike=> bike.idx ===  this.state.selectedVehicle);
    this.insertCustomRide(veh.did, veh.idx, (this.state.startDt / 1000), (this.state.endDt / 1000));
  }

  validateRideInsertion(){
    if(this.state.endDt - this.state.startDt < 2 * 86400000 || this.state.endDt - this.state.startDt < 0){
      this.setState({
        labelMsg: "Maximum date range should be one day"
      })
      return;
    }

    if(this.customRides && this.customRides.length){
      let existingRide = this.customRides.find((ride)=> (ride.tsFr === (this.state.startDt / 1000)) && (ride.tsTo === (this.state.endDt / 1000)))
      if(existingRide){
        this.setState({
          labelMsg:"Ride already exists for the specified date."
        })
      }
    }
  }
  insertCustomRide(did, vid, stdt, endt){
    var data = {
      did: did,
      vid: vid,
      tsFr: stdt,
      tsTo: endt
    };
    var url = glHostName + "/gl/users/rides/createcustomride";
    Net.PostRequest(url, data,(resp)=>{
      var response = JSON.parse(resp.response).data;
      if(resp.status !== 200){
        this.setState({
          labelMsg: response
        })
        return;
      }
      this.customRides.unshift(response);
      this.setState({
        labelMsg: "",
        showInput : false,
        showEndDate : false,
        createClick : false
      })
    })
  }
  selectVehicle(e){
    this.setState({
      selectedVehicle: parseInt(e.target.value,10)
    })
  }
  onEditClick(ride){
    this.isEdit = ride;
    this.setState({ startDt : ride.tsFr * 1000, endDt: ride.tsTo * 1000, showEndDate: true,showInput:true,selectedVehicle :ride.vid });
  }
  UpdateRide(){
    if(!this.isEdit){
      this.setState({
        labelMsg: "Nothing to update"
      })
    }
    if((this.state.startDt / 1000 === this.isEdit.tsFr) && (this.state.endDt / 1000 === this.isEdit.tsTo)){
      this.setState({
        labelMsg: "Please provide some different dates if you want to update this existing ride"
      })
      return;
    }
    this.validateRideInsertion();
    var data = {
      idx: this.isEdit.idx,
      tsFr: this.state.startDt / 1000,
      tsTo: this.state.endDt / 1000
    };
    var url = glHostName + "/gl/users/rides/editcustomride";

    Net.PostRequest(url, data, (resp)=>{
      try{
        var response = JSON.parse(resp.response).data;
        if(resp.status !== 200){
          this.setState({
            labelMsg:response
          })
          return;
        }
        var index = this.customRides.findIndex((ride)=> ride.idx === data.idx);
        this.customRides[index] = response;
        this.isEdit = null;
        this.setState({
          labelMsg: "",
          selectedVehicle: -1,
          showEndDate: false,
          showInput : false,
          startDt: "",
          endDt : ""
        })
      }
      catch(e){
        console.log(e);
      }
    })
  }
  onDeleteClick(ride){
    this.deleteToRide = ride;
    this.setState({
      showDeleteModal: true,
      labelMsg:"",
      endDt : null,
      startDt : null
    });
  }
  shareRide(ride){
    var url = glHostName + "/gl/users/rides/sharecustomride";
    Net.PostRequest(url, { idx: ride.idx}, (resp)=>{
      var response = JSON.parse(resp.response).data;
      if(resp.status !== 200){
        ride.errorMsg = response;
        this.forceUpdate();
        return;
      }
      else{
        if(response && response.tkn) ride.tkn = response.tkn;
        this.forceUpdate();
      }
    })
  }
  unshareRide(ride){
    var url = glHostName + "/gl/users/rides/unsharecustomride";
    Net.PostRequest(url, { idx: ride.idx, tkn: ride.tkn}, (resp)=>{
      var response = JSON.parse(resp.response).data;
      if(resp.status !== 200){
        ride.errorMsg = response;
        this.forceUpdate();
        return;
      }
      delete ride.tkn;
      this.forceUpdate();
    })
  }
  showCustomRideTile(ride){
    return(
      <div className="trip-mast flex" key={ride.idx}>
        <div className="trip-details ride">
          <div className="padding-bottom10">
            <div className="trip-date col-lg-3 margin-bottom15">{changeTo12HrFormat(ride.tsFr * 1000) + " to " + (ride.tsTo ? new Date(ride.tsFr * 1000).getDate() === new Date(ride.tsTo * 1000).getDate() ? timeFormat(ride.tsTo * 1000,true) : changeTo12HrFormat(ride.tsTo * 1000) : "NA")}</div>
            <button title="Edit" className="btn btn-warning btn-sm margin-left15" onClick={()=> this.onEditClick(ride)} disabled={this.state.createClick}><i className="icon  ajjas-edit"></i></button>
            <button title="Delete" className="btn btn-danger btn-sm margin-left15" data-toggle="modal" data-target="#deleteRide" onClick={()=> this.onDeleteClick(ride)}><i className="icon ajjas-delete"></i></button>
            {ride.tkn ? null : <button title="Share" className="btn btn-primary btn-sm margin-left15" onClick={()=> this.shareRide(ride)}><i className="icon ajjas-startshare"></i></button>}
            {
              ride.tkn ?
                [<button key="2" className="btn btn-sm btn-primary margin-left15" onClick={()=> this.unshareRide(ride)}>Stop Sharing</button>,
                  <a key="1" className="sharelink" href={sharingHostName + "?tkn=" + ride.tkn} target="_blank" rel="noopener noreferrer"> Open Shareable Link</a>]
                 : null
            }
            <Link className="sharelink" to={"/webapp/showridedetails?idx=" + ride.idx} target="_blank">Show in Map</Link>
            { ride.errorMsg ? <label className="margin-left15">{ride.errorMsg} </label> : null}
            <span className="sharelink" title="Vehicle">{ride.vid && this.bikes.find(bike=> bike.idx === ride.vid) ? this.bikes.find(bike=> bike.idx === ride.vid).mo : "-"}</span>
            <div className="clear"></div>
          </div>
          <div className="tabs flex">
            <span className="span-tiles">
            </span>
          </div>
        </div>
      </div>
    )
  }
  removeTile(idx){
    var data = this.customRides.findIndex( (d) => d.idx === idx);
    this.customRides.splice(data,1);
    this.forceUpdate();
  }
  dateChange(from ,to){
    if(from && from.length){
      this.setState({
        startDt : from[0].getTime(),
        showEndDate: true
      });
    }
    if(to && to.length){
      this.setState({
        endDt: to[0].getTime()
      })
    }
  }
  createCRide = () =>{
    this.isEdit = false;
    this.setState({
      showInput : true,
      startDt : "",
      endDt : "",
      selectedVehicle : -1,
      createClick : true,
      showEndDate : false
    });
  }

  onCancel = () =>{
    this.setState({
      showInput : false,
      createClick : false
    })
  }

  showForm = (bikes,selectedVehicle , startDt, endDt,isEdit ) =>{
    return(
      <div style={{border:"1px solid #9b9bb9b"}}>
        { bikes && bikes.length ?
          InputField('Select Vehicle',<DropDown className="form-control" options={bikes} defaultOption="Select Vehicle"
          value={selectedVehicle} unique="idx" name="nm" onChange={(e)=>this.selectVehicle(e)}/>,"col-lg-3")
          : null
        }
        {
          InputField('Select Start Date & Time',<DateInput disableMobile={true} defaultdate={isEdit ? startDt : null} maxdate={Date.now()} enableTime={true} onChange={(e) => this.dateChange(e, null)} />,"col-lg-3")
        }
        {
          this.state.showEndDate ?
            InputField('Select End Date & Time',<DateInput disableMobile={true} defaultdate={endDt} mindate={isEdit ? endDt : startDt} maxdate={isEdit ? ( GetTSForGivenDays(endDt,2) <= Date.now() ? GetTSForGivenDays(endDt,2) : Date.now()) : GetTSForGivenDays(startDt,2)} enableTime={true} onChange={(e) => this.dateChange(null, e)}/>,"col-lg-3")
          : null
        }
        {
          <div className="col-lg-3" style={{paddingTop:"2%"}}>
          {
            endDt ?
              <Button bsStyle="primary" id="ok" onClick={() => {isEdit ? this.UpdateRide() : this.getRides()}} >{isEdit ? "Update" : "Save"}</Button>
            : null
          }
          <Button bsStyle="primary" id="cancel" style={{marginLeft:"2%"}} onClick={() => this.onCancel()}>Cancel</Button>
          </div>
        }
      </div>
    )
  }
  render(){
    return(
      <div className="col-lg-12">
        <div className="col-lg-12">
          {
            !this.state.createClick ?
            <button className="btn btn-info" onClick={()=> this.createCRide()}>Create Custom Ride</button>
            : null
          }
          {
            this.state.showInput ? this.showForm(this.bikes, this.state.selectedVehicle, this.state.startDt, this.state.endDt, this.isEdit)
            : null
          }
        </div>
        <div className="col-lg-12 col-sm-12 col-xs-12" style={{paddingTop: "1%"}}>
          {
            this.customRides && this.customRides.length ?
            this.customRides.map((ride)=>{
              return this.showCustomRideTile(ride)
            }) : null
          }
        </div>
        <div className="modal fade bs-example-modal-lg" id="deleteRide" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
          { this.state.showDeleteModal ? <DeleteModal ride={this.deleteToRide} show={this.state.showDeleteModal} rideIndex={(idx)=> this.removeTile(idx)} onClose={()=> this.setState({showDeleteModal: false})}/>: null}
        </div>
      </div>
    )
  }
}

class DeleteModal extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      respText: "",
      disabledOk : false
    }
  }
  deleteRide(){
    var url = glHostName + "/gl/users/rides/deletecustomride";
    var data = {
      idx:this.props.ride.idx,
    };
    this.setState({
      disabledOk : true
    })
    try{
      Net.PostRequest(url, data, (resp)=>{
      if(resp.status === 200){
        // var response = JSON.parse(resp.response).data;
        var respData = {};
        this.props.rideIndex(data.idx);
        respData.respText = "Deleted Successfully ! ";
        this.setState(respData);
      }
      else
        this.setState({
          respText: JSON.parse(resp.response).data,
          disabledOk : false
        })
      })
    }
    catch(e){
      console.log(e)
    }
  }
  render(){
    return(
      <Modal show={this.props.show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are your sure to delete this custom ride ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <label>{this.state.respText}</label>
          <Button bsStyle="primary" id="ok" onClick={()=>this.deleteRide()} disabled={this.state.disabledOk || false} >Ok</Button>
          <Button id="close" onClick={this.props.onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}