import React from 'react';
import PropTypes from 'prop-types';
import Box from './box';
import Drawer from './drawer';
import { DEALER_MENU, getMenu, PROFILE_MENU } from  '../../lib/enums';

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuPath: []
    }
    this.menu = getMenu(this.props.type);
    if (window.localStorage.getItem("usrTyp") === "3")
      this.menu.screens = this.menu.screens.concat(DEALER_MENU.screens);
    if(this.props.type === "drawer") this.menu.screens = this.menu.screens.concat(PROFILE_MENU.screens);
  }
  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.MENU_CHANGE, this.onMenuChange);
  }
  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.MENU_CHANGE, this.onMenuChange);
  }

  onMenuChange = (menuPath) => {
    if (Array.isArray(menuPath)) {
      this.setState({
        menuPath: menuPath
      });
    }
  }
  render() {
    return (
      this.props.type === "box" ?
        <Box {...this.props} menu={this.menu} selMenuPath={this.state.menuPath} />
        : <Drawer {...this.props} menu={this.menu} selMenuPath={this.state.menuPath} />
    )
  }
}
Menu.propTypes = {
  type: PropTypes.string,
  location: PropTypes.object,
  name: PropTypes.string,
}