// Generic
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Col, Alert, ProgressBar, Breadcrumb } from 'react-bootstrap/lib';

// Components
import PanelView from '../components/panel/PanelView';
import SimplePanelContainer from '../components/panel/SimplePanelContainer';
import PanelSection from '../components/panel/PanelSection';

// Services
import { GetSalesPanelCount } from '../services/apiFunnel';
import { formatCurrency } from '../lib/common';

const { MENUPATH } = require('../lib/enums');

export default class SalesPanel extends React.Component {

  state = {};

  componentDidMount() {
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.SALES_PANEL);
    GetSalesPanelCount()
      .then((countObj) => this.setState({ countObj }))
      .catch((e) => this.setState({ err: e }));
  }

  onMenuSelect = (menu) => {
    this.props.router.replace(menu.link);
  }

  render() {
    let s = this.state.countObj;
    return (
      <div>

        <Col>
          <Breadcrumb>
            <Breadcrumb.Item componentClass='span'><Link to='/webapp/dashboard'>Dashboard</Link></Breadcrumb.Item>
            <Breadcrumb.Item active>Sales Panel</Breadcrumb.Item>
          </Breadcrumb>
        </Col>

        <Col>
          {
            s ?
              <PanelView bsStyle='success' heading='Sales Panel'>

                {/* Revenue Section */}
                <PanelSection heading='Revenue'>
                  <SimplePanelContainer count={getCountLink(formatCurrency(s.total_revenue))} description='Total Revenue' />
                  <SimplePanelContainer count={getCountLink(formatCurrency(s.daily_average))} description='Daily Average' />
                  <SimplePanelContainer count={getCountLink(formatCurrency(s.monthly_average))} description='Monthly Average' />
                  <SimplePanelContainer count={getCountLink(formatCurrency(s.projected_monthly))} description='Projected Monthly Earnings' />
                  <SimplePanelContainer count={getCountLink(formatCurrency(s.projected_yearly))} description='Projected Yearly Earnings' />
                </PanelSection>

                {/* Devices Section */}
                <PanelSection heading='Devices'>
                  <SimplePanelContainer count={getCountLink(s.total_pitched)} description='Ajjas Pitched' />
                  <SimplePanelContainer count={getCountLink(s.booked)} description='Booked' />
                  <SimplePanelContainer count={getCountLink(s.completed)} description='Installations Completed' />
                  <SimplePanelContainer count={getCountLink(s.cancelled)} description='Bookings Cancelled' />
                </PanelSection>

              </PanelView>
              : this.state.err
                ? <Alert className='text-center font-bold' bsSize='medium' bsStyle='danger'>{this.state.err}</Alert>
                : <ProgressBar active now={100} label='Loading, Please wait...' />
          }
        </Col>
      </div>

    );
  }
}
SalesPanel.propTypes = {
  router: PropTypes.object
}

function getCountLink(val) {
  return (<span style={{ color: 'green' }}>{val}</span>);
}