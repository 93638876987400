/**
 * TODO: Nest according to structure in future
 * All api services must be async and return a Promise either resolving or rejecting
 * Services are data agnostic, their only concern is ensuring proper data flow from UI to backend
 * Services handle url building
 */
import { browserHistory } from 'react-router';
const NET = require('../lib/net.js');
const crmhostname = require('../config.js').config.crmhostname;

/**
 * @function IsLoggedIn
 * @returns Success or Failure Promise depending on whether a user is logged in or not
 */
export function IsLoggedIn() {
  const url = crmhostname + '/crm/auth/isloggedin';
  return new Promise((resolve, reject) => {
    NET.GetRequest(url, (resp) => {
      let data = resp.response ? JSON.parse(resp.response) : null;
      if (resp.status !== 200) reject(data);
      else resolve(parseInt(data.code, 10));
    });
  });
}

/**
 * @function Login
 * @param {string} eml String Email
 * @param {string} pwd Encrypted Hash Password
 * @returns Success or Failure Promise for given credentials
 */
export function Login({ eml, pwd }) {
  const url = crmhostname + '/crm/auth/login';
  return new Promise((resolve, reject) => {
    NET.PostRequest(url, { eml, pwd, isDealer: true }, (resp) => {
      let data = JSON.parse(resp.response).data;
      if (resp.status !== 200) reject(data);
      else {
        window.localStorage.setItem('name', data.fn ? data.fn : 'User');
        window.localStorage.setItem('dep', parseInt(data.dep, 10));
        window.localStorage.setItem('des', parseInt(data.des, 10));
        window.localStorage.setItem('eml', data.eml);
        resolve(data);
      }
    });
  });
}

/**
 * @function Logout
 * @returns Success redirect or failure log
 */
export function Logout() {
  const url = crmhostname + '/crm/auth/logout/';
  NET.PostRequest(url, '', (resp) => {
    let data = JSON.parse(resp.response).data;
    if (resp.status !== 200) console.log(data);
    else {
      window.localStorage.clear();
      browserHistory.push('/dealership/');
    }
  });
}

