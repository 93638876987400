import React, { Component} from 'react';
import { getTriggerTypes ,saveNewTriType ,saveTrigger,getTriggersData} from '../../lib/db/triggers';
import {Alert, Col} from 'react-bootstrap/lib';
import {browserHistory} from 'react-router';
import { MENUPATH } from '../../lib/enums';
const { GeoFenceInput } = require('./gfinputs');
const TRIGGER_TYP = 2;

//GF = GEO FENCE

export default class AddGeoFence extends Component{
  constructor(props){
    super(props);
    this.state = {
      gFTypArr: [],
      selectedVehId: [],
      center: { lat: 20.5837, lng: 78.9629 },
      zoom :10,
      errMsg: "",
      disabled: false,
      triggerData: ""
    }
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentDidMount(){
    if(global.wsOnline){
      if(global.bikeDetails) {
        this.onBikes(global.bikeDetails);
      }
    }
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.ADD_GF );
    getTriggersData(TRIGGER_TYP,(err, gfData) =>{
      if(err){
        this.setState({
          infoMsg: err
        });
        return;
      }
      if(gfData && gfData.length) {
         this.setState({ triggerData: gfData})
      }
    });
  }

  onBikes = (bikes) => {
    global.bikeDetails = bikes;
    this.plus = bikes.filter((bike) => { return bike.did });
    if(this.plus && this.plus.length){
      getTriggerTypes(2 ,(err, response) =>{
        if(err) this.setState({ errMsg: err });
        else this.setState({ gFTypArr: response });
      });
    }
    else this.setState({ errMsg: "This feature is only available for Plus device users. Add a vehicle with Plus HW."})
  }

  addGeoFenceType(){
    this.setState({ addType: true });
  }

  onSaveGFNewType(){
    if(!this.newGFType){
      this.setState({errMsg: "Please Enter Geo Fence Type"});
      return;
    }
    this.setState({
      addType: false,
      title: this.newGFType,
      errMsg: ""
    });
    document.getElementById('applybutton').disabled = true;
    saveNewTriType({ nm: this.newGFType} , TRIGGER_TYP,(err, response) =>{
      if(err) this.setState({ errMsg: err });
      else {
        this.selectedType = response.idx;
        this.forceUpdate();
      }
    });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.onSaveGFNewType()
    }
    // this.forceUpdate();
  }

  onSelectAllVehicle(e){
    var vehID = [];
    var did = [];
    var items = document.getElementsByName('test');
    for (var i = 0; i < items.length; i++) {
      if (e.target.checked && items[i].type === 'checkbox') {
        vehID.push(parseInt(items[i].id));
        did.push(parseInt(items[i].value));
        items[i].checked = true;
      }
      else items[i].checked = false;
    }
    this.setState({ selectedVehId: vehID });
  }

  onVehicleSelect(e){
    var items = document.getElementById('selectAll'); items.checked = false;
    var vehId = parseInt(e.target.id);
    if(e && e.target.checked){
      this.state.selectedVehId.push(vehId);
    }
    else {
      for(var i = 0 ; i < this.state.selectedVehId.length ; i++) {
        if(this.state.selectedVehId[i] === vehId) {
          this.state.selectedVehId.splice(i, 1);
        }
      }
    }
  }

  onSaveGeoFence(data){
    var params = {
      title : data.newGFName,
      r: data.selectedShape.r,
      typ: 2,
      ts: Date.now(),
      clat: data.selectedShape.clat,
      clong: data.selectedShape.clong,
      vehIdArr: data.selectedVehId,//chnage name
    }
    if(data.selectedType) params.gfTyp = data.selectedType;
    if(data.desc) params.desc = data.desc;
    this.setState({ disabled: true})
    saveTrigger( params ,(err, response) =>{
      if(err) this.setState({ errMsg: "Server Unreachable. Please Try Again" });
      else browserHistory.push("/webapp/gf/view");
    });
  }

  render(){
    return(
      this.plus && this.plus.length?
      <GeoFenceInput
        header="Add Geo-fence Zone"
        gFTypArr={this.state.gFTypArr}
        onSaveGFNewType={this.onSaveGFNewType}
        bikeDetails = {global.bikeDetails}
        disabled = {this.state.disabled}
        onSaveGeoFence={ (e) => this.onSaveGeoFence(e)}
        errMsg = { this.state.errMsg ?  this.state.errMsg : null}
        addMap = {true}
        triggerData ={ this.state.triggerData}
      />
      : this.state.errMsg ?
        <Col> <Alert bsStyle="warning">{this.state.errMsg}</Alert> </Col>
        : null
    )
  }
}
