import React, { Component } from 'react';
import { DashBoardDropDown, DashBoardButton} from '../generic/input';
const { MAP_FILTERS, VEH_SHIP_STATS} = require('../lib/enums');
const GREEN_BUTTON = 1;
const BLUE_BUTTON = 3;
const GREY_BUTTON = 4;

export default class DashBoardFilter extends Component {
  constructor(props) {
    super(props);
    this.titleHashMap = { green: 0, red: 0, blue: 0, grey: 0}
    this.datacalled = false;
    this.subTitleCount = { green: { 1: 0, 2: 0},  red: { 1: 0, 2: 0} }
    this.newId = null;
    this.subId = null
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps && nextProps.vehicleStatus && Object.keys(nextProps.vehicleStatus).length > 0){
      if(nextProps.filt){
        if(nextProps.filt && nextProps.subId){
          if(this.newId !== nextProps.filt && this.subId !==  nextProps.subId){
            this.changeSelectedColor = [nextProps.filt, nextProps.subId];
            if(nextProps.filt === 1) {
              this.onClickButton(nextProps.filt, nextProps.subId);
            }
          }
        }
        else if(!nextProps.subId && (this.newId !== nextProps.filt)){
          this.changeSelectedColor = "";
          this.onClickButton(nextProps.filt);
        }
      }

    }
  }

  onChangeVehicleStatus = (currentStatus, prevStatus) =>{
    this.decrementVehStatus(prevStatus);
    var subCount = "";
    if(currentStatus.stat === VEH_SHIP_STATS.ENROUTE_DEST || currentStatus.stat === VEH_SHIP_STATS.AT_DEST){
      this.titleHashMap["green"] += 1;
      if(currentStatus.stat === VEH_SHIP_STATS.ENROUTE_DEST){
        subCount = this.subTitleCount["green"][1];
        this.subTitleCount["green"][1] = subCount + 1
      }
      if(currentStatus.stat === VEH_SHIP_STATS.AT_DEST){
        subCount = this.subTitleCount["green"][2];
        this.subTitleCount["green"][2] = subCount + 1
      }
    }
    else if(currentStatus.stat === VEH_SHIP_STATS.ENROUTE_SRC){
      this.titleHashMap["blue"] += 1;
    }
    else if(currentStatus.stat === VEH_SHIP_STATS.AT_SRC_END){
      this.titleHashMap["grey"] += 1;
    }
    this.forceUpdate();
  }

  decrementVehStatus = (id) =>{
    if(id === VEH_SHIP_STATS.AT_SRC_START ) {
      this.titleHashMap["grey"] = this.titleHashMap["grey"] - 1;
    }
    else if(id === VEH_SHIP_STATS.ENROUTE_SRC) this.titleHashMap["blue"] = this.titleHashMap["blue"] - 1;
    else if(id === VEH_SHIP_STATS.ENROUTE_DEST || id === VEH_SHIP_STATS.AT_DEST){
      this.titleHashMap["green"] = this.titleHashMap["green"] - 1;
      if(id === VEH_SHIP_STATS.ENROUTE_DEST) this.subTitleCount["green"][1] = this.subTitleCount["green"][1] - 1;
      else if(id === VEH_SHIP_STATS.AT_DEST) this.subTitleCount["green"][2] = this.subTitleCount["green"][2] - 1;
    }
    else if(id === VEH_SHIP_STATS.ENROUTE_SRC) this.titleHashMap["grey"] = this.titleHashMap["grey"] - 1;
  }

  getTitleForFilter = (data) => {
    this.lastSeenData = {};
    if (this.props.selectedVeh && this.props.selectedVeh.length) {
      this.props.selectedVeh.forEach(veh => {
        if (veh.did && data && data[veh.did]) {
          this.lastSeenData[veh.did] = data[veh.did];
        }
      });
      data = this.lastSeenData;
    }
    this.titleHashMap = { green: 0, red: 0, blue: 0, grey: 0};
    this.subTitleCount = { green: { 1: 0, 2: 0},  red: { 1: 0, 2: 0}}
    var subCount = "";
    var blueCount = this.titleHashMap["blue"];
    var greenCount = this.titleHashMap["green"];
    var redCount = this.titleHashMap["red"];
    var greyCount = this.titleHashMap["grey"];
    if(data && Object.keys(data).length > 0){
      var lsData = Object.keys(data);
      if(lsData && lsData.length){
        lsData.forEach(ls=>{
          var temp = data[ls];
          var vid = this.props.deviceHashmap[temp.devid];
          if(vid) {
            if(this.props.vehicleStatus){
              var status = this.props.vehicleStatus[vid];
              if(status === VEH_SHIP_STATS.ENROUTE_SRC ) blueCount += 1;
              if(status === VEH_SHIP_STATS.ENROUTE_DEST  || status ===  VEH_SHIP_STATS.AT_DEST  ){
                greenCount += 1;
                if(status === VEH_SHIP_STATS.ENROUTE_DEST ){
                  subCount = this.subTitleCount["green"][1];
                  this.subTitleCount["green"][1] = subCount + 1
                }
                if(status === VEH_SHIP_STATS.AT_DEST){
                  subCount = this.subTitleCount["green"][2];
                  this.subTitleCount["green"][2] = subCount + 1
                }
              }
              else {
                greyCount += 1;
              }
            }
          }
        });
        this.titleHashMap["grey"] = greyCount;
        this.titleHashMap["green"] = greenCount;
        this.titleHashMap["blue"] = blueCount;
        this.titleHashMap["red"] = redCount;
        this.forceUpdate()
      }

    }
  }

  onClickButton = (id, subId) => {
    if(this.props.data) {
      this.newId = id;
      if(subId) this.subId = id;
    }

    var newlsDid = [];
    var lsData = Object.keys(this.props.data);
    if(lsData && lsData.length){
      lsData.forEach(ls=>{
        var did = parseInt(ls);
        var temp = this.props.data[did];
        var vid = this.props.deviceHashmap[temp.devid];

        if(vid){
          if(this.props.vehicleStatus){
            var status = this.props.vehicleStatus[vid];
            if(id === GREY_BUTTON && (!status || status === VEH_SHIP_STATS.AT_SRC_START || status === VEH_SHIP_STATS.AT_SRC_END)) {
              newlsDid.push(did);
            }
            else if(id === BLUE_BUTTON && status === VEH_SHIP_STATS.ENROUTE_SRC) {
              newlsDid.push(did);
            }
            else if(id === GREEN_BUTTON && subId){
              if(subId === 1 && status === VEH_SHIP_STATS.ENROUTE_DEST) {
                newlsDid.push(did);
              }
              else if(subId === 2 && status === VEH_SHIP_STATS.AT_DEST) {
                newlsDid.push(did);
              }
            }
          }
        }
      });
      this.props.changeFilter(newlsDid, id, subId);
    }
  }

  onClickMenu = (id, subid) => {
    var loc = this.props.location;
    var newUrl = loc.pathname;
    if(loc.query && loc.query.vid){
      if(loc.query && Array.isArray(loc.query.vid)){
        loc.query.vid.forEach((d,i)=> {
          if(i === 0) newUrl += "?vid=" + d;
          else newUrl += "&vid=" + d;
        });
      }else newUrl += "?vid=" + loc.query.vid;

      if(id){
        newUrl += "&filt=" + id;
        if(subid) newUrl += "&subId=" + subid.id;
      }
    }
    else{
      if(id){
        newUrl += "?filt=" + id;
        if(subid) newUrl += "&subId=" + subid.id;
      }
    }
    this.props.router.replace(newUrl);
    if(subid && subid.id) this.changeSelectedColor = [id, subid.id];
    if(!subid) this.changeSelectedColor = "";
    if(id === GREEN_BUTTON) this.onClickButton(GREEN_BUTTON, subid.id);
    // else if(id === 2) this.onClickButton(2, subid.id);
    else if(id === BLUE_BUTTON) this.onClickButton(BLUE_BUTTON);
    else if(id === GREY_BUTTON) this.onClickButton(GREY_BUTTON);
  }

  ViewAllClick = () =>{
    var newUrl = this.props.location.pathname;
    this.props.router.replace(newUrl);
    var didArr = []
    var lsData = Object.keys(this.props.data);
    lsData.forEach(d=> didArr.push(parseInt(d)));
    this.props.changeFilter(didArr, "", "");
  }

  render() {
    return (
      <div className="trips_btn">
        <DashBoardButton onHover="View all vehicle" buttonStyle={{width: "44px", borderColor: "#00a8e2"}}
          style={{color: "#00a8e2"}} onClick={()=>this.ViewAllClick()} title="All"
        />
        <DashBoardButton id={4} onHover="Vehicle not assigned" buttonStyle={{backgroundColor: "#9b9b9b", marginLeft: "7px", width: "35px"}}
          style={{color: "white"}} onClick={()=>this.onClickMenu(4)} title={this.titleHashMap["grey"]}
        />
        <DashBoardButton id={3} onHover="Vehicle is returning to source" buttonStyle={{backgroundColor: "#00a8e2", marginLeft: "7px", width: "35px"}}
          style={{color: "white"}} onClick={()=>this.onClickMenu(3)} disabled={this.titleHashMap["blue"] ? false : true} title={this.titleHashMap["blue"]}
        />
        {
          MAP_FILTERS.map((d,i)=>{
            return(
              <DashBoardDropDown key={i} className="width35px color-ffffff" id={d.id} title={this.titleHashMap[d.Menuid]}
                style={{backgroundColor: d.bgcolor, marginLeft: "7px"}} options={d.options} optionStyle={d.style}
                changeColor={ this.changeSelectedColor ? this.changeSelectedColor : null} onHover={d.onHover}
                noCaret={true} Menuid ={d.Menuid} subtitleCount={this.subTitleCount[d.Menuid]}
                onMenuClick={(id)=>this.onClickMenu(d.id, id)}>
              </DashBoardDropDown>
            )
          })
        }

      </div>
    )
  }
}



