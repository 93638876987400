import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap/lib';

export default class SimplePanelContainer extends React.PureComponent {
  render() {
    return (
      <Col sm={12} md={6}>
        <Row>
          <Col xs={6}>
            <span className='lead'>{this.props.description}</span>
          </Col>
          <Col xs={6}>
            <span className='lead'><b>{this.props.count}</b></span>
          </Col>

          <br /><hr />
        </Row>
      </Col>
    )
  }
}
SimplePanelContainer.propTypes = {
  count: PropTypes.node,
  description: PropTypes.string
}