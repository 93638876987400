import React, { Component} from 'react';
import {browserHistory} from 'react-router';
import {Col, Alert} from 'react-bootstrap/lib';
import { getTriggersData ,getTriggerTypes,editTrigger } from '../../lib/db/triggers';
import { clone } from '../../lib/jsonUtils';
import { MENUPATH } from '../../lib/enums';
const { GeoFenceInput } = require('./gfinputs');
const TRIGGER_TYP = 2;

export default class EditGeoFence extends Component{
  constructor(props){
    super(props);
    this.state = {
      disabled: false,
      errMsg: "",
      nameErr: false,
      geoErr: false,
      vehErr: false,
      bikes: "",
      triggerIdData: ""
    }
    this.gfTypeMap = {};
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentDidMount(){
    if(global.wsOnline){
      if(global.bikeDetails) this.onBikes(global.bikeDetails);
    }
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.VIEW_GF);
  }

  onBikes = (bike) => {
    global.bikeDetails = bike;
    let bikes = clone(global.bikeDetails);
    getTriggersData(TRIGGER_TYP ,(err ,gfData ) => {
      if(err){
        this.setState({
          errMsg: err
        });
        return;
      }
      if(gfData && gfData.length) {
        if(this.props.location && this.props.location.query && this.props.location.query.tid) {
          getTriggerTypes(TRIGGER_TYP , (e, triTyp) => {
            if(triTyp && triTyp.length) {
              triTyp.forEach((d)=>{
                this.gfTypeMap[d.idx] = d.nm;
              });
            }
            var tidData = gfData.find(d=> d.idx === parseInt(this.props.location.query.tid));
            if(tidData){
              for (let i = 0; i < bikes.length; i++) {
                if (tidData.vehIdArr.includes(bikes[i].idx)) bikes[i].checked = true;
                else bikes[i].checked = false;
              }
              this.trigger = clone(tidData);
              this.setState({
                triggerData: gfData,
                triggerIdData: tidData,
                gFTypArr: triTyp,
                bikes
              });
            }
          });
        }
      }
    });
  }

  validateInputs = (newData) => {
    var OldData = this.trigger;
    var changedData = {};
    if(newData.newGFName && newData.newGFName !== OldData.title) changedData.title = newData.newGFName;
    if(newData.selectedShape){
      if(newData.selectedShape.r !== OldData.r ) changedData.r = newData.selectedShape.r
      if(newData.selectedShape.clat !== OldData.clat ) changedData.clat = newData.selectedShape.clat;
      if(newData.selectedShape.clong !== OldData.clong ) changedData.clong = newData.selectedShape.clong;
    }
    if(newData.selectedType){
      if(!OldData.gfTyp) changedData.gfTyp = newData.selectedType;
      else if(newData.selectedType !== OldData.gfTyp) changedData.gfTyp = newData.selectedType;
    }
    if(newData.desc){
      if(!OldData.desc) changedData.desc = newData.desc;
      else if(newData.desc !== OldData.desc) changedData.desc = newData.desc;
    }
    if(newData.selectedVehId && newData.selectedVehId.length){
      var changeVid = this.getChangeArray(OldData.vehIdArr, newData.selectedVehId)
      if(changeVid) changedData.vehIdArr = newData.selectedVehId;
    }
    if(Object.keys(changedData).length) return changedData;
    else return -1;
  }

  getChangeArray(arr1, arr2){
    var changedVid = "";
    if(arr1.length > arr2.length) changedVid = arr1.filter(d => arr2.indexOf(d) < 0);
    else changedVid = arr2.filter(d => arr1.indexOf(d) < 0);
    if(changedVid && changedVid.length) return true;
  }

  onSaveGeoFence(data){
    let validated = this.validateInputs(data);
    if(validated){
      if(validated === -1) browserHistory.push("/webapp/gf/view");
      else{
        this.setState({ disabled: true})
        validated.mts = Date.now();
        validated.idx = parseInt(this.props.location.query.tid);
        editTrigger(validated,TRIGGER_TYP, (err, gfData) =>{
          if(err) this.setState({ errMsg: "Server Unreachable. Please Try Again" });
          else browserHistory.push("/webapp/gf/view");
        });
      }
    }
  }


  render(){
    return(
      this.state.triggerIdData ?
      <GeoFenceInput
        header="Edit geo-fence zone"
        gFTypArr={this.state.gFTypArr}
        triggerData ={ this.state.triggerData}
        disabled = {this.state.disabled}
        onSaveGeoFence={ (e) => this.onSaveGeoFence(e)}
        gfType={this.state.triggerIdData && this.state.triggerIdData.gfTyp ? this.gfTypeMap[this.state.triggerIdData.gfTyp] : null}
        bikeDetails = {this.state.bikes}
        triggerIdData = {this.state.triggerIdData}
        nameErr = { this.state.nameErr ?  this.state.nameErr : null}
        vehErr = { this.state.vehErr ?  this.state.vehErr : null}
        geoErr = { this.state.geoErr ?  this.state.geoErr : null}
        errMsg = { this.state.errMsg ?  this.state.errMsg : null}
        viewMap = {true}
      />
      : this.state.errMsg ?<Col lg={10} md={9} sm={12} xs={12} > <Alert bsStyle="warning">{this.state.errMsg}</Alert> </Col>: null
    )
  }
}