import React, { Component } from 'react';
import { VehMultiDropDown } from '../inputs/vehMultiSelect';
import { browserHistory } from 'react-router';
import { getAlertsFromDb } from '../../lib/db/triggers';
import { Col, Row, Alert, ListGroupItem ,Table} from 'react-bootstrap/lib';
import { GetDateFromNow, timeFormat, StampTODate } from '../../lib/dtconv';
import { DateInput,LoadingScreen } from '../../generic/input';
import { MENUPATH } from "../../lib/enums";
import { compareTwoArrays, isVehValid, _setNewUrl, getVidArray, isValidFromTS, isValidToTS} from '../../lib/common';

export default class AlertTiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      labelMsg: "",
      tileData: "",
      errMsg: "",
      from : "",
      to: ""
    }
    this.vehIds = [];
    this.vehMap = {};
    this.proVehs = false;
    if (global.bikeDetails) this.vehicles = global.bikeDetails;
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.addEventListener(global.EE.EVENT_TYPES.ON_NEW_NOTIF, this.getLiveNotif);
  }

  componentDidMount() {
    if (global.wsOnline) this.onBikes(global.bikeDetails);
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.ALERTS );
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.ON_NEW_NOTIF, this.getLiveNotif);
  }

  componentWillReceiveProps(newProps){
    var diffElem ,newVidArr = [];
    if(newProps.location && newProps.location.query && newProps.location.query.vid){
      if (Array.isArray(newProps.location.query.vid) === false) {
        newVidArr.push(parseInt(newProps.location.query.vid));
        diffElem = compareTwoArrays(newVidArr, this.vehIds );
      } else {
        newProps.location.query.vid.forEach(v => {
          newVidArr.push(parseInt(v));
        });
        if(newVidArr.length) diffElem = compareTwoArrays(newVidArr, this.vehIds );
      }
    }
    if( this.vehicles &&  (this.state.from !== parseInt(newProps.location.query.from) 
      || this.state.to !== parseInt(newProps.location.query.to) || diffElem )) 
      this.showAlerts(newProps.location.query,newVidArr);
  }

  showAlerts(loc ,vids){
    this.setState({
      from: loc.from,
      to: loc.to
    });
    this.showAlertTiles(vids)
  }

  setDateInURL() {
    var newUrl;
    if (this.props.location) {
      var loc = this.props.location;
      this.setState({
        from: loc.query && loc.query.from && isValidFromTS(parseInt(loc.query.from), GetDateFromNow(9) * 1000 ) ? parseInt(loc.query.from) : GetDateFromNow(9) * 1000,
        to: loc.query && loc.query.to && isValidToTS(parseInt(loc.query.to), new Date().setHours(23, 59, 59, 999)) ? parseInt(loc.query.to) : new Date().setHours(23, 59, 59, 999)
      }, () => {
        if (loc.query && loc.query.vid) {
          this.vehIds = [];
          this.vehIds = getVidArray(loc.query.vid);
          if (this.vehicles && this.vehIds && this.vehIds.length) this.validVeh = isVehValid(this.vehicles, this.vehIds);
          if (this.validVeh) {
            newUrl = _setNewUrl(loc, this.vehIds, this.state.from, this.state.to);
            this.props.router.replace(newUrl);
            this.showAlertTiles(this.vehIds)
          } else {
            this.setState({
              isLoading: false
            });
          }
        } else if (this.vehicles && this.vehicles.length) {
          newUrl = _setNewUrl(loc, null, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
          this.showAlertTiles(Object.keys(this.vehMap));
        }
      });
    }
  }

  getLiveNotif = (notif) =>{
    var loc = this.props.location;
    var toTs = Math.trunc(parseInt(loc.query.to)/1000)
    if(loc.query && loc.query.to && toTs >= notif.dc){
      if(loc && loc.query && loc.query.vid){
        if(notif && this.vehIds.indexOf(notif.vid) > -1) {
          this.showAlertTiles([notif.vid]);
        }
      }else this._addNewNotif(notif);
    }
  }

  _addNewNotif(notif){
    var alerts  = this.state.tileData;
    alerts.push(notif)
    alerts = alerts.sort((a, b) => b.dc - a.dc)
    this.createView(alerts);
    this.setState({
      tileData: alerts,
      isLoading: false,
      errMsg : ""
    });
  }

  onBikes = (bikes) => {
    if (bikes && bikes.length === 0){
      this.setState({
        isLoading: false,
        errMsg : "No vehicle added please add vehicle from app"});
      return;
    }
    if (bikes && bikes.length) {
      bikes = bikes.filter(veh => {
        this.vehMap[veh.idx] = { nm: veh.vmk + " " + veh.mo, no: (veh.treg || veh.reg) };
        return veh;
      });
    }
    this.vehicles = bikes;
    this.setState({
      isLoading : true
    });
    this.setDateInURL();
  }

  showAlertTiles(vidArr) {
    var params = {};
    vidArr = vidArr.map(v => parseInt(v));
    this.vehIds = vidArr;
    params.vids = vidArr;
    params.from = Math.trunc(this.state.from / 1000);
    params.to = Math.trunc(this.state.to / 1000);
    if (global.userId) params.allNotif = true;
    getAlertsFromDb(params, (err, alerts) => {
      if (err) {
        this.setState({
          errMsg: err,
          isLoading: false
        });
        return;
      }
      else {
        if (alerts && alerts.length === 0) {
          this.setState({
            isLoading: false,
            errMsg: "Alert list is empty. Add triggers to view list here."
          });
        } else {
          var sortedData = alerts.sort((a, b) => b.dc - a.dc);
          this.createView(sortedData);
          this.setState({
            tileData: sortedData,
            isLoading: false,
            errMsg : ""
          })
        }
      }
    });
  }

  createView(alerts) {
    this.alertTile = [];
    var icon = "";
    if (alerts && alerts.length) {
      alerts.forEach((alt) => {
        if (alt.t === 201) icon = "speed-alert";
        else if (alt.t === 202) icon = "geo-fence";
        else icon = "info";
        this.alertTile.push({
          id: alt, data:
            <div>
              <Table style={{ borderTop: "hidden", marginBottom: "0px" }}>
                <tbody>
                  <tr>
                    <td style={{  width: "1%",borderBottom: "hidden" ,borderRight: "none" }}><i className={"icon ajjas-" + icon}
                      style={{ color:"#52d2ff", paddingRight: "2%", fontSize: "38px", width: "40px" }} /></td>
                    <td style={{ width: "30%", borderBottom: "hidden", borderLeft: "none", paddingLeft: "0px" }}><b>{alt.h ? alt.h : ""}</b>
                      <div style={{marginTop: "1%"}}>{alt.msg ? alt.msg : ""}
                        <div align="right" className="font-size14px color-9b9b9b" >{alt && alt.dc ? StampTODate(alt.dc * 1000) + " " + timeFormat(alt.dc * 1000) : ""}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
        });
      });
    }
  }

  onLink(e) {
    var newUrl = "/webapp/notification";
    if (e && e.vid) {
      newUrl += "?vid=" + e.vid;
    }
    browserHistory.push(newUrl);
  }

  clearAll = (vehs) => {
    var loc = this.props.location
    var vids = [];
    var newUrl = loc.pathname;
    vehs.forEach(veh => {
      vids.push(veh.idx);
    });
    if (loc && loc.query && loc.query.from && loc.query.to) newUrl += "?from=" + loc.query.from + "&to=" + loc.query.to;
    this.props.router.replace(newUrl);
    this.showAlertTiles(vids);
  }

  onClickApplyButton = (selectedIds) => {
    var vids = [], selIds = [];
    selectedIds.forEach(veh => {
      if (veh && veh.checked) {
        vids.push(veh.idx);
        selIds.push(veh);
      }
    });
    if (selIds && selIds.length) {
      var newUrl = _setNewUrl(this.props.location, vids, this.state.from, this.state.to);
      this.props.router.replace(newUrl);
      // this.showAlertTiles(vids);
    }
    else return;
  }

  dateChange = (e) => {
    var newUrl, vidArr = [];
    if (e.length > 1) {
      this.setState({
        from: e[0].getTime(),
        to: e[1].setHours(23, 59, 59, 999),
        isLoading: true
      }, () => {
        if (this.props.location && this.props.location.query && this.props.location.query.vid) {
          if (Array.isArray(this.props.location.query.vid) === false) {
            vidArr.push(parseInt(this.props.location.query.vid));
          } else {
            this.props.location.query.vid.forEach(v => {
              vidArr.push(parseInt(v));
            });
          }
          newUrl = _setNewUrl(this.props.location, vidArr, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
          this.showAlertTiles(vidArr);
        }
        else {
          newUrl = _setNewUrl(this.props.location, null, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
          this.showAlertTiles(Object.keys(this.vehMap));
        }
      });
    }
  }


  render() {
    return (
      <Col>
        <Row>
          <Col lg={4} lgOffset={5} md={6} mdOffset={2} sm={6} xs={12} className="vehicle-padding">
            {
              this.vehicles && this.vehicles.length > 1 ?
                <VehMultiDropDown
                  headerName="Vehicle Filter"
                  headingStyle={{ fontSize: "15px", border: "1px solid #ccc", borderRadius: "5px", width: "100%" }}
                  clearStyle={{ color: "#d6001a", fontSize: "14px" }}
                  selectedVehs={this.props.location.query && this.props.location.query.vid ? Array.isArray(this.props.location.query.vid) === true ?
                    this.props.location.query.vid : [this.props.location.query.vid] : 0}
                  options={this.vehicles}
                  onClear={(vehs) => this.clearAll(vehs)}
                  onApply={(selectedIds) => this.onClickApplyButton(selectedIds)} />
                : null
            }
          </Col>
          {
            this.state.from && this.state.to && this.vehicles && this.vehicles.length ?
              <Col lg={3} md={4} sm={6} xs={12}>
                <DateInput height="40px" defaultdate={[this.state.from, this.state.to]} mode={true} mindate={GetDateFromNow(9) * 1000} maxdate={new Date().setHours(23, 59, 59, 999)} onChange={(e) => this.dateChange(e)}></DateInput>
              </Col>
              : null
          }
        </Row>

        {
          this.alertTile && this.alertTile.length && this.state.tileData.length && !this.state.errMsg  ?
            <Row style={{ marginTop: "2%" }}>
              <Col lg={12} md={12} sm={12} xs={12} className="overflowY-auto" style={{ height: "75vh" }} >
                {
                  this.alertTile.map((d, i) => {
                    return (
                      <ListGroupItem style={{ position: "initial", marginBottom: "2%", borderRadius: "4px", padding: "0px", boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.1)" }} header={d.data}
                        key={i}></ListGroupItem>
                    )
                  })
                }
              </Col>
            </Row>
            :
            this.state.isLoading ? <LoadingScreen/> :
            null
        }
        {
          this.state.errMsg ?
            <Row style={{ marginTop: "2%" }}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Alert bsStyle="warning">{this.state.errMsg}</Alert>
              </Col>
            </Row>
          : null
        }
      </Col>
    )
  }
}