import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { logout } from '../lib/common';
import PropTypes from 'prop-types';
import { Image, Col, DropdownButton, MenuItem } from 'react-bootstrap/lib';
import { getFromStore, createStore } from '../lib/db/common';
import Menu from '../components/menu/index';

/* if user authorized */
export class Header extends Component {
  constructor(props) {
    super(props);
    this.state={
      name: "",
    }
  }

  componentDidMount() {
    createStore('user');
    getFromStore('user', 'name').then(name => {
      this.setState({
        name: name
      })
    }).catch(() => {
      return;
    });
  }

  onProfileClick = () => {
    let dd_item = document.getElementById("dd_item");
    dd_item.parentElement.style.width = "170px";
  }

  navigateToLink(e) {
    let link = e.target.getAttribute("link");
    if (link) browserHistory.push(link);
  }

  onLogout() {
    logout();
  }

  render() {
    return (
      <div>
        <nav className="toolbar">
          <div className="toolbar__navigation">
            <Col xs={3} sm={4} className="drawer_btn" >
              <Menu type="drawer" {...this.props} name={this.state.name}/> 
            </Col>
            <Col xs={6} sm={5} md={9} lg={9}>
              <a href="/webapp/dashboard"><Image className="toolbar_image" src={require("../images/logo-lat.png")}/></a>
            </Col>
            <Col md={3} lg={3} className="profile_dd" style={{paddingRight: "0"}}> 
              <div className="float-right">
                <DropdownButton noCaret pullRight id="profile" onClick={this.onProfileClick} style={{ borderStyle: "none", backgroundColor: "#f8f8f8", overflow: "hidden", boxShadow: "none"}}
                  title={<span><i className="icon ajjas-profile font-size40px" /><span className="caret" style={{ marginTop: "-20%" }}></span></span>}>
                  <MenuItem id='dd_item' className="overflowY-auto" style={{ paddingBottom: '4px', paddingTop: "4px" }}><span className='font-bold font-size18px'>{this.state.name}</span> </MenuItem>
                  <hr className="margin-top0 margin-bottom0" />
                  <MenuItem style={{ paddingTop: "4px" }} onClick={this.navigateToLink} link="/webapp/sessions">
                    <i className="icon ajjas-session margin-right10 font-size13px" />App Sessions
                  </MenuItem>
                  <hr className="margin-top0 margin-bottom0" />
                  {
                    window.localStorage.getItem("usrTyp") !== "3" ?
                      <MenuItem style={{ paddingTop: "4px" }} onClick={this.navigateToLink} link="/webapp/accountdetails">
                        <i className="icon ajjas-plan margin-right10 font-size13px" />Account Details
                          </MenuItem>
                      : null
                  }
                  <hr className="margin-top0 margin-bottom0" />
                  {
                    window.localStorage.getItem("usrTyp") === "3"
                      ? <MenuItem style={{ paddingTop: "4px" }} onClick={this.navigateToLink} link="/webapp/profilesettings">
                        <i className="icon glyphicon glyphicon-user margin-right10" style={{ marginLeft: "5px" }} />Profile
                            </MenuItem>
                      : null
                  }
                  <MenuItem className="font-size14px" style={{ paddingTop: "4px" }} onClick={this.onLogout}><i className="icon ajjas-logout margin-right10 font-size13px" />Signout</MenuItem>
                </DropdownButton>
              </div>
            </Col>
          </div>
        </nav>
      </div>
    )
  }
}
Header.propTypes = {
  headNav: PropTypes.string,
  userName: PropTypes.string,
  navigator: PropTypes.bool,
  location: PropTypes.object
}

export class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.navigator = false;
  }
  checkNavBar() {
    var pathname = window.location.pathname;
    if (pathname === '/webapp' || pathname === '/webapp/logout' || pathname === '/webapp/') {
      this.link = "/webapp/";
      this.navigator = false;
      this.profile = false;
    }
    else if (this.props.navigator === false) {
      this.navigator = false;
      this.profile = false;
      this.link = "/webapp/";
    }
    else if( pathname === '/webapp/logout' ){
      this.navigator = true;
      this.profile = false;
    }
    else {
      this.link = "/webapp/dashboard";
      this.navigator = true;
      this.profile = true;
    }
  }

  render() {
    this.checkNavBar();
    // var getDate = new Date();
    // var copyRightYear = getDate.getFullYear();
    return (
      <div>
        {this.navigator && this.profile ?
          <footer className="text-center footer-padding " >
            <p style={{ color: '#9b9b9b', fontSize: '15px' }}>© 2018, All rights reserved by HPS Lab Designs</p>
          </footer>
          : null
        }
      </div>
    );
  }
}


