import React, { Component} from 'react';
import {browserHistory} from 'react-router';
import {Col, Row, Alert, ListGroupItem} from 'react-bootstrap/lib';
import { getTriggersData, deleteTrigger } from '../../lib/db/triggers';
import {InfoModal, LoadingScreen} from '../../generic/input';
import { MENUPATH } from '../../lib/enums';
const TRIGGER_TYP = 1;

export default class ViewSpeedLimit extends Component{
  constructor(props){
    super(props);
    this.state = {
      errMsg: "",
      showVehicle: false,
      data: [],
      isLoading : true
    }
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentDidMount() {
    if(global.wsOnline){
      if(global.bikeDetails) this.onBikes(global.bikeDetails);
    }
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.VIEW_SL );
    getTriggersData(TRIGGER_TYP,(err, data)=>{
      if(err){
        this.setState({
          errMsg: err,
          isLoading : false
        });
        return;
      }
      else{
        if(data && data.length === 0){
          this.setState({
            isLoading : false,
            errMsg: "Speed Alert list is empty. Add speed limit to view list here."
          });
        }else{
          var sortedData = data.sort((a,b) => b.ts - a.ts);
          this.createView(sortedData);
          this.setState({
            data: sortedData,
            isLoading: false
          })
        }
      }
    });
  }

  onBikes = (bikes) => {
    this.bikeDetails = bikes;
    this.forceUpdate();
  }

  onViewVehicle(e){
    var vehArr = this.spdAlertTile.filter(d=> d.id.idx === parseInt(e.target.value)) //vehArr[0].id.vehIdArr
    this.vehicleDetails = this.bikeDetails.filter(d=> vehArr[0].id.vehIdArr.includes(d.idx))
    this.setState({ showVehicle: true });
  }

  onVehicleClose = ()=>{
    this.setState({ showVehicle: false });
  }

  onDelete = (d)=>{
    var del = window.confirm("Are you sure you want to delete?");
    if(del){
      var index;
      var vidArr = d.vehIdArr;
      index = this.spdAlertTile.findIndex((x,i) => {
        return (x.id.idx === d.idx)
      });
      if(vidArr && vidArr.length){
        deleteTrigger({ vehIdArr: vidArr, idx: d.idx}, TRIGGER_TYP, (err, response) =>{
          if(err) this.setState({ errMsg: err });
          else {
            this.spdAlertTile.splice(index, 1);
            this.forceUpdate();
          }
        });
      }
    }
  }

  onEdit = (d)=>{
    var newUrl = "/webapp/sl/edit?tid=" + d.idx;
    browserHistory.push(newUrl);
  }

  createView(data){
    this.spdAlertTile = [];
    if(data && data.length){
      data.forEach(d=>{
        this.spdAlertTile.push({
          id: d ,data:
          <div>
            <Row>
              <Col md={4} xs={6} >
                <Col xs={12} style={{ fontSize: "14px", color: "#b5b5b5", marginTop:"7%"}}>Trigger Title</Col>
                <Col xs={12} style={{fontSize: "16px", color: "#788995", marginTop:"4%"}}>{d.title ? d.title : "-"}</Col>
              </Col>
              <Col md={4} xs={6} >
                <Col xs={12} style={{ fontSize: "14px", color: "#b5b5b5", marginTop:"7%"}}>Speed Limit</Col>
                <Col xs={12} style={{fontSize: "16px", color: "#788995", marginTop:"4%"}}>{d.spdLmt ? d.spdLmt + " km/h": "-"}</Col>
              </Col>
              <Col md={4} xs={6} >
                <Col xs={12} style={{ fontSize: "14px", color: "#b5b5b5", marginTop:"7%"}}>For Continuous</Col>
                <Col xs={12} style={{fontSize: "16px", color: "#788995", marginTop:"4%"}}>{d.thrshld ? (d.thrshld/ 1000) + " sec": "-"}</Col>
              </Col>
            </Row>
            <div style={{background: "#f5f5f5", marginLeft: "-15px", marginRight: "-15px", marginTop:" 19px", marginBottom: "-15px", height: "40px"}}>
              <button value={d.idx} style={{paddingTop:"10px",  paddingLeft:" 25px", color: "#0094de", fontSize:"14px"}}
              onClick={(e)=>this.onViewVehicle(e)}>View Assigned vehicles</button>
              <button  style={{float:"right", paddingRight:"20px", paddingTop:"10px"}}><i className="icon ajjas-delete"  id={d.idx}
              onClick={()=>this.onDelete(d)} style={{color: "#0094de"}}/></button>
              <button value={d.idx} style={{float:"right", paddingRight:"30px", paddingTop:"10px"}}><i className="icon ajjas-edit" style={{color: "#0094de"}}
              onClick={()=>this.onEdit(d)}/>
              </button>
            </div>
          </div>
        });
      })
    }
  }

  render(){
    return(
      <div>
        {
          this.state.errMsg || (this.spdAlertTile && this.spdAlertTile.length === 0) ?
            <Col>
              <Alert bsStyle="warning">
                {this.state.errMsg ? this.state.errMsg : "Speed Alert list is empty. Add speed limit to view list here." }
              </Alert>
            </Col>
          : null
        }
        {
          this.spdAlertTile && this.spdAlertTile.length && this.state.data.length ?
            <Col className="height-83vh overflowY-auto" >
              {
                this.spdAlertTile.map((d,i)=>{
                  return (
                    <ListGroupItem style={{position: "initial" ,marginBottom: "2%",  borderRadius:"4px"}} header={d.data}
                    key={i}></ListGroupItem>
                  )
                })
              }
            </Col>
          : this.state.isLoading ? <LoadingScreen/> : null
        }
        {
          <InfoModal show={this.state.showVehicle} modalSize="small" close={this.onVehicleClose} bodyStyle={{paddingBottom: "0px"}} formStyle={{marginTop: "-12px"}}>
            {
              this.vehicleDetails
              ? this.vehicleDetails.map((veh, i)=>{
                return(
                  <div key={i.toString()} style={{marginTop:"5%", borderBottom: "1px solid #e1e1e1", marginLeft: "-12px", marginRight: "-12px",
                    textAlign: "center", fontSize:" 14px", height:"60px"}}>
                    <span style={{color: "#788995"}}>{veh.mo}<br />{veh.reg ? veh.reg : veh.treg}</span>
                    <span style={{color:"#cdcdcd",marginLeft: '15px', marginTop:"-4%", fontSize:"12px"}}>{veh.did ? "PLUS" : "PRO"}</span>
                  </div>
                )
              })
              : "No vehicle Present"
            }
          </InfoModal>
        }
      </div>
    )
  }
}
