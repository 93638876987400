import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Col, Alert, ProgressBar, Breadcrumb } from 'react-bootstrap/lib';
import PanelView from '../components/panel/PanelView';
import SimplePanelContainer from '../components/panel/SimplePanelContainer';
import PanelSection from '../components/panel/PanelSection';
import { GetServiceListCount } from '../services/apiFunnel';
const { MENUPATH } = require('../lib/enums');


export default class ServicePanel extends React.Component {

  state = {};

  componentDidMount() {
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.SERVICE_PANEL);
    GetServiceListCount()
      .then((srvListCnt) => this.setState({ srvListCnt }))
      .catch((e) => this.setState({ err: e }));
  }

  navigateToList = (e) => {
    let vidArr = e.target.getAttribute('data-vidarr').split(',').map((d) => +d); // Split string into array and convery each element to number
    this.props.router.push({ pathname: '/webapp/serviceList', state: { vidArr } });
  }

  getCountLink(val) {
    return (<button className="link-button" data-vidarr={val} onClick={this.navigateToList}>{val.length}</button>);
  }

  onMenuSelect = (menu) => {
    this.props.router.replace(menu.link);
  }

  render() {
    let s = this.state.srvListCnt;
    return (
      <Col>

        <Col>
          <Breadcrumb>
            <Breadcrumb.Item componentClass='span'><Link to='/webapp/dashboard'>Dashboard</Link></Breadcrumb.Item>
            <Breadcrumb.Item active>Service Panel</Breadcrumb.Item>
          </Breadcrumb>
        </Col>

        <Col>
          {
            s ?
              <PanelView heading='Service Panel'>

                {/* Service Section */}
                <PanelSection heading='Services Count'>
                  <SimplePanelContainer count={this.getCountLink(s.all)} description='All' />
                  <SimplePanelContainer count={this.getCountLink(s.service_1)} description='1st Service' />
                  <SimplePanelContainer count={this.getCountLink(s.service_2)} description='2nd Service' />
                  <SimplePanelContainer count={this.getCountLink(s.service_3)} description='3rd Service' />
                  {
                    s.service_4.length > 0
                      ? <SimplePanelContainer count={this.getCountLink(s.service_4)} description='4th Service' />
                      : null
                  }
                  {
                    s.service_5.length > 0
                      ? <SimplePanelContainer count={this.getCountLink(s.service_5)} description='5th Service' />
                      : null
                  }
                  {
                    s.service_6.length > 0
                      ? <SimplePanelContainer count={this.getCountLink(s.service_6)} description='6th Service' />
                      : null
                  }
                  <SimplePanelContainer count={this.getCountLink(s.paid)} description='Paid Service' />
                  <SimplePanelContainer count={this.getCountLink(s.other)} description='Others' />
                </PanelSection>

                {/* Calls Section */}
                <PanelSection heading='Calls Count'>
                  <SimplePanelContainer count={this.getCountLink(s.todaysCalls)} description='Todays Calls' />
                  <SimplePanelContainer count={this.getCountLink(s.pendingCalls)} description='Pending Calls' />
                </PanelSection>

              </PanelView>
              : this.state.err
                ? <Alert className='text-center font-bold' bsSize='medium' bsStyle='danger'>{this.state.err}</Alert>
                : <ProgressBar active now={100} label='Loading, Please wait...' />
          }
        </Col>
      </Col>
    );
  }
}
ServicePanel.propTypes = {
  router: PropTypes.object
}
