/**
 * For extending functionality : https://flatpickr.js.org/examples/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

/**
 * @class DateInput
 * @requires name Unique String
 * @exports DatePicker Calendar Day/Month/Year Input
 */
export class DateInput extends React.Component {
  render() {
    return (
      // Note that Value is in seconds while default is in miliseconds, hence the division
      <Flatpickr name={this.props.name} defaultValue={(this.props.defaultdate / 1000).toString()}
        options={{
          defaultDate: this.props.defaultdate,
          maxDate: this.props.maxdate,
          minDate: this.props.mindate,
          altInput: true,
          altFormat: 'j F Y',
          dateFormat: 'U',
          disableMobile: true
        }}
      />
    )
  }
}
DateInput.propTypes = {
  name: PropTypes.string,
  defaultdate: PropTypes.number,
  maxdate: PropTypes.number,
  mindate: PropTypes.number
}
