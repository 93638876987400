// Generic
import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link } from 'react-router';
import { Grid, Row, Col, Label, Breadcrumb, Alert, ProgressBar } from 'react-bootstrap/lib';
import { StampTODate } from '../lib/dtconv';

// Components
import { EditModal, InfoModal } from '../components/Modal';
import { GenericDropdown } from '../components/inputs/Dropdowns';
import { DateInput } from '../components/inputs/DatePicker';
import { GlyphButton } from '../components/inputs/Buttons';

// Services
import { AddServiceData, MarkServiceDone, GetServiceList } from '../services/apiFunnel';
import { SRVC_TYPES, CALL_STAT } from '../lib/enums';

export default class ServiceList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      filteredList: [],
      msg: '',
      msgClr: 'black',

      // edit modal states
      showEditModal: false,
      editModalBody: null,
      selectedRowId: -1,
      editModalMsg: '',
      editModalSubmitBtnDisabled: false,
    }
    this.serviceArr = [];
    this.filteredList = [];
    this.vidArr = this.props.location.state ? this.props.location.state.vidArr : []; // Get vid arr passed from panel
  }

  componentDidMount() {
    // Get list of customers and corresponding data by sel
    GetServiceList(this.vidArr)
      .then((customers) => {
        customers.forEach((c) => c.cnm = c.fn + ' ' + (c.ln || ''));
        this.setState({ customers });
      })
      .catch((e) => this._showMessage({ err: e, type: 'danger' }));
  }

  _showMessage = ({ err, timeout, type }) => { // To set and clear status messages
    this.setState({ err, type }, () => {
      if (timeout) {
        setTimeout(() => {
          this.setState({ err: '' })
        }, timeout)
      }
    });
  }

  _getCallStatusDef(clst) { // Get get call status string from number
    return CALL_STAT.find((c) => c['idx'] === clst).nm;
  }

  _getServiceType(type) { // Get service type string from number
    return SRVC_TYPES.find((l) => l['idx'] === type).nm;
  }

  _markComplete = (e) => { // Mark a service complete
    e.target.disabled = true;
    let data = { vid: +e.target.getAttribute('data-vid') };
    MarkServiceDone(data)
      .then(this._updateList)
      .catch((err) => this._showMessage({ type: 'danger', err }));
  }

  _updateList = (serviceData) => { // Update customers to reflect changes from user interaction

    // find customer in list
    let customers = [...this.state.customers];
    let customer = customers.find((d) => d.vid === serviceData.vid);

    // update data as necessary
    customer.com = serviceData.com;
    customer.clst = serviceData.clst;
    if (serviceData.srvcLog) customer.srvcLog = serviceData.srvcLog;
    if (serviceData.nxtSrvc) customer.nxtSrvc = serviceData.nxtSrvc;
    else customer.nxtSrvc = null;

    this.setState({ customers, editModalMsg: 'Update Successful' });
  }

  _onMenuSelect = (menu) => {
    this.props.router.replace(menu.link);
  }


  /* TABLE FUNCTIONS START */
  showCustomers = (list) => {
    return (
      <ReactTable
        data={list}
        columns={this._getCustomersColumns()}
        showPageSizeOptions={false}
        defaultPageSize={15}
        getTdProps={this._getCustomCellStyle}
        sortable={true}
        resizable={true}
      />
    )
  }
  _getCustomersColumns = () => {
    return [
      {
        Header: 'Service Customers (Count : ' + this.state.customers.length + ')',
        columns: [
          { Header: '#', minWidth: 30, Cell: props => <span>{props.index + 1}</span>, },
          { Header: 'Customer Details', minWidth: 100, id: 'cnm', width: 180, filterable: true, filterMethod: this._containsFilter, accessor: (d) => d.cnm + ' ' + d.mob + ' ' + d.reg, Cell: this._getCustomerDetailsCell },
          { Header: 'Vehicle', minWidth: 100, id: 'vehDtls', filterable: true, filterMethod: this._containsFilter, accessor: (d) => (d.make + ' ' + d.model) },
          { Header: 'Last Service ', id: 'lstsrvc', accessor: (d) => this._getLastService(d) },
          { Header: 'Next Service ', id: 'nxtsrvc', accessor: (d) => this._getNextService(d) },
          { Header: 'Call Status', id: 'clst', accessor: (d) => d.clst ? this._getCallStatusDef(d.clst) : 'NA' },
          { Header: 'Last comment', id: 'lstcmt', accessor: (d) => this._getComment(d) },
          { Header: 'Mark Complete', id: 'done', accessor: (d) => <GlyphButton disabled={d.nxtSrvc ? false : true} glyphicon='ok' data-vid={d.vid} onClick={this._markComplete} bsStyle='success' /> },
        ]
      }
    ]
  }
  _getCustomCellStyle() {
    return {
      'style': {
        'textAlign': 'center',
        'whiteSpace': 'pre-line'
      }
    }
  }
  _containsFilter(filter, row) {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? (String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1) : true;
  }
  _getCustomerDetailsCell = (row) => {
    return (
      <div>
        <button type="button" data-packet={JSON.stringify(row.original)} className='link-button'
          onClick={this.showEditModal}>{row.original.cnm}</button><br />
        <span style={{ fontWeight: 'bold' }}>{row.original.mob}</span><br />
        <span>{row.original.reg}</span>
      </div>
    )
  }
  _getLastService(d) {
    if (d.srvcLog && d.srvcLog.length) {
      let log = d.srvcLog;
      let srvc = this._getServiceType(log[log.length - 1].srvcTyp) + ' \n ' + StampTODate(log[log.length - 1].srvcDt);
      return (<button type="button" data-packet={JSON.stringify(d.srvcLog)} className='link-button'
        onClick={this.showServiceLogModal}>{srvc}</button>);
    } else {
      return ('Not Added');
    }
  }
  _getNextService(d) {
    if (d.nxtSrvc) return (this._getServiceType(d.nxtSrvc.srvcTyp) + ' \n ' + StampTODate(d.nxtSrvc.srvcDt));
    else return ('Not Added');
  }
  _getComment(d) {
    if (d.com && d.com.length) {
      return (<button data-packet={JSON.stringify(d.com)} className='link-button'
        onClick={this.showCommentModal}>{d.com[d.com.length - 1].body}</button>);
    } else {
      return ('Not Added');
    }
  }
  /* TABLE FUNCTIONS END */


  /* EDIT MODAL FUNCTION START */
  showEditModal = (e) => {
    // Get data for selected entity
    let selectedRow = JSON.parse(e.target.getAttribute('data-packet'));
    this.setState({ showEditModal: true, selectedRow, selectedRowId: selectedRow.idx });
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false, editModalBody: null, selectedRowId: -1, editModalMsg: '', editModalSubmitBtnDisabled: false });
  }

  submitEditModalData = (serviceData) => {

    // disable modal submit button
    this.setState({ editModalSubmitBtnDisabled: true });

    // Validate Data
    let err = this._validateServiceData(serviceData);
    if (err) return this.setState({ editModalMsg: err, editModalSubmitBtnDisabled: false });

    // Prep Payload
    let payload = {
      vid: this.state.selectedRowId,
      com: { ts: Date.now(), body: serviceData.comBody },
      clst: +serviceData.clst
    };
    if (serviceData.srvcDt) {
      payload.nxtSrvc = {
        srvcDt: +serviceData.srvcDt,
        srvcTyp: +serviceData.srvcTyp
      };
    }

    // Send to backend
    AddServiceData(payload)
      .then(this._updateList)
      .catch((e) => this.setState({ editModalMsg: e, editModalSubmitBtnDisabled: false }));

  }
  _validateServiceData(data) {
    if (!data.comBody) return ('Please enter comment');
    if (!data.clst) return ('Please enter call status');
    if (data.srvcDt && !data.srvcTyp) return ('Please select service type');
    if (data.srvcTyp && !data.srvcDt) return ('Please select service date');
    return (0);
  }
  /* EDIT MODAL FUNCTION START */


  /* PREVIOUS COMMENDS MODAL FN START */
  showCommentModal = (e) => {
    // Get data for selected entity
    let selectedComments = JSON.parse(e.target.getAttribute('data-packet'));
    this.setState({ showCommentModal: true, selectedComments });
  }

  closeCommentModal = () => {
    this.setState({ showCommentModal: false, selectedComments: null });
  }
  /* PREVIOUS COMMENDS MODAL FN END */


  /* SERVICE LOG MODAL FN START */
  showServiceLogModal = (e) => {
    // Get data for selected entity
    let selectedServiceLog = JSON.parse(e.target.getAttribute('data-packet'));
    this.setState({ showServiceLogModal: true, selectedServiceLog });
  }

  closeServiceLogModal = () => {
    this.setState({ showServiceLogModal: false, selectedServiceLog: null });
  }
  /* SERVICE LOG MODAL FN END */


  render() {
    return (
      <div className='container'>
        <Grid>

          <Row>
            <Col xs={12} md={10}>
              <Breadcrumb>
                <Breadcrumb.Item componentClass='span'><Link to='/webapp/dashboard'>Dashboard</Link></Breadcrumb.Item>
                <Breadcrumb.Item componentClass='span'><Link to='/webapp/servicepanel'>Service Panel</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Service List</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {/* Customers Table */}
            <Col xs={12} md={10}>
              {
                this.state.customers.length > 0
                  ? this.showCustomers(this.state.customers)
                  : this.state.err
                    ? <Alert className='text-center font-bold' bsSize='medium' bsStyle={this.state.type}>{this.state.err}</Alert>
                    : <ProgressBar active now={100} label='Loading, Please wait...' />
              }
            </Col>
          </Row>

        </Grid>


        {/* Update Customer List Modal */}
        <EditModal show={this.state.showEditModal} title='Edit Customer Details' id='editModal'
          close={this.closeEditModal} submit={this.submitEditModalData} modalSize='large' msg={this.state.editModalMsg}
          submitBtnDisabled={this.state.editModalSubmitBtnDisabled}>
          {
            this.state.selectedRow
              ? [
                <Row key={'customerDetails'}>
                  {this._textView('Name : ', this.state.selectedRow.cnm)}
                  {this._textView('Mobile : ', this.state.selectedRow.mob)}
                  {this._textView('Vehicle : ', this.state.selectedRow.make + ' ' + this.state.selectedRow.model)}
                  <br /><br />
                </Row>,
                <Row key={'serviceDetails'}>
                  {this._textView('Registration : ', this.state.selectedRow.reg)}
                  {this._textView('Service Date : ', <DateInput name='srvcDt' mindate={Date.now()} />)}
                  {this._textView('Service Type : ', <GenericDropdown name='srvcTyp' options={SRVC_TYPES} optionkey='idx' optionval='nm' defaultValue={-1} defaultoption='Select Service Filter' />)}
                  <br /><br />
                </Row>,
                <Row key={'inputDetails'}><br />
                  {this._textView('Call Status : ', <GenericDropdown name='clst' options={CALL_STAT} optionkey='idx' optionval='nm' defaultValue={-1} defaultoption='Select Call Status' />)}
                  {this._textView('Comment : ', <textarea name='comBody' className='form-control' />, { sm: 12, md: 12, lg: 8 })}
                </Row>
              ]
              : null
          }
        </EditModal>

        {/* Previous Comments Modal */}
        <InfoModal show={this.state.showCommentModal} title='Previous Comments' close={this.closeCommentModal}>
          {
            this.state.selectedComments
              ? this.state.selectedComments.map((comment, i) => {
                return (
                  <div key={i.toString()}>
                    <Label>{StampTODate(comment.ts)}</Label>
                    <h3 className='lead'>{comment.body}</h3>
                  </div>
                )
              })
              : 'No comments to show'
          }
        </InfoModal>

        {/* Service Log Modal */}
        <InfoModal modalSize='small' show={this.state.showServiceLogModal} title='Service Log' close={this.closeServiceLogModal}>
          {
            this.state.selectedServiceLog
              ? this.state.selectedServiceLog.map((service, i) => {
                return (
                  <div key={i.toString()}>
                    <Label>{StampTODate(service.srvcDt)}</Label>
                    &nbsp;&nbsp; <span className='lead'>{this._getServiceType(service.srvcTyp)}</span>
                  </div>
                )
              })
              : 'No service log to show'
          }
        </InfoModal>

      </div>
    )
  }
  _textView(labelName, textView, style = {}) {
    return (
      <Col sm={style.sm || 12} md={style.md || 6} lg={style.lg || 4}  >
        <label>{labelName}</label> {textView}
      </Col>
    )
  }

}
ServiceList.propTypes = {
  location: PropTypes.object
}