import React, { Component} from 'react';
import { browserHistory } from 'react-router';
import { Alert, Col } from 'react-bootstrap/lib';
import { getRouteData } from '../../lib/db/routes';
import { AddJourney } from '../../lib/db/journeys';
import { MENUPATH } from '../../lib/enums';
const { ShipmentInput } = require('./tripInputs');

export default class AddShipment extends Component{
  constructor(props){
    super(props);
    this.state = {
      isLoading: true,
      errMsg: "",
      routeArr: [],
      disabled: false
    }
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentDidMount(){
    if(global.wsOnline){
      if(global.bikeDetails) {
        this.onBikes(global.bikeDetails);
      }
    }
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.NEW_TRIP );

    getRouteData((err, routes) =>{
      if(err) {
        this.setState({ errMsg: err , disabled: false});
      }
      else {
        if(routes && routes.length) this.setState({routeArr: routes})
      }
    });
  }

  onBikes = (bikes)=>{
    global.bikeDetails = bikes;
    this.plus = bikes.filter((bike) => { return bike.did });
    if(this.plus && this.plus.length){
      this.forceUpdate();
    }
    else this.setState({ errMsg: "This feature is only available for Plus device users. Add a vehicle with Plus HW."})
  }

  onSaveShipment=(shipment)=>{
    this.setState({ disabled: true})
    AddJourney(shipment , (err, response) =>{
      if(err) {
        this.setState({ errMsg: err, disabled: false});
        return;
      }
      var url = "/webapp/sp/";
      if(response.stdt > Date.now()) url += "upcoming";
      else if(response.eddt < Date.now()) url += "completed";
      else url += "ongoing";
      browserHistory.push(url);
    });
  }

  render(){
    return(
      this.plus && this.plus.length?
      <ShipmentInput
        header="New Trip"
        addMap = {true}
        routeArr={this.state.routeArr}
        bikeDetails = {this.plus}
        onSaveShipment = {(data)=> this.onSaveShipment(data)}
        errMsg= {this.state.errMsg}
        disabled = {this.state.disabled}
      />
      : this.state.errMsg ?
        <Col> <Alert bsStyle="warning">{this.state.errMsg}</Alert> </Col>
        : null
    )
  }

}
