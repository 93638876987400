import React, { Component } from "react";
import { Button } from 'react-bootstrap/lib';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import MapControl from './../mapControl';
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
const { Circle } = require("react-google-maps/lib/components/Circle");
const _ = require("lodash");

class MyMapComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      bounds: null,
      center: { lat: 20.5837, lng: 78.9629 },
      markers: [],
    }
    this.ref = {}
  }

  onRadiusChanged(overlayContext,MyMapContext){
    this.props.onRadiusChanged(overlayContext)
  }

  onCenterChanged(overlayContext,MyMapContext){
    this.props.onCenterChanged(overlayContext)
  }

  onClear =() =>{
    if(this.CircleInput){
      this.CircleInput.setOptions({drawingControlOptions: {position: 3, drawingModes: ['circle'] }});
      this.CircleInput.e.overlay.setMap(null);
      this.CircleInput = "";
    }
    this.props.onClear();
  }

  handleOverlayComplete(overlayContext,MyMapContext){
    overlayContext.setDrawingMode(null);
    overlayContext.setOptions({drawingControlOptions: {position: 3, drawingModes: [] }});
    this.CircleInput = overlayContext;
    this.props.onCircleComplete(overlayContext)
  };

  onMapMounted = ref => {
    this.ref.map = ref
  }

  onSearchBoxMounted = ref => {
    this.ref.searchBox = ref;
  }

  onCircleMounted = ref => {
    this.ref.circle = ref;
  }

  onPlacesChanged = () => {
    const places = this.ref.searchBox.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();
    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport)
      } else {
        bounds.extend(place.geometry.location)
      }
    });
    const nextMarkers = places.map(place => ({
      position: place.geometry.location,
    }));
    const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

    this.setState({
      center: nextCenter,
      markers: nextMarkers,
    });
    this.ref.map.fitBounds(bounds);
  }

  fitToBounds = () => {
    if(!this.props.drawcalled) this.ref.map.fitBounds(this.ref.circle.getBounds());
  }

  render() {
    var MyMapContext = this;
    return (
      <GoogleMap
        ref={this.onMapMounted}
        defaultZoom={this.props.zoom}
        defaultCenter={this.props.center}
        options={{scaleControl:false, streetViewControl: false, mapTypeControl: false}}
        onProjectionChanged={this.fitToBounds}
      >
        <SearchBox
          ref={this.onSearchBoxMounted}
          bounds={this.bounds}
          controlPosition={1}
          onPlacesChanged={this.onPlacesChanged}
        >
        <input
          type="text"
          placeholder="Search location"
          style={{
            boxSizing: "border-box",
            border: "1px solid transparent",
            width: "200px",
            height: `35px`,
            marginTop: `4px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: "ellipses",
            marginLeft:"3%"
          }}
        />
        </SearchBox>
        { this.props.drawcalled ?
          <DrawingManager
            defaultOptions={{
              drawingControl: true,
              drawingControlOptions: {
                position: 3,
                drawingModes: ['circle']
              },
              circleOptions: {
                fillColor: "#00a8e2",
                strokeWeight: 1,
                clickable: false,
                editable: true,
                zIndex: 1,
              }
            }}
            onOverlayComplete={function(e){
              this.e = e;
              MyMapContext.handleOverlayComplete(this,MyMapContext);
            }}
          />
          :
          <Circle
            ref={this.onCircleMounted}
            radius={this.props.radius}
            center={this.props.center}
            editable={true}
            clickable= {false}
            options={{fillColor:"#00a8e2", strokeWeight: "1"}}
            zIndex= {1}
            onRadiusChanged={function(e){
              this.e = e;
              MyMapContext.onRadiusChanged(this,MyMapContext);
            }}
            onCenterChanged={function(e){
              this.e = e;
              MyMapContext.onCenterChanged(this,MyMapContext);
            }}
          />


        }

        {
          this.state.markers.map((marker, index) =>
            <Marker key={index} position={marker.position} />
          )
        }
        {
          this.CircleInput || this.props.radius ?
          <MapControl position={3}>
            <Button style={{float: "right", marginRight:"10%",width: "50px", marginTop: "6px"}} id="clear" bsSize="small" onClick={() => this.onClear()} ><span style={{ fontSize: "12px", color: "red" }} >Clear</span></Button>
          </MapControl>
          : null
        }


      </GoogleMap>

    );
  }
}

const MapDisplay = withScriptjs(withGoogleMap(MyMapComponent));

export const GeoFenceMap = (props) => {
  return (
    <div>
      <MapDisplay
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAZZ1EgiGO1AVPgChSEqS9iQwVZl2Xfdw8&v=3.exp&libraries=drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        // center={{ lat: 20.5837, lng: 78.9629 }}
        center = { props.center}
        zoom={props.zoom}
        onCircleComplete = {props.onCircleComplete}
        onClear = {props.onClear}
        radius={ props.radius}
        // center={ props.center}
        onRadiusChanged = {props.onRadiusChanged}
        onCenterChanged = {props.onCenterChanged}
        drawcalled = { props.draw }
      />
    </div>
  );
};
