import React from 'react';
import SpeedLimit from './spdLmt';

export default class EditSpeedLimit extends React.Component{
  render(){
    return(
      <SpeedLimit location={this.props.location} edit={true} />
    )
  }
}
