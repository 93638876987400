const DAY = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const TODAY_START_TS = new Date().setHours(0, 0, 0, 0);
const TODAY_END_TS = new Date().setHours(23, 59, 59, 999);
const ONE_DAY_TS = 24 * 60 * 60 * 1000;

/*
  When any item added in the menu then it is necessary to add constant in this menu path file with path array.
  Array consists nm from menu object
 */
const MENUPATH = {
  DASHBOARD: ["Dashboard"],
  RIDES: ["Rides"],
  DAILY_RIDES: ["Rides", "Daily_Rides_Drives"],
  RIDE_LOG: ["Rides", "Ride_Logs"],
  STATS: ["Statistics"],
  ALERTS: ["Alerts"],
  TRIGGERS: ["Triggers"],
  SPEED_LIMIT: ["Triggers", "Speed_Limit"],
  VIEW_SL: ["Triggers", "Speed_Limit", "View_SL"],
  ADD_SL: ["Triggers", "Speed_Limit", "Add_SL"],
  GEO_FENCE: ["Triggers", "Geo_Fence"],
  VIEW_GF: ["Triggers", "Geo_Fence", "View_GF"],
  ADD_GF: ["Triggers", "Geo_Fence", "Add_GF"],
  TRIPS: ["Trips"],
  VIEW_TRIPS: ["Trips", "View_Trips"],
  ONGOING: ["Trips", "View_Trips", "Ongoing"],
  UPCOMING: ["Trips", "View_Trips", "Upcoming"],
  COMPLETED: ["Trips", "View_Trips", "Completed"],
  NEW_TRIP: ["Trips", "New_Trip"],
  SERVICE_PANEL: ["Service_Panel"],
  SALES_PANEL: ["Sales_Panel"],
  ACCOUNT_DETAILS: ["Account_Details"],
  CURRENT_SESSIONS: ["Current_Sessions"]
}

const BOX = {
  itemStyle: {
    initialColor: "#9b9b9b",
    selectedColor: "#0094de",
  },
  iconStyle: {
    fontSize: "18px"
  },
  titleStyle: {
    fontSize: "16px"
  }
}

const DRAWER= {
  itemStyle: {
    initialColor: "#9b9b9b",
    selectedColor: "#0094de",
  },
  iconStyle: {
    fontSize: "16px"
  },
  titleStyle: {
    fontSize: "14px"
  }
}

const BOX_SUBMENU = {
  itemStyle: {
    initialColor: "#9b9b9b",
    selectedColor: "#0094de",
    marginLeft: "18%",
  },
  iconStyle: {
    fontSize: "17px"
  },
  titleStyle: {
    fontSize: "15px"
  }
}

const DRAWER_SUBMENU = {
  itemStyle: {
    initialColor: "#9b9b9b",
    selectedColor: "#0094de",
    marginLeft: "20%",
  },
  iconStyle: {
    fontSize: "16px"
  },
  titleStyle: {
    fontSize: "14px"
  }
}

const BOX_LEAFMENU = {
  itemStyle: {
    initialColor: "#9b9b9b",
    selectedColor: "#0094de",
    marginLeft: "23%",
  },
  iconStyle: {
    fontSize: "16px"
  },
  titleStyle: {
    fontSize: "14px"
  }
}

const DRAWER_LEAFMENU = {
  itemStyle: {
    initialColor: "#9b9b9b",
    selectedColor: "#0094de",
    marginLeft: "30%",
  },
  iconStyle: {
    fontSize: "14px"
  },
  titleStyle: {
    fontSize: "12px"
  }
}

function getMenu(type) {
  /*
    Object consist screens and style of menu.
    Screens is array of menu items. 'submenu' key in the any menu item specified that it has submenu.
  */
  return {
    screens: [
      { label: 'Dashboard', nm: "Dashboard", icon: 'ajjas-speedometer', link: '/webapp/dashboard' },
      {
        label: 'Rides', nm: "Rides", icon: 'ajjas-rides', submenu: {
          screens: [
            { label: 'Daily Rides/Drives', nm: "Daily_Rides_Drives", link: '/webapp/dailyRidesPage' },
            { label: 'Ride Logs', nm: "Ride_Logs", link: '/webapp/rideLogs' }
          ],
          style: type === "box" ? BOX_SUBMENU : DRAWER_SUBMENU
        }
      },
      { label: 'Statistics', nm: "Statistics", icon: 'ajjas-stats', link: '/webapp/allvehstat' },
      { label: 'Alerts', nm: "Alerts", icon: 'ajjas-bell', link: '/webapp/alerts' },
      {
        label: 'Triggers', nm: "Triggers", icon: 'ajjas-info', submenu: {
          screens: [
            {
              label: 'Speed Limit', nm: "Speed_Limit", submenu: {
                screens: [
                  { label: 'View Speed Alerts', nm: "View_SL", link: '/webapp/sl/view' },
                  { label: '+ Add Speed Limit', nm: "Add_SL", link: '/webapp/sl/add' }
                ],
                style: type === "box" ? BOX_LEAFMENU : DRAWER_LEAFMENU
              }
            },
            {
              label: 'Geo-fence', nm: "Geo_Fence", submenu: {
                screens: [
                  { label: 'View Geo-fences', nm: "View_GF", link: '/webapp/gf/view' },
                  { label: '+ Add Geo-fence', nm: "Add_GF", link: '/webapp/gf/add' }
                ],
                style: type === "box" ? BOX_LEAFMENU : DRAWER_LEAFMENU
              }
            }
          ],
          style: type === "box" ? BOX_SUBMENU : DRAWER_SUBMENU
        }
      },
      {
        label: 'Trips', nm: "Trips", icon: 'ajjas-order', submenu: {
          screens: [
            {
              label: 'View Trips', nm: "View_Trips", submenu: {
                screens: [
                  { label: 'Ongoing', nm: "Ongoing", link: '/webapp/sp/ongoing' },
                  { label: 'Upcoming', nm: "Upcoming", link: '/webapp/sp/upcoming' },
                  { label: 'Completed', nm: "Completed", link: '/webapp/sp/completed' }
                ],
                style: type === "box" ? BOX_LEAFMENU : DRAWER_LEAFMENU
              }
            },
            { label: '+ New trip', nm: "New_Trip", link: '/webapp/sp/add' }
          ],
          style: type === "box" ? BOX_SUBMENU : DRAWER_SUBMENU
        }
      },
    ],
    style: type === "box" ? BOX : DRAWER
  }
}

const DEALER_MENU = {
  screens: [
    { label: 'Service Panel', nm: "Service_Panel", icon: 'glyphicon glyphicon-wrench', link: '/webapp/servicePanel' },
    { label: 'Sales Panel', nm: "Sales_Panel", icon: 'glyphicon glyphicon-usd', link: '/webapp/salesPanel' },
  ]
}

const PROFILE_MENU = {
  screens: [
    { label: 'Account Details', nm: "Account_Details", icon: 'ajjas-plan', link: '/webapp/accountdetails' },
    { label: 'App Sessions', nm: "Current_Sessions", icon: 'ajjas-session', link: '/webapp/sessions' },
    { label: 'Signout', nm: "Signout", icon: 'ajjas-logout', link:"/webapp" },
  ]
}

const SRVC_TYPES = [
  { idx: 1, nm: 'First Service' },
  { idx: 2, nm: 'Second Service' },
  { idx: 3, nm: 'Third Service' },
  { idx: 4, nm: 'Fourth Service' },
  { idx: 5, nm: 'Paid Service' },
  { idx: 6, nm: 'Other' }
];

const CALL_STAT = [
  { idx: 1, nm: 'Connected' },
  { idx: 2, nm: 'Not Connected' },
  { idx: 3, nm: 'Wrong Number' },
  { idx: 4, nm: 'Busy' },
  { idx: 5, nm: 'Ringing' }
];

const NOTIFICATION_TYPE = [
  { nm: "Motion sensed", t: 1 },
  { nm: "Ignition off", t: 2 },
  { nm: "Ignition on", t: 3 },
  { nm: "Accident detected", t: 4 },
  { nm: "Stationary fall detected", t: 5 },
  { nm: "Power supply off", t: 6 },
  { nm: "Vehicle switched off", t: 7 },
  { nm: "Vehicle switched on", t: 8 },
  { nm: "Power supply on", t: 9 },
  { nm: "Vehicle unreachable", t: 100 },
  { nm: "Speed limit", t: 201 },
  { nm: "Geo-fence alert", t: 202 },
];

const STATS_LIST = [
  { name: "Distance", icon: 'distance-1', id: 'dst', unit: 'KM' },
  { name: "Duration", icon: 'stopwatch', id: 'dur', unit: '' },
  { name: "Top speed", icon: 'topspeed', id: 'tps', unit: 'Km/h' },
  { name: "AVG speed", icon: 'avgspeed', id: 'avs', unit: 'Km/h' },
  { name: "AVG distance per ride", icon: 'avgdis', id: 'avd', unit: 'Km/h' },
  // {name: "Fuel Mileage", icon: 'petrol-pump', id: 'mlg',unit: 'Km/L'},
  // {name: "Fuel spending", icon: 'oil',id: 'fcost',unit: ''},
  { name: "Max lean angle", icon: 'leanangle', id: 'mang', unit: '' },
];

const DASHBOARD_GREEN = [
  // { id: 1, nm: "View all"},
  { id: 1, nm: "OnRoute" },
  // { id: 3, nm: "At Start Location"},
  { id: 2, nm: "Reached destination" }
]

const DASHBOARD_RED = [
  { id: 1, nm: "Speed alert" },
  { id: 2, nm: "GPS Lost" },
  { id: 3, nm: "Exceeded estimated time" },
  { id: 4, nm: "Exceeded estimated distance" },
  { id: 5, nm: "Not moved for 6 hours" }
]

const MAP_FILTERS = [
  { id: 1, options: DASHBOARD_GREEN, onHover: "Vehicle in a trip", bgcolor: "#6ac803", Menuid: "green", style: "-114px" },
  // { id: 2, options: DASHBOARD_RED, onHover: "View alerts", bgcolor: "#f3001d", Menuid : "red", style: "-208px" },
];

const VEH_SHIP_STATS = { // status of an individual vehicle in a shipment
  AT_SRC_START: 1,
  ENROUTE_DEST: 2,
  AT_DEST: 3,
  ENROUTE_SRC: 4,
  AT_SRC_END: 5,
  ABORTED: 6,
  GPS_LOST: -1
};

const ARROW = {
  path: "M32 16C32 24.83594 24.83594 32 16 32C7.164062 32 0 24.83594 0 16C0 7.164062 7.164062 0 16 0C24.83594 0 32 7.164062 32 16ZM16.08984 18.41797L21.91016 23.20703L16.08984 5.753906L10.27344 23.20703Z",
  fillOpacity: 1,
  anchor: { x: 12, y: 12 },
  strokeColor: "#FFFFFF",
  strokeWeight: 2,
  scaledSize: { height: 32, width: 32 },
}

var ICON = { anchor: { x: 20, y: 20 } }

module.exports = {
  DAY,
  TODAY_START_TS,
  TODAY_END_TS,
  DEALER_MENU,
  SRVC_TYPES,
  CALL_STAT,
  NOTIFICATION_TYPE,
  STATS_LIST,
  DASHBOARD_GREEN,
  DASHBOARD_RED,
  MAP_FILTERS,
  VEH_SHIP_STATS,
  ARROW,
  ICON,
  ONE_DAY_TS,
  MENUPATH,
  PROFILE_MENU,
  getMenu
};
