var modes = {
  isRelease: 1,
  isStaging: 2,
  isDebug: 3
};

var mode = modes.isRelease;

var config = {};

if(mode === modes.isRelease){
  config.wshost = 'wss://api.ajjas.com';
  config.crmhostname = 'https://crmapi.ajjas.com';
  config.glHostName = 'https://api.ajjas.com';
  config.sharingHostName = "https://www.ajjas.com/customrides/";
}
else if(mode === modes.isStaging){
  config.wshost = 'wss://fooapi.ajjas.com';
  config.crmhostname = 'https://foocrmapi.ajjas.com';
  config.glHostName = 'https://fooapi.ajjas.com';
  config.sharingHostName = "https://foo.ajjas.com/customrides/";
}
else{
  config.wshost = 'ws://localhost:9099';
  config.crmhostname =  'http://localhost:9091';
  config.glHostName = 'http://localhost:9092';
  config.sharingHostName = "http://localhost:9000/customrides/";
  // config.webPushTkn = 'BFN2btido7feQxICcylhzP7-5gmK6qQGoIFyIa9P95oVLbMcww-fFUhWGV7ebY733t6V1Zowyi6jkx4QKB301I8';
}

module.exports = {
  config: config
};
