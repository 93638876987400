/**
 * Usage :
 *
 * <PanelView>
 *  <PanelSection> // Optional
 *    <SimplePanelContainer />
 *    <MultiPanelContainer />
 *  </PanelSection> // Optional
 * </PanelView>
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap/lib';

export default class PanelView extends React.PureComponent {
  render() {
    return (
      <Panel bsStyle={this.props.bsStyle || 'primary'} style={{ borderRadius: '4px 4px 20px 20px' }}>
        <Panel.Heading className='text-center'>
          <Panel.Title componentClass='h3'>{this.props.heading}</Panel.Title>
        </Panel.Heading>
        <Panel.Body>{this.props.children}</Panel.Body>
        {
          this.props.footer
            ? <Panel.Footer>{this.props.footer}</Panel.Footer>
            : null
        }
      </Panel>
    );
  }
}
PanelView.propTypes = {
  bsStyle: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.any,
  footer: PropTypes.any
}

