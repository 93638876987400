import Net from '../net';
import { getFromStore, setToStore ,getAllkeys} from './common';
const glHostName = require('../../config').config.glHostName;
const TODAY_START_TS = require('../enums').TODAY_START_TS;
const NOTIF_TYPE = {
  1 : "Motion sensed",
  2 : "Ignition off",
  3 : "Ignition on",
  4 : "Accident detected",
  5 : "Stationary fall detected",
  6 : "Power supply on",
  7 : "Vehicle switched off",
  8 : "Vehicle switched on",
  9 : "Power supply on",
  100 : "Vehicle unreachable",
  201 : "Speed alert",
  202 : "Geo-fence alert"
}

export function getNotifCountFromDb(vidArr, cb) {
  getAllkeys('notifCount').then((vids) => {
    if(vids && vids.length) {
      _getNotifCountFromLocalDb(vidArr, function(err, notif){
        if(err) cb(err, null)
        else cb(null, notif);
      });
    }
    else {
      _getNotifCountFromServer(vidArr, function(err, notification){
        if(err) cb(err, null);
        else cb(null, notification);
      });
    }
  }).catch(() => {return ;});
}

function _checkNotifCountForVidArr(vidArr,cb){
  var foundData = [] ,newVidArr = [];
  vidArr.forEach((d ,i) => {
    getFromStore('generic',"notifCount_"+d).then((ts) => {
      if(ts !== TODAY_START_TS){
        newVidArr.push(d);
        if((vidArr.length - 1) === i) cb(null ,{foundData : foundData,newVidArr: newVidArr})
      }else{
        getFromStore('notifCount', d).then(notif => {
          if (Object.keys(notif).length > 0) foundData.push({vid: d, count: notif});
          else if(!notif) newVidArr.push(d);
          if((vidArr.length - 1) === i) cb(null, {foundData : foundData , newVidArr: newVidArr});
        }).catch((e) => {
          cb(e,null);
        });
      }
    }).catch(() => {
      return;
    })
  });
}

function _getNotifCountFromLocalDb(vidArr, cb){
  _checkNotifCountForVidArr(vidArr , (err ,data) => {
     if(data){
      if(data.newVidArr && data.newVidArr.length){
        _getNotifCountFromServer(data.newVidArr, function(err, notification){
          if(err) cb(err, null);
          else{
            if(notification && notification.length){
              if(data.foundData && data.foundData.length) {
                data.foundData = data.foundData.concat(notification);
                cb(null, data.foundData);
              }
              else cb(null, notification);
            }
            else {
              if(data.foundData && data.foundData.length) cb(null, data.foundData);
              else cb(null, notification);
            }
          }
        });
      }
      else{
        cb(null, data.foundData);
      }
     }
  })
}

function _getNotifCountFromServer(vidArr, cb){
  var newNotif = [];
  var NotifCount = [];
  var url = glHostName + "/gl/users/notifications/getNotifCountByVehicleId";
  Net.PostRequest(url, {vidArr: vidArr}, (resp) => {
    if (resp.status === 200) {
      var notification = JSON.parse(resp.response).data;
      vidArr.forEach(vid=>{
        var temp = {};
        notification.forEach((d)=>{
          if(vid === d._id.vid) temp[NOTIF_TYPE[d._id.typ]] = d.count
        });
        if(Object.keys(temp).length > 0) NotifCount.push({ vid: vid, count: temp})
      });
      if(NotifCount && NotifCount.length) {
        _insertNotifCountToLocalDB(vidArr, NotifCount ,(data) => {
          cb(null, data);
        });
      }
      else {
        vidArr.forEach((d,i) => {
          setToStore('notif',d,[]).then(() => {
            setToStore('generic',"notifCount_"+ d ,TODAY_START_TS ).then(()=>{
              if((vidArr.length - 1) === i) cb(null, newNotif);
            });
          });
        });

      }
    }
    else cb("Error While Getting Data from Server", null);
  });
}

function _insertNotifCountToLocalDB(vidArr, notifcount ,cb){
  var newNotif = [];
  var notFound = [];
  var foundVid = notifcount.map(d=> d.vid);
  vidArr.forEach(d=>{ if(foundVid.indexOf(d) < 0) notFound.push({ vid: d, count: {}})}); //change when indexdb will release
  if(notFound && notFound.length) notifcount = notifcount.concat(notFound);
  notifcount.forEach((d,i)=> {
    setToStore('notifCount',d.vid, d.count).then(()=>{
      if(Object.keys(d.count).length > 0) {
        newNotif.push({vid: d.vid, count: d.count });
      }
      setToStore('generic',"notifCount_"+d.vid, TODAY_START_TS).then(() => {
        if((notifcount.length - 1) === i) cb(newNotif);
      }).catch(() => {
        return;
      })
    }).catch((e) => {
      return;
    });
  });
}


export function getNotifFromDb(vid, cb) {
  getFromStore('notif', vid).then(tt => {
    if (tt) {
      getFromStore('generic',"notif_" + vid).then((ts) => {
        if (ts) {
          if (ts !== TODAY_START_TS) {
            _getNotifDataFromServer(vid, function(err, notif){
              if(err) cb(err, null);
              else cb(null, notif);
            })
          }
          cb(null, tt);
        }
        else {
          _getNotifDataFromServer(vid, function(err, notif){
            if(err) cb(err, null);
            else cb(null, notif);
          })
        }
      });

    } else {
      _getNotifDataFromServer(vid, function(err, notif){
        if(err) cb(err, null);
        else cb(null, notif);
      })
    }
  });
}



function _getNotifDataFromServer(vid, cb){
  var url = glHostName + "/gl/users/notifications/getNotifByVehicleId?vid=" + vid;
  Net.GetRequest(url, (resp) => {
    var notification = JSON.parse(resp.response).data;
    if (resp.status !== 200) {
      cb("Error While Finding Notification Data", null)
    }
    else {
      setToStore('notif', vid, notification).then(()=>{
        setToStore('generic',"notif_"+ vid ,TODAY_START_TS ).then(()=>{
          cb(null, notification)
        });
      })
    }
  });
}

export function saveNewNotif(notif, cb) {
  if (notif && Object.keys(notif).length > 1) {
    getFromStore('alerts', notif.vid).then(nf => {
      if (!nf) nf = [];
      nf.push(notif);
      setToStore('alerts', notif.vid, nf).then(()=>{
        cb(null, notif);
      })
      .catch((e)=>{
        cb(e, null);
      });
    });
  }
}

// export function saveNewNotif(notif, cb) {
//   if (notif && Object.keys(notif).length > 1) {
//     getFromStore('notifCount', notif.vid).then(nfc => {
//       if (!nfc) nfc = {};
//       var newNotif = nfc;
//       var notifCount = newNotif[notif.h];
//       if(notifCount) newNotif[notif.h] = notifCount + 1;
//       else newNotif[notif.h] = 1;
//       setToStore('notifCount', notif.vid, newNotif).then(()=>{
//         getFromStore('notif', notif.vid).then(nf => {
//           if (!nf) nf = [];
//           nf.push(notif);
//           setToStore('notif', notif.vid, nf).then(()=>{
//             cb(null, notif);
//           })
//           .catch((e)=>{
//             cb(e, null);
//           });
//         });
//       })
//       .catch((e)=>{
//         cb(e, null);
//       });
//     });
//   }
// }


