import React, { Component } from 'react';
import { StampTODate, parseMSIntoReadableTime } from '../../lib/dtconv';
import { browserHistory } from 'react-router';
import {GetDateFromNow} from '../../lib/dtconv';
import { Col, Row, ListGroupItem, Table, Alert } from 'react-bootstrap/lib';
import { getTodaysRides, getMultivehDR, fetchDatafromServer } from '../../lib/db/rides';
import { compareTwoArrays } from '../../lib/common';
const {DAY, ONE_DAY_TS} = require('../../lib/enums');
const todayStartTS = require('../../lib/enums').TODAY_START_TS;
const LAST_FIVE_DAY_TS = GetDateFromNow(4) * 1000;

export default class DailyRidesTiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyStat: [],
      errMsg: "",
      tileClick: false,
      isLoading: false,
      loadMoreDisabled: false,
      loadMore: false,
      labelMsg: "",
    }
    this.dailyRideTiles = [];
    this.singleRide = [];
    this.selectedVehicle = [];
    this.from = null;
    this.to = null;
    this.showMoreRideBtn = true;
    this.dailyRideData = 0;
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.ON_NEW_RIDE, this.onNewRides);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.ON_NEW_RIDE, this.onNewRides);
  }

  componentDidMount() {
    if (this.props.args.vids && this.props.args.from && this.props.args.to) this.getRides(this.props.args);
  }

  componentWillReceiveProps(newProps) {
    var diffElem = compareTwoArrays(newProps.args.vids,this.selectedVehicle);
    if(this.state.loadMore) return ;
    if(this.from !== newProps.args.from || this.to !== newProps.args.to || diffElem) {
      this.dailyRideData = 0;
      this.getRides(newProps.args);
    }
    else return;
  }

  onNewRides = (todayNewStat) => {
    var newStats = [];
    if (Object.keys(todayNewStat).length > 0) {
      newStats.push(todayNewStat);
      var index = this.state.dailyStat.findIndex(daily => { return (daily.ts === todayStartTS && daily.vid === todayNewStat.vid)});
      if(index >= 0)this.state.dailyStat.splice(index,1);
      newStats = newStats.concat(this.state.dailyStat);
      this.createRideData(newStats);
      this.setState({
        dailyStat: newStats,
        errMsg: ""
      });
    }
  }

  getRides(params) {
    this.selectedVehicle = params.vids;
    this.from = params.from;
    this.to = params.to;
    this.getTodaysRidesStats(params);
  }

  getDailyRidesStats(params) {
    var dailyStat = [];
    getMultivehDR(params, this.dailyRideData, (err, data, skipLength) => {
      if(skipLength) this.dailyRideData = skipLength;
      this.from = params.from;
      this.to = params.to;
      this.showDailyRide(data, dailyStat);
    });
  }

  showDailyRide = (data, dailyStat) =>{
    if (data && data.length) {
      data.forEach((veh) => {
        dailyStat = dailyStat.concat(veh.dailyRides);
      });
    }
    if (dailyStat && dailyStat.length) {
      if (dailyStat.length === 1 && Object.keys(dailyStat[0]).length < 2) {
        this.setState({
          errMsg: "Rides are not present for this vehicle",
          isLoading: false,
          loadMoreDisabled: false,
          labelMsg: ""
        });
      } else {
        dailyStat.sort((a, b) => b.ts - a.ts);
        this.createRideData(dailyStat);
        this.setState({
          dailyStat: dailyStat,
          isLoading: false,
          errMsg: "",
          loadMoreDisabled: false,
          labelMsg: ""
        });
      }
    } else {
      this.setState({
        isLoading: false,
        errMsg: "Rides are not present for this vehicle"
      });
    }
    this.props.loading(this.state.isLoading);
    this.setState({ loadMore: false})
  }

  getTodaysRidesStats(params) {
    this.setState({ isLoading: true })
    getTodaysRides((err,data) => {
      this.getDailyRidesStats(params);
    });
  }

  createRideData(dailyStat) {
    this.dailyRideTiles = [];
    var dt, midnight;
    if (dailyStat && dailyStat.length) {
      dailyStat.forEach((ride) => {
        if (ride.ts) dt = new Date(ride.ts);
        else dt = new Date(ride.sdt * 1000);
        midnight = new Date().setHours(0, 0, 0, 0);
        if (ride.hasOwnProperty('dst')) {
          this.dailyRideTiles.push({
            id: ride, data:
              <div>
                <div className="font-size16px padding-left1 color-9b9b9b">{ride.vid ? this.props.vehMap[ride.vid] : null}</div>
                <div className="font-size20px padding-left1">{dt.getTime() < midnight ? StampTODate(dt) + " " + DAY[dt.getDay()] : "Today"}</div>
                <Table style={{ borderTop: "hidden", marginTop: "1%", marginBottom: "0px" }}>
                  <tbody>
                    <tr>
                      <td align="center" className="color-9b9b9b font-size14px" style={{ width: "20%", borderBottom: "hidden" }}>Rides<div className="font-size18px" style={{ color: "black" }}>{ride.rids ? ride.rids.length : "-"}</div></td>
                      <td align="center" className="color-9b9b9b font-size14px" style={{ width: "30%", borderBottom: "hidden" }}>Distance<div className="font-size18px" style={{ color: "black" }}>{ride.dst ? (ride.dst / 1000).toFixed(1) + " Km" : "-"}</div></td>
                    {/* </tr>
                    <tr> */}
                      <td align="center" className="color-9b9b9b font-size14px" style={{ width: "30%" }}>Duration<div className="font-size18px" style={{ color: "black" }}>{ride.dur ? parseMSIntoReadableTime(ride.dur * 1000) : "-"}</div></td>
                      <td align="center" className="color-9b9b9b font-size14px" style={{ width: "40%" }}>Engine On Duration<div className="font-size18px" style={{ color: "black" }}>{ride.ignOnDur ? parseMSIntoReadableTime(ride.ignOnDur * 1000) : "-"}</div></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
          });
        }
      });
    }
  }

  onTileClick = (ride) => {
    this.setState({
      tileClick: true
    });
    var newUrl = "/webapp/dailyRidesPage/rides";
    var loc = this.props.location;
    if (loc && loc.query && loc.query.vid) {
      if (Array.isArray(loc.query.vid)) {
        loc.query.vid.forEach((d, i) => {
          if (i === 0) newUrl += "?vid=" + d;
          else newUrl += "&vid=" + d;
        });
      } else newUrl += "?vid=" + loc.query.vid;
      newUrl += "&";
    } else newUrl += "?";

    newUrl += "veh=" + ride.vid + "&ts=" + (ride.ts ? ride.ts : todayStartTS);
    browserHistory.push(newUrl);
    this.props.onTileClick(true);
  }

  getMoreRides = () =>{
    this.setState({loadMoreDisabled: true, loadMore: true,errMsg : "", labelMsg: ""}, ()=>{
      var params = {
        from: this.from,
        to:  this.to,
        vids: this.props.args.vids
      }
      if(params.to >= LAST_FIVE_DAY_TS && params.from >= LAST_FIVE_DAY_TS) {
        return this.setState({ loadMore: false, labelMsg: "No Data to Show"});
      }
      if(params.from < LAST_FIVE_DAY_TS && params.to > LAST_FIVE_DAY_TS){
        params.to =  LAST_FIVE_DAY_TS - ONE_DAY_TS;
      }
      fetchDatafromServer(params, this.dailyRideData, (err, data, dataLength) => {
        if(data && data.length === 0) {
          this.setState({ loadMore: false, labelMsg: "No Data to Show"});
        }
        else {
          this.dailyRideData += dataLength;
          this.showDailyRide(data, this.state.dailyStat);
        }
      });
    });

  }

  render() {
    return (
      <Col lg={12} md={12} sm={12} xs={12} className={(this.state.tileClick ? "border-gray " : "height-70vh overflowY-auto")}>
        {
          this.state.errMsg || !this.props.vehicle || this.dailyRideTiles.length === 0 ?
            <Row >
              {this.state.errMsg ?
                <Col lg={12} md={12} sm={12} xs={12} style={{ paddingLeft: "0px" }}><Alert bsStyle="warning">{this.state.errMsg}</Alert></Col>
                : null}
            </Row>
            : null
        }
        {
          this.state.dailyStat && this.state.dailyStat.length && !this.state.errMsg && !this.state.tileClick ?
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} style={{ paddingLeft: "0px" }}>
                {
                  this.dailyRideTiles.map((ride, i) => {
                    return (
                      <ListGroupItem style={{ position: "initial", marginBottom: "2%", borderRadius: "4px" }} header={ride.data} key={i} onClick={() => this.onTileClick(ride.id)}></ListGroupItem>
                    )
                  })
                }
                { this.showMoreRideBtn ? <button className="btn btn-sm btn-info" disabled={this.state.loadMoreDisabled} onClick={()=> this.getMoreRides()}>Show More Rides</button> : null}
                { this.state.labelMsg ? <p>{this.state.labelMsg}</p> : null}
              </Col>
            </Row>
            : null
        }
      </Col>
    )
  }
}

export function getRidesStat(statsArr) {
  var dst = 0 ,dur = 0 ,tps = 0 ,avs = 0 ,mang = 0 ,sdt = null ,edt = 0 ,totalRide = 0 ,ignOnDur = 0 ;
  var rids = [];
  var rideStat = {};
  if (statsArr && statsArr.length) {
    statsArr.forEach((stat) => {
      if (stat.hasOwnProperty('dst')) {
        if (!sdt && stat.sdt) sdt = stat.sdt;
        if (stat.dst) dst += stat.dst;
        if (stat.dur) dur += stat.dur;
        if (stat.ignOnDur) {
          ignOnDur += stat.ignOnDur;
        }
        if (stat.idx) rids.push(stat.idx);
        if (stat.rids) {
          totalRide += stat.rids.length;
          rids = rids.concat(stat.rids);
        }
        if (stat.tps && tps < stat.tps) tps = stat.tps;
        if (stat.mang && (mang < stat.mang)) mang = stat.mang;
        edt = stat.edt;
      }
    })
    if(dur && dst) avs = (dst / dur) * (18 / 5);
  }
  rideStat.dst = dst;
  rideStat.dur = dur;
  rideStat.avs = avs;
  rideStat.tps = tps;
  rideStat.sdt = sdt;
  rideStat.edt = edt;
  rideStat.mang = mang;
  rideStat.ignOnDur = ignOnDur;
  if (rids && rids.length) rideStat.rids = rids;
  if (totalRide !== 0) rideStat.totalRide = totalRide;
  return (rideStat)
}