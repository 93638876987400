import Net from '../net';
import { getRidesStat } from '../../components/dailyRides/dailyRideTiles';
import { getFromStore, setToStore, getAllkeys } from './common';
import {GetDateFromNow} from '../dtconv';
const glHostName = require('../../config').config.glHostName;
const {TODAY_START_TS, ONE_DAY_TS} = require('../enums');
const TODAY_START_INSEC = Math.round((new Date().setHours(0, 0, 0)) / 1000);
const LAST_FIVE_DAY_TS = GetDateFromNow(4) * 1000;

function _saveTodaysStatsIndaily(vid, todaysStat, cb) {
  var todaySts = {};
  todaysStat.ts = TODAY_START_TS;
  todaysStat.vid = vid;
  todaySts[TODAY_START_TS] = todaysStat;
  getFromStore('dailyRides', vid).then(res => {
    if (!res) res = {};
    res = Object.assign(res, todaySts);
    setToStore('dailyRides', vid, res).then(val => {
      cb();
    }).catch(() => {
      return;
    });
  }).catch(() => {
    return;
  });
}

function getVehWiseTodaysRides(ridArr, cb) {
  var distinctVids = [], vehRideArr = [];
  distinctVids = [...new Set(ridArr.map(ride => ride.vid))];
  distinctVids.forEach((v, i) => {
    var vehRides = [], ridesObj = {} ,todaysRideArr = [];
    vehRides = ridArr.filter(d => d.vid === v);
    todaysRideArr = vehRides;
    getFromStore('rides', v).then((data) => {
      if (data && Object.keys(data).length) {
        vehRides.forEach((ride) => {
          if (Object.keys(data).indexOf(ride.idx.toString()) > -1) delete data[ride.idx];
        });
        vehRides = vehRides.concat(Object.values(data));
      } else data = {};

      vehRides.forEach((rideData) => {
        ridesObj[rideData.idx] = rideData;
        data = Object.assign(data, ridesObj);
      });
      vehRideArr.push({ vid: v, rides: todaysRideArr })
      setToStore('rides', v, data).then(() => {
        if (distinctVids.length - 1 === i) cb(vehRideArr);
      }).catch(() => {
        return;
      });
    });
  });
}

function storeLatestRid(rideArr, cb) {
  var latestRid = rideArr.reduce((max, p) => p.idx > max ? p.idx : max, rideArr[0].idx);
  setToStore('generic', "latestRide", latestRid).then(() => cb());
}

export function getTodaysRides(cb) {
  var paramObj = {}, todaysStat = {};
  getFromStore("generic", "latestRide").then((rid) => {
    if (rid) paramObj.rid = rid;
    else paramObj.rid = 0;
    paramObj.ts = TODAY_START_INSEC;
    _getTodayRidesFromServer(paramObj, (err, data) => {
      if (data && data.rides && data.rides.length) {
        storeLatestRid(data.rides, () => {
          getVehWiseTodaysRides(data.rides, (vehRideArr) => {
            vehRideArr.forEach((veh, i) => {
              if (veh.rides.length) todaysStat = getRidesStat(veh.rides);
              _saveTodaysStatsIndaily(veh.vid, todaysStat, () => {
                if (vehRideArr.length - 1 === i) cb(null, true);
              });
            });
          });
        });
      } else if (data && data.rides.length === 0) {
        cb(null, true);
      }
    });
  });
}

function _getTodayRidesFromServer(params, cb) {
  var url = glHostName + '/gl/users/rides/getridesforalluservehicle?rid=' + params.rid + "&ts=" + params.ts;
  url += '&fuelLog=' + false + '&sharedRides=' + false + "&customSharedRides=" + false + "&mileages=" + false + "&pro="+ true;
  Net.GetRequest(url, (resp) => {
    var data = JSON.parse(resp.response).data;
    if (resp.status === 200)  cb(null, data);
    else cb(data, null);
  });
}

export function getDayData(vid, ts, cb) {
  getFromStore('dailyRides', vid).then((vehDRides) => {
    if (vehDRides && vehDRides.hasOwnProperty(ts)) {
      cb(vehDRides[ts]);
    } else {
      var paramObj = {};
      getTodaysRides(() => {
        paramObj.vids = [vid];
        paramObj.from = GetDateFromNow(4) * 1000;
        paramObj.to = new Date().setHours(23, 59, 59, 999);
        getMultivehDR(paramObj, 0,(err, daywise) => {
          var data = _getDaywiseRides(vid, ts, daywise);
          if(data)  {
            cb(data);
          }
          else {
            fetchRidesfromServer(vid, ts, (data) => {
              cb(data)
            })
          }
        });
      });
    }
  }).catch(() => {
    return;
  });
}

function _getDaywiseRides(vid, ts, daywise){
  var currVehRides = [];
  if (daywise && daywise.length) {
    daywise.forEach(data => {
      if(data.vid === vid) currVehRides = currVehRides.concat(data.dailyRides);
    });
    var dayObj = currVehRides.filter((ride) => {
      return ts === ride.ts;
    });
    if(dayObj && dayObj.length) return dayObj[0];
    else return false;
  }
  else return false;
}

function fetchRidesfromServer(vid, ts, cb){
  var paramObj = {}
  paramObj.vids = [vid];
  paramObj.from = ts;
  paramObj.to = new Date(ts).setHours(23, 59, 59, 999);
  _getDRFromServer(paramObj, (err, dailyRides) => {
    if (err) cb(false);
    var data = _getDaywiseRides(vid, ts, dailyRides);
    cb(data);
  });
}

export function savePingsAndStatsInDb(vid, rideId, path, stats, cb) {
  getFromStore('rides', vid).then((rides) => {
    if (!rides) rides = {};
    if (rides && !rides.hasOwnProperty(rideId)) {
      rides[rideId] = Object.assign(stats);
      setToStore('rides', vid, rides).catch(() => {
        return;
      });
    }
    getFromStore('paths', vid).then((paths) => {
      if (!paths) paths = {};
      if (paths && !paths.hasOwnProperty(rideId)) {
        paths[rideId] = Object.assign(path);
        setToStore('paths', vid, paths).then(path => {
          cb();
        }).catch(() => {
          return;
        });
      }
    }).catch(() => {
      return;
    });
  }).catch(() => {
    return;
  });
}

export function getStatForRides(vid, rideIds, cb) {
  var statArr = [];
  getFromStore('rides', vid).then((rides) => {
    if (rideIds.length) {
      rideIds.forEach((rid) => {
        if (rides && rides.hasOwnProperty(rid)) statArr = statArr.concat(rides[rid]);
      });
      cb(statArr);
    }
  }).catch(() => {
    return;
  });
}

export function getPathForRides(vid, rideIds, cb) {
  var pathArr = [];
  getFromStore('paths', vid).then((paths) => {
    if (rideIds.length) {
      rideIds.forEach((rid) => {
        if (paths && paths.hasOwnProperty(rid)) {
          pathArr = pathArr.concat(paths[rid]);
        }
      });
      cb(pathArr);
    }
  }).catch(() => {
    return;
  });
}

export function saveRideInTodayStat(vid, newRideStats, cb) {
  var statArr = [] , todaykeyLen ;
  getFromStore('dailyRides', vid).then((vehDRides) => {
    if (!vehDRides) vehDRides = {};
    if (vehDRides && vehDRides.hasOwnProperty(TODAY_START_TS)) {
      todaykeyLen = Object.keys(vehDRides[TODAY_START_TS]).length;
      if (todaykeyLen > 1) statArr.push(vehDRides[TODAY_START_TS]);
      if (todaykeyLen < 2) statArr.push(newRideStats);
      else if (todaykeyLen > 1 && vehDRides[TODAY_START_TS].rids.indexOf(newRideStats.idx) < 0) statArr.push(newRideStats);
      _saveNewTodayStats(vid, vehDRides, statArr ,(newStats) => {
        cb(newStats);
      });
    } else {
      statArr.push(newRideStats);
      _saveNewTodayStats(vid, vehDRides, statArr ,(newStats) =>{
        cb(newStats);
      });
    }
  }).catch(() => {
    return;
  });
}

function _saveNewTodayStats(vid, vehDRides, statArr, cb) {
  var newStats = {}, todaySts = {};
  if (statArr && statArr.length) {
    newStats = getRidesStat(statArr);
    newStats.ts = TODAY_START_TS;
    newStats.vid = vid;
    todaySts[TODAY_START_TS] = newStats;
    vehDRides = Object.assign(vehDRides, todaySts)
    setToStore('dailyRides', vid, vehDRides).then(() => {
      cb(newStats);
    }).catch(() => {
      return;
    });
  }
}

export function getMultivehDR(params, skip, cb) {
  getAllkeys('dailyRides').then((vids) => {
    if (vids && vids.length) {
      _getDRFromLocalDb(params, skip, (err, dailyRides) => {
        if (err) cb(err, null);
        else {
          if(params.to >= LAST_FIVE_DAY_TS && params.from >= LAST_FIVE_DAY_TS) cb(null, dailyRides);
          else if(params.to <= LAST_FIVE_DAY_TS) fetchDatafromServer(params, skip, cb);
          else if(params.to > LAST_FIVE_DAY_TS && params.from <= LAST_FIVE_DAY_TS){
            var getData = {
              from: params.from,
              to: LAST_FIVE_DAY_TS - ONE_DAY_TS,
              vids: params.vids
            }
            var dataLength = 0;
            fetchDatafromServer(getData, skip, (err, data) =>{
              if(err) return cb(err, null);
              if(data.length === 0) cb(null, dailyRides);
              else{
                data.forEach((d) => {
                  dataLength += d.dailyRides.length;
                  var temp = dailyRides.find(ride => ride.vid === d.vid);
                  if(temp) temp.dailyRides = temp.dailyRides.concat(d.dailyRides);
                  else dailyRides.push(d)
                });
                cb(null, dailyRides, dataLength);
              }
            });
          }
        }
      });
    }
    else {
      params.fetchAll = true;
      _getDRFromServer(params, (err, dailyRides) => {
        if (err) cb(err, null);
        else cb(null, dailyRides);
      });
    }
  }).catch(() => { return; });
}

function _filterFoundData(result,foundData, cb  ){
  if(result && result.length){
    result.forEach((d,i) => {
      var index = foundData.findIndex(e => { return e.vid === d.vid });
        if (index !== -1) foundData.splice(index, 1)
    });
    cb(foundData);
  }else cb(foundData);
}

function _getDRFromLocalDb(params, skip, cb) {
  _checkDRforVids(params, (err, data) => {
    if (data) {
      if (data.newVidArr && data.newVidArr.length) {
        var paramObj = {};
        paramObj.vids = data.newVidArr;
        paramObj.from = params.from;
        paramObj.to = params.to;
        paramObj.fetchAll = true;
        _getDRFromServer(paramObj, function (err, res) {
          if (err) cb(err, null);
          else {
            if (res && res.length) {
              if (data.foundData && data.foundData.length) {
                _filterFoundData(res,data.foundData , (foundData) => {
                  foundData = foundData.concat(res);
                  cb(null, foundData);
                });
              }
              else cb(null, res);
            }
            else {
              if (data.foundData && data.foundData.length) cb(null, data.foundData);
              else cb(null, res);
            }
          }
        });
      }
      else {
        cb(null, data.foundData);
      }
    }
  });
}

function _checkDRforVids(params, cb) {
  var foundData = [], newVidArr = [];
  params.vids.forEach((d, i) => {
    getFromStore('generic', "dailyRides_" + d).then((ts) => {
      if (!ts) newVidArr.push(d);
      if (ts && (ts !== TODAY_START_TS)) {
        newVidArr.push(d);
        if ((params.vids.length - 1) === i) cb(null, { foundData: foundData, newVidArr: newVidArr })
      } else {
        getFromStore('dailyRides', d).then(vehDRides => {
          if (vehDRides && Object.keys(vehDRides).length > 0) {
            _filterDataByDate(vehDRides, params.from, params.to, (statArr) => {
              if (statArr.length) foundData.push({ vid: d, dailyRides: statArr });
            });
          }
          if ((params.vids.length - 1) === i) cb(null, { foundData: foundData, newVidArr: newVidArr });
        }).catch(() => {
          return;
        });
      }
    }).catch(() => {
      return;
    })
  });
}

function _filterDataByDate(vehDRides, from, to, cb) {
  var dailyTs = [], statArr = []
  dailyTs = Object.keys(vehDRides);
  dailyTs.forEach((ts) => {
    if (parseInt(ts) >= from && parseInt(ts) <= to) {
      if (Object.keys(vehDRides[ts]).length > 1) statArr.push(vehDRides[ts]);
    }
  });
  cb(statArr);
}

function __getDailyRidesFromServer(params, cb) {
  var url = glHostName + '/gl/users/rides/getDailyRidesForVehs';
  Net.PostRequest(url, params, (resp) => {
    var data = JSON.parse(resp.response).data;
    if (resp.status === 200) {
      var vehRideArr = [];
      if(data && data.length){
        vehRideArr = getRidesByVid(data, vehRideArr);
      }
      cb(null, vehRideArr);
    }
    else cb("Error While Getting Data from Server", null);
  });
}

function _getDRFromServer(params, cb) {
  __getDailyRidesFromServer(params, (err, res) => {
    if (err) cb(err, null);
    if (res && res.length) {
      _storeDailyRidesInDB(params, res, (data) => {
        cb(null, data);
      });
    }
    else {
      _storeDRTSInGeneric(params.vids, () => {
        cb(null, res);
      });
    }
  });
}

function _storeDailyRidesInDB(params, data, cb) {
  var dailyRidesArr = [];
  data.forEach((d, i) => {
    var statsObj = {};
    d.dailyRides.forEach((day) => {
      statsObj[day.ts] = day;
    });
    getFromStore('dailyRides', d.vid).then(vehDRides => {
      if (!vehDRides) vehDRides = {};
      vehDRides = Object.assign(vehDRides, statsObj);
      _filterDataByDate(vehDRides, params.from, params.to, (statArr) => {
        if (statArr.length) dailyRidesArr.push({ vid: d.vid, dailyRides: statArr });
      });
      setToStore('dailyRides', d.vid, vehDRides).then(() => {
        if ((data.length - 1) === i) {
          _storeDRTSInGeneric(params.vids, () => {
            cb(dailyRidesArr);
          });
        }
      }).catch(() => {
        return;
      });
    });
  });
}

function _storeDRTSInGeneric(vids, cb) {
  vids.forEach((id, i) => {
    setToStore('generic', "dailyRides_" + id, TODAY_START_TS).then(() => {
      if ((vids.length - 1) === i) cb();
    }).catch(() => {
      return;
    });
  });
}

export function fetchDatafromServer(params, skip, cb){
  params.skip = skip;
  __getDailyRidesFromServer(params, (err, dailyRides) => {
      if (err) cb(err, null);
      else {
        var dataLength = 0;
        dailyRides.forEach((d) => { dataLength += d.dailyRides.length });
        cb(null, dailyRides, dataLength);
      }
    });
}

export function GetStatistics(params, cb){
  var url = glHostName + "/gl/users/rides/getStatsByTime";

  Net.PostRequest(url, params, function(resp){
    try{
      var data = JSON.parse(resp.response).data;
    }
    catch(e){
      console.log("Exception in GetStatistics",e);
    }
    if(resp.status === 200){
      cb(null, data);
    }
    else cb("Error while getting stats", data);
  });
}

export function getRidesByVid(data, vehRideArr){
  let distinctVids = [...new Set(data.map(ride => ride.vid))];
  distinctVids.forEach((v,i) => {
    var vehRides = [];
    vehRides = data.filter(d => d.vid === v);
    vehRideArr.push({ vid: v, dailyRides: vehRides })
  });
  return vehRideArr;
}