/**
 * This component exports different types of pre-configureed buttons
 * TODO: Define required props and propTypes
 */
import React from 'react';
import { Button, Glyphicon } from 'react-bootstrap/lib';
import Proptypes from 'prop-types';

export class GenericButton extends React.PureComponent {
  render() {
    return (
      <Button  {...this.props}> {this.props.btnlabel || 'Button'} </Button>
    )
  }
}

export class SubmitButton extends React.PureComponent {
  render() {
    return (
      <Button bsStyle='primary' {...this.props}> {this.props.btnlabel || 'Submit'} </Button>
    )
  }
}

export class ResetButton extends React.PureComponent {
  render() {
    return (
      <Button bsStyle='warning' {...this.props}> {this.props.btnlabel || 'Reset'} </Button>
    )
  }
}

export class CloseButton extends React.PureComponent {
  render() {
    return (
      <Button bsStyle='danger' {...this.props}> {this.props.btnlabel || 'Close'} </Button>
    )
  }
}

export class GlyphButton extends React.PureComponent {
  render() {
    return (
      <Button {...this.props}> <Glyphicon glyph={this.props.glyphicon} /> </Button>
    )
  }
}
GlyphButton.propTypes = {
  glyphicon: Proptypes.string.isRequired
};
