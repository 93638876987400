const engMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function StampTODate(timestamp){
  var todate = new Date(timestamp).getDate();
  var toyear = new Date(timestamp).getFullYear();
  var a = new Date(timestamp).getMonth()
  var month = engMonthNames[a];

  var original_date = todate + ' ' + month + ' ' + toyear;
  return original_date;
}

export function timeFormat(dt, withSeconds) {
  var date = new Date(dt);
  var h = date.getHours();
  var m = date.getMinutes();
  var s;
  if(withSeconds){
    s = date.getSeconds().toString();
    if(s.length === 1) s = '0' + s;
  }
  var x = h >= 12 ? 'PM' : 'AM';
  h = h > 12 ? (h % 12) : h;
  m = m < 10 ? ("0" + m) : m;
  var mytime = h + ':' + m + (s ? (':' + s) : '') + ' ' + x;
  return mytime;
}

export function toLocaleDateString(date, format){
  var localDateString = date ? new Date(date) : new Date()
  if(format && format === "yyyy-mm-dd")
    return localDateString.getFullYear() + "-" + (((localDateString.getMonth()+1) < 10) ? "0"+(localDateString.getMonth()+1) : (localDateString.getMonth()+1)) + "-" + ((localDateString.getDate() < 10) ? "0"+localDateString.getDate() : localDateString.getDate());
  else if(format && format === "dd/mm/yyyy")
    return ((localDateString.getDate() < 10) ? "0"+localDateString.getDate() : localDateString.getDate()) + "/" + (((localDateString.getMonth()+1) < 10) ? "0"+(localDateString.getMonth()+1) : (localDateString.getMonth()+1)) + "/" + localDateString.getFullYear();
  else
    return ((localDateString.getMonth()+1) < 10 ? "0"+(localDateString.getMonth()+1) : (localDateString.getMonth()+1))+"/"+((localDateString.getDate() < 10) ? "0"+localDateString.getDate() : localDateString.getDate())+"/"+localDateString.getFullYear();
}

export function changeTo12HrFormat(ts){
  var date = new Date(ts);
  return date.toDateString() + " " + timeFormat(ts, true);
}

export function convertToDateTimeString(param){
  if(param && (param !== 'N/A' && param !== 'NA')){
    return toLocaleDateString(param) + " " + (new Date(param)).toLocaleTimeString();
  }
  else return "";
}

export function parseMSIntoReadableTime(milliseconds){
  //Get hours from milliseconds
  var hours = milliseconds / (1000*60*60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  var seconds = (minutes - absoluteMinutes) * 60;
  var absoluteSeconds = Math.round(seconds);
  var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

  return (h > 0 ? h +'h ': "") + (m > 0 ? m +'m ' : "") + s + 's';
}

export function GetDateFromNow(days, date){ // will return date of no. of days passed in args from now in epoc ts.
  return Math.round(new Date((date || Date.now()) - (86400000 * days)).setHours(0, 0, 0, 0) / 1000);
}
export function GetTSForGivenDays(ts,days){ // will return TS of no. of days passed in args from given ts.
  return new Date(ts + (86400000 * days)).getTime();
}

export function getHourMinSec(date) {
  if (date) {
    var hrs = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();
    var mid = "AM";

    if (hrs === 0)
      hrs = "12";
    else if (hrs >= 12) {
      if (hrs !== 12)
        hrs = hrs % 12;
      mid = "PM";
    }
    return ((hrs < 10 ? ("0" + hrs) : hrs) + ":" + (min < 10 ? ("0" + min) : min) + ":" + (sec < 10 ? ("0" + sec) : sec) + " " + mid);
  }
}

export function timeDiffInDays(ts1, ts2) {
  var difference = ts1 - ts2;
  var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  return Math.abs(daysDifference);
}

export function getTimeElapsed(fromTs, toTs) {
  var days = timeDiffInDays(fromTs, toTs);
  var months = parseInt(days / 30);
  var years = parseInt(months / 12);

  if (years) return years + (years === 1 ? " year" : " years");
  if (months) return months + (months === 1 ? " month" : " months");
  if (days) return days + (days === 1 ? " day" : " days");

  return "Today";
}