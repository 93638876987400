import React from 'react';
import { Table, Button, Breadcrumb, Alert } from 'react-bootstrap/lib';
import { StampTODate, getHourMinSec, getTimeElapsed } from '../../lib/dtconv';
import Net from '../../lib/net';
import { MENUPATH } from '../../lib/enums';
var crmhostname = require('../../config').config.crmhostname;

export default class Sessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appSessions: [],
      errMsg: ""
    }
  }

  componentDidMount() {
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.CURRENT_SESSIONS);
    this._getCurrentSessions();
  }
  _getCurrentSessions() {
    var url = crmhostname + "/crm/users/users/getAllSessions";
    Net.GetRequest(url, (resp) => {
      try {
        var data = JSON.parse(resp.response).data;
      } catch (e) {
        this.setState({ errMsg: "Something went wrong. PLease try again later." });
        return;
      }
      if (resp.status === 200) {
        if (data && data.appSess && data.appSess.length && data.userDevices) {
          this._addUserDevices(data.appSess, data.userDevices);
        }
        else {
          this.setState({
            errMsg: "App sessions are not present"
          });
        }
      } else {
        this.setState({
          errMsg: typeof data === "string" ? data : ""
        });
      }
    });
  }

  _addUserDevices(sessions, userDevices) {
    var filteredSess = sessions.map((sess) => {
      if (sess.uuid) {
        var matchedUD = userDevices.find((ud) => {
          return (ud.uuid === sess.uuid)
        });
        if (matchedUD) sess.ud = matchedUD;
      }
      else {
        try {
          var session = JSON.parse(sess.session);
          sess.ts = session.ts;
        } catch (e) {
          console.error(e);
        }
      }
      return sess;
    });
    this.setState({
      appSessions: filteredSess
    })
  }

  deleteSession(sesObj) {
    var url = crmhostname + "/crm/users/users//deleteSessionById";
    var params = { id: sesObj._id };
    if (sesObj.ud) params.uuid = sesObj.uuid;

    Net.PostRequest(url, params, (resp) => {
      if (resp.status === 200) {
        var appSessions = [...this.state.appSessions];
        var stateObj = {};
        appSessions = appSessions.filter((ses) => ses._id !== sesObj._id);
        stateObj.appSessions = appSessions;
        if (!appSessions.length) stateObj.errMsg = "App sessions are not present";
        this.setState(stateObj);
      } else {
        this.setState({
          errMsg: "Unable to delete session"
        });
      }
    });
  }
  render() {
    return (
      <div>
        <Breadcrumb style={{ marginBottom: "10px" }}>
          <Breadcrumb.Item active>{"App Sessions - " + (this.state.appSessions && this.state.appSessions.length ? this.state.appSessions.length : "")}</Breadcrumb.Item>
        </Breadcrumb>
        {
          this.state.appSessions && this.state.appSessions.length ?
            <div style={{ height: "80vh", overflowY: "auto" }}>
              <Table style={{ borderTop: "hidden", borderLeft: "hidden", marginBottom: "0px" }}>
                <tbody>
                  {
                    this.state.appSessions.map((ses, i) => {
                      return (
                        <tr key={i} style={{ padding: "0px", borderBottom: (i === (this.state.appSessions.length - 1) ? "1px solid #ddd" : "") }}>
                          <td style={{ width: "60px", borderRight: "1px solid white", paddingLeft: "0" }}>
                            <i className={"icon ajjas-" + (ses.ud && ses.ud.src === "ios" ? "apple" : "android") + " font-size22px"} />
                          </td>
                          <td style={{ borderRight: "1px solid white", width: "70%", paddingLeft: "0" }} className={"color-9b9b9b deviceName"}>
                            <span className="font-bold color-4a4a4a">{(ses.ud && ses.ud.brand ? ses.ud.brand : "") + " " + (ses.ud && ses.ud.mod ? ses.ud.mod : "") + "  "}</span>
                            <div className="device_info">{"Last used - " + (ses.ud && ses.ud.ts ? (StampTODate(ses.ud.ts) + " at " + getHourMinSec(new Date(ses.ud.ts))) : (ses.ts ? (StampTODate(ses.ts) + " at " + getHourMinSec(new Date(ses.ts))) : "NA"))}</div>
                            <div className="device_info">{"OS - " + (ses.ud && ses.ud.src ? (ses.ud.src.charAt(0).toUpperCase() + ses.ud.src.slice(1) + " " + ses.ud.osver) : "NA")}</div>
                          </td>
                          <td align="center" className={"color-9b9b9b"} style={{ paddingRight: "0" }}>
                            <div className="device_info" style={{ paddingBottom: "5px" }}>{ses.ud && ses.ud["_id"] ? getTimeElapsed(tsFromObjectId(ses.ud["_id"]), Date.now()) : ""}</div>
                            <Button style={{ borderColor: "#0094de", color: "#0094de" }} onClick={() => this.deleteSession(ses)}><b>Sign out</b></Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
            : this.state.errMsg ? <Alert bsStyle="warning">{this.state.errMsg}</Alert> : null
        }
      </div>
    )
  }
}

function tsFromObjectId(objectId) {
  return parseInt(objectId.substring(0, 8), 16) * 1000;
};