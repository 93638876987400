import React from 'react';
import { Link } from 'react-router';
import { ListGroup, ListGroupItem, Col } from 'react-bootstrap/lib';
import PropTypes from 'prop-types';
import { compareTwoArrays } from '../../lib/common';
import { menuClick, calManuLength } from './common';

export default class Box extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selMenuPath: [],
      selNmArr: [],
      menu: this.props.menu,
      menuLength: this.props.menu && this.props.menu.screens && this.props.menu.screens.length ? this.props.menu.screens.length : 0
    }
  }
  
  componentDidMount() {
    this.setState({
      selMenuPath: this.props.selMenuPath && Object.keys(this.props.selMenuPath).length ? this.props.selMenuPath : [],
    })
  }
  static getDerivedStateFromProps(newprops, prevState) {
    var isDiff = compareTwoArrays(prevState.selMenuPath, newprops.selMenuPath);
    var len;
    if (isDiff) {
      if (prevState.menu && prevState.menu.screens) {
        len = calManuLength(prevState.menu.screens, newprops.selMenuPath, prevState.menu.screens.length);
      }
      return{
        selMenuPath: newprops.selMenuPath,
        selNmArr: newprops.selMenuPath,
        menuLength: len
      }
    } else return null;
  }

  onMenuClick = (selNmArr, newLength) => {
    var newState = {};
    newState.menuLength = newLength;
    if (selNmArr) newState.selNmArr = selNmArr;
    if (Object.keys(newState).length) this.setState(newState);
  }

  nestedDD = (menu, mstyle, isSubmenu) => {
    let selMenuPath = this.state.selMenuPath;
    let selNmArr = this.state.selNmArr;
    if (!isSubmenu ) this.menuIndex = 0;
    return (
      menu.map((m, i) => {
        let itemStyle = { color: selMenuPath && selMenuPath.length && selMenuPath.includes(m.nm) ? mstyle.itemStyle.selectedColor : mstyle.itemStyle.initialColor };
        this.menuIndex = this.menuIndex + 1;

        if (this.menuIndex === 1) {
          itemStyle.borderBottom = "none";
        }
        else if (this.menuIndex !== 1 && (this.menuIndex !== this.state.menuLength)) {
          itemStyle.borderBottom = "none";
          itemStyle.borderRadius = "0px";
        } else {
          itemStyle.borderTopLeftRadius = "0px";
          itemStyle.borderTopRightRadius = "0px";
        }
        if (m.submenu) {
          return (
            <Col key={m.nm} >
              <ListGroupItem style={itemStyle}
                onClick={() => menuClick(this.state.menuLength, m, [...this.state.selNmArr], this.props.location, (selNmArr,linkClicked, newLength) => this.onMenuClick(selNmArr, newLength))}>
                <span style={mstyle.itemStyle}>
                  <i className={"icon " + m.icon + " padding-right8"} style={mstyle.iconStyle} />
                  <span style={mstyle.titleStyle}>{m.label}</span>
                  <span className={"caret " + (selNmArr && selNmArr.length && selNmArr.includes(m.nm) ? "rotate-180deg" : "")} style={{ float: "right", marginTop: "3%" }} ></span>
                </span>
              </ListGroupItem>
              {
                selNmArr && selNmArr.length && selNmArr.includes(m.nm) ?
                  this.nestedDD(m.submenu.screens, m.submenu.style , true)
                  : null
              }
            </Col>
          )
        } else {
          let linkStyle = { color: selMenuPath && selMenuPath.length && selMenuPath.includes(m.nm) ? mstyle.itemStyle.selectedColor : mstyle.itemStyle.initialColor };
          linkStyle = Object.assign(linkStyle, mstyle.titleStyle);
          return (
            <ListGroupItem key={m.nm} style={itemStyle} onClick={() => menuClick(this.state.menuLength, m, [...this.state.selNmArr], this.props.location, (selNmArr, linkClicked, newLength) => this.onMenuClick(selNmArr, newLength))}>
              <span style={mstyle.itemStyle} >
                <i className={"icon " + m.icon + " padding-right8"} style={mstyle.iconStyle}/>
                <Link style={linkStyle} onClick={() => menuClick(this.state.menuLength, m, [...this.state.selNmArr], this.props.location, (selNmArr, linkClicked, newLength) => this.onMenuClick(selNmArr, newLength))} >{m.label}</Link>
              </span>
            </ListGroupItem>
          )
        }
      })
    )
  }

  render() {
    return (
      <ListGroup className='color-ffffff font-family-roboto'>
        {
          this.state.menu && Object.keys(this.state.menu).length ? this.nestedDD(this.state.menu.screens, this.state.menu.style ):  null
        }
      </ListGroup>
    )
  }
}
Box.propTypes = {
  menuPath: PropTypes.array,
  location: PropTypes.object,
  linkClicked: PropTypes.func
}