import React, { Component } from 'react';
import {Link, browserHistory} from 'react-router';
import { StampTODate, timeFormat } from '../lib/dtconv';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import {
  DropdownButton, MenuItem,
  ListGroupItem, ListGroup,
  Modal, Button, Col,
  Form, CloseButton, FormControl, Table, Checkbox,
  OverlayTrigger, Tooltip
} from 'react-bootstrap/lib';
import { clone } from '../lib/jsonUtils';
import PropTypes from 'prop-types';
// const dtconv = require('../lib/dtconv');
// const $ = require('jquery');
var j = 0;
// var $ = window.$;
// console.log(window);
export class DropDown extends Component {
  render() {
    return (
      <select onChange={this.props.onChange} className={this.props.className} defaultValue={this.props.defaultValue} value={this.props.value} id={this.props.id}
        required={this.props.required} disabled={this.props.disabled} style={this.props.style}>
        <option value={-1} disabled>{this.props.defaultOption}</option>
        {
          this.props.options.map((e, i) => {
            return <option value={e[this.props.unique]} key={i} ref={e[this.props.unique]}>
              {e[this.props.name]}
            </option>
          })
        }
      </select>
    );
  }
}
export class ReactDropDown extends Component {
  render() {
    return (
      <DropdownButton bsSize={this.props.bsSize} title={this.props.title} className={this.props.className} id={this.props.id} key={this.props.key} style={{ height: "40px", fontSize: "15px" }}>
        <MenuItem value={-1} disabled>{this.props.defaultOption} </MenuItem>
        {this.props.options ?
          this.props.options.map((opt, i) => {
            return <MenuItem value={opt[this.props.unique]} onClick={() => this.props.onClick(opt)} key={opt[this.props.unique]}>
              {opt[this.props.name]}
            </MenuItem>
          })
          : null
        }
      </DropdownButton>
    );
  }
}

export class MultiSelectDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: clone(this.props.options),
    }
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this._handleClickOutside = this._handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ options: clone(newProps.options) });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  _handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.dropDownClose();
    }
  }

  onClickCheckbox(e) {
    let clickedId = +e.target.id;
    let options = this.state.options;

    for (let i = 0; i < options.length; i++) {
      if (options[i].id === clickedId) {
        options[i].checked = e.target.checked;
        break;
      }
    }

    this.setState({ options });
  }

  dropDownClose() {
    // Hide dropdown from view
    var items = document.getElementById('items');
    var anchor = document.getElementById('anchor');
    if (items.classList.contains('visible')) {
      items.classList.remove('visible');
      items.style.display = "none";
      anchor.className = "anchorOpen";
    }

    // Clear dirty ticks
    let stateOps = clone(this.state.options);
    let propOps = this.props.options;

    for (let i = 0; i < stateOps.length; i++) {
      if (stateOps[i].checked !== propOps[i].checked) {
        stateOps[i].checked = propOps[i].checked;
      }
    }

    this.setState({ options: stateOps });
  }

  changevisible() {
    var items = document.getElementById('items');
    var anchor = document.getElementById('anchor');
    if (items.classList.contains('visible')) {
      items.classList.remove('visible');
      items.style.display = "none";
      anchor.className = "anchorOpen";
    }
    else {
      items.classList.add('visible');
      items.style.display = "block";
      anchor.className = "anchorClose";
    }
    items.onblur = function (evt) {
      items.classList.remove('visible');
    }
  }

  onApply() {
    // Filter out selected Ids
    let selectedIds = [];
    for (let i = 0; i < this.state.options.length; i++) {
      if (this.state.options[i].checked) {
        selectedIds.push(this.state.options[i].id);
      }
    }
    this.props.onApply(selectedIds);
    this.dropDownClose();
  }

  render() {
    return (
      <div ref={this.setWrapperRef}>
        <Col className="dropdown-check-list padding-top5" style={{ position: "absolute", backgroundColor: 'white' }}>
          <span id="anchor" className="anchorOpen" style={{ color: "#0094de", fontSize: "15px", paddingLeft: "62px", width: "106%" }} onClick={(e) => this.changevisible()}>
            {this.props.headerName}
          </span>
          <ul id="items" className="items" style={{ width: "220px" }}>
            <li style={{ paddingTop: "5px", paddingBottom: "6px", borderBottom: "1px solid #ccc" }}>
              <button id="clear" style={{ marginLeft: "6%" }} onClick={this.props.onClear}><span style={this.props.headingStyle} >Clear All</span></button>
              {this.props.apply ?
                <Button id="applybutton" bsSize="small" style={this.props.applyStyle} onClick={() => this.onApply()}><span style={{ fontSize: "12px" }} >{this.props.apply}</span></Button>
                : null
              }
            </li>

            <div style={{ overflowY: "auto", maxHeight: "250px" }}>
              {
                this.state.options.map((d, i) => {
                  return (
                    <li key={i} className={d.disabled ? "filter-disable" : null} style={{ padding: "10px" }}>
                      <input type="checkbox" checked={d.checked || false} disabled={d.disabled}
                        name="check" id={d.id} style={{ marginRight: "10%" }}
                        onChange={(e) => this.onClickCheckbox(e)} />{d.nm} <br />
                    </li>
                  )
                })
              }
            </div>
          </ul>
        </Col>
      </div>
    )
  }
}

export class DashBoardDropDown extends Component {
  // constructor(props) {
  //   super(props);
  // }

  check = () => {
    let child = document.getElementById(this.props.Menuid);
    child.parentElement.style.marginLeft = this.props.optionStyle;
    child.parentElement.style.marginTop = "4px";
  }

  render() {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${"top"}`}>
          {this.props.onHover}.
        </Tooltip>}
        >
        <DropdownButton bsSize={this.props.bsSize} title={this.props.title ? (isNaN(this.props.title) ? "0" : this.props.title) : "0"} className={this.props.className} id={this.props.id} style={this.props.style} noCaret={this.props.noCaret} onClick={this.check}>
          <span className={"caret color-e1e1e1 rotate-180deg" }
            style={{float: "right", marginRight: "5%", marginTop: "1%"}}>
          </span>
            <ul id={this.props.Menuid}></ul>
            {this.props.options ?
              this.props.options.map((d, i) => {
                return <MenuItem  disabled={this.props.subtitleCount[d.id] === 0 ? true : false} onClick={() => this.props.onMenuClick(d)} key={d.id}>
                  <span style={{fontSize: "12px", color: this.props.changeColor && this.props.changeColor[0] === this.props.id &&  this.props.changeColor[1] === d.id ? "#00a8e2" : "#4a4a4a"}}>
                    {d.nm + " (" + (this.props.subtitleCount ? this.props.subtitleCount[d.id] : "0") + ")"}
                  </span>
                </MenuItem>
              })
              : null
            }
        </DropdownButton>
      </OverlayTrigger>

    );
  }
}

export class EmailField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emlClassName: ""
    }
  }
  onChangeEmail(e) {
    var email = null;
    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;// eslint-disable-line
    if (regex.test(e.target.value))
      email = e.target.value;

    this.props.onChangeEmail(email);

    if (e.target.value)
      this.setState({
        emlClassName: "has-val"
      })
    else
      this.setState({
        emlClassName: ""
      })
  }
  render() {
    return (
      <div className={this.props.divClassName ? this.props.divClassName : "wrap-input100"}>
        <input className={this.state.emlClassName + (this.props.className ? this.props.className : " input100")} type="email" name="email" onBlur={(e) => this.onChangeEmail(e)} />
        <span className="focus-input100" data-pla ceholder="Email"></span>
      </div>
    )
  }
}

export class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pwdClassName: ""
    }
  }
  onChangePassword(e) {
    var pass = e.target.value;
    if (pass)
      this.setState({
        pwdClassName: "has-val"
      })
    else
      this.setState({
        pwdClassName: ""
      })
    this.props.onChangePassword(pass);
  }
  render() {
    return (
      <div className={this.props.divClassName ? this.props.divClassName : "wrap-input100"}>
        <input className={this.state.pwdClassName + (this.props.className ? this.props.className : " input100")} type="password" name="pass" onChange={(e) => this.onChangePassword(e)} />
        <span className="focus-input100" data-placeholder="Password"></span>
      </div>
    )
  }
}

// export class DateInput extends Component {
//   componentDidMount() {
//     var dateFormat = {
//       format: 'mm/dd/yyyy',
//       weekStart: 1,
//       todayBtn: 1,
//       autoclose: 1,
//       todayHighlight: 1,
//       startView: 2,
//       minView: 2,
//       ignoreReadonly: true
//     }
//     if (this.props.dateFormat === "end-date-picker") {
//       dateFormat.endDate = dtconv.toLocaleDateString();
//     } else if (this.props.dateFormat === "start-date-picker") {
//       dateFormat.startDate = dtconv.toLocaleDateString();
//     }

//     $('#' + this.props.id).datetimepicker(dateFormat).on('changeDate', (ev) => {
//       this.props.dateprop(new Date(ev.date).setHours(0, 0, 0));
//     });
//   }
//   dateField() {
//     var dateFormat = "form-control " + this.props.dateFormat;
//     return (
//       <div className={this.props.div}>
//         <input type="text" className={dateFormat} value={this.props.value} defaultValue={this.props.defaultValue} id={this.props.id} placeholder="MM/DD/YYYY" ref={this.props.id} readOnly={true} required={this.props.required} />
//       </div>
//     )
//   }

//   render() {
//     return (
//       this.props.labelName ?
//         <div className="form-group">
//           <div className="container">
//             <label className="col-lg-2 control-label fontlarger">{this.props.labelName}<span className="red">{this.props.span}</span></label>
//             {this.dateField()}
//           </div>
//         </div> :
//         this.dateField()
//     )
//   }
// }

export class DateInput extends React.Component {
  render() {
  var options={
        defaultDate: this.props.defaultdate,
        defaultMinute: this.props.defaultMinute ? this.props.defaultMinute : 0,
        maxDate: this.props.maxdate ? this.props.maxdate : null,
        minDate: this.props.mindate ? this.props.mindate : null,
        altInput: true,
        altFormat: this.props.dateFormat ? "Y-m-d H:i" : "d M Y",
        disableMobile: this.props.disableMobile ? (window.innerWidth <= 335 ? false : true) : true ,
        onChange: this.props.onChange,
        enableTime: this.props.enableTime ? true : false,
      }
    if(this.props.mode) options["mode"] = "range";
    return (
      // Note that Value is in seconds while default is in miliseconds, hence the division
      <Flatpickr className="flatpicker-style" style={{ marginTop: "5%",height: this.props.height }} name={this.props.name}
        options={options}
      >
      </Flatpickr>
    )
  }
}

DateInput.propTypes = {
  dateprop: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  labelName: PropTypes.string,
  div: PropTypes.string,
  span: PropTypes.string,
  minDate: PropTypes.string,
  readOnly: PropTypes.bool,
}

export class TimeInput extends React.Component {
  render() {
    return (
      // Note that Value is in seconds while default is in miliseconds, hence the division
      <Flatpickr style={{ marginTop: "5%" }} name={this.props.name}
        options={{
          maxDate: this.props.maxdate ? this.props.maxdate : null,
          minDate: this.props.mindate ? this.props.mindate : null,
          altInput: true,
          altFormat:  "H:i",
          disableMobile: this.props.disableMobile ? (window.innerWidth <= 335 ? false : true) : true ,
          onChange: this.props.onChange,
          enableTime: true,
          noCalendar: true,
          defaultDate: this.props.defaultdate
        }}
      >
      </Flatpickr>
    )
  }
}

TimeInput.propTypes = {
  dateprop: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  labelName: PropTypes.string,
  div: PropTypes.string,
  span: PropTypes.string,
  minDate: PropTypes.string,
  readOnly: PropTypes.bool,
}

export class CheckBox extends Component {
  render() {
    return (
      <div>
        <input type="checkbox"
          className={this.props.className}
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          checked={this.props.checked}
          onClick={this.props.onClick}
          defaultChecked={this.props.defaultChecked}
          style={this.props.style}
        />
        <label className="padding-left2" style={{ fontWeight: "normal", fontSize: "11px", marginTop: '0%' }}>{this.props.labelName}
        </label>

      </div>
    );
  }
}
CheckBox.propTypes = {
  type: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  defaultChecked: PropTypes.bool
}

export class List extends Component {
  render() {
    return (
      <ListGroup>
        {
          this.props.headings.map((e, i) => {
            return <ListGroupItem style={this.props.style} header={e} key={i} onClick={this.props.onClick}></ListGroupItem>
          })
        }
      </ListGroup>
    )
  }
}

export function DailyRide(label, text, labelClassName, style) {
  return (
    <div key={j++} className={style ? style : "col-lg-2 col-md-4 col-sm-4 col-xs-4 margin-bottom "} style={{ paddingLeft: "0px"}}>
      <label className={labelClassName ? labelClassName :  "color-9b9b9b stats-heading font-normal"}>{label}</label>
      <div className="stats-content">{text}</div>
    </div>
  )
}

export class RidesModal extends React.PureComponent {
  render() {
    return (
      <Modal bsSize={this.props.modalSize} id={this.props.id} show={this.props.show} onHide={this.props.close}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto", maxHeight: "50vh" }}>
          {this.props.children}
        </Modal.Body>
        <Modal.Footer>
          {this.props.errMsg ? <span className="color-red">{this.props.errMsg + " "}</span> : null}
          <Button bsStyle="primary" id="ok" onClick={this.props.onSubmit} disabled={this.props.disabledOk || false} >Ok</Button>
          <Button id="close" onClick={this.props.close} active>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
RidesModal.propTypes = {
  close: PropTypes.func.isRequired
}

export class NestedDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleClick: false,
      subMenuIds: [],
      leafMenuId: ''
    }
    this.menuArr = [];
  }
  
  componentDidMount() {
    if(this.props.submenuId) this.menuArr.push(this.props.submenuId);
    this.setState({
      titleClick: this.props.menuOpen,
      subMenuIds: this.menuArr ? clone(this.menuArr) : [],
      leafMenuId: this.props.leafMenuId ? clone(this.props.leafMenuId) : ''
    })
  }

  componentWillReceiveProps(newProps) {
    this.menuArr = [];
    if (newProps.submenuId) this.menuArr.push(newProps.submenuId);
    this.setState({
      titleClick: newProps.menuOpen,
      subMenuIds: this.menuArr ? clone(this.menuArr) : [],
      leafMenuId: newProps.leafMenuId ? clone(newProps.leafMenuId) : ''
    })
  }

  onTitleClick() {
    var showDd;
    if (this.state.titleClick) showDd = false;
    else showDd = true;
    this.setState({
      titleClick: showDd
    });
  }

  onSubDdClick(sub) {
    var idArr = this.state.subMenuIds;
    if (sub && sub.id){
      if (this.state.subMenuIds.indexOf(sub.id) > - 1){
        if(idArr.includes(sub.id)){
          idArr = idArr.filter(id => id !== sub.id);
        }
      }else idArr.push(sub.id);
      if(sub && sub.link){
        browserHistory.push(sub.link);
        this.props.onLinkClick(sub);
      }
    }else {
      this.setState({
        leafMenuId: sub.subid,
      });
      if(sub && sub.link){
        browserHistory.push(sub.link);
        this.props.onLinkClick(sub);
      }
    }

    this.setState({
      subMenuIds: idArr,
    });
  }

  render() {
    return (
      <Col>
        <ListGroupItem style={this.props.style ? this.props.style : (this.props.hstyle ? this.props.hstyle : { borderRadius: "0px" })} onClick={() => this.onTitleClick()}>
          <span className={(this.state.titleClick ? "color-0094de" : "color-9b9b9b")} >
            <span className="col-lg-11 col-md-11 col-sm-10 col-xs-10" style={{paddingLeft: "0px"}}>
            {
              this.props.icon ?
                <i className={"icon " + this.props.icon + " padding-right5 " + (this.state.titleClick ? "color-0094de" : "color-9b9b9b")} />
                : null
            }
            {this.props.title}</span>
            <span className={"caret " + (this.state.titleClick ? "rotate-180deg" : "")} ></span>
          </span>
        </ListGroupItem>
        {
          this.state.titleClick ?
            this.props.subDropdown.map((sub, i) => {
              return (
                <div key={i}>
                  {
                    sub.expand && (sub.id || (this.state.subMenuIds.indexOf(sub.id) > -1 || this.state.subMenuIds.indexOf(sub.menu) > -1)) ?
                      <ListGroupItem style={this.props.style ? this.props.style : {  borderTop: i > 1 ? "none" : "" }}
                      key={i} onClick={() => this.onSubDdClick(sub)}>
                        <div className="margin-left4">
                          {
                            sub.id ?
                              <div>
                                <span className={(this.state.subMenuIds.indexOf(sub.id) > -1 ? " color-0094de" : " color-9b9b9b")}>
                                  <span className={"col-lg-11 col-md-11 col-sm-10 col-xs-10 font-size14px"}>
                                    {
                                      sub.icon ?
                                        <i className={"icon " + sub.icon + " padding-right5 " + (this.state.subMenuIds.indexOf(sub.id) > -1 ? " color-0094de" : " color-9b9b9b")} />
                                        : null
                                    }
                                    {sub.title}</span>
                                  <span className={"caret " + (this.state.subMenuIds.indexOf(sub.id) > -1 ? " rotate-180deg " : "")} ></span>
                                </span>
                              </div>
                              :
                              <div onClick={()=> this.props.onLinkClick(sub)}>
                                {
                                  sub.icon ?
                                    <i className={"icon " + sub.icon + " margin-left20 font-size11px " + (this.state.leafMenuId === sub.subid ? "color-0094de" : "color-9b9b9b")} />
                                    : null
                                }
                                <Link className={"font-size13px " + (sub.icon ? "margin-left4 " : "margin-left20 ") + (this.state.leafMenuId === sub.subid ? "color-0094de" : "color-9b9b9b")} to={sub.link} >{sub.title}</Link>
                              </div>
                          }
                        </div>
                      </ListGroupItem>
                      :
                      sub.id ?
                      <ListGroupItem style={this.props.style ? this.props.style : { borderRadius: "0px", borderTop: i > 1 ? "none" : "" }} key={i} onClick={() => this.onSubDdClick(sub)}>
                        <div className="margin-left10">
                          {
                              sub.icon ?
                                <i className={"icon " + sub.icon + " padding-right5 " + (this.state.subMenuIds.indexOf(sub.id) > -1 ? " color-0094de" : " color-9b9b9b")} />
                              : null
                          }
                            <Link className={"font-size14px " + (sub.icon ? "margin-left4 " : "margin-left11 ") + (this.state.subMenuIds.indexOf(sub.id) > -1 ? " color-0094de" : " color-9b9b9b")} to={sub.link} >{sub.title}</Link>
                        </div>
                      </ListGroupItem>
                      : null

                  }

                </div>
              )
            })
            : null
        }
      </Col>
    )
  }
}

export class SingleDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleClick: false,
      subMenuClick: false,
      leafMenuId: ''
    }
  }

  componentDidMount() {
    this.setState({
      titleClick: this.props.menuOpen,
      leafMenuId: this.props.leafMenuId ? clone(this.props.leafMenuId) : ''
    })
  }


  onTitleClick() {
    var showDd;
    if (this.state.titleClick) showDd = false;
    else showDd = true;
    this.setState({
      titleClick: showDd
    });
  }

  onSubDdClick(sub){
    if(sub && sub.link) browserHistory.push(sub.link);
  }

  render() {
    return (
      <Col>
        <ListGroupItem style={this.props.style ? this.props.style : { borderRadius: "0px", borderTop: "none" }} onClick={() => this.onTitleClick()}>
          {
            this.props.icon ?
              <i className={"icon " + this.props.icon + " padding-right5 " + (this.state.titleClick ? "color-0094de" : "color-9b9b9b")} />
              : null
          }
          <span className={(this.state.titleClick ? "color-0094de" : "color-9b9b9b")}>{this.props.title}<span className={"caret margin-left14 " + (this.state.titleClick ? "rotate-180deg" : "")} ></span>
          </span>
        </ListGroupItem>
        {
          this.state.titleClick ?
            this.props.subDropdown.map((sub, i) => {
              return(
                <ListGroupItem style={this.props.style ? this.props.style : { borderRadius: "0px", borderTop: i > 1 ? "none" : "" }} key={i} onClick={() => this.onSubDdClick(sub)} ><div className="margin-left10">
                  <div key={i} >
                  <div onClick={()=> this.props.onLinkClick(sub)}>
                      {
                        sub.icon ?
                          <i className={"icon " + sub.icon + " margin-left10 font-size11px " + (this.state.leafMenuId === sub.subid ? "color-0094de" : "color-9b9b9b")} />
                          : null
                      }
                      <Link className={"font-size13px " + (sub.icon ? "margin-left4 " : "margin-left11 ") + (this.state.leafMenuId === sub.subid ? "color-0094de" : "color-9b9b9b")} to={sub.link} >{sub.title}</Link>
                    </div>
                  </div>
                </div>
                </ListGroupItem>
              )
            })
          : null
        }
      </Col>
    )
  }
}

export class MyLocButton extends React.PureComponent {
  render() {
    return (
      <div style={{ marginRight: "10px", width: "40%" }} onClick={this.props.onClick}>
        <button className="widget-mylocation-button ripple-container">
          <div className={"widget-mylocation-button-icon-common widget-mylocation-cookieless widget-mylocation-button-normal"}></div>
        </button>
      </div>
    )
  }
}


export class InfoModal extends React.Component {
  render() {
    return (
      <Modal bsSize={this.props.modalSize} id={this.props.id} show={this.props.show} onHide={this.props.close}>
        <Modal.Header closeButton >
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={this.props.bodyStyle ? this.props.bodyStyle : null}>
          <Form style={this.props.formStyle ? this.props.formStyle : null}>
            {this.props.children}
          </Form>
        </Modal.Body>
        {
          this.props.footer ?
            <Modal.Footer>
              <CloseButton id="close" onClick={this.props.close} />
            </Modal.Footer>
            : null
        }

      </Modal>
    )
  }
}

export class GeoFenceTextBox extends React.Component {
  render(){
    return(
      <Col >
        <span className="GeoFenceTextBox" style={this.props.headerstyle ? this.props.headerstyle : null}>{this.props.nm ? this.props.nm : null}</span>
        <FormControl
          className={this.props.className ? this.props.className : null}
          style={this.props.style}
          type="text"
          value={this.props.value}
          placeholder={this.props.placeholder ? this.props.placeholder : null}
          defaultValue={this.props.title ? this.props.title : null}
          onChange={(e) => { this.props.onChange(e) }}
          onKeyPress={this.props.handleKeyPress ? this.props.handleKeyPress : null}
        />
      </Col>
    )
  }
}

export class LoadingScreen extends Component {
  render() {
    return (
      <div className="overlay">
        <div className="overlay-body" style={{ height: this.props.height}}>
          <div className="ajjas-loaderImg"></div>
          <p className="font-bold">Loading...</p>
        </div>
      </div>
    )
  }
}

export class ShowCountTile extends Component {
  render(){
    return(
      <Col className='overflowY-auto' lg={12} md={12} sm={12} xs={12} style={{ height: "75vh", border: "1px solid #ccc" }}>
      {
        this.props.data.map((d,i)=>{
          var keys = Object.keys(d.count);
          return(
            <Col key={i} md={6} xs={12} >
              <div style={{ border: "1px solid #ccc", marginTop: "25px", borderRadius: "4px"}}>
              <div style={{marginTop: "10px"}}>
                <span style={{marginTop:"10px", marginLeft: "20px", fontSize:"18px", color: "#4a4a4a"}}>{d.nm}</span><br/>
                <span style={{marginLeft: "20px", fontSize:"16px", color: "#4a4a4a"}}>{d.vehno}</span><br/>
              </div>
                { d.h ?
                  <div style={{ marginLeft: "20px", lineHeight: "14px", fontSize:"14px", color: "#4a4a4a"}}>
                    <div style={{marginTop: "8px"}}><span>Last notification</span><br/></div>
                    <span >{d.h + "."}</span>
                    <span style={{ paddingLeft: "10px" }}>{timeFormat(d.ts * 1000)}</span>
                    <span style={{ paddingLeft: "5px" }}>{StampTODate(d.ts * 1000)}</span>
                  </div>
                 : null
                }
                <div style={{ marginTop:"8px"}}>
                {
                  this.props.label ?
                  <span style={{marginLeft: "20px", fontSize:"14px", color: "#9b9b9b"}}>{this.props.label}</span>
                  : null
                }

                  <div className='overflowY-auto' style={{height: this.props.tableheight ? this.props.tableheight : "19vh", marginTop:"5px", paddingBottom: "0px"}}>
                    <Table>
                      <tbody>
                      { keys && keys.length ?
                        keys.map((d1,i)=>{
                          return(
                            <tr key={i} style={{borderBottom:"1px solid #ddd", fontWeight: "bold"}}>
                              <td style={{paddingLeft: "20px"}}>{d1}</td>
                              <td  align="left">{d.count[d1]}</td>
                            </tr>
                          )
                        })
                       : null
                      }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div style={{float: "right", color: "#00a8e2", marginTop: "2px"}}><Link onClick={() => this.props.onLink(d)} >{this.props.linkName}</Link></div>
            </Col>
          )
        })
      }
      </Col>
    )
  }
}

export class TimeFormatInput extends React.Component {
  render(){
    return(
      <div className="color-e1e1e1" style={{marginLeft: "4%"}}>
        <Col lg={3} md={3} sm={3} xs={3} style={{ padding: "0%"}}>
          <input className="border-bottom-only" type="number" step={1} value={this.props.value}
          style={{ fontSize: "24px", width:"60px", color:"#4a4a4a", textAlign: "center", marginLeft: "14px" }}
          onChange={(e)=> this.props.onChange(e) }></input>
        </Col>
        <Col lg={1} md={1} sm={1} xs={1} style={ { marginTop: "1%", paddingLeft: "0%"}}>
          <span style={{color: "#4a4a4a", fontSize: "12px"}}>{this.props.nm}</span>
        </Col>
      </div>
    )
  }
}

export class VehicleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehId: [],
    }
  }

  onSelectAllVehicle(e){
    var vehID = [];
    var items = document.getElementsByName('vehicle');
    for (var i = 0; i < items.length; i++) {
      if (e.target.checked && items[i].type === 'checkbox') {
        vehID.push(parseInt(items[i].id));
        items[i].checked = true;
      }
      else items[i].checked = false;
    }
    this.setState({ selectedVehId: vehID });
    this.props.selectedVehIdArr(vehID);
  }

  onVehicleSelect(e){
    var items = document.getElementById('selectAll'); items.checked = false;
    var vehId = parseInt(e.target.id);
    if(e && e.target.checked){
      this.state.selectedVehId.push(vehId);
    }
    else {
      for(var i = 0 ; i < this.state.selectedVehId.length ; i++) {
        if(this.state.selectedVehId[i] === vehId) {
          this.state.selectedVehId.splice(i, 1);
        }
      }
    }
    this.props.selectedVehIdArr(this.state.selectedVehId);
  }
  render(){
    return (
      <Col>
        <p style={{ marginLeft: "4%", fontSize: "15px"}}>Select vehicle to get alert</p>
        {this.props.vehErr ? <p style={{marginLeft: "4%", marginTop:"1%", fontSize: "12px"}}><span style ={{color: "red" }}>{this.props.vehErr}</span></p> : null}
        <Checkbox validationState="success" style={{marginLeft: "4%",  marginTop:"5%", color:"#cdcdcd"}} id="selectAll" onClick={(e) => this.onSelectAllVehicle(e)}>Select for all</Checkbox>
        <Col style={{ overflowY: "auto", maxHeight: "230px",border: "1px solid #e1e1e1" }}>
          {
            this.props.bikeDetails && this.props.bikeDetails.map((veh, i) => {
              return (
                <Col key={i}>
                {veh.did ?
                  <Col  className={"col-lg-4 col-md-4 col-sm-12 col-xs-12 "} style={{ paddingLeft: "0px", color: "#4a4a4a",paddingRight: "0px", paddingTop: "0px"}}>
                    <Col style={{border: "1px solid #e1e1e1" }}>
                      <Checkbox validationState="success" defaultChecked={veh.checked ? veh.checked : false} onClick={(e) => this.onVehicleSelect(e)} id={veh.idx} value={veh.did}
                        name="vehicle" style={{marginLeft: "12%", marginTop:"6%", marginBottom:"6%"}} >
                        <Col>
                          <span style={{color: "#788995"}}>{veh.mo}<br />{veh.reg ? veh.reg : veh.treg}</span>
                          <span style={{color:"#cdcdcd",marginLeft: '15px', marginTop:"-4%", fontSize:"12px"}}>{veh.did ? "PLUS" : "PRO"}</span>
                        </Col>
                      </Checkbox>
                    </Col>
                  </Col>
                : null}
                </Col>
              )
            })
          }
        </Col>
      </Col>
    )
  }
}

export class DashBoardButton extends Component {

  _getTitle(title) {
    if (title === "All") return title;
    title = parseInt(title, 10);
    if (isNaN(title) || title < 0 ) return 0;
    return title;
  }

  render(){
    return (
      <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${"top"}`}>
            {this.props.onHover}.
          </Tooltip>}
          >
          <Button id={this.props.id} variant="success" style={this.props.buttonStyle} disabled={this.props.disabled} onClick={()=> {this.props.onClick()}}>
          <span style={this.props.style}>{this._getTitle(this.props.title)}</span>
          </Button>
      </OverlayTrigger>
    )
  }
}

export class VehSingleDropDown extends Component {
  constructor(props){
    super(props);
    this.state = { title: this.props.title }
    this.vehicles = this.props.vehicles;
    this.vehiclesToShow = this.props.vehicles;
    this.filteredVehicles = [];
  }

  dropDownClose() {
    // Hide dropdown from view
    var items = document.getElementById('items');
    var anchor = document.getElementById('anchor');
    if (items.classList.contains('visible')) {
      items.classList.remove('visible');
      items.style.display = "none";
      anchor.className = "anchorOpen";
    }
  }

  filterList = (event) => {
    var inputText = event.target.value;
    if (inputText) {
      this.filteredVehicles.length = 0;
      inputText = inputText.toLowerCase();
      this.vehicles.forEach(veh => {
        if ((veh.vmk && veh.vmk.toLowerCase().indexOf(inputText) !== -1) || ( veh.mo && veh.mo.toLowerCase().indexOf(inputText) !== -1) || (veh.reg && veh.reg.toLowerCase().indexOf(inputText) !== -1))
          this.filteredVehicles.push(veh);
      });
      this.vehiclesToShow = this.filteredVehicles;
    }
    else this.vehiclesToShow = this.vehicles;
    this.forceUpdate();
  }

  changevisible = () =>{
    var items = document.getElementById('items');
    var anchor = document.getElementById('anchor');
    if (items.classList.contains('visible')) {
      items.classList.remove('visible');
      items.style.display = "none";
      anchor.className = "anchorOpen";
    }
    else {
      items.classList.add('visible');
      items.style.display = "block";
      anchor.className = "anchorClose";
    }
    items.onblur = function (evt) {
      items.classList.remove('visible');
    }
  }

  onClickVehicle = (bike) =>{
    this.dropDownClose();
    this.props.onClick(bike);
  }

  render(){
    var icon = "";
    return(
      <Col className="dropdown-veh-list" style={{ position: "relative", backgroundColor: 'white' }}>
        <span id="anchor" className="anchorOpen" style={{ fontSize: "15px", border: "1px solid #ccc", borderRadius: "5px", width: "100%" }} onClick={(e) => this.changevisible()}>{this.state.title}</span>
        <ul id="items" className="items veh-dropDown" style={{ width: "100%" }}>
          <li style={{ borderBottom: "1px solid #ccc" }}>
            <input type="text" className="form-control form-control-lg" placeholder="Search" onChange={this.filterList} style={{ border: "none", boxShadow: "none" }} />
          </li>
          <div style={{ overflowY: "auto", maxHeight: "250px" }}>
            {this.vehicles && this.vehicles.length ?
              !this.vehiclesToShow.length ? <li style={{marginLeft:"1%",padding:"2%"}}>Match not found</li> :
                this.vehiclesToShow.map((bike, i) => {
                  if (bike.typ === 1) icon = "bike";
                  else if (bike.typ === 2) icon = "car";
                  else if (bike.typ === 3) icon = "auto";
                  else if (bike.typ === 4) icon = "truck";
                  var isDisabled = (bike.menu !== 1 && !bike.did);
                  return (
                    <button key={i} onClick={() => this.onClickVehicle(bike)}>
                      <li className={isDisabled ? "filter-disable" : null} >
                        <Table style={{ borderTop: "hidden", borderLeft: "hidden", marginBottom: "0px" }}>
                          <tbody>
                            <tr style={{ padding: "0px" }}>
                              <td style={{ borderRight: "1px solid white" }}><i className={"icon ajjas-" + icon + "icon font-size22px"} style={{color: bike.disabled ? "#9b9b9b" : ""}} /></td>
                              <td style={{ borderRight: "1px solid white" }} className={isDisabled ? "color-9b9b9b" :  "color-4a4a4a"}>
                                <span className="font-bold">{(bike.vmk ? bike.vmk : "") + " " + (bike.mo ? bike.mo : "") + "  "}</span>
                                <span style={{ fontSize: "13px" }}>{(bike.reg ? bike.reg : bike.treg) + "  "}</span>
                                <span style={{ color: "gray", fontSize: "12px" }}>{bike.did ? "PLUS" : "PRO"}</span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </li>
                    </button>
                  )
                })
              : null
            }
          </div>
        </ul>
      </Col>
    )
  }

}

export class BackDrop extends Component {
  render() {
    return (
      <div className="backdrop" onClick={() => this.props.click()}/>
    )
  }
}