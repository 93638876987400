import React, { Component } from 'react';
import { Col, Row, Alert, Button, ButtonGroup } from 'react-bootstrap/lib';
import { LoadingScreen } from '../generic/input';
import { VehMultiDropDown } from './inputs/vehMultiSelect';
import { GetSalesPanelCount } from '../services/apiFunnel';
import { formatCurrency, isVehValid, _setNewUrl, getDevices, getVidArray } from '../lib/common';
import TabularFormat from './tabularFormat';
import { getTriggersData } from '../lib/db/triggers';
import { GetVehicleStatus, GetJourney } from '../lib/db/journeys';
import MapDisplay from './mapDisplay';
import DashBoardFilter from './dashboardFilters.js';
import { Object } from 'core-js';
const { VEH_SHIP_STATS, MENUPATH } = require('../lib/enums');
const TRIGGER_TYP = 2;

export default class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gfData: "",
      errMsg: "",
      list: false,
      vehicles: [],
      isLoading: true,
      filt: "",
      subId: "",
    }
    this.selectedVehs = [];
    this.startVeh = [];
    this.lastSeenData = {};
    this.validVeh = null;
    this.selectedIds = [];
    this.vehicleStatus = {};
    this.deviceVehHashMap = {};
    this.journeys = {};
    this.vehInShipment = {};
    this.showFiltData = {};
    this.filterBikeDetailsToShow = {};
    this.pingData = {};
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.addEventListener(global.EE.EVENT_TYPES.SUB_DEV, this.onDevSub);
    global.EE.addEventListener(global.EE.EVENT_TYPES.ON_CHANGE_VEHSTAT, this.onChangeVehicleStat);
  }

  componentDidMount() {
    if (global.wsOnline) {
      if (global.bikeDetails) this.onBikes(global.bikeDetails);
    }
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.DASHBOARD);

    if (window.localStorage.getItem("usrTyp") === "3") { // Dealer
      GetSalesPanelCount()
        .then((countObj) => this.setState({ countObj }))
        .catch((e) => this.setState({ err: e }));
    }
    if (this.props.location.query && this.props.location.query.filt) {
      this.setState({
        filt: parseInt(this.props.location.query.filt),
        subId: parseInt(this.props.location.query.subId)
      })
    }
    GetJourney((err, journeys) => {
      if (err) {
        this.setState({
          infoMsg: err
        });
        return;
      }
      if (journeys && journeys.length) {
        journeys.forEach(d => {
          this.journeys[d.idx] = d.nm;
        });
        this.forceUpdate();
      }
    });
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.SUB_DEV, this.onDevSub);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.ON_CHANGE_VEHSTAT, this.onChangeVehicleStat);
  }

  onChangeVehicleStat = (data) => {
    var sendStatus = 1;
    var status = this.vehicleStatus[data.vid];
    if (status) sendStatus = status;
    if (this._filter) this._filter.onChangeVehicleStatus(data, sendStatus)
    this.vehicleStatus[data.vid] = data.stat;
    if (data.stat === VEH_SHIP_STATS.ENROUTE_DEST || data.stat === VEH_SHIP_STATS.AT_DEST || data.stat === VEH_SHIP_STATS.ENROUTE_SRC)
      this.vehInShipment[data.vid] = data.journeyId;
    if (data.stat === VEH_SHIP_STATS.AT_SRC_END) {
      var shipmentExist = this.vehInShipment[data.vid];
      if (shipmentExist) delete this.vehInShipment[data.vid];
    }
    this.forceUpdate();
  }

  GetVehicleStatus = (vehIdArr) => {
    GetVehicleStatus(vehIdArr, (err, vehicleStatus) => {
      if (err) {
        this.setState({
          infoMsg: err
        });
        return;
      }
      if (vehicleStatus) {
        var vehStatus = Object.keys(vehicleStatus);
        if (vehStatus.length > 0) {
          vehStatus.forEach(d => {
            this.vehicleStatus[parseInt(d)] = vehicleStatus[d]["stat"];
            var status = vehicleStatus[d]["stat"];
            if (status && (status === VEH_SHIP_STATS.ENROUTE_DEST || status === VEH_SHIP_STATS.AT_DEST || status === VEH_SHIP_STATS.ENROUTE_SRC))
              this.vehInShipment[parseInt(d)] = vehicleStatus[d]["journeyId"];
          });
          this.forceUpdate();
        }
        this.sendTitleToFilters(vehStatus.length);
      }
      else this.sendTitleToFilters(0);
    });
  }

  onBikes = (bikes) => {
    if (bikes && bikes.length) {
      this.vehicles = bikes;
      var devices = getDevices(bikes);
      if (devices.length) {
        var vehIdArr = [];
        bikes.forEach((bike, i) => {
          if (bike.did) {
            vehIdArr.push(bike.idx);
            this.deviceVehHashMap[bike.did] = bike.idx;
          }
        });
        this.GetVehicleStatus(vehIdArr);
        if (this.props.location && this.props.location.query && this.props.location.query.vid) {
          this.selectedIds = getVidArray(this.props.location.query.vid);
          if (this.selectedIds && this.selectedIds.length) this.validVeh = isVehValid(bikes, this.selectedIds);
          if (this.validVeh) {
            var selVehs = bikes.filter(v => {
              return this.selectedIds.indexOf(v.idx) > -1
            });
            this.onVehicleSelect(selVehs);
          }
        } else this.onVehicleSelect(bikes);
      } else {
        this.didnotpresent = true;
        this.setState({
          errMsg: "Please install ajjas device",
          isLoading: false
        });
      }
    } else {
      this.setState({
        errMsg: "",
        isLoading: false
      });
    }
  }

  checkFilterApplied = (data) => {
    if (this.props.location.query && this.props.location.query.filt) {
      var filtId = parseInt(this.props.location.query.filt);
      if (filtId === 4) return data;
      var did = data.devid;
      var vid = this.deviceVehHashMap[did];
      var status = this.vehicleStatus[vid];
      if (status) {
        if (filtId === 3) {
          if (status === VEH_SHIP_STATS.ENROUTE_SRC) return data;
          return false;
        }
        if (this.props.location.query.subId) {
          var subId = parseInt(this.props.location.query.subId);
          if (filtId === 1 && subId === 1) {
            if (status === VEH_SHIP_STATS.ENROUTE_DEST) return data;
            return false;
          }
          else if (filtId === 1 && subId === 2) {
            if (status === VEH_SHIP_STATS.AT_DEST) return data;
            return false;
          }
        }
      }
    }
    return data;
  }

  onDevSub = (data) => { //realtime data
    if (data && data.obj && (data.obj.lat || data.obj.lng)) {
      this.lastSeenData[data.obj.devid] = data.obj;
      if (this.pingData && this.pingData[data.obj.devid]) delete this.pingData[data.obj.devid];
    }
    else if (data && data.obj && data.obj.devid) this.pingData[data.obj.devid] = data.obj;
    if (this.selectedFiltDid && this.selectedFiltDid.length) {
      var sendData = this.selectedFiltDid.find(d => d === data.obj.devid);
      if (sendData) {
        if (this.state.list && this._tabularFormatRef) this._tabularFormatRef.acceptLastSeen(this.lastSeenData);
        else if (this._mapRef) this._mapRef.acceptLastSeen(this.lastSeenData);
      }
    }
    else {
      if (this.state.list && this._tabularFormatRef) this._tabularFormatRef.acceptLastSeen(this.lastSeenData);
      else if (this._mapRef) this._mapRef.acceptLastSeen(this.lastSeenData);
    }
  }

  showGeoFence(selectedVehs) {
    getTriggersData(TRIGGER_TYP, (err, gfData) => {
      if (err) {
        this.setState({
          infoMsg: err
        });
        return;
      }
      if (gfData && gfData.length) {
        gfData = gfData.filter(d => !d.src) //seperate shipment Geofence from original Geofence
        var newGfData = [], vids = [];
        if (selectedVehs && selectedVehs.length) {
          selectedVehs.forEach(veh => {
            vids.push(veh.idx);
          });
        }
        gfData.forEach(d => {
          this._checkGfVidsAreSelected(d.vehIdArr, vids, () => {
            newGfData.push(d);
          });
        });

        this.setState({ gfData: newGfData })
      }
    });
  }

  _checkGfVidsAreSelected(gfvids, vids, cb) {
    gfvids.forEach((v) => {
      if (vids.indexOf(v) > -1) cb();
    });
  }

  getLastSeen = (devids) => {
    var reqLsDids = [];
    if (!this.state.list && this._mapRef) this._mapRef.changeMapstate();
    if (devids.length) {
      if (this.vehicles) {
        var data = { a: "getLastSeen" };
        if (devids) {
          if (this.lastSeenData && Object.keys(this.lastSeenData).length) {
            devids.forEach(id => {
              if (Object.keys(this.lastSeenData).indexOf(id.toString()) < 0) reqLsDids.push(id);
            });
            if (reqLsDids && reqLsDids.length) {
              devids = reqLsDids;
              data.d = { devid: devids };
            }
            else {
              this.sendTitleToFilters(0);
              if (!this.state.list && this._mapRef)
                this._mapRef.acceptLastSeen(this.lastSeenData);
              return;
            }
          } else data.d = { devid: devids };
        }
        global.gWebSocket.SendRequest({
          data: data,
          cb: (resp) => {
            if (resp && resp.d && resp.d.length) {
              resp.d.forEach((dev, i) => {
                if (dev && Object.keys(dev).length) this.lastSeenData[dev.devid] = dev;
                if (i === (resp.d.length - 1)) {
                  this.sendTitleToFilters(0);
                  this.setState({
                    errMsg: ""
                  });
                  if (!this.state.list && this._mapRef) {
                    this._mapRef.acceptLastSeen(this.lastSeenData);
                  }
                }
              });
            }
            else {
              var err = true;
              this.selectedVehs.forEach((veh) => {
                if (veh && veh.did) if (Object.keys(this.lastSeenData).indexOf(veh.did.toString()) > -1) err = false;
                else if (Object.keys(this.lastSeenData).indexOf(veh.idx.toString()) > -1) err = false;
              });
              if (err) {
                this.setState({
                  errMsg: "No location data available for this vehicle yet. Vehicle will be visible on map when there is a location update. Please select another vehicle/contact service executive."
                });
              }
            }
            this.setState({ isLoading: false })
          }
        });
        this.getDevLoc(devids);
      }
    } else {
      this.setState({
        isLoading: false,
        errMsg: "Map an ajjas device to track these vehicles"
      });
    }
  }

  sendTitleToFilters(vehStatusLength) {
    if (this._filter && (Object.keys(this.vehicleStatus).length || vehStatusLength === 0) && Object.keys(this.lastSeenData).length) {
      this._filter.getTitleForFilter(this.lastSeenData);
    }
  }

  getDevLoc(devids) {  //subscribe devices
    var subDevices = [];
    var toBeSubDev = [];
    if (!devids) {
      var devices = this.vehicles;
      devids = devices.filter((d) => { return (d.did); }).map((dev) => dev.did);
    }
    if (devids && global.subdev) subDevices = global.subdev.filter(subdev => devids.includes(subdev));
    toBeSubDev = devids.filter(devids => subDevices.indexOf(devids) < 0);
    if (toBeSubDev && toBeSubDev.length) {
      var data = { a: "subdev", d: { dev: toBeSubDev } };
      global.subdev.concat(devids);
      global.gWebSocket.SendRequest({ data: data });
    }
  }

  unSubscribe(device) {
    var devices = this.selectedVehs;
    if (devices && device.length) {
      device.forEach(unsub => {
        var index = global.subdev.findIndex(sub => { return sub === unsub });
        if (index !== -1) global.subdev.splice(index, 1)
      });
      var data = { a: "unsubdev", d: { dev: device } };
      global.gWebSocket.SendRequest({
        data: data
      })
      device.forEach((dev) => {
        delete this.lastSeenData[dev];
      });
    }
  }

  findIndexInData(data, key, value) {  //find the index of value in array of objects specifying key name
    for (var i = 0, l = data.length; i < l; i++) {
      if (data[i][key] === value) return i;
    }
    return -1;
  }

  onVehicleSelect(selectedVeh) {
    this.showFiltData = {};
    this.filterBikeDetailsToShow = {};
    var subDev = [], unsubDev = [];
    this.selectedVehs = selectedVeh;
    this.vehicles.forEach(veh => {
      if (this.findIndexInData(selectedVeh, 'idx', veh.idx) < 0)
        if (veh.did) unsubDev.push(veh.did);
    });

    if (selectedVeh && selectedVeh.length) {
      selectedVeh.forEach(veh => {
        if (veh.did) subDev.push(veh.did);
      });
    }

    this.showGeoFence(selectedVeh);
    this.forceUpdate(() => {
      if (unsubDev && unsubDev.length) this.unSubscribe(unsubDev);
      if (subDev) { this.getLastSeen(subDev); }
    });
  }

  OnToggleMap(id) {
    //remain to check that if list toggle to map
    this.setState({ list: id === 1 ? false : true })
  }

  onClickApplyButton = (selectedIds) => {
    var vids = [], selIds = [];
    selectedIds.forEach(veh => {
      if (veh && veh.checked) {
        vids.push(veh.idx);
        selIds.push(veh);
      }
    });

    if (selIds.length) {
      this.validVeh = isVehValid(this.vehicles, vids);
      var newUrl = _setNewUrl(this.props.location, vids);
      this.props.router.replace(newUrl);
      this.onVehicleSelect(selIds);
    }
    else {
      this.props.router.replace('/webapp/dashboard');
      this.onVehicleSelect(this.vehicles);
    }
  }
  clearAll = (vehs) => {
    var loc = this.props.location
    var newUrl = loc.pathname;
    this.props.router.replace(newUrl);
    this.onVehicleSelect(this.vehicles);
  }

  changeFilter = (didArr, filt, subId) => {
    this.showFiltData = {};
    this.selectedFiltDid = didArr;
    didArr.forEach(d => {
      var lastSeen = this.lastSeenData[d];
      if (lastSeen) this.showFiltData[d] = lastSeen;

    });
    if (filt) {
      var bikes = this.selectedVehs.filter(d => { return didArr.indexOf(d.did) > -1 });
      this.filterBikeDetailsToShow = bikes;
    }
    else this.filterBikeDetailsToShow = this.selectedVehs;
    var setData = { filt: filt }
    if (subId) setData.subId = subId;
    this.setState(setData);
  }

  render() {
    return (
      <Col>
        {this.state.isLoading ? <LoadingScreen /> : null}
        {/* Statistics View Row */}
        {
          this.state.countObj
            ? <Row>
              <Col xs={12} md={4}>
                <h2>Revenue Summary</h2>
                <Col xs={12}>
                  <h4>Total Revenue Earned: {getCount(formatCurrency(this.state.countObj.total_revenue))} </h4>
                </Col>
                <Col xs={12}>
                  <h4>Daily Average Earnings: {getCount(formatCurrency(this.state.countObj.daily_average))}</h4>
                </Col>
                <Col xs={12}>
                  <h4>Projected Yearly Earnings: {getCount(formatCurrency(this.state.countObj.projected_yearly))}</h4>
                </Col>
              </Col>
              <Col xs={12} md={4}>
                <h2>Ajjas Summary</h2>
                <Col xs={12}>
                  <h4>Total Devices Pitched: {getCount(this.state.countObj.total_pitched)}</h4>
                </Col>
                <Col xs={12}>
                  <h4>Daily Average Bookings: {getCount(this.state.countObj.daily_average_bookings)}</h4>
                </Col>
              </Col>
            </Row>
            : null
        }
        <div>
          {
            this.didnotpresent && this.state.errMsg ?
              <Alert bsStyle="warning">{this.state.errMsg}</Alert>
              :
              <div>
                {
                  this.vehicles && this.vehicles.length ?
                    <Row style={{ marginBottom: "2%" }}>
                      <Col xs={12}>
                        <ButtonGroup size="lg" >
                          <Button id={1} style={{ width: "110px", backgroundColor: (!this.state.list ? "#00a8e2" : "white") }} onClick={(e) => this.OnToggleMap(1)}><span style={{ color: (!this.state.list ? "white" : "#00a8e2") }}>Map</span></Button>
                          <Button id={2} style={{ width: "110px", backgroundColor: (!this.state.list ? "white" : "#00a8e2") }} onClick={(e) => this.OnToggleMap(2)}><span style={{ color: (!this.state.list ? "#00a8e2" : "white") }}>List</span></Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    : null
                }
                <Row>
                  {
                    this.vehicles && this.vehicles.length > 1  && !this.state.errMsg ?
                      <Col lg={2} lgOffset={this.vehicles && this.vehicles.length > 1 ? 6 : 10} md={4} mdOffset={this.vehicles && this.vehicles.length > 1 ? 2 : 9}
                        sm={4} smOffset={this.vehicles && this.vehicles.length > 1 ? 2 : 8} xs={12} style={{ paddingRight: "0px", marginBottom: "2%" }}>
                        <DashBoardFilter data={this.lastSeenData} vehicleStatus={this.vehicleStatus} deviceHashmap={this.deviceVehHashMap} selectedVeh={this.selectedVehs}
                          changeFilter={(data, filt, subId) => this.changeFilter(data, filt, subId)} router={this.props.router} location={this.props.location}
                          ref={filter => this._filter = filter} filt={this.state.filt} subId={this.state.subId}
                        />
                      </Col>
                      : null
                  }
                  {
                    this.vehicles && this.vehicles.length > 1 ?
                      <Col lg={4} lgOffset={this.state.errMsg ? 8 : 0} md={6} mdOffset={this.state.errMsg ? 6 : 0} sm={6} smOffset={this.state.errMsg ? 6 : 0} xs={12} style={{ marginBottom: "2%" }}>
                        <VehMultiDropDown
                          headerName="Vehicle Filter"
                          headingStyle={{ fontSize: "15px", border: "1px solid #ccc", borderRadius: "5px", width: "100%" }}
                          clearStyle={{ color: "#d6001a", fontSize: "14px" }}
                          options={this.vehicles}
                          selectedVehs={this.props.location.query && this.props.location.query.vid ? Array.isArray(this.props.location.query.vid) === true ?
                            this.props.location.query.vid : [this.props.location.query.vid] : 0}
                          onClear={(vehs) => this.clearAll(vehs)}
                          onApply={(selectedIds) => this.onClickApplyButton(selectedIds)}
                        />
                      </Col>
                    : null
                  }
                </Row>
                <Row>
                  {
                    this.state.list && this.vehicles && this.vehicles.length ? this.vehicles.length === 1 && !this.vehicles[0].did ? null :
                      <TabularFormat
                        vehicles={this.filterBikeDetailsToShow && Object.keys(this.filterBikeDetailsToShow).length ? this.filterBikeDetailsToShow : this.selectedVehs}
                        lastSeen={this.showFiltData && Object.keys(this.showFiltData).length ? this.showFiltData : this.lastSeenData}
                        ref={tabularFormat => this._tabularFormatRef = tabularFormat} deviceHashmap={this.deviceVehHashMap}
                        vehicleStatus={this.vehicleStatus} vehInShipment={this.vehInShipment} journeys={this.journeys}
                      />
                      : this.state.errMsg ?
                        <Col lg={12} md={12} sm={12}><Alert bsStyle="warning">{this.state.errMsg}</Alert></Col>
                        :
                        this.vehicles && this.vehicles.length &&
                          ((this.showFiltData && Object.keys(this.showFiltData).length) || (this.lastSeenData && Object.keys(this.lastSeenData).length)) ?
                          <MapDisplay selectedVeh={this.selectedVehs} ref={mapDisplay => this._mapRef = mapDisplay}
                            gfData={this.state.gfData && this.state.gfData.length ? this.state.gfData : null}
                            vehicleStatus={this.vehicleStatus} deviceHashmap={this.deviceVehHashMap} pingData={this.pingData}
                            lastSeenData={this.showFiltData && Object.keys(this.showFiltData).length ? this.showFiltData : this.lastSeenData}
                          />
                          : null
                  }
                </Row>
              </div>
          }
        </div>
      </Col>
    )
  }
}

function getCount(val) { return (<span style={{ color: 'green' }}>{val || '...'}</span>); }
