import React from "react";
import { Router, Route, browserHistory, IndexRoute } from 'react-router';
import '../css/bootstrap.css'
import '../css/style.css';
import '../css/fontello.css';
import '../css/bootstrap-datetimepicker.min.css';
import '../css/glweb.css';
import HomePage from '../components/homePage';
import IssueFeedback from '../components/feedback/issueFeedback';
// import EnterDetails from '../components/enterdetails';
// import AddVehicle from '../components/addVehicle.js';
import DashBoard from '../components/dashBoard';
import { DailyRidesPage } from '../components/dailyRides/dailyRidePage';
import Notification from '../components/notifications/notifications';
import NotifTiles from '../components/notifications/notifTiles';
import CustomRides from '../components/customRides';
import ShowRideDetails from '../components/showRideDetails';
import LoginPage from '../components/login';
import PageNotFound from '../components/pageNotFound';
import chkUnauthorized from '../funnel/chkUnauthorized';
import AddGeoFence from '../components/geoFence/addGF';
import ViewGeoFence from '../components/geoFence/viewGF';
import EditGeoFence from '../components/geoFence/editGF';
import AddSpeedLimit from '../components/spdLimit/addSL';
import Rides from '../components/dailyRides/rides';
import ViewSpeedLimit from '../components/spdLimit/viewSL';
import EditSpeedLimit from '../components/spdLimit/editSL';
import AllVehStatsTiles from '../components/statistics/allVehStatsTiles';
import AlertTiles from '../components/alerts/alerts';
import AddShipment from '../components/trips/addTrip';
import ViewShipments from '../components/trips/viewShipments';
import Sessions from '../components/settings/sessions';

// import DealerDashboard from '../components/dealerDashboard';
import ServiceList from '../components/serviceList';
import ServicePanel from '../components/servicePanel';
import ProfileSettings from '../components/profileSettings';
import SalesPanel from '../components/salesPanel';
import AccountDetails from '../components/accountDetails';
import GetRideLog from '../components/getRideLog';

var routes = (
  <Router history={browserHistory}>
    <Route path="/webapp" component={HomePage}>
      <IndexRoute component={LoginPage}/>
      <Route component={chkUnauthorized} >
        {/*<Route path="/addvehicle" component={AddVehicle}/>*/}
        <Route path="/webapp/dashboard" component={DashBoard} />
        <Route path="/webapp/dailyRidesPage" component={DailyRidesPage} />
        <Route path="/webapp/dailyRidesPage/rides" component={Rides} />
        <Route path='/webapp/allvehstat' component={AllVehStatsTiles}/>
        <Route path="/webapp/alerts" component={AlertTiles}/>
        <Route path="/webapp/notifTiles" component={NotifTiles} />
        <Route path="/webapp/notification" component={Notification} />
        <Route path="/webapp/customRides" component={CustomRides} />
        <Route path="/webapp/showridedetails" component={ShowRideDetails} />
        <Route path="/webapp/gf/add" component={AddGeoFence} />
        <Route path="/webapp/gf/view" component={ViewGeoFence} />
        <Route path="/webapp/gf/edit" component={EditGeoFence} />
        <Route path="/webapp/sl/add" component={AddSpeedLimit} />
        <Route path="/webapp/sl/view" component={ViewSpeedLimit} />
        <Route path="/webapp/sl/edit" component={EditSpeedLimit} />
        <Route path="/webapp/sp/add" component={AddShipment} />
        <Route path="/webapp/sp/upcoming" component={ViewShipments} />
        <Route path="/webapp/sp/ongoing" component={ViewShipments} />
        <Route path="/webapp/sp/completed" component={ViewShipments} />
        <Route path="/webapp/sessions" component={Sessions} />
        {/* <Route path="/dailyrides" component={ShowDailyRides} /> */}

        <Route path='/webapp/serviceList' component={ServiceList} />
        <Route path='/webapp/servicePanel' component={ServicePanel} />
        <Route path='/webapp/profileSettings' component={ProfileSettings} />
        <Route path='/webapp/salesPanel' component={SalesPanel} />
        <Route path='/webapp/accountdetails' component={AccountDetails} />
        <Route path='/webapp/rideLogs' component={GetRideLog} />
      </Route>
    </Route>
    <Route path="/i/:id" component={IssueFeedback}></Route>
    <Route path="*" component={PageNotFound} />
  </Router>
)
export default routes;