import React, { Component} from 'react';
import {Col, Alert, Button, Row, DropdownButton, MenuItem} from 'react-bootstrap/lib';
import { GeoFenceTextBox } from '../../generic/input';
import { saveRoute } from '../../lib/db/routes';
import {getTriggersData} from '../../lib/db/triggers';
import { clone } from '../../lib/jsonUtils';
const { GeoFenceMap } = require('../geoFence/GeoFenceMap');
const TRIGGER_TYP = 2;

export class AddRoute extends Component{
  constructor(props){
    super(props);
    this.state = {
      onStartLoc: false,
      onEndLoc: false,
      onNewStartLoc: false,
      onNewEndLoc: false,
      onRouteName: false,
      errMsg: "",
      disabled: false,
      GfSourceErr: "",
      GfDestErr: "",
      geoFenceOption: [],
      geoFenceData: [],
      save: false,
      startTitle: "Geo-fence list",
      EndTitle: "Geo-fence list",
      startDone: false,
      endDone: false
    }
    this.selectedShape = {};
    this.selectedData = {}
  }

  ShowMapCompent = (data) =>{
    var temp = ""
    if(data === 1) {
      temp = this.state.onStartLoc;
      this.setState({onStartLoc : !temp})
    }
    if(data === 2) {
      temp = this.state.onEndLoc;
      this.setState({onEndLoc : !temp})
    }
  }

  onCircleComplete1 = (data, id) =>{
    this.selectedData[id] = { r: data.e.overlay.radius, clat: data.e.overlay.center.lat(), clong: data.e.overlay.center.lng()}
    this.selectedShape[id] = data;
    this.forceUpdate();
  }

  componentDidMount(){
    getTriggersData(TRIGGER_TYP,(err, gfData) =>{
      if(err){
        this.setState({
          errMsg: err
        });
        return;
      }
      if(gfData && gfData.length) {
        gfData.forEach(d=> d["disabled"] = false)
        this.setState({ geoFenceOption: gfData, geoFenceData: gfData})
      }
    });
  }

  onClear(id){
    if(this.selectedData && this.selectedData[id]) delete this.selectedData[id];
    if(this.selectedShape[id]) delete this.selectedShape[id]
    if(this.props.viewMap) this.view = true
    this.forceUpdate();
  }

  onChangeFence(e, id){
    if(this.selectedData && this.selectedData[id]){
      this.selectedData[id].r =  e.radius;
      this.selectedData[id].clat = e.center.lat();
      this.selectedData[id].clong =  e.center.lng();
    }
  }

  mapDisplay(id) {
    // if(this.selectedShape && this.selectedShape[id]) var showfence = true;
    // var radius = showfence ? this.selectedData[id].r : this.props.triggerIdData ? this.props.triggerIdData.r : null;
    // var clat = showfence ? this.selectedData[id].clat : this.props.triggerIdData ? this.props.triggerIdData.clat : null;
    // var clong = showfence ? this.selectedData[id].clong: this.props.triggerIdData ? this.props.triggerIdData.clong : null;
    return(
      <div>
      <GeoFenceMap
        onCircleComplete= {(key)=> {this.onCircleComplete1(key, id)}}
        onClear ={ ()=> this.onClear(id)}
        center = {this.props.addMap || this.view ? { lat: 20.5837, lng: 78.9629 } : {lat: this.props.triggerIdData.clat, lng: this.props.triggerIdData.clong} }
        zoom = {8}
        onRadiusChanged = {this.props.addMap || this.view ? null : (key)=> {this.onChangeFence(key, id)}}
        onCenterChanged = {this.props.addMap || this.view ? null : (key)=> {this.onChangeFence(key, id)}}
        radius = {this.props.addMap || this.view ? null : this.props.triggerIdData.r}
        draw = { this.props.addMap || this.view ? true : null}

      />
      <Button id="applyGfbutton" style={{ backgroundColor:"#00a8e2", float: "right", marginRight: "20px", marginTop: "12px", height: "45px"}} onClick={() => this.onSaveFenceData(id)} ><span style={{ fontSize: "12px", color:"#ffffff" }} >{id === 1 ? "SAVE START" : "SAVE DESTINATION"}</span></Button>
      { id === 1 ? <span style ={{color: "red" }}>{this.state.GfSourceErr}</span> : ""}
      { id === 2 ? <span style ={{color: "red" }}>{this.state.GfDestErr}</span> : ""}
      </div>
    )
  }

  onSaveFenceData = (id) =>{
    if(id === 1 && !this.StartName) {
      this.setState({ GfSourceErr: "Please Enter Source Name"});
      return;
    }
    if(id === 2 && !this.destName) {
      this.setState({ GfDestErr: "Please Enter Destination Name"});
      return;
    }
    if(this.selectedData && !this.selectedData.hasOwnProperty(id)){
      if(id === 1) {
        this.setState({ GfSourceErr: "Please Enter Start location"});
        return;
      }
      if(id === 2) {
        this.setState({ GfDestErr : "Please Enter Destination location" });
        return;
      }
    }
    if(this.selectedData && Object.keys(this.selectedData).length) {
      var temp = this.selectedData[id];
      if(temp) {
        var addnewFence = {clat: this.selectedData[id].clat, clong:this.selectedData[id].clong, r: this.selectedData[id].r, src: 1, typ: 2, disabled: true}
        if(id === 1) {
          addnewFence["title"] = this.StartName;
          this.state.geoFenceOption.push(addnewFence)
          this.setState({ startDone: true, onStartLoc: false ,onNewStartLoc: false, errMsg: "", GfSourceErr: "", GfDestErr: "", startTitle: this.StartName});
          return;
        }
        if(id === 2) {
          addnewFence["title"] = this.destName;
          this.state.geoFenceData.push(addnewFence)
          this.setState({ endDone: true, onEndLoc: false, onNewEndLoc: false,  errMsg: "", GfSourceErr: "", GfDestErr: "", EndTitle: this.destName });
        }
      }
    }
  }

  showMap = (id) =>{
    return(
      <Col>
          <p className ="font-size16px" style={{marginLeft: "4%", marginTop: "2%", color: "#b5b5b5", fontSize: "13px"}}>{id === 1 ? "Name of the Source" : "Name of the Destination"}</p>
          <GeoFenceTextBox
            className="routeName"
            placeholder="Add Route Name"
            style={{ marginLeft: "4%", fontSize:"12px", height:"45px", marginTop: "0px", width: "89%"}}
            value={this.state.value}
            // title={ id === 1 ? (this.StartName ? this.StartName : null) : (this.destName ? this.destName : null)}
            onChange={(e)=>{ id === 1 ? this.StartName = e.target.value : this.destName = e.target.value}}
            onKeyPress={this.handleKeyPress}
          />
        <Col style={{marginTop: "2%"}}>
            <span  style={{marginLeft: "4%", marginTop:"3%", fontSize: "12px"}}>ADD LOCATION ON THE MAP</span>
            <Col className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginLeft: "2%", paddingRight:"8%",paddingBottom: "5%"}}>{this.mapDisplay(id)}</Col>
        </Col>

      </Col>
    )
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSaveNewRoute()
    }
  }

  onClearRoute(){
    this.props.Onsave()
  }

  RouteState = (id, title) =>{
    return(
      <div onClick={() => this.ShowMapCompent(id)}>
        <span style={{ marginLeft: "4%", fontSize: "12px", color: "#b5b5b5"}}>{title}</span>
        {
          <i className={"icon ajjas-circletick margin-left3 font-size14px color-7ac700" } />
        }
        <span className={"caret color-e1e1e1" + (this.state.titleClick ? "rotate-180deg" : "")}
          style={{float: "right", marginRight: "5%", marginTop: "1%"}}>
        </span>
      </div>
    )
  }

  onGeofenceSelect(e, id){
    var selectedRoute = this.state.geoFenceOption.filter((d)=> {return d.idx ===  parseInt(e.target.id)});
    if(selectedRoute && selectedRoute.length){
      this.selectedData[id] = { r: selectedRoute[0].r, clat: selectedRoute[0].clat, clong: selectedRoute[0].clong}
      let geoFenceOption = clone(this.state.geoFenceData);
      var index = geoFenceOption.findIndex(d => d.idx === selectedRoute[0].idx)
      geoFenceOption.splice(index, 1);
      if(id === 1) {
        this.StartName = selectedRoute[0].title;
        this.setState({ startDone: true, onStartLoc: false, startTitle: selectedRoute[0].title, geoFenceOption});
      }
      if(id === 2) {
        this.destName = selectedRoute[0].title;
        this.setState({ endDone: true,onEndLoc: false,  EndTitle: selectedRoute[0].title, geoFenceOption });
      }
    }
  }

  addNewFence(id){
    if(id === 1) this.setState({ startDone: false, onStartLoc: false, onNewStartLoc: true })
    else if(id === 2) this.setState({ endDone: false, onEndLoc: false, onNewEndLoc: true })
  }

  geoFenceFilter = (id) =>{
    return(
      <Col className="margin-left4">
        <p className="color-e1e1e1" style={{fontSize: "12px", marginTop:"1%", color: "#b5b5b5"}}>SELECT FROM GEOFENCE</p>
        <DropdownButton title={id === 1 ? this.state.startTitle : this.state.EndTitle} className="addRouteType" bsSize="large" style={{color: "#b5b5b5", fontSize: "14px", height:"46px"}} id="split-button-pull-right">
          <ul id="positionMe" className="addRouteType1"></ul>
          {this.state.geoFenceOption && this.state.geoFenceOption.length ?
            this.state.geoFenceOption.filter(d => !d.src).map((d, i)=>{
              return (
                <MenuItem key={i} id ={d.idx} disabled={d.disabled} onClick={(e)=>this.onGeofenceSelect(e, id)}><span style={{ color: "#4a4a4a" }}>{d.title}</span></MenuItem>
              )
            })
           :
            null}
          {this.state.geoFenceOption && this.state.geoFenceOption.length ? <MenuItem divider={true}/>: null}
          {this.state.geoFenceOption.filter(d => d.src).map((d, i)=>{
              return (
                <MenuItem key={i} id ={d.idx} disabled={d.disabled} onClick={(e)=>this.onGeofenceSelect(e, id)}><span style={{ color: "#4a4a4a" }}>{d.title}</span></MenuItem>
              )
            })
          }
          {this.state.geoFenceOption && this.state.geoFenceOption.length ? <MenuItem divider={true}/>: null}
          <MenuItem  onClick={()=>this.addNewFence(id)}><span style={{color: "#4a4a4a" }}>+ Add new route</span></MenuItem>
        </DropdownButton>
        {this.state.routeErr ? <span style ={{color: "red" }}>{this.state.routeErr}</span> : null}
      </Col>
    )
  }

  validateInputs(){ //geofence id should not be same
    var errMsg = "";
    if((this.selectedData && Object.keys(this.selectedData).length === 0) || this.selectedData[1] === undefined) errMsg = "Please Enter Start location";
    else if(this.selectedData && this.selectedData[2] === undefined) errMsg =  "Please Enter Destination location";
    if(errMsg) return errMsg
  }

  onSaveNewRoute(){
    let err = this.validateInputs();
    if(err) this.setState({ errMsg: err})
    else {
      var routeDetails = {

        stloc: {coordinates: [this.selectedData[1].clong, this.selectedData[1].clat]},
        stlocr: this.selectedData[1].r,
        edloc: {coordinates: [this.selectedData[2].clong, this.selectedData[2].clat]},
        edlocr: this.selectedData[2].r,
        stlocnm: this.StartName,
        edlocnm: this.destName,
        nm: this.StartName + " to " + this.destName
      }
      this.setState({ disabled: true});
      saveRoute(routeDetails , (err, response) =>{
        if(err) {
          this.setState({ errMsg: err , disabled: false});
        }
        else {
          this.props.Onsave(response)
        }
      });
    }
  }

  render(){
    return(
      <Col>
        <Col>
          <p style={{ marginLeft: "4%", fontSize: "15px"}}>New route</p>
          <hr/>
          <Col >
            <Button onClick={() => this.ShowMapCompent(1)} style={{backgroundColor: "white", borderColor: "white", width: "100%"}} >
              <span style={{ marginLeft: "2%", fontSize: "12px", color: "#b5b5b5", float: "left"}}>1. START LOCATION</span>
              {this.state.startDone ? <i className={"icon ajjas-circletick margin-left3 font-size14px color-7ac700 float-left" } /> : null}
              <span className={"caret color-e1e1e1" + (this.state.startDone ? "rotate-180deg" : "")}
                style={{float: "right", marginRight: "5%", marginTop: "1%"}}>
              </span>
            </Button>
            { this.state.onStartLoc ? this.geoFenceFilter(1) : null}
            { this.state.onNewStartLoc ? this.showMap(1) : null}
          </Col>
          <hr/>
          <Col>
            <Button onClick={() => this.ShowMapCompent(2)} style={{backgroundColor: "white", borderColor: "white", width: "100%"}} disabled={this.selectedData && this.selectedData[1] ? false : true}>
              <span style={{ marginLeft: "2%", fontSize: "12px", color: "#b5b5b5", float: "left"}}>2. DESTINATION</span>
              {this.state.endDone ? <i className={"icon ajjas-circletick margin-left3 font-size14px color-7ac700 float-left"} /> : null}
              <span className={"caret color-e1e1e1" + (this.state.endDone ? "rotate-180deg" : "")}
                style={{float: "right", marginRight: "5%", marginTop: "1%"}}>
              </span>
            </Button>
            { this.state.onEndLoc ? this.geoFenceFilter(2) : null}
            { this.state.onNewEndLoc ? this.showMap(2) : null}
          </Col>
            <hr/>
          { this.state.errMsg ?
          <Col style={{ width: "90%", marginTop: "2%", marginLeft: "4%", height: "60px"}}>
             <Alert  style={{paddingLeft: "10px"}} bsStyle="danger">{this.state.errMsg}</Alert>
          </Col>
          : null}
          <Col >
            <Row style={{float: "right", marginRight: "55px"}}>
              <Button  id="clearbutton"  style={{ borderColor: "#ffffff", height: "45px", marginRight: "30px"}} onClick={() => this.onClearRoute()} >
                <span style={{ fontSize: "12px", color:"#00a8e2" }} >CANCEL</span>
              </Button>
              <Button id="applybutton" className="height45" style={{ backgroundColor: this.state.startDone && this.state.endDone ? "#00a8e2" : "#bfbfbf"}}
                disabled={(this.state.startDone && this.state.endDone) || this.state.disabled ? false : true} onClick={() => this.onSaveNewRoute()} >
                <span style={{ fontSize: "12px", color:"#ffffff" }} >SAVE ROUTE</span>
              </Button>
            </Row>
          </Col>
        </Col>
      </Col>
    )
  }
}