import React, { Component} from 'react';
import {Col, Row, Alert, MenuItem, DropdownButton , Button} from 'react-bootstrap/lib';
import { TimeFormatInput, DateInput, TimeInput, GeoFenceTextBox} from '../../generic/input';
import { VehMultiDropDown } from '../inputs/vehMultiSelect';
const { AddRoute } =  require('./addRoute');
const START_DATE = 1;
const END_DATE = 2;

export class ShipmentInput extends Component{
  constructor(props){
    super(props);
    this.state = {
      title: "Select from route",
      addRoute: false,
      kmValue: 0,
      vehErr: "",
      vehicles: this.props.bikeDetails,
      disableSubmit: true,
      errMsg: ""
    }
    this.selectedVehId = [];
    this.startDate = new Date(new Date(Date.now()).setHours(12,0,0,0));
    this.endDate = new Date(new Date(Date.now() + 86400000).setHours(12,0,0,0));
  }

  addRoute = () =>{
    this.setState({ addRoute: true, title: "Select from route" });
  }

  onSaveRoute = (data) =>{
    if(data) {
      this.props.routeArr.push(data)
      this.selectedRoute = data.idx;
      this.setState({ title: this.showTitle(data.stlocnm, data.edlocnm)})
    }
    this.setState({ addRoute: false})
  }

  distChange = (e, id) =>{
    if(id === 1 ) this.setState({ kmValue: e.target.value});
  }

  onSaveShipnent = ()=>{
    var vidArr = []
    if((this.startDate).getTime() > (this.endDate).getTime()){
      this.setState({ errMsg: "Please select a correct end date. End date should be more than the start date."});
      return;
    }
    if((this.endDate).getTime() < Date.now()){
      this.setState({ errMsg: "End date should be greater than today"});
      return;
    }
    this.selectedVehId.forEach(d=> vidArr.push(parseInt(d)))
    var ShipmentInput = {
      nm: this.shipName,
      typ: 1,
      vidArr: vidArr,
      routeId: this.selectedRoute,
      stdt: this.startDate.getTime(),
      eddt: this.endDate.getTime(),
    }
    if(this.props.addMap) this.props.onSaveShipment(ShipmentInput)
  }

  onRouteSelect(id){
    var selectedRoute = this.props.routeArr.filter((d)=> {return d.idx ===  parseInt(id)});
    if(selectedRoute && selectedRoute.length){
      this.selectedRoute = selectedRoute[0].idx;
      this.setState({ title : this.showTitle(selectedRoute[0].stlocnm, selectedRoute[0].edlocnm)});
    }
  }

  showTitle(startRoute, endRoute){
    return (
      <Col  lg={11} md={11} sm={11} xs={11} className= "font-size13px" style={{ color: "#b5b5b5" }}>
        <span>{startRoute}</span>
        <i className="icon ajjas-arrright margin-left1 margin-right2 "/>
        <span>{endRoute}</span>
      </Col>
    )
  }

  RouteOption = () =>{
    return(
      <Col>
        <p className="color-e1e1e1" style={{fontSize: "12px", color: "#b5b5b5"}}>Select From Route</p>
        <DropdownButton title={this.state.title} className="addRouteType" bsSize="large" style={{color: "#b5b5b5", fontSize: "14px", height:"46px"}} id="split-button-pull-right">
          <ul id="positionMe" className="addRouteType1"></ul>
          {this.props.routeArr ?
            this.props.routeArr.map((d, i)=>{
              return (
                <MenuItem key={i} id={d.idx} onClick={(e)=>this.onRouteSelect(d.idx)}>
                  <div className= "font-size13px" style={{ color: "#b5b5b5" }}>
                    <span>{d.stlocnm}</span>
                    <i className="icon ajjas-arrright margin-left1 margin-right2 "/>
                    <span>{d.edlocnm}</span>
                  </div>
                </MenuItem>
              )
            })
           :
            null}
          <MenuItem  onClick={()=>this.addRoute()}><span style={{color: "#b5b5b5" }}>+ Add new route</span></MenuItem>
        </DropdownButton>
      </Col>
    )
  }

  dateChange = (selectedDate, id) =>{
    if(id === 1) this.startDate =  new Date(selectedDate[0]);
    else if(id === 2) this.endDate = new Date(selectedDate[0].setHours(23,59,59,999));
    this.forceUpdate();
  }

  timeChange = (selectedTime, id) =>{
    var hours = selectedTime[0].getHours();
    var mins = selectedTime[0].getMinutes();
    if(id === 1) {
      if(this.startDate) this.startDate = new Date(this.startDate.setHours(hours, mins, 0, 0));
      else this.startDate = selectedTime[0];
    }
    else if(id === 2){
      if(this.endDate) this.endDate = new Date(this.endDate.setHours(hours, mins, 0, 0));
      else this.endDate = selectedTime[0];
    }
    this.forceUpdate();
  }

  selectStartTIme = (id) =>{
    return(
      <Row >
        <Col lg={4} md={4} sm={12} xs={12} style={{ marginLeft: "2%", marginTop: "3%"}}>
          <p className="color-e1e1e1 font-size12px color-b5b5b5">{id === 1 ? "All Vehicles Should Start By" : "Expected Date Of Arrival"}</p>
            <DateInput
            defaultdate={ id === 1 ? this.startDate.getTime()  : this.endDate.getTime() }
            noMode= {true}
            onChange={(e) => this.dateChange(e, id)}/>
        </Col>

        <Col lg={4} md={4} sm={12} xs={12} style={{ marginLeft: "2%", marginTop: "3%"}}>
          <p className="color-e1e1e1" style={{fontSize: "12px", color: "#b5b5b5"}}>{id === 1 ? "Start Time" : "Expected Time"}</p>
            <TimeInput
              defaultdate={ "12:00" }
              onChange={(e) => this.timeChange(e, id)}
            />
        </Col>
      </Row>
    )
  }

  EstimateTimeAndDist = () =>{
    return(
      <div >
        <Row>
          <Col lg={6} md={8} sm={12} xs={12} style={{ marginLeft: "2%"}}>
            <p className="color-e1e1e1" style={{fontSize: "12px", paddingTop: "15px", color: "#b5b5b5"}}>ESTIMATED DISTANCE(OPTIONAL)</p>
            <Row >
              <TimeFormatInput id={1} nm="km" onChange={(e)=> this.distChange(e, 1)} value={this.state.kmValue}/>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  saveButton = () =>{
    var disabled = true;
    if(this.selectedRoute && this.shipName && this.selectedVehId && this.selectedVehId.length) disabled = false;

    return(
      <Col>
        { this.props.errMsg || this.state.errMsg? <Col style={{ width: "95%", marginTop: "2%", marginLeft: "2%", height: "60px"}}><Alert  style={{paddingLeft: "10px"}} bsStyle="danger">{this.props.errMsg ? this.props.errMsg : this.state.errMsg}</Alert></Col> : ""}
        <Button id="save"
        style={{marginLeft: "2%", backgroundColor: disabled || this.props.disabled ? "#bfbfbf": "#00a8e2" , width:"96%", marginTop: "20px", height:"45px", borderRadius: "2px"}}
        disabled={disabled || this.props.disabled}
        onClick={() => this.onSaveShipnent()}><span style={{ fontSize: "14px", color:"#ffffff" }} >Save</span></Button>
      </Col>
    )
  }

  onClickApplyButton = (vehicles) => {
    var selectedVehId = []
    vehicles.forEach(d=> {if((d.checked && d.checked) === true) selectedVehId.push(JSON.stringify(d.idx))})
    this.selectedVehId = selectedVehId;
    this.forceUpdate();
  }

  clearAll = (vehs) => {
    this.selectedVehId = [];
  }

  render(){
    return(
      <Col style={{ overflowY: "auto", height: "75vh", paddingLeft:"0%", paddingBottom:"2px", paddingRight:"0%", border: "1px solid #e1e1e1"}}>
        <p className ="font-size16px" style={{marginLeft: "4%", marginTop: "1%"}}>{this.props.header}</p>
        <hr/>
        <GeoFenceTextBox
          nm="Trip Name"
          headerstyle = {{color: "#b5b5b5"}}
          placeholder="Enter Trip Name"
          style={{marginLeft: "4%", width: "93%", fontSize:"12px", height:"45px", marginTop: "2%"}}
          value={this.state.value}
          title={  null}
          onChange={(e)=>{ this.shipName = e.target.value}}
        />
        <Col className="margin-top2 margin-left4">{ this.RouteOption()}</Col>
        { this.state.addRoute ?
          <Col style={{marginTop: "4%"}}>
          {<AddRoute addMap={this.props.addMap} Onsave={(data)=>this.onSaveRoute(data)}/>}
          </Col>
          : null
        }
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
          {this.selectStartTIme(START_DATE, 1)}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginTop: "-1%"}}>
          {this.selectStartTIme(END_DATE, 2)}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-top2" style={{marginLeft: "-1%"}}>
          <p className="color-e1e1e1 font-size12px color-b5b5b5" style={{marginLeft: "3%"}}>Allocated Vehicles</p>
          {
            this.props.bikeDetails && this.props.bikeDetails ?
              <VehMultiDropDown
                headerName="Select Vehicle"
                headingStyle = {{fontSize: "15px", border: "1px solid #ccc", borderRadius: "6px", width: "96%", color:  "#b5b5b5", marginLeft: "3%"}}
                clearStyle={{ color: "#d6001a", fontSize: "14px" }}
                options={this.state.vehicles}
                selectedVehs={this.selectedVehId}
                onClear={(vehs) => this.clearAll(vehs)}
                onApply={(selectedIds) => this.onClickApplyButton(selectedIds)}>
              ></VehMultiDropDown>
            : null
          }
        </div>
        {
          //  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginTop: "1%"}}>
          //   {this.EstimateTimeAndDist()}
          // </div>
        }

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
          {this.saveButton()}
        </div>
      </Col>
    )
  }
}