import React, { Component } from 'react';
import { VehMultiDropDown } from '../inputs/vehMultiSelect';
import {browserHistory} from 'react-router';
import { getNotifCountFromDb } from '../../lib/db/notif';
import { isVehValid, _setNewUrl, getVidArray } from '../../lib/common';
import { Col, Row, Grid, Alert} from 'react-bootstrap/lib';
import { GetDateFromNow } from '../../lib/dtconv';
import { ShowCountTile, DateInput } from '../../generic/input';

export default class NotifTiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading : true,
      selectedVehicle: 0,
      labelMsg: "",
      tileData: "",
      allPro : false,
      vehicles : []
    }
    this.vehIds = [];
    this.vehMap = {};
    if (global.bikeDetails) this.vehicles = global.bikeDetails;
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.addEventListener(global.EE.EVENT_TYPES.ON_NEW_NOTIF, this.getLiveNotif);
  }

  componentDidMount() {
    if (global.wsOnline) this.onBikes(global.bikeDetails);
    // if (this.props.location && this.props.location.query && !this.props.location.query.vid) this.setState({ errMsg: "No vehicle added please add vehicle from app " });
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.ON_NEW_NOTIF, this.getLiveNotif);
  }

  componentWillReceiveProps(nextProps){
    // if(nextProps.location && nextProps.location.query.vid){
    //   var newVid = parseInt(nextProps.location.query.vid);
    //   if(newVid !== this.state.selectedVehicle) {
    //     if(newVid === 0) this._vehDropdown.showAll()
    //     else {
    //       var vehicle = global.bikeDetails.filter(d=> d.idx === newVid);
    //       this._vehDropdown.selectVehicle(vehicle[0])
    //     }
    //   }
    // }
  }

  setDateInURL() {
    var newUrl;
    if (this.props.location) {
      var loc = this.props.location;
      this.setState({
        from: loc.query && loc.query.from ? parseInt(loc.query.from) : GetDateFromNow(4) * 1000,
        to: loc.query && loc.query.to ? parseInt(loc.query.to) : new Date().setHours(23, 59, 59, 999)
      }, () => {
        if (loc.query && loc.query.vid) {
          this.vehIds = getVidArray(loc.query.vid);
          if (this.vehicles && this.vehIds && this.vehIds.length) this.validVeh = isVehValid(this.vehicles, this.vehIds );
          if (this.validVeh) {
            newUrl = _setNewUrl(loc,  this.vehIds , this.state.from, this.state.to);
            this.props.router.replace(newUrl);
            this.ShowNotifTiles(this.vehIds)
          }
        } else if(this.vehicles && this.vehicles.length){
          newUrl = _setNewUrl(loc, null, this.state.from, this.state.to); 
          this.props.router.replace(newUrl);
          if(!this.state.allPro) this.ShowNotifTiles(Object.keys(this.vehMap));
        }
      });
    }
  }

  onBikes = (bikes) => {
    global.bikeDetails = bikes;
    this.vehicles = bikes;
    var allvehPro = true;
    if (bikes && bikes.length) {
      bikes = bikes.filter(veh => {
        this.vehMap[veh.idx] = { nm: veh.vmk + " " + veh.mo, no: veh.treg ? veh.treg : veh.reg };
        veh.menu = 4;
        if (veh.did) allvehPro = false;
        return veh;
      });
    }
    this.setState({
      vehicles: bikes,
      allPro: allvehPro
    });
    this.setDateInURL();
  }

  getLiveNotif = (notif) =>{
    if(notif.vid){
      if(this.props.location && this.props.location.query && this.props.location.query.vid){
        if(parseInt(this.props.location.query.vid) === 0) this.ShowNotifTiles(global.bikeDetails);//setTimeout(()=>this.ShowNotifTiles(global.bikeDetails), 2000)
        else {
          if(parseInt(this.props.location.query.vid) === notif.vid) {
            var vehicle = global.bikeDetails.filter(d=> d.idx === notif.vid);
            this.ShowNotifTiles(vehicle[0]);
          }
        }
      }
    }
    else {
      this.setState({
        isLoading : false,
        labelMsg: "Error While Getting Data from Server",
      });
    }
  }

  ShowNotifTiles(vidArr){
    var tileData = [];
    // var vehmap = {};
    // var vidArr = [];
    // selectedVeh.forEach(d=>{
    //   if(d.did) vidArr.push(d.idx)
    //   vehmap[d.idx] = { nm: d.vmk + " " +d.mo, no: d.treg ? d.treg : d.reg}
    // });
    vidArr = vidArr.map(v => parseInt(v));
    if(vidArr && vidArr.length){
      getNotifCountFromDb( vidArr, (err,notifData) => {
        if(err || notifData.length === 0) {
          this.setState({
            tileData: [],
            isLoading : false,
            labelMsg: err ? "Error While Getting Data from Server" : "No notification available for this vehicle",
          });
          return; 
        }
        notifData.forEach(d=>{
          var vehnm = this.vehMap[d.vid]
          tileData.push({ nm: vehnm.nm, vehno: vehnm.no, count: d.count, ts: (Date.now() - 432000000), vid: d.vid})
        });
        this.setState({ tileData: tileData , isLoading : false,labelMsg: ""})
      });
    }
    else{
      this.setState({
        tileData: [],
        isLoading : false,
        labelMsg: "No notification available for this vehicle",
      });
    }
  }

  getNotifCount(notifData){
    var Notifcount = [];
    notifData.forEach(d=>{
      var result = d.notif.reduce(function(d, i) {  //compute notification count
        d[i.h] = (d[i.h] || 0) + 1;
        return d;
      }, {});
      Notifcount.push({ vid: d.vid, count: result, ts: d.notif[0].dc, h: d.notif[0].h})
    });
    return Notifcount
  }

  onLink(e){
    var newUrl = "/webapp/notification";
    if(e && e.vid){
      newUrl += "?vid=" + e.vid;
    }
    browserHistory.push(newUrl);
  }

  clearAll = (vehs) => {
    var loc = this.props.location
    var vids = [];
    var newUrl = loc.pathname;
    vehs.forEach(veh => {
      vids.push(veh.idx);
    });
    if (loc && loc.query && loc.query.from && loc.query.to) newUrl += "?from=" + loc.query.from + "&to=" + loc.query.to;
    this.props.router.replace(newUrl);
    this.ShowNotifTiles(vids);
  }

  onClickApplyButton = (selectedIds) => {
    var vids = [] ,selIds = [];
    selectedIds.forEach(veh => {
      if (veh && veh.checked) {
        vids.push(veh.idx);
        selIds.push(veh);
      }
    });
    if (selIds && selIds.length) {
      var newUrl = _setNewUrl(this.props.location, vids, this.state.from, this.state.to);
      this.props.router.replace(newUrl);
      this.ShowNotifTiles(vids);
    }
    else return;
  }

  dateChange = (e) => {
    var newUrl , vidArr = [];
    if (e.length > 1) {
      this.setState({
        from: e[0].getTime(),
        to: e[1].setHours(23, 59, 59, 999)
      }, () => {
        if (this.props.location && this.props.location.query && this.props.location.query.vid){
          if (Array.isArray(this.props.location.query.vid) === false) {
            vidArr.push(parseInt(this.props.location.query.vid));
          } else {
            this.props.location.query.vid.forEach(v => {
              vidArr.push(parseInt(v));
            });
          }
          newUrl =_setNewUrl(this.props.location, vidArr, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
          this.ShowNotifTiles(vidArr);
        }
        else {
          newUrl =_setNewUrl(this.props.location,null, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
          this.ShowNotifTiles(Object.keys(this.vehMap));
        }
      });
    }

  }

  render(){
    return(
      <Grid style={{paddingRight:"0px",marginLeft:"0%",marginRight:"0%" ,width:"100%",height:"60%"}}>
        <Row style={{marginLeft:"1%", marginRight:"1%"}}>
          <Col lg={10} md={9} sm={12} xs={12} style={{ paddingBottom: "2%" }}>
            <Row>
              <Col lg={4} lgOffset={5} md={6} mdOffset={2} sm={6} xs={12} className="vehicle-padding">
                {
                  this.vehicles  && this.vehicles.length > 1 && !this.state.allPro ?
                    <VehMultiDropDown
                      headerName="Vehicle Filter"
                      headingStyle ={{ fontSize: "15px", border: "1px solid #ccc", borderRadius: "5px", width: "100%" }}
                      clearStyle={{ color: "#d6001a", fontSize: "14px" }}
                      selectedVehs={this.props.location.query && this.props.location.query.vid ? Array.isArray(this.props.location.query.vid) === true ?
                        this.props.location.query.vid : [this.props.location.query.vid] : 0}
                      options={this.state.vehicles}
                      onClear={(vehs) => this.clearAll(vehs)}
                      onApply={(selectedIds) => this.onClickApplyButton(selectedIds)}/>
                    : null
                }
              </Col>
              {
                this.state.from && this.state.to && this.vehicles && this.vehicles.length && !this.state.allPro ?
                  <Col lg={3} md={4} sm={6} xs={12}>
                    <DateInput height="40px" defaultdate={[this.state.from, this.state.to]} mode={true} mindate={GetDateFromNow(4) * 1000} maxdate={new Date().setHours(23, 59, 59, 999)} onChange={(e) => this.dateChange(e)}></DateInput>
                  </Col>
                  : null
              }
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} style={{ paddingBottom: "15px",marginTop: "2%"}}>
                {/* {this.state.isLoading ? <LoadingScreen /> : null} */}
                {
                  this.state.tileData && this.state.tileData.length ?
                    <ShowCountTile data={this.state.tileData} label="Notif" linkName="View all notification" onLink={(e) => this.onLink(e)}/>
                  : 
                  // this.state.isLoading ? <LoadingScreen/> : 
                  this.state.labelMsg ? <Alert bsStyle="warning">{this.state.labelMsg}</Alert> : null

                }
              </Col>
            </Row>
          </Col>

          {/* 
          <Col lg={10} md={9} sm={12} xs={12} style={{ paddingBottom: "15px" }}>
            {
              this.state.tileData && this.state.tileData.length ?
                <ShowCountTile data={this.state.tileData} label="ALERTS" linkName="View all notification" link="/webapp/notification"/>
              : this.state.isLoading ? <LoadingScreen/> : this.state.labelMsg ? <Alert bsStyle="warning">{this.state.labelMsg}</Alert> : null

            }
          </Col> */}
        </Row>
      </Grid>
    )
  }
}