import React from 'react';
import SpeedLimit from './spdLmt';

export default class AddSpeedLimit extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      errMsg: "",
    }
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentDidMount(){
    if(global.wsOnline){
      if(global.bikeDetails) {
        this.onBikes(global.bikeDetails);
      }
    }
  }

  onBikes = (bikes) => {
    global.bikeDetails = bikes;
    this.plus = bikes.filter((bike) => { return bike.did });
    if(this.plus && this.plus.length === 0) this.setState({ errMsg: "This feature is only available for Plus device users. Add a vehicle with Plus HW."})
  }
  render(){
    return(
      <SpeedLimit location={this.props.location} errMsg={this.state.errMsg}/>
    )
  }
}
