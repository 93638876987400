import React from 'react';

export function InputField(label,param,className,style){
  return(
    <div className={className + " col-sm-6 margin-bottom15"} style={style ? style : null}>
      <label className="searchlabel">{label}</label>
      {param}
    </div>
  )
}
export function User(label,text,labelClassName,style){
  return(
    <div className={style ? style : "col-lg-2 col-sm-4 margin-bottom15"}>
      <label className={labelClassName}>{label}</label>
      <p>{text}</p>
    </div>
  )
}