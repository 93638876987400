import { IsLoggedIn, Login, Logout } from './genericApis';
import { AddServiceData, GetServiceList, MarkServiceDone, GetServiceListCount, ChangeLoginPassword } from './servicePanelApis';
import { GetSalesPanelCount } from './salesPanelApis';
import { GetAPIKey, MyAccountDetails, RenewSubscription } from './financeApis';

export {

  // Generic
  IsLoggedIn,
  Login,
  Logout,

  // Seller Panel
  AddServiceData,
  GetServiceList,
  MarkServiceDone,
  GetServiceListCount,
  ChangeLoginPassword,

  // Sales Panel
  GetSalesPanelCount,

  // Accounts Section
  GetAPIKey,
  MyAccountDetails,
  RenewSubscription,
}