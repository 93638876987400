import React from 'react';
import PropTypes from 'prop-types';
import { Row, Well } from 'react-bootstrap/lib';

export default class PanelView extends React.PureComponent {
  render() {
    return (
      <Row className='margin-bottom15'>
        {this.props.heading ? <Well className='text-center'><b>{this.props.heading}</b></Well> : null}
        {this.props.children}
      </Row>
    );
  }
}
PanelView.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.any
}