import React from 'react';
import { savePingsAndStatsInDb, saveRideInTodayStat } from '../lib/db/rides';
import { createStore } from '../lib/db/common';
import { Header } from '../generic/headFoot';
import { GetDateFromNow } from '../lib/dtconv';
import { saveNewNotif } from '../lib/db/notif';//SetNewVehStat
import { SetNewVehStat } from '../lib/db/journeys';
import Menu from '../components/menu/index';
import { LoadingScreen } from '../generic/input';
import { Col, Row, Grid, Alert } from 'react-bootstrap/lib';
const computeStats = require('../lib/geoDistCal').computeStats;
const TODAY_START_TS = require('../lib/enums').TODAY_START_TS;
const TODAY_END_TS = require('../lib/enums').TODAY_END_TS;
var WebSocketClient = require('../lib/ws').WebSocketClient;

export default class Authorized extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      vehicles: [],
      errMsg: "",
      isLoading: true
    }
    this.singleRide = [];
    this.devRideMap = {};
    this.liveRide = [];
    this.angArr = [];
    this.rids = [];
    this.pathArr = [];
    this.statsArr = [];
    createStore('rides');
    createStore('dailyRides');
    createStore('paths');
    createStore('notifCount');
    createStore('notif');
    createStore('triggerTypes');
    createStore('triggers');
    createStore('generic');
    createStore('alerts');
    createStore('journeys');
    createStore('vehicleStatus');
    if(!global.gWebSocket){
      global.gWebSocket = new WebSocketClient();
    }
    this.eventsObj = {
      "WS_ONLINE_STATE": this.onWsReady,
      "BIKES": this.onBikes,
      "LIVE_RIDES": this.onLiveRides,
      "GET_RIDE": this.onRidesPings,
      "SUB_DEV": this.onRTData,
      "NOTIF": this.getLiveNotif,
      "GETPATH": this.onGetPath,
      "VEHSTAT": this.onChangeVehicleStat,
      "CHANGE_GET_RIDE_EVENT": this.changeGetRideEvent,
    };
  }
  componentWillMount() {
    Object.keys(this.eventsObj).forEach(e => {
      global.EE.addEventListener(global.EE.EVENT_TYPES[e], this.eventsObj[e]);
    });
  }
  componentWillUnmount() {
    Object.keys(this.eventsObj).forEach(e => {
      global.EE.removeEventListener(global.EE.EVENT_TYPES[e], this.eventsObj[e]);
    });
  }

  changeGetRideEvent = (add) =>{
    if (add) global.EE.addEventListener(global.EE.EVENT_TYPES.GET_RIDE, this.onRidesPings);
    else global.EE.removeEventListener(global.EE.EVENT_TYPES.GET_RIDE, this.onRidesPings);
  }

  saveNotifInLocalDb = (notif) =>{
    saveNewNotif(notif, (err, nf) => {
      global.EE.emitEvent(global.EE.EVENT_TYPES.ON_NEW_NOTIF, err || notif);
    });
  }

  getLiveNotif = (notif) => {
    var self = this;

    if(global.userId) this.saveNotifInLocalDb(notif);
    else if(notif.t === 201 || notif.t === 202) this.saveNotifInLocalDb(notif);

    if (notif && notif.t && (notif.t === 201 || notif.t === 202)) {
      Notification.requestPermission().then(function (result) {
        self.showNotification(notif);
        if (result === 'denied') {
          console.log('Permission wasn\'t granted. Allow a retry.');
          return;
        }
        if (result === 'default') {
          console.log('The permission request was dismissed.');
          return;
        }
      });

    }
  }

  showNotification = (notif) => {
    var self = this;
    var options = {
      body: notif.msg,
      icon: 'https://res.ajjas.com/img/webpush.png',
    }
    var notification = new Notification(notif.h, options);
    notification.onclick = function(event) {
      var date = GetDateFromNow(4) * 1000;
      if(self.props.location && self.props.location.query && self.props.location.query.from) date = self.props.location.query.from;
      var newUrl = "/webapp/alerts?vid="+ notif.vid + "&from=" + date + "&to=" + new Date().setHours(23, 59, 59, 999) ;
      self.props.router.push(newUrl);
    }
  }

  onChangeVehicleStat = (data) => {
    SetNewVehStat(data, (err, stats) =>{
      global.EE.emitEvent(global.EE.EVENT_TYPES.ON_CHANGE_VEHSTAT, err || data);
    });

  }

  onBikes = (vehicles) => {
    var devids = [], selectedVeh, obj = {};
    this.vehicles = vehicles;
    if (vehicles && vehicles.length) {
      global.userId = vehicles[0].uid;
      if (this.props.location && this.props.location.query && this.props.location.query.vid) {
        selectedVeh = vehicles.find((veh) => {
          return veh.idx === parseInt(this.props.location.query.vid)
        });
      }
      if (selectedVeh && selectedVeh.did) devids.push(selectedVeh.did);
      else devids = vehicles.filter((d) => { return (d.did); }).map((dev) => dev.did);

      if (devids && devids.length) {
        global.subdev = devids;
        this.SendRequest({ a: "subdev", d: { dev: devids } });
        this.SendRequest({ a: "getLiveRides" });
      }
    } else obj.errMsg = "Please install ajjas device to enable this features in website"
    obj.isLoading = false;
    this.setState(obj);
  }

  SendRequest = (data) => {
    global.gWebSocket.SendRequest({ data: data })
  }

  onLiveRides = (liveRide) => {
    this.liveRide = liveRide.d;
  }

  onWsReady = (state) =>{
    this.wsState = state;
  }

  onGetPath = (data) => {
    this.rids = data;
    this.ridIndex = 0;
    this.lastRid = this.rids[this.rids.length - 1];
    this.sendReqForRPings(this.rids[this.ridIndex]);
  }

  onRidesPings = (pings) => {
    var stats, veh;
    var path = [];
    if (pings.obj.ts !== 0) {
      this.singleRide.push(pings.obj);
    }
    else {
      stats = computeStats(this.singleRide.sort((a, b) => a.ts - b.ts));
      if (stats) {
        stats.dur = stats.edt - stats.sdt;
        stats.avs = ((stats.dst / stats.dur) * (18 / 5)) || 0;
        stats.idx = this.singleRide[0].rideid;
      }
      if(this.singleRide.length){
        this.singleRide.sort((a, b) => a.ts - b.ts);
        this.singleRide.forEach((p) => {
          if(global.userId) path.push(p);
          else path.push({lat: p.lat, lng: p.lng});

          this.angArr.push(p.ang);
        });

        if (this.vehicles && this.vehicles.length) {
          veh = this.vehicles.find((bike) => {
            return bike.did === this.singleRide[0].devid;
          });
        }

        if(veh){
          savePingsAndStatsInDb(veh.idx, this.singleRide[0].rideid, path, stats, () => {
            if( this.singleRide[0] && this.singleRide[0].ts && (this.singleRide[0].ts) * 1000 > TODAY_START_TS && (this.singleRide[0].ts) * 1000 < TODAY_END_TS){
              var currentRide = this.singleRide[0].rideid;
              saveRideInTodayStat(veh.idx, stats, (todayNewStat) => {
                if(this.rids && this.rids.indexOf(currentRide) < 0){
                  global.EE.emitEvent(global.EE.EVENT_TYPES.ON_NEW_RIDE, todayNewStat);
                }
              });
            }
            this.singleRide = [];
            this.ridIndex += 1;
            if(path && path.length) this.pathArr = this.pathArr.concat(path);
            if(stats && Object.keys(stats).length > 1) this.statsArr.push(stats);
            if (this.lastRid === stats.idx) {
              var rideData = {};
              rideData.path = this.pathArr ;
              rideData.stats = this.statsArr;
              rideData.ang = this.angArr;
              global.EE.emitEvent(global.EE.EVENT_TYPES.RIDE_DATA, rideData);
              this.pathArr = [];
              this.statsArr = [];
              this.angArr = [];
            }
            if(this.rids && this.rids[this.ridIndex] ) this.sendReqForRPings(this.rids[this.ridIndex]);
          });
        }
      }
    }
  }

  sendReqForRPings = (rideId) => {
    if(this.timeout) clearTimeout(this.timeout);
    if(!this.wsState){
      this.timeout = setTimeout(()=>{
        this.sendReqForRPings(rideId);
      },2000);
      return;
    }
    global.gWebSocket.SendRequest({
      data: { a: "getride", d: { rideid: rideId } }
    });
  }

  onRTData = (data) => {
    if (!this.devRideMap.hasOwnProperty(data.obj.devid) && data.obj.rideid > 0) this.devRideMap[data.obj.devid] = data.obj.rideid;
    if (data.obj.rideid === -1) {
      if (Object.keys(this.devRideMap).length > 0) {
        if (this.devRideMap[data.obj.devid]) {
          this.sendReqForRPings(this.devRideMap[data.obj.devid]);
        }
      } else if (this.liveRide && this.liveRide.length) {
        var ride = this.liveRide.find((ride) => {
          return ride.did === data.obj.devid;
        });
        if (ride) this.sendReqForRPings(ride.idx);
      }
    }
  }

  render() {
    return (
      <Grid className="container-width">
        {this.state.isLoading ? <LoadingScreen /> : null}
        <Row>
          <Header location={this.props.location} />
        </Row>
        <Row style={{ width: "100%", height: "100px" }}></Row>
        <Row>
          <Col lg={2} md={3} className="hidden-xs hidden-sm visible-md visible-lg overflowY-auto" style={{height: "90vh"}}>
            <Menu type="box" {...this.props} />
          </Col>
          <Col lg={10} md={9} sm={12} xs={12}>
            {
              !this.state.errMsg && !this.state.isLoading ?
                this.props.children
                : this.state.errMsg ? <Alert bsStyle="warning">{this.state.errMsg} </Alert> : null
            }
          </Col>
        </Row>
      </Grid>
    )
  }
}