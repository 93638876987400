//remaining to display new stats when new ride completed
import React, { Component } from 'react';
import { Col, Row, Alert, Button } from 'react-bootstrap/lib';
import { VehMultiDropDown } from '../inputs/vehMultiSelect';
import { isVehValid, _setNewUrl, getVidArray } from '../../lib/common';
import { ShowCountTile, DateInput } from '../../generic/input';
import { getMultivehDR, getTodaysRides, GetStatistics, getRidesByVid } from "../../lib/db/rides";
import { parseMSIntoReadableTime, GetDateFromNow, toLocaleDateString, StampTODate } from '../../lib/dtconv';
import { jsonToCSVConvertor } from '../../lib/jsonUtils';
import { getRidesStat } from '../dailyRides/dailyRideTiles';
import { LoadingScreen } from '../../generic/input';
import { TODAY_START_TS, MENUPATH } from '../../lib/enums';
const todayStartTS = TODAY_START_TS;
const SPEED_THRESHOLD = 250;
export default class AllVehStatsTiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: global.bikeDetails ? global.bikeDetails : [],
      tileData: [],
      from: "",
      to: "",
      isLoading: true,
      infoMsg : "",
      allVehStats : []
    }
    this.vehIds = [];
    if (global.bikeDetails) this.vehicles = global.bikeDetails;
    this.vehMap = {};
    this.proVehs = false;
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.addEventListener(global.EE.EVENT_TYPES.TP_DEVICES, this.onTPDevices);
    global.EE.addEventListener(global.EE.EVENT_TYPES.ON_NEW_RIDE, this.onNewRides);
  }

  componentDidMount() {
    if (global.wsOnline) this.onBikes(global.bikeDetails);
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH.STATS );
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.TP_DEVICES, this.onTPDevices);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.ON_NEW_RIDE, this.onNewRides);
  }

  onTPDevices = (devices) => {
    global.tpDevDetails = devices;
    this.vehicles = devices;
    this.forceUpdate();
  }

  onNewRides = (todayNewStat) => {
    var newStats = [];
    if (Object.keys(todayNewStat).length > 0) {
      newStats.push(todayNewStat);
      if (this.state.allVehStats && this.state.allVehStats.length) {
        this.state.allVehStats.forEach(d => {
          if (todayNewStat.vid === d.vid) {
            if (d.dailyRides && d.dailyRides.length) d.dailyRides = d.dailyRides.filter(data =>  data.ts !== todayStartTS )
            d.dailyRides = newStats.concat(d.dailyRides);
          }
        });
       this._getTileData(this.state.allVehStats);
      } else {
        var allVehStats = [];
        allVehStats.push({ vid: todayNewStat.vid, dailyRides: newStats });
        this._getTileData(allVehStats);
      }
    }
  }

  _getTileData(data) {
    var tileData = [];
    var ridesCount = this._getStatsCount(data);
    if (ridesCount && ridesCount.length) {
      ridesCount.forEach(rd => {
        var vehnm = this.vehMap[rd.vid];
        tileData.push({ nm: vehnm.nm, vehno: vehnm.no, count: rd.count, vid: rd.vid })
      });
      this.setState({
        tileData: tileData,
        infoMsg: "",
      });
    }
  }

  setDateInURL() {
    var newUrl;
    if (this.props.location) {
      var loc = this.props.location;
      this.setState({
        from: loc.query && loc.query.from ? parseInt(loc.query.from) : GetDateFromNow(4) * 1000,
        to: loc.query && loc.query.to ? parseInt(loc.query.to) : new Date().setHours(23, 59, 59, 999)
      }, () => {
        if (loc.query && loc.query.vid) {
          this.vehIds = getVidArray(loc.query.vid);
          if (this.vehicles && this.vehIds && this.vehIds.length) this.validVeh = isVehValid(this.vehicles, this.vehIds );
          if (this.validVeh) {
            newUrl = _setNewUrl(loc,  this.vehIds , this.state.from, this.state.to);
            this.props.router.replace(newUrl);
            this._getStatistics(this.vehIds);
          } else {
            this.setState({
              isLoading: false
            });
          }
        } else if(this.vehicles && this.vehicles.length){
          newUrl = _setNewUrl(loc, null, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
          this._getStatistics(Object.keys(this.vehMap));
        }
      });
    }
  }

  _getStatistics = (vehIds) => {
    var params = {};
    vehIds = vehIds.map(v => parseInt(v));
    params.vids = vehIds;
    params.from = this.state.from;
    params.to = this.state.to;
    // if (params.vids && params.vids.length && params.from && params.to) this._getDataFromDB(params);
    if (params.vids && params.vids.length && params.from && params.to) this.GetStats(params);
  }

  onBikes = (bikes) => {
    this.vehicles = bikes;
    if (bikes && bikes.length === 0) {
      this.setState({
        isLoading: false
      });
    }
    if (bikes && bikes.length) {
      bikes = bikes.filter(veh => {
        this.vehMap[veh.idx] = { nm: veh.vmk + " " + veh.mo, no: veh.treg ? veh.treg : veh.reg };
        return veh;
      });
    }
    this.setState({
      vehicles: bikes,
      isLoading : true
    });
    this.setDateInURL();
  }

  clearAll = (vehs) => {
    var loc = this.props.location
    var vids = [];
    var newUrl = loc.pathname;
    vehs.forEach(veh => {
      vids.push(veh.idx);
    });
    if (loc && loc.query && loc.query.from && loc.query.to) newUrl += "?from=" + loc.query.from + "&to=" + loc.query.to;
    this.props.router.replace(newUrl);
    this._getStatistics(vids);
  }

  onClickApplyButton = (selectedIds) => {
    var vids = [] ,selIds = [];
    selectedIds.forEach(veh => {
      if (veh && veh.checked) {
        vids.push(veh.idx);
        selIds.push(veh);
      }
    });
    if (selIds && selIds.length) {
      var newUrl = _setNewUrl(this.props.location, vids, this.state.from, this.state.to);
      this.props.router.replace(newUrl);
      this._getStatistics(vids);
    }
  }

  chkDateAndGetTodaysRides(toDt, cb){
    let todaysStartDate = new Date().setHours(0,0,0,0);
    if(todaysStartDate > toDt) return cb();

    getTodaysRides((err, data) => {
      cb();
    });
  }

  _getDataFromDB = (params) => {
    var tileData = [];
    this.chkDateAndGetTodaysRides(params.to, ()=>{
      getMultivehDR(params, (err, dailyStats) => {
        if(dailyStats && dailyStats.length){
          var ridesCount = this._getStatsCount(dailyStats);
          if (ridesCount && ridesCount.length) {
            ridesCount.forEach(rd => {
              var vehnm = this.vehMap[rd.vid];
              tileData.push({ nm: vehnm.nm, vehno: vehnm.no, count: rd.count, vid: rd.vid});
            });
            this.setState({
              tileData: tileData,
              infoMsg: "",
              isLoading: false,
              allVehStats: dailyStats
            });
          }
        }else{
          this.setState({
            tileData: [],
            isLoading: false,
            allVehStats: [],
            infoMsg: "No rides available for the selected date range"
          })
        }
      });
    });
  }

  GetStats = (params)=>{
    GetStatistics(params, (err, data)=>{
      if(err){
        this.setState({
          tileData: [],
          isLoading: false,
          allVehStats: [],
          infoMsg: "Some error occurred while fetching stats, Please try again after some time"
        });
        return;
      }
      if(data.todayRides && data.todayRides.length){
        data.todayRides.forEach(ride=>{
          ride.ts = Date.now();
        })
      }
      let overallRides = data.dailyRidesStats.concat(data.todayRides);
      if (overallRides && overallRides.length) {
        let dailyStats = getRidesByVid(overallRides, []);
        var ridesCount = this._getStatsCount(dailyStats);
        var tileData = [];
        if (ridesCount && ridesCount.length) {
          ridesCount.forEach(rd => {
            var vehnm = this.vehMap[rd.vid];
            tileData.push({ nm: vehnm.nm, vehno: vehnm.no, count: rd.count, vid: rd.vid});
          });
        }
        this.setState({
          tileData: tileData,
          infoMsg: "",
          isLoading: false,
          allVehStats: ridesCount,
          overallRides: overallRides
        });
      } else {
        this.setState({
          tileData: [],
          isLoading: false,
          allVehStats: [],
          infoMsg: "No rides available for the selected date range"
        });
      }
    });
  }

  _getStatsCount(dailyStats) {
    var dailyCount = [];
    dailyStats.forEach(dr => {
      dr.dailyRides = dr.dailyRides.sort((a, b) => a.ts - b.ts);
      dailyCount.push({ vid: dr.vid, count: this._getStats(dr.dailyRides) });
    });
    return dailyCount;
  }

  _getStats(dailyArr) {
    var resObj = {}, avd = 0;
    dailyArr = dailyArr.sort((a, b) => a.ts - b.ts);
    var result = getRidesStat(dailyArr);
    avd = result.dst / result.totalRide;
    resObj['Distance KM'] = (result.dst / 1000).toFixed(1);
    resObj['Duration'] = parseMSIntoReadableTime(result.dur * 1000);
    resObj['Top speed KM'] = result.tps < SPEED_THRESHOLD ? result.tps.toFixed(1) : SPEED_THRESHOLD;
    resObj['Avg speed KM'] = result.avs < SPEED_THRESHOLD ? result.avs.toFixed(1) : SPEED_THRESHOLD;
    resObj['Avg distance per ride KM'] = (avd / 1000).toFixed(1);
    resObj['Max lean angle'] = result.mang.toFixed(1);
    resObj['Total no. rides'] = result.totalRide;
    return resObj;
  }

  dateChange = (e) => {
    var newUrl, vidArr = [];
    if (e.length > 1) {
      this.setState({
        from: e[0].getTime(),
        to: e[1].setHours(23, 59, 59, 999),
        isLoading: true
      }, () => {
        if (this.props.location && this.props.location.query && this.props.location.query.vid) {
          if (Array.isArray(this.props.location.query.vid) === false) {
            vidArr.push(parseInt(this.props.location.query.vid));
          } else {
            this.props.location.query.vid.forEach(v => {
              vidArr.push(parseInt(v));
            });
          }
          newUrl = _setNewUrl(this.props.location, vidArr, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
          this._getStatistics(vidArr);
        }
        else {
          newUrl = _setNewUrl(this.props.location, null, this.state.from, this.state.to);
          this.props.router.replace(newUrl);
          this._getStatistics(Object.keys(this.vehMap));
        }
      });
    }
  }

  sendToExcel(){
    this.state.overallRides.sort((a,b)=> b.ts - a.ts);
    var rides = this.state.overallRides.map(ride=>{
      var obj = {};
      let avd = ride.dst / ride.rids.length;
      obj['Vehicle Name'] = this.vehMap[ride.vid].nm;
      obj['Date'] = StampTODate(ride.ts);
      obj['Distance (in km)'] = (ride.dst / 1000).toFixed(2);
      obj['Top Speed(in km/hr)'] = ride.tps > SPEED_THRESHOLD ? SPEED_THRESHOLD : ride.tps.toFixed(2);
      obj['Avg speed KM'] = ride.avs < SPEED_THRESHOLD ? ride.avs.toFixed(1) : SPEED_THRESHOLD;
      obj['Avg distance per ride KM'] = (avd / 1000).toFixed(1);
      obj['Max Lean Angle'] = ride.mang ? ride.mang.toFixed(1) : 0;
      return obj;
    });

    jsonToCSVConvertor(rides, toLocaleDateString(this.state.from) + ' to ' + toLocaleDateString(this.state.to), true, "ajjas_statistics_");
  }

  showAllVehStats() {
    if (this.vehicles && this.vehicles.length && this.validVeh === false  ) {
      return (
        <Col style={{ paddingTop: "2%" }}>
          <Alert bsStyle="warning">No rides available for the selected date range</Alert>
        </Col>
      )
    }
    else if (this.vehicles && this.vehicles.length && this.state.tileData && this.state.tileData.length) {
      return (
        <Col style={{ paddingTop: "2%" }}>
          <ShowCountTile data={this.state.tileData} tableheight="30vh" stats={true} />
        </Col>
      )
    }else if(this.state.infoMsg){
      return (
        <Col style={{ paddingTop: "2%" }}>
         <Alert bsStyle="warning">{this.state.infoMsg}</Alert>
        </Col>
      )
    }
  }

  render() {
    return (
      <Col>
        <Row>
          <Col lg={4} lgOffset={4} md={6} mdOffset={1} sm={5} xs={12} className="vehicle-padding">
            {
              this.vehicles && this.vehicles.length > 1 ?
                <VehMultiDropDown
                  headerName="Vehicle Filter"
                  headingStyle={{ fontSize: "15px", border: "1px solid #ccc", borderRadius: "5px", width: "100%" }}
                  clearStyle={{ color: "#d6001a", fontSize: "14px" }}
                  selectedVehs={this.props.location.query && this.props.location.query.vid ? Array.isArray(this.props.location.query.vid) === true ?
                    this.props.location.query.vid : [this.props.location.query.vid] : 0}
                  options={this.state.vehicles}
                  onClear={(vehs) => this.clearAll(vehs)}
                  onApply={(selectedIds) => this.onClickApplyButton(selectedIds)}
                />
              : null
            }
          </Col>
          {
            this.state.from && this.state.to && this.vehicles && this.vehicles.length ?
              <Col lg={3} md={4} sm={5} xs={12} className="vehicle-padding">
                <DateInput height="40px" defaultdate={[this.state.from, this.state.to]} mode={true} maxdate={new Date().setHours(23, 59, 59, 999)} onChange={(e) => this.dateChange(e)}></DateInput>
              </Col>
              : null
          }
          {
            this.vehicles && this.vehicles.length ? 
              <Col lg={1} md={1} sm={2} xs={12}>
                <Button className="btn-primary" style={{ borderRadius: "4px", padding: "1px" }} title="Download CSV" onClick={() => this.sendToExcel()}
                  disabled={!(this.state.tileData && this.state.tileData.length) || false}>
                  <i className="ajjas-cloud" style={{ fontSize: "24px" }} />
                </Button>
              </Col>
              : null
          }
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} style={{ paddingBottom: "15px" }}>
            {this.state.isLoading ? <LoadingScreen /> : null}
            {this.showAllVehStats()}
          </Col>
        </Row>
      </Col>
    )
  }
}