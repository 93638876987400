/**
 * This components shows all shipments for a given user.
 * The shipments are filtered in 3 categories based on URL : Upcoming, Ongoing & Completed
 */

import React from "react";
import { Row, Col, Alert } from 'react-bootstrap/lib';
import { ViewTrip } from "./viewTrip";
import { getRouteData } from '../../lib/db/routes';
import { GetShipments } from '../../lib/db/journeys';
import { MENUPATH } from '../../lib/enums';

const SHIPMENT_TYPE = {
  "ongoing": 1,
  "upcoming": 2,
  "completed": 3
};
const SHIPMENT_TYPE_MENU_NM = {
  1: "ONGOING",
  2: "UPCOMING",
  3: "COMPLETED"
};

export default class ViewShipments extends React.Component {

  state = { msg: "", filteredShipments: []};

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.setBikesWithPlusDevices);
  }

  componentDidMount() {
    if (global.bikeDetails) this.setBikesWithPlusDevices(global.bikeDetails);

    let shipmentTyp = this.getShipmentTypeFromURL(window.location.pathname);
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH[SHIPMENT_TYPE_MENU_NM[shipmentTyp]]);

    this.setState({ shipmentTyp }, () => {
      GetShipments()
        .then(allShipments => {
          this.allShipments = allShipments;
          let filteredShipments = this.filterShipmentsByTyp(this.allShipments, shipmentTyp);
          if (filteredShipments.length === 0) this.setState({ msg: "No trips to show" });
          else this.setState({ filteredShipments });
        })
        .catch(msg => this.setState({ msg: JSON.stringify(msg) }));
    });
    getRouteData((err, routes) =>{
      if(err) {
        this.setState({ msg: "Error while Getting Data"});
      }
      else {
        if(routes && routes.length) {
          this.routeHashmap = {};
          routes.forEach(d=>{
             this.routeHashmap[d.idx] = this.showTitle(d.stlocnm, d.edlocnm)
          })
        }
      }
    });

  }

  showTitle(startRoute, endRoute){
    return (
      <Col  lg={11} md={11} sm={11} xs={11} className= "font-size16px" style={{ marginLeft: "-15px" }}>
        <span>{startRoute}</span>
        <i className="icon ajjas-arrright margin-left1 margin-right4" style={{fontSize:"10px"}}/>
        <span>{endRoute}</span>
      </Col>
    )
  }

  componentDidUpdate() {
    let shipmentTyp = this.getShipmentTypeFromURL(window.location.pathname);
    if (this.state.shipmentTyp !== shipmentTyp && this.allShipments) {
      global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, MENUPATH[SHIPMENT_TYPE_MENU_NM[shipmentTyp]]);
      this.setState({ msg: "", filteredShipments: [], shipmentTyp }, () => {
        let filteredShipments = this.filterShipmentsByTyp(this.allShipments, shipmentTyp);
        if (filteredShipments.length === 0) this.setState({ msg: "No trips to show" });
        else this.setState({ filteredShipments });
      });
    }
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.setBikesWithPlusDevices);
  }

  setBikesWithPlusDevices = (bikeDetails) => {

    if (!global.bikeDetails) global.bikeDetails = bikeDetails;

    let plusDevices = bikeDetails.filter(bike => bike.did);
    if (!plusDevices || plusDevices.length === 0) {
      this.setState({ msg: "Only available for plus users" });
    } else {
      this.setState({ bikeWithPlusDevices: plusDevices });
    }

  }

  getShipmentTypeFromURL(pathname) {
    let type = pathname.split("/").pop();
    return SHIPMENT_TYPE[type];
  }

  filterShipmentsByTyp = (allShipments, shipmentTyp) => {

    const NOW = Date.now();

    switch (shipmentTyp) {

      case SHIPMENT_TYPE.upcoming:
        return allShipments.filter(s => s.stdt > NOW);

      case SHIPMENT_TYPE.ongoing:
        return allShipments.filter(s => s.stdt <= NOW && s.eddt >= NOW);

      case SHIPMENT_TYPE.completed:
        return allShipments.filter(s => s.eddt < NOW);

      default:
        return [];

    }

  }

  render() {
    return (
        <Row>
        {this.state.msg && <Col xs={12}><Alert bsStyle="warning" >{this.state.msg}</Alert></Col>}
        <Col className="height-83vh overflowY-auto" style={{ marginTop: "-15px" }}>
          {
            this.state.filteredShipments.length > 0 &&
            this.state.bikeWithPlusDevices &&
            <ViewTrip
              journeys={this.state.filteredShipments}
              bikes={this.state.bikeWithPlusDevices}
              route={this.routeHashmap}
            />
          }
        </Col>
        </Row>
    )
  }
}