import React from 'react'
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem, Col } from 'react-bootstrap/lib';
import { menuClick, DrawerToggleButton, calManuLength } from './common';
import { BackDrop } from '../../generic/input';
import { compareTwoArrays } from '../../lib/common';

export default class Drawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
      selMenuPath: [],
      selNmArr: [],
      menu: this.props.menu,
      menuLength: this.props.menu && this.props.menu.screens && this.props.menu.screens.length ? this.props.menu.screens.length : 0
    }
  }
  componentDidMount() {
    this.setState({
      selMenuPath: this.props.selMenuPath && Object.keys(this.props.selMenuPath).length ? this.props.selMenuPath : [],
    })
  }

  static getDerivedStateFromProps(newprops, prevState) {
    var isDiff = compareTwoArrays(prevState.selMenuPath, newprops.selMenuPath);
    var len;
    if (isDiff) {
      if (prevState.menu && prevState.menu.screens) {
        len = calManuLength(prevState.menu.screens, newprops.selMenuPath, prevState.menu.screens.length);
      }
      return {
        selMenuPath: newprops.selMenuPath,
        selNmArr: newprops.selMenuPath,
        menuLength: len
      }
    } else return null;
  }

  drawerToggleHandler = () => {
    this.setState((prevState) => {
      return { drawerOpen: !prevState.drawerOpen }
    });
  }
  backDropClickHandler = () => {
    this.setState({ drawerOpen: false });
  }

  onMenuClick = (selNmArr, linkClicked, newLength) => {
    var newState = {};
    newState.menuLength = newLength;
    if (selNmArr) newState.selNmArr = selNmArr;
    if (linkClicked) newState.drawerOpen = false;
    if (Object.keys(newState).length) this.setState(newState);
  }

  nestedDD = (menu, mstyle, isSubmenu) => {
    let selMenuPath = this.state.selMenuPath;
    let selNmArr = this.state.selNmArr;
    if (!isSubmenu) this.menuIndex = 0;
    return (
      menu.map((m, i) => {
        let itemStyle = {
          paddingLeft: "25px", borderLeft: "none", borderRight: "none", borderRadius: "0px", borderColor: "#f6f6f6",
          color: (selMenuPath && selMenuPath.length && selMenuPath.includes(m.nm) ? mstyle.itemStyle.selectedColor : mstyle.itemStyle.initialColor), 
        };
        this.menuIndex++;
        if (this.menuIndex !== this.state.menuLength) itemStyle.borderBottom = "none";
        else itemStyle.borderBottom = "1px solid #f6f6f6";
        
        if (m.submenu) {
          return (
            <Col key={m.nm} >
              <ListGroupItem style={itemStyle}
                onClick={() => menuClick(this.state.menuLength, m, [...this.state.selNmArr], this.props.location, (selNmArr, linkClicked, newLength) => this.onMenuClick(selNmArr, linkClicked, newLength))}>
                <span style={mstyle.itemStyle}>
                  <i className={"icon " + m.icon + " padding-right8"} style={mstyle.iconStyle} />
                  <span className="drawer_menu_name" style={mstyle.titleStyle}>{m.label}</span>
                  <span className={"caret " + (selNmArr && selNmArr.length && selNmArr.includes(m.nm) ? "rotate-180deg" : "")} style={{ float: "right", marginTop: "3%" }} ></span>
                </span>
              </ListGroupItem>
              {
                selNmArr && selNmArr.length && selNmArr.includes(m.nm) ?
                  this.nestedDD(m.submenu.screens, m.submenu.style, true)
                  : null
              }
            </Col>
          )
        } else {
          let linkStyle = { color: selMenuPath && selMenuPath.length && selMenuPath.includes(m.nm) ? mstyle.itemStyle.selectedColor : mstyle.itemStyle.initialColor };
          linkStyle = Object.assign(linkStyle, mstyle.titleStyle);
          return (
            <ListGroupItem key={m.nm} style={itemStyle} onClick={() => menuClick(this.state.menuLength, m, [...this.state.selNmArr], this.props.location, (selNmArr, linkClicked, newLength) => this.onMenuClick(selNmArr, linkClicked, newLength))}>
              <span style={mstyle.itemStyle} >
                <i className={"icon " + m.icon + " padding-right8"} style={mstyle.iconStyle} />
                <Link className="drawer_menu_name" style={linkStyle} onClick={() => menuClick(this.state.menuLength, m, [...this.state.selNmArr], this.props.location, (selNmArr, linkClicked) => this.onMenuClick(selNmArr, linkClicked))} >{m.label}</Link>
              </span>
            </ListGroupItem>
          )
        }
      })
    )
  }
  render() {
    return (
      <div>
        {this.state.drawerOpen ? <BackDrop click={this.backDropClickHandler} /> : <DrawerToggleButton click={this.drawerToggleHandler} />}
        <div className={"side-drawer" + (this.state.drawerOpen ? " open" : "")}>
          <div className="color-0094de profile_row">
            <div className="profile_pic" >{this.props.name ? this.props.name.charAt(0) : ""}</div>
            <div className="user_name">{this.props.name}</div>
          </div>
          <div style={{ overflowY: "auto", height: "87%" }}>
            <ListGroup className='color-ffffff font-family-roboto'>
              {
                this.state.menu && Object.keys(this.state.menu).length ? this.nestedDD(this.state.menu.screens, this.state.menu.style) : null
              }
            </ListGroup>
          </div>
        </div>
      </div>
    )
  }
}
Drawer.propTypes = {
  selMenuPath: PropTypes.array,
  name: PropTypes.string,
  location: PropTypes.object,
}
