import React, { Component } from 'react';
import { getNotifFromDb } from '../../lib/db/notif';
import { isVehValid } from '../../lib/common';
import { Col, Row, Grid, Alert} from 'react-bootstrap/lib';
import { StampTODate, timeFormat } from '../../lib/dtconv';
import { MultiSelectDropDown } from '../../generic/input';
import { NOTIFICATION_TYPE } from '../../lib/enums';
import { clone } from '../../lib/jsonUtils';
const TRIGGER_NOTIF = 200;
// import { permissionCheck } from '../webnotiflibrary/notif';

const colorForNotif = {
  1: "timeline-item-orange",         //motion
  2: "timeline-item-yellow",         //Ignition Off"
  3: "timeline-item-yellow",         //Ignition On
  4: "timeline-item-red",            //Accident detected
  5: "timeline-item-red",            //Stationary Fall Detected
  6: "timeline-item-green",         //Power Supply Off
  7: "timeline-item-yellow",        //Vehicle Switched Off
  8: "timeline-item-yellow",        //Vehicle Switched on
  9: "timeline-item-green",          //Power Supply on
  100: "timeline-item-grey",         //"Vehicle Unreachable"
  201: "timeline-item-blue",         //"speed limit"
  202: "timeline-item-blue"         //"geo fence alert"
};

export default class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: 0,
      activeNotifications: [],
      labelMsg: "",
      selectedNotifId: [],
      filterErrorMsg: "",
      clearNotif: false,
      notifFilterOptions: [],
      errMsg: "",
      valErr: "",
      isLoading : true
    }
    this.allNotifications = [];
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.addEventListener(global.EE.EVENT_TYPES.ON_NEW_NOTIF, this.getLiveNotif);
  }

  componentDidMount() {
    if (global.wsOnline) {
      this.onBikes(global.bikeDetails);
    }
    if (this.props.location && this.props.location.query && !this.props.location.query.vid) {
      this.setState({ errMsg: "No vehicle added please add vehicle from app " });
    }
  }

  componentWillReceiveProps(nextProps){
    // if(!(global.bikeDetails && global.bikeDetails.length)) return;
    // if(nextProps.location && nextProps.location.query.newnotif){
    //   if(nextProps.location.query.vid !== this.state.selectedVehicle) {
    //     var vehicle = global.bikeDetails.filter(d=> d.idx === parseInt(nextProps.location.query.vid));
    //     this._vehDropdown.selectVehicle(vehicle[0])
    //   }
    // }
  }

   componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
    global.EE.removeEventListener(global.EE.EVENT_TYPES.ON_NEW_NOTIF, this.getLiveNotif);
  }

  getLiveNotif = (notif) =>{
    if(notif.vid){
      if(parseInt(this.props.location.query.vid) === notif.vid) {
        var vehicle = global.bikeDetails.filter(d=> d.idx === notif.vid);
        this.showNotification(vehicle[0]);
      }
    }
    else {
      this.setState({
        isLoading: false,
        labelMsg: "Error While Getting Data from Server",
      });
    }
  }

  onBikes = (bikes) => {
    global.bikeDetails = bikes;
    if(bikes.length < 1) this.setState({
      labelMsg:  "No vehicle added please add vehicle from app"
    })
    else{
      // var newUrl = this.props.location.pathname + "?vid=" + this.props.location.query.vid;
      // if(this.props.location.query.ntf)  {
      //   if(typeof this.props.location.query.ntf === "string") newUrl += "&ntf=" + this.props.location.query.ntf ;
      //   else this.props.location.query.ntf.forEach(d=>{ newUrl += "&ntf=" + d });
      // }
      // if (global.bikeDetails && global.bikeDetails.length === 1) {    remaining to check for single vehicle
      //   this.showNotification(global.bikeDetails[0]);
      //   this.setState({ selectedVehicle: global.bikeDetails[0].vid });
      // }
      if(this.props.location && this.props.location.query && this.props.location.query.vid){
        this.validVeh = isVehValid(bikes,this.props.location.query.vid);
        this.showNotification(parseInt(this.props.location.query.vid));
        this.forceUpdate();
      }
    }
  }

  showNotification(selectedVeh){
    getNotifFromDb(selectedVeh ,(err,notifData) => {
      if(err){
        this.setState({
          activeNotifications: [],
          isLoading : false,
          labelMsg: "Error While Getting Data from Server",
        });
        return;
      }
      if (notifData && notifData.length <= 0) {
        this.allNotifications = [];
        this.setState({
          activeNotifications: [],
          isLoading : false,
          labelMsg: "No notification available for this vehicle",
        });
        return;
      }
      const uniqueNotificationSet = new Set();
      var notifFilterOptions = [];
      var uniqueIdInNotif = [];
      notifData.forEach(d => { //create unique notification Id fro selected vehicle
        if (!uniqueNotificationSet.has(d.t)) {
          uniqueNotificationSet.add(d.t);
          uniqueIdInNotif.push(d.t);
        }
      });

      NOTIFICATION_TYPE.forEach(d => { // create notification filter
        notifFilterOptions.push({ id: d.t, nm: d.nm, checked: false, disabled: true });
      });
      //if notification Id present in query
      if(this.props.location.query.ntf){
        var notifId = [];
        if(typeof this.props.location.query.ntf === "string") notifId.push(parseInt(this.props.location.query.ntf));
        else this.props.location.query.ntf.forEach(d=> notifId.push(parseInt(d)))
        for (var i = 0; i < notifFilterOptions.length; i++) {
          if(typeof this.props.location.query.ntf === "string" && (parseInt(this.props.location.query.ntf) === notifFilterOptions[i].id)) notifFilterOptions[i].checked = true; //for single ntf id
          else if (notifId.includes(notifFilterOptions[i].id)) notifFilterOptions[i].checked = true; // for multiple notif id
        }
        var activeNotifications = notifData.filter(d => notifId.includes(d.t));
      }

      notifFilterOptions.forEach(d=>{ //change disable state according to notification present for the selected vehicle
        if(uniqueIdInNotif.includes(d.id)) d.disabled = false;
      });
      this.allNotifications = notifData;
      this.setState({
        notifFilterOptions,
        activeNotifications: activeNotifications ? activeNotifications : notifData, // Initially all are active
        labelMsg: "",
        isLoading : false,
        errMsg: activeNotifications && activeNotifications.length === 0 ? "This vehicle doesn’t have notifications with selected filter. Edit the filter to view available notifications." : ""
      });
    });
  }

  onClickApplyButton(selectedIds) {
    var newUrl = this.props.location.pathname + "?vid=" + this.props.location.query.vid;
    if (selectedIds.length > 0) {
      selectedIds.forEach(d=>{
        newUrl += "&ntf=" + d;
      });
      this.props.router.replace(newUrl);
      let activeNotifications = this.allNotifications.filter(d => selectedIds.includes(d.t));
      // Set options in dropdown that have been ticked
      let notifFilterOptions = clone(this.state.notifFilterOptions);
      for (let i = 0; i < notifFilterOptions.length; i++) {
        if (selectedIds.includes(notifFilterOptions[i].id)) {
          notifFilterOptions[i].checked = true;
        } else {
          notifFilterOptions[i].checked = false;
        }
      }
      this.setState({ activeNotifications, notifFilterOptions });
    }
    else this.clearAll();

  }

  clearAll() {
    // Untick all notification filters
    let notifFilterOptions = clone(this.state.notifFilterOptions);
    for (let i = 0; i < notifFilterOptions.length; i++) {
      notifFilterOptions[i].checked = false;
    }
    var newUrl = this.props.location.pathname + "?vid=" + this.props.location.query.vid;
    this.props.router.replace(newUrl);
    this.setState({ activeNotifications: this.allNotifications, notifFilterOptions });
  }

  render(){
    return(
      <Grid style={{paddingRight:"0px",marginLeft:"0%",marginRight:"0%" ,width:"100%",height:"60%"}}>
        <Row style={{marginLeft:"1%", marginRight:"1%"}}>
          <Col lg={10} md={9} sm={12} xs={12} style={{ paddingBottom: "15px" }}>
            {
              parseInt(this.props.location.query.vid) !== 0 && this.allNotifications.length?
                <Col lg={10} md={10} sm={12} xs={12} style={{ height: "70vh", border: "1px solid #ccc" }}>
                  <Col lg={10} md={10} sm={12} xs={12} style={{ zIndex: "99", width: "230px", float: "right" }} >
                    <MultiSelectDropDown
                      headerName="Notification filters"
                      headingStyle={{ color: "#d6001a", fontSize: "14px" }}
                      apply="Apply"
                      applyStyle={{ paddingTop: "4px", marginLeft: "40%", backgroundColor: "#0094de", borderColor: "#0094de", color: "#fff" }}
                      options={this.state.notifFilterOptions}
                      onClear={() => this.clearAll()}
                      onApply={(selectedIds) => this.onClickApplyButton(selectedIds)}>
                    </MultiSelectDropDown>
                  </Col>
                  {this.state.activeNotifications.length ?
                    <Col lg={10} md={9} sm={12} xs={12} className="notif">
                      <Col className="left-timeline" >{
                        this.state.activeNotifications
                          .sort((a, b) => { return b.dc - a.dc })
                          .map((state, i) => {
                            return (
                              <Col key={i} className="row" style={{ marginLeft: "-12px", marginTop: "25px" }}>
                                <Col className={colorForNotif[state.t] + " col-lg-12"}>
                                  <Col style={{ marginTop: "-17px" }}>
                                    <Col className="col-lg-12">
                                      <span className="font-size14px"><b>{timeFormat(state.dc * 1000)}</b></span>
                                      <span className="font-size14px color-9b9b9b" style={{ paddingLeft: "15px" }}>{StampTODate(state.dc * 1000)}</span>
                                    </Col>
                                    <div className="clear"></div>
                                    <Col className="col-lg-12">
                                      <span className="font-size16px color-4a4a4a" >{state.t > TRIGGER_NOTIF ? state.msg :  state.h}</span>
                                    </Col>
                                  </Col>
                                </Col>
                              </Col>
                            )
                          })
                      }
                      </Col>
                    </Col>
                    : this.state.errMsg ? <Alert className="noNotif" bsStyle="warning">This vehicle doesn’t have notifications with selected filter on. Edit the filter to view available notifications.</Alert> : null //errMsg
                  }
                </Col>
              :
              // this.state.isLoading ?
              // <LoadingScreen/> :
              this.state.labelMsg ?
              <Alert bsStyle="warning">{this.state.labelMsg}</Alert> :
              !this.validVeh && global.bikeDetails &&  global.bikeDetails.length ? <Alert bsStyle="warning">Please select a vehicle to view notification</Alert> :  
              // this.state.isLoading ? <LoadingScreen/> : 
              null
            }
          </Col>
        </Row>
      </Grid>
    )
  }
}