import Net from '../net';
import { getFromStore, setToStore, getAllkeys } from './common';
const glHostName = require('../../config').config.glHostName;
const TODAY_START_TS = require('../enums').TODAY_START_TS;

export function getTriggerTypes(typ, cb) {
  getFromStore('triggerTypes', typ).then(tt => {
    if (tt && tt.length) {
      cb(null, tt);
    } else {
      var url = glHostName + '/gl/users/triggers/getGeoFenceType';
      Net.GetRequest(url, (resp) => {
        var Type = JSON.parse(resp.response).data;
        if (resp.status !== 200) {
          cb("Error While Finding GeoFence Type", null)
        }
        else {
          setToStore('triggerTypes', typ, Type)
          cb(null, Type);
        }
      });
    }
  });
}

export function saveNewTriType(newTyp, triTyp, cb) {
  getFromStore('triggerTypes', triTyp).then(tt => {
    if (tt && tt.length) {
      var exist = tt.find((typ) => { return typ.nm === newTyp.nm });
      if (exist) {
        cb("Geofence type already exist", null);
        return;
      }
    }
    if (!tt) tt = [];
    var url = glHostName + '/gl/users/triggers/addGeoFenceType';
    Net.PostRequest(url, newTyp, (resp) => {
      var data = JSON.parse(resp.response).data;
      if (resp.status !== 200) {
        cb(data, null);
      }
      else {
        tt.push({ nm: newTyp.nm, idx: data.idx });
        setToStore('triggerTypes', triTyp, tt);
        cb(null, data);
      }
    });
  });
}

export function saveTrigger(trigger, cb) {
  var url = glHostName + '/gl/users/triggers/insertTrigger';
  Net.PostRequest(url, trigger, (resp) => {
    var data = JSON.parse(resp.response).data;
    if (resp.status !== 200) {
      cb(data, null);
    }
    else {
      getFromStore('triggers', trigger.typ).then((trgs) => {
        if (!trgs) trgs = [];
        trigger.idx = data;
        trgs.push(trigger);
        setToStore('triggers', trigger.typ, trgs).then(() => {
          cb(null, data);
        });
      });
    }
  });
}

export function getTriggersData(typ, cb) {
  getFromStore('triggers', typ).then(trgs => {
    if (trgs && trgs.length) {
      cb(null, trgs);
    } else {
      var url = glHostName + '/gl/users/triggers/getTriggerData';
      Net.PostRequest(url, { typ: typ }, (resp) => {
        var data = JSON.parse(resp.response).data;
        if (resp.status !== 200) {
          cb(data, null);
        }
        else {
          setToStore('triggers', typ, data).then(()=>{
            cb(null, data);
          });
        }
      });
    }
  });
}

export function editTrigger(data, typ, cb) {
  var url = glHostName + '/gl/users/triggers/updatetrigger';
  Net.PostRequest(url, data, (resp) => {
    var response = JSON.parse(resp.response).data;
    if (resp.status !== 200) {
      cb(response, null)
    }
    else {
      if (typ && typ === 1) {
        _updateSLById(data, typ , () => {
          cb(null , data);
        });
      } else {
        _updateGFById(data, typ ,() => {
          cb(null , data);
        });
      }
    }
  });
}

function _updateSLById(data, typ ,cb) {
  getFromStore('triggers', typ).then((tri) => {
    var tidData = tri.find(d => d.idx === data.idx);
    if (data.title) tidData.title = data.title;
    if (data.spdLmt) tidData.spdLmt = data.spdLmt;
    if (data.vehIdArr) tidData.vehIdArr = data.vehIdArr;
    if (data.thrshld) tidData.thrshld = data.thrshld;
    var index = tri.findIndex(x => x.idx === data.idx);
    tri.splice(index, 1);
    tri.push(tidData);
    setToStore('triggers',typ, tri).then(() => {
      cb ();
    });
  });
}

function _updateGFById(data, typ ,cb) {
  getFromStore('triggers', typ).then((tri) => {
    var tidData = tri.find(d => d.idx === data.idx);
    if (data.title) tidData.title = data.title;
    if (data.clat) tidData.clat = data.clat;
    if (data.clong) tidData.clong = data.clong;
    if (data.r) tidData.r = data.r;
    if (data.desc) tidData.desc = data.desc;
    if (data.vehIdArr) tidData.vehIdArr = data.vehIdArr;
    if (data.gfTyp) tidData.gfTyp = data.gfTyp;
    var index = tri.findIndex(x => x.idx === data.idx);
    tri.splice(index, 1);
    tri.push(tidData);
    setToStore('triggers',typ, tri).then(() => {
      cb ();
    });
  });
}

export function deleteTrigger(trigger, typ, cb){
  var url = glHostName +'/gl/users/triggers/deletetrigger';
  Net.PostRequest(url, trigger, (resp)=>{
    var data = JSON.parse(resp.response).data;
    if(resp.status !== 200){
      cb(data, null);
    }
    else {
      getFromStore('triggers', typ).then((tri) => {
        var index = tri.findIndex(x => x.idx === trigger.idx);
        tri.splice(index, 1);
        setToStore('triggers' , typ , tri).then(()=>{
          cb(null, data);
        });
      });
    }
  });
}

export function getAlertsFromDb(params , cb){
  getAllkeys('alerts').then((vids) => {
    if(vids && vids.length) {
      _getAlertsFromLocalDb(params, function(err, alerts){
        if(err) cb(err, null)
        else cb(null, alerts);
      });
    }
    else {
      _getAltFromServer(params, function(err, alerts){
        if(err) cb(err, null);
        else cb(null, alerts);
      });
    }
  }).catch(() => {return ;});
}

function _getAlertsFromLocalDb(params, cb) {
  _checkAltforVids(params, (err, data) => {
    if (data) {
      if (data.newVidArr && data.newVidArr.length) {
        var paramObj = {};
        paramObj.vids = data.newVidArr;
        paramObj.from = params.from;
        paramObj.to = params.to;
        if (params.allNotif) paramObj.allNotif = params.allNotif;
        _getAltFromServer(paramObj, function (err, res) {
          if (err) cb(err, null);
          else {
            if (res && res.length) {
              if (data.foundData && data.foundData.length) {
                data.foundData = data.foundData.concat(res);
                cb(null, data.foundData);
              }
              else cb(null, res);
            }
            else {
              if (data.foundData && data.foundData.length) cb(null, data.foundData);
              else cb(null, res);
            }
          }
        });
      }
      else cb(null, data.foundData);
    }
  });
}
function _checkAltforVids(params, cb) {
  var foundData = [], newVidArr = [];
  params.vids.forEach((d, i) => {
    getFromStore('generic', "alerts_" + d).then((ts) => {
      if (!ts) newVidArr.push(d);
      if (ts && (ts !== TODAY_START_TS)) {
        newVidArr.push(d);
        if ((params.vids.length - 1) === i) cb(null, { foundData: foundData, newVidArr: newVidArr })
      } else {
        getFromStore('alerts', d).then(alerts => {
          if (alerts && alerts.length > 0) {
            _filterDataByDate(alerts, params.from, params.to, (altArr) => {
              if (altArr.length) foundData = foundData.concat( altArr );
            });
          }
          if ((params.vids.length - 1) === i) cb(null, { foundData: foundData, newVidArr: newVidArr });
        }).catch(() => {
          return;
        });
      }
    }).catch(() => {
      return;
    })
  });
}

function _getAltFromServer(params, cb) {
  _getAlertsFromServer(params, (err, res) => {
    if (err) cb(err, null);
    if (res && res.length) {
      _storeAlertsInDB(params, res, (data) => {
        cb(null, data);
      });
    }
    else {
      _storeTSInGeneric(params.vids, () => {
        cb(null, res);
      });
    }
  });
}

function _getAlertsFromServer(params, cb){
  var url = glHostName + "/gl/users/notifications/getAlertsByVehicleIds";
  Net.PostRequest(url, params, (resp) => {
    if (resp.status === 200) {
      var data = JSON.parse(resp.response).data;
      var distinctVids = [], vehAlerts = [];
      if(data && data.length ){
        distinctVids = [...new Set(data.map(nf => nf.vid))];
        distinctVids.forEach((v,i) => {
          var vehAlt = [];
          vehAlt = data.filter(d => d.vid === v);
          vehAlerts.push({ vid: v, alerts: vehAlt })
        });
      }
      cb(null, vehAlerts);
    }
    else cb("Error While Getting Data from Server", null);
  });
}

function _storeAlertsInDB(params, data, cb) {
  var alertsArr = [];
  data.forEach((d, i) => {   
    getFromStore('alerts', d.vid).then(alerts => {
      if (!alerts) alerts = [];
      alerts = alerts.concat(d.alerts);
      _filterDataByDate(alerts, params.from, params.to, (altArr) => {
        if (altArr.length) alertsArr = alertsArr.concat(altArr);
      });
      setToStore('alerts', d.vid, alerts).then(() => {
        if ((data.length - 1) === i) {
          _storeTSInGeneric(params.vids, () => {
            cb(alertsArr);
          });
        }
      }).catch(() => {
        return;
      });
    });
  });
}

function _filterDataByDate(alerts, from, to, cb) {
  var res = []
  // dailyTs = Object.keys(vehDRides);
  alerts.forEach((alt) => {
    if (alt.dc >= from && alt.dc <= to) {
      res.push(alt);
    }
  });
  cb(res);
}

function _storeTSInGeneric(vids , cb){
  vids.forEach((id, i) => {
    setToStore('generic', "alerts_" + id, TODAY_START_TS).then(() => {
      if ((vids.length - 1) === i) cb();
    }).catch(() => {
      return;
    });
  });
}