import React, { Component} from 'react';
import {browserHistory} from 'react-router';
import {Col, Row, Alert, ControlLabel, FormControl, Button, Checkbox} from 'react-bootstrap/lib';
import { saveTrigger, getTriggersData, editTrigger } from '../../lib/db/triggers';
import { clone } from '../../lib/jsonUtils';
import { ReactDropDown } from "../../generic/input";
import { MENUPATH } from '../../lib/enums';
const TRIGGER_TYP = 1;
const THRESHOLD= [{ idx: 1, nm: "for more than 10 sec", time: 10 },
                { idx: 2, nm: "for more than 1 min", time: 60 },
                { idx: 3, nm: "for more than 3 min", time: 180 },
                { idx: 4, nm: "for more than 5 min", time: 300 } ];

export default class SpeedLimit extends Component{
  constructor(props){
    super(props);
    this.state = {
      errMsg: "",
      selectedVehId: [],
      speedLimitTitle: THRESHOLD[0].nm,
      spdLmt: 0
    }
    this.title = "";
    this.titleErr = "";
    this.spdLmtErr = "";
    this.vehErr = "";
    this.thrshld = 1;
  }

  componentWillMount() {
    global.EE.addEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentWillUnmount() {
    global.EE.removeEventListener(global.EE.EVENT_TYPES.BIKES, this.onBikes);
  }

  componentDidMount(){
    if(global.wsOnline){
      if(global.bikeDetails) {
        this.onBikes(global.bikeDetails);
      }
    }
    global.EE.emitEvent(global.EE.EVENT_TYPES.MENU_CHANGE, this.props.edit ? MENUPATH.VIEW_SL : MENUPATH.ADD_SL );
    this.getDetails();
  }

  getDetails = () => {
    if(this.bikeDetails && this.bikeDetails.length){
      getTriggersData( TRIGGER_TYP, (err, data)=>{
        if(data.length){
          this.distinctspd = {};
          data.forEach((d)=>{
            if(this.distinctspd[d.spdLmt] && this.distinctspd[d.spdLmt].vehIdArr){
              d.vehIdArr.forEach((veh)=>{
                this.distinctspd[d.spdLmt].vehIdArr.push(veh);
              });
            }
            else this.distinctspd[d.spdLmt] = { vehIdArr: d.vehIdArr, title: d.title };

            if(this.props.edit && parseInt(this.props.location.query.tid) === d.idx){
              this.existingData = clone(d);
            }
          });
          if(this.props.edit){
            var selectedVeh = [];
            var speed;
            this.title = this.existingData.title;
            this.showDefaultThrshld(this.existingData.thrshld/1000);
            speed = this.existingData.spdLmt;
            for (var i = 0; i < this.bikeDetails.length; i++) {
              if (this.existingData.vehIdArr.includes(this.bikeDetails[i].idx)) {
                document.getElementById(this.bikeDetails[i].idx).checked = true;
                selectedVeh.push(this.bikeDetails[i].idx);
              }
            }
            this.setState({
              selectedVehId: selectedVeh,
              spdLmt: speed
            })
          }
        }
      });
    }
  }

  onBikes = (bikes) => {
    this.bikeDetails = bikes;
    this.getDetails();
    this.forceUpdate();
  }

  validation = () =>{
    if(this.title === ""){
      this.titleErr = "Enter trigger title";
      return 1;
    }

    if(this.state.spdLmt === 0 || this.state.spdLmt === ""){
      this.spdLmtErr = "Set speed limit in number";
      this.titleErr = "";
      return 1;
    }

    if(this.state.selectedVehId.length === 0){
      this.vehErr = "Select atleast one vehicle";
      this.titleErr = "";
      this.spdLmtErr = "";
      return 1;
    }

    if(this.distinctspd && this.distinctspd[this.state.spdLmt]){
      var noOfDup = 0;
      var dupVeh = "";
      this.state.selectedVehId.forEach((veh)=>{
        if(this.props.edit){
          if(this.distinctspd[this.state.spdLmt].vehIdArr.includes(veh) && !this.existingData.vehIdArr.includes(veh)){
            noOfDup++;
            dupVeh = veh;
          }
        }
        else {
          if(this.distinctspd[this.state.spdLmt].vehIdArr.includes(veh)){
            noOfDup++;
            dupVeh = veh;
          }
        }
      });

      if(noOfDup === 1){
        var duplicateVeh = this.bikeDetails.filter((veh)=>{
          return (veh.idx === dupVeh)
        });
        this.setState({
          errMsg: this.state.spdLmt+" speed limit is already set for vehicle "+duplicateVeh[0].vmk+" "+duplicateVeh[0].mo+ " "+duplicateVeh[0].reg+", edit speed limit '"+ this.distinctspd[this.state.spdLmt].title + "'"
        })
        return 1;
      }
      if(noOfDup > 1){
        this.setState({
          errMsg: this.state.spdLmt+" speed limit is already set for "+noOfDup+ " vehicles, edit speed limit '"+this.distinctspd[this.state.spdLmt].title+ "'"
        })
        return 1;
      }
    }
    return 0;
  }

  isChanged = () => {
    var update = {};
    if(this.title !== this.existingData.title){
      update.title = this.title;
    }
    if(this.state.spdLmt !== this.existingData.spdLmt){
      update.spdLmt = this.state.spdLmt;
    }
    if(this.state.selectedVehId.length){
      var changeVid = this.getChangeArray(this.existingData.vehIdArr, this.state.selectedVehId)
      if(changeVid) {
        update.vehIdArr = this.state.selectedVehId;
      }
    }

    if(this.existingthrshld !== this.thrshld){
      update.thrshld = THRESHOLD.find(op=> op.idx === this.thrshld).time * 1000;
    }

    if(Object.keys(update).length > 0){
      update.idx = this.existingData.idx;
      update.typ = TRIGGER_TYP;
      return update;
    }
    else return false;

  }

  getChangeArray(arr1, arr2){
    var changedVid = "";
    if(arr1.length > arr2.length) changedVid = arr1.filter(d => arr2.indexOf(d) < 0);
    else changedVid = arr2.filter(d => arr1.indexOf(d) < 0);
    if(changedVid && changedVid.length) return true;
  }

  edit = () => {
    var updated = this.isChanged();
    if(updated){
      document.getElementById('save').disabled = true;
      editTrigger(updated ,TRIGGER_TYP, (err, response) =>{
        if(err) this.setState({ errMsg: err });
        else browserHistory.push('/webapp/sl/view');
      });
    }
    this.setState({
      errMsg: ""
    })
  }

  save = () => {
    var err = this.validation();
    if(err === 1){
      this.forceUpdate();
      return;
    }
    this.titleErr = "";
    this.spdLmtErr = "";
    this.vehErr = "";
    this.forceUpdate();
    if(this.props.edit){
      this.edit();
    }
    else{
      var data = {};
      data.typ = TRIGGER_TYP;
      data.title = this.title;
      data.spdLmt = this.state.spdLmt;
      data.vehIdArr = this.state.selectedVehId;
      data.ts = Date.now();
      data.thrshld = THRESHOLD.find(op=> op.idx === this.thrshld).time * 1000;
      document.getElementById('save').disabled = true;
      saveTrigger( data ,(err, response) =>{
        if(err) this.setState({ errMsg: err });
        else browserHistory.push('/webapp/sl/view');
      });
    }
  }

  onSelectAllVehicle(e){
    var vehID = [];
    var did = [];
    var items = document.getElementsByName('veh');
    for (var i = 0; i < items.length; i++) {
      if (e.target.checked && items[i].type === 'checkbox') {
        vehID.push(parseInt(items[i].id));
        did.push(parseInt(items[i].value));
        items[i].checked = true;
      }
      else items[i].checked = false;
    }
    this.setState({
      selectedVehId: vehID
    });
  }

  onVehicleSelect(e){
    var items = document.getElementById('selectAll'); items.checked = false;
    var vehId = parseInt(e.target.id);
    if(e && e.target.checked){
      this.state.selectedVehId.push(vehId);
    }
    else {
      for(var i = 0 ; i < this.state.selectedVehId.length ; i++) {
        if(this.state.selectedVehId[i] === vehId) {
          this.state.selectedVehId.splice(i, 1);
        }
      }
    }
  }

  selectThrshld(thshdopt){
    this.thrshld = thshdopt.idx;
    this.setState({
      speedLimitTitle: THRESHOLD.find(op=> op.idx === thshdopt.idx).nm
    })
  }

  showDefaultThrshld(thrshldtime){
    this.existingthrshld = THRESHOLD.find(op => op.time === thrshldtime).idx;
    this.thrshld = this.existingthrshld;
    this.setState({
      speedLimitTitle: THRESHOLD.find(op => op.time === thrshldtime).nm
    })
  }

  onSpdChange(e){
    if(e.target.value > 250 || e.target.value < 1){
      e.target.value = "";
      this.setState({
        spdLmt: ""
      })
      return;
    }
    this.setState({
      spdLmt: parseInt(e.target.value)
    })
  }

  render(){
    return(
      <div>
        {
          this.props && this.props.errMsg ?
          <Col> <Alert bsStyle="warning">{this.props.errMsg}</Alert> </Col>
          :
          <Col
            style={{ overflowY: "auto", height: "85vh", paddingLeft:"0%", paddingBottom:"15px", paddingRight:"0%", border: "1px solid #e1e1e1"}}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} style={{ width: "90%", marginTop: "1%", marginLeft: "4%"}}>
              {this.state.errMsg ? <Alert style={{paddingLeft: "10px", marginTop: "2%", marginBottom: "0%"}} bsStyle="danger"> {this.state.errMsg} </Alert> : ""}
              </Col>
            </Row>
            <Row>
              <Col lg={9} md={9} sm={9} xs={12}>
                <p className ="font-size16px" style={{marginLeft: "4%", marginTop: "4%", marginBottom: "0%"}}>
                {this.props.edit ? "Edit Speed Limit Trigger" : "Add Speed Limit Trigger"}</p>
                <p className ="font-size14px" style={{marginLeft: "4%", marginTop: "1%", color:"#9b9b9b"}}>
                  Notification will be triggered when vehicle reaches the speed
                </p>
              </Col>
              <Col lg={3} md={3} sm={3} xs={12}>
                <Button className="margin-right10 savebtn" id="save" style={{marginLeft: "4%", backgroundColor:"#00a8e2", width:"112px", marginRight: "10%"}}
                onClick={() =>  this.save()}><span style={{ fontSize: "16px", color:"#ffffff" }}>Save</span></Button>
              </Col>
            </Row>
            <hr style={{marginLeft: "1%", marginRight:"1%", color: "#4a4a4a"}}/>
            <Col lg={12} md={12} sm={12} xs={12} style={{marginTop: "0%"}}>
              <form style={{color: "#b5b5b5"}}>
                <ControlLabel style={{marginLeft: "2%", marginTop: "1%", fontSize: "12px"}}>Trigger Title</ControlLabel>
                <FormControl style={{marginLeft: "2%", width: "70%", fontSize: "12px", height: "45px"}}
                  type="text"
                  defaultValue={this.title}
                  id = "title"
                  onChange={(e)=>{ this.title = e.target.value } }
                />
                <Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12} style={{ marginLeft: "4%"}}>
                      <span style={{color: "red"}}> {this.titleErr} </span>
                    </Col>
                  </Row>
                  <ControlLabel style={{marginLeft: "4%", marginTop:"2%", fontSize: "12px"}}>Set Speed Limit</ControlLabel>
                </Row>
                <Row>
                  <Col lg={1} md={2} sm={3} xs={3} style={{ marginLeft: "3%", paddingRight: "0%"}}>
                    <input className="border-bottom-only" type="number" min={10} max={250} step={1} value={this.state.spdLmt}
                    style={{ fontSize: "24px", width:"92%", color:"#4a4a4a", textAlign: "center" }}
                    onChange={(e)=> { this.onSpdChange(e) }}></input>
                  </Col>
                  <Col lg={2} md={4} sm={6} xs={6} style={{ marginTop: "1%", padding: "0%"}}>
                    <span className="color-grey">km/hr </span>
                    <span className="color-grey font-size14px"> speed exceeds</span>
                  </Col>
                  <Col lg={6} md={5} sm={12} xs={12} style={{margin: "0%"}}>
                    <ReactDropDown className="speedExceedsText" bsSize="small" options={THRESHOLD} unique="idx" name="nm"
                    title={this.state.speedLimitTitle} id="thhd" required value={this.thrshld}
                    style={{border: "none" ,width:"95%", color:"#4a4a4a" ,backgroundColor: "white"}} onClick={(thshdopt) => this.selectThrshld(thshdopt)}/>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} style={{ marginLeft: "4%"}}>
                    <span style={{color: "red"}}> {this.spdLmtErr} </span>
                  </Col>
                  <hr style={{marginLeft: "1%", marginRight:"1%", color: "#4a4a4a"}}/>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} style={{ marginLeft: "4%"}}>
                    <Checkbox style={{fontSize: "20px", color:  "#4a4a4a"}} id="selectAll" onClick={(e) => this.onSelectAllVehicle(e)}>Select for all</Checkbox>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <span style={{color: "red", marginLeft: "4%"}}>{this.vehErr}</span>
                  </Col>
                </Row>
                <Row style={{ overflowY: "auto", maxHeight: "230px",border: "1px solid #e1e1e1" }}>
                  <Col>
                  {
                    this.bikeDetails && this.bikeDetails.map((veh, i) => {
                      return (
                          veh.did ?
                            <div key={veh.did}  className={"col-lg-4 col-md-4 col-sm-12 col-xs-12 "}
                            style={{ paddingLeft: "0px", color: "#4a4a4a",paddingRight: "0px", paddingTop: "0px", border: "1px solid #e1e1e1"}}>
                              <Checkbox validationState="success" onClick={(e) => this.onVehicleSelect(e)} id={veh.idx} value={veh.did}
                                name="veh" style={{marginLeft: "12%", marginTop:"6%", marginBottom:"6%"}} >
                                <div>
                                  <span style={{color: "#788995"}}> {veh.mo} <br/> {veh.reg ? veh.reg : veh.treg} </span>
                                  <span style={{color: "#cdcdcd", marginLeft: '15px', marginTop:"-4%", fontSize:"12px"}}> {veh.did ? "PLUS" : "PRO"} </span>
                                </div>
                              </Checkbox>
                            </div>
                          : null
                        )
                      })
                    }
                  </Col>
                </Row>
              </form>
            </Col>
          </Col>
         }
      </div>
    )
  }
}
