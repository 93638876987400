function FullNameVal(name){
  return (/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-., ])*$/).test(name);
}

function MobVal(mob){
  var regex = /(^[0-9+]{4,15}$)/;
  if(regex.test(mob))
    return true;
  else
    return false;
}

function PassVal(pwd){
  var pattern = /^.{6,}$/
  if(pattern.test(pwd) && pwd)
    return true;
  else
    return false;
}

function IsUserHandle(str){
 var regex = /^[a-z0-9A-Z_]*$/;
 return (regex.test(str) && str.length > 0 && str.length < 15)
}


module.exports = {
  FullNameVal: FullNameVal,
  MobVal: MobVal,
  PassVal: PassVal,
  IsUserHandle: IsUserHandle
};