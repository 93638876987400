/*global google*/
import React, { Component } from 'react';
import { Link } from 'react-router';
import { Col, Alert } from 'react-bootstrap/lib';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, OverlayView, InfoWindow } from "react-google-maps";
import { MyLocButton } from '../generic/input';
import MapControl from './mapControl';
import { StampTODate, timeFormat } from "../lib/dtconv";
const { VEH_SHIP_STATS, ARROW, ICON } = require('../lib/enums');
const { Circle } = require("react-google-maps/lib/components/Circle");
const staticMarker = require('../images/staticMarker.png');
const greyMarker = require('../images/greyMarker.png');
const greenMarker = require('../images/greenMarker.png');
const BLUE_ARROW = Object.assign({ "fillColor": "#00a8e2" }, ARROW);
const GREEN_ARROW = Object.assign({ "fillColor": "#6ac803" }, ARROW);
const GREY_ARROW = Object.assign({ "fillColor": "#9b9b9b" }, ARROW);
const BLUE_ICON = Object.assign({ "url": staticMarker }, ICON);
const GREEN_ICON = Object.assign({ "url": greenMarker }, ICON);
const GREY_ICON = Object.assign({ "url": greyMarker }, ICON);

export default class MapDisplay extends Component {
  constructor(props) {
    super(props);
    this.zoom = 13;
    this.center = { lat: 20.5837, lng: 78.9629 };
    this.maxZoom = 18;
    this.showInfoBox = new Set();
    this.showGfInfoBox = new Set();
    this.vehicleModal = {};
    this.vehicleNum = {};
    this.handleMapMounted = this.handleMapMounted.bind(this);
    this.isDrag = false;
    this.pingData = this.props.pingData;
    this.lastSeenData = {};
  }

  componentDidMount() {
    this.acceptLastSeen(this.props.lastSeenData);
  }
  acceptLastSeen(lastSeen) {
    this._filterSelectedVehLastSeenData(lastSeen);
  }

  _filterSelectedVehLastSeenData(lastSeen) {
    this.lastSeenData = {};
    if (this.props.selectedVeh && this.props.selectedVeh.length) {
      this.props.selectedVeh.forEach(veh => {
        if (veh.did && lastSeen && lastSeen[veh.did]) {
          this.vehicleModal[veh.did] = veh.mo;
          this.vehicleNum[veh.did] = veh.reg;
          this.lastSeenData[veh.did] = lastSeen[veh.did];
        }
      });
      this.forceUpdate(() => {
        if (!this.mapMounted) this.handleMapMounted();
      });
    }
  }

  _onChange(latlng, zoom) {
    if (this.maxZoom === 18) {
      this.maxZoom++;
      return;
    } else this.forceUpdate();
    this.maxZoom++;
    if (this.props.selectedVeh && this.props.selectedVeh.length === 1) {
      this.center = latlng;
      this.forceUpdate();
    }
  }

  changeMapstate = () => {
    this.mapMounted = false;
  }

  handleMapMounted(cb) {
    if (this.mapMounted) return;
    if (Object.keys(this.lastSeenData).length === 1) {
      var data = Object.values(this.lastSeenData)[0];
      var latlng = { lat: data.lat, lng: data.lng };
      if (!this.isDrag) this.center = latlng;
      this.zoom = 16;
      this.forceUpdate();
    }
    else if (Object.keys(this.lastSeenData).length > 1 && this._ref) {
      this.fitToBounds(cb);
    }
  }

  fitToBounds(cb) {
    if (!this._ref) return;
    var bounds = new window.google.maps.LatLngBounds();
    Object.keys(this.lastSeenData).forEach((key) => {
      if (this.lastSeenData[key] && this.lastSeenData[key].lat && this.lastSeenData[key].lng) {
        var latLng = new window.google.maps.LatLng(parseFloat(this.lastSeenData[key].lat, 10), parseFloat(this.lastSeenData[key].lng, 10))
        if (latLng) bounds.extend(latLng);
      }
    });
    this._ref.fitBounds(bounds);
    if (cb) cb();
  }

  displayInfoBox(key) {
    if (this.showInfoBox.has(key)) this.showInfoBox.delete(key);
    else this.showInfoBox.add(key);
    this.forceUpdate();
  }

  handleMyLoc = () => {
    this.isDrag = false;
    if (Object.keys(this.lastSeenData).length === 1) this.fitToBounds();
    else {
      this.mapMounted = false;
      this.handleMapMounted();
    }
  }

  handleDrag = () => {
    this.isDrag = true;
  }

  OnGeoFenceClick = (key) => {
    this.showGfInfoBox.add(key);
    this.forceUpdate();
  }

  onGFToggleClose = (key) => {
    if (this.showGfInfoBox.has(key)) {
      this.showGfInfoBox.delete(key);
      this.forceUpdate();
    }
  }

  getMapIcon(data) {
    var did = data.devid;
    var vid = this.props.deviceHashmap[did];
    var status = this.props.vehicleStatus[vid];
    if (status) {
      if (status === VEH_SHIP_STATS.AT_SRC_START || status === VEH_SHIP_STATS.AT_SRC_END) {
        if (data.ignition) {
          GREY_ARROW["rotation"] = data.brg;
          return GREY_ARROW;
        }
        else return GREY_ICON;
      }
      else if (status === VEH_SHIP_STATS.ENROUTE_DEST || status === VEH_SHIP_STATS.AT_DEST) {
        if (data.ignition) {
          GREEN_ARROW["rotation"] = data.brg;
          return GREEN_ARROW;
        }
        else return GREEN_ICON;
      }
      else if (status === VEH_SHIP_STATS.ENROUTE_SRC) {
        if (data.ignition) {
          BLUE_ARROW["rotation"] = data.brg;
          return BLUE_ARROW;
        }
        else return BLUE_ICON;
      }
    }
    else {
      if (data.ignition) {
        GREY_ARROW["rotation"] = data.brg;
        return GREY_ARROW;
      }
      else return GREY_ICON;
    }
  }

  MapDisplay() {
    return (
      <MapComponent
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAZZ1EgiGO1AVPgChSEqS9iQwVZl2Xfdw8&v=3.exp&libraries=drawing,places"
        loadingElement={<Col style={{ height: '100%' }} />}
        containerElement={<Col style={{ height: '70vh', marginBottom: "15px" }} />}
        mapElement={<div style={{ height: '100%' }} />}
        center={this.center}
        zoom={this.zoom}
        onZoomChanged={() => this._onChange(this.center, this.zoom)}
        trafficCheck={false}
        markers={this.lastSeenData}
        onMapIdle={() => {
          if (!this.mapMounted) {
            this.handleMapMounted();
            this.mapMounted = true;
          }
        }}
        reference={(ref) => this._ref = ref}
        maxZoom={this.maxZoom}
        onPopupClick={(key) => this.displayInfoBox(key)}
        displayInfoBox={[...this.showInfoBox]}
        vehicleModal={this.vehicleModal}
        vehicleNum={this.vehicleNum}
        pingData={this.pingData}
        onMyLocation={this.handleMyLoc}
        onDrag={this.handleDrag}
        showGf={this.props.gfData ? this.props.gfData : null}
        OnGeoFenceClick={(key) => this.OnGeoFenceClick(key)}
        openGfInfoBox={[...this.showGfInfoBox]}
        onGFToggleClose={(key) => this.onGFToggleClose(key)}
        getMapIcon={(key) => this.getMapIcon(key)}
      />
    )
  }

  render() {
    return (
      <Col lg={12} md={12} sm={12}>
        {Object.keys(this.lastSeenData).length > 0 ? this.MapDisplay() : <Alert bsStyle="warning">Map an ajjas device to track these vehicles</Alert>}
      </Col>
    )
  }
}

const MapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    zoom={props.zoom}
    center={props.center}
    onZoomChanged={props.onZoomChanged}
    options={{ scaleControl: true, streetViewControl: false, maxZoom: props.maxZoom }}
    onIdle={props.onMapIdle}
    ref={props.reference}
    onDrag={props.onDrag}
  >
    {props.showGf ?
      props.showGf.map((d, key) => {
        return (
          <Circle
            key={key}
            radius={d.r}
            center={{ lat: d.clat, lng: d.clong }}
            editable={false}
            clickable={false}
            options={{ fillColor: "#AF90FF", strokeWeight: "0", fillOpacity: 0.6 }}
            zIndex={1}
            onClick={(e) => props.OnGeoFenceClick(d)}
          />
        )
      })
      : null}
    <MapControl position={google.maps.ControlPosition.RIGHT_BOTTOM}>
      <MyLocButton onClick={() => props.onMyLocation()} />
    </MapControl>
    {

      Object.keys(props.markers).length > 0 ?
        Object.keys(props.markers).map((key) => {
          if (props.markers[key] && props.markers[key].lat) {
            var newicon = props.getMapIcon(props.markers[key])
            return (
              <Marker key={key} position={{ lat: props.markers[key].lat, lng: props.markers[key].lng }}
                icon={newicon} />
            )
          }
          else return null
        }) : null
    }
    {
      Object.keys(props.markers).length > 0 ?
        Object.keys(props.markers).map((key, i) => {
          var dev = props.markers[key].devid || props.markers[key].idx;
          if (props.markers[key] && props.markers[key].lat)
            return (
              <OverlayView key={Math.random()}
                position={{ lat: props.markers[key].lat, lng: props.markers[key].lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                <div className=" popup-bubble popup-bubble-anchor" style={{ backgroundColor: 'white', padding: "7px", maxWidth: "200px", cursor: "pointer" }} onClick={() => { props.onPopupClick(props.markers[key].devid ? props.markers[key].devid : props.markers[key].vid) }}>
                  {
                    props.displayInfoBox.length && ((props.displayInfoBox.indexOf(dev) > -1) || (props.displayInfoBox.indexOf(props.markers[key].vid)) > -1) ?
                      <InfoWindowData data={props.markers[key]} vehicleModal={props.vehicleModal[key] ? props.vehicleModal[key] : ""} vehicleNum={props.vehicleNum[key] ? props.vehicleNum[key] : ""} pingData={props.pingData && props.pingData[key] ? props.pingData[key] : null} />
                      : <div>{props.vehicleModal[key] ? props.vehicleModal[key] : ""} </div>
                  }
                </div>
              </OverlayView>
            )
          else return null
        }) : null
    }
    {
      props.openGfInfoBox && props.openGfInfoBox.length ?
        props.openGfInfoBox.map((d, key) => {
          return (
            <InfoWindow key={key} onCloseClick={(e) => props.onGFToggleClose(d)} position={{ lat: d.clat, lng: d.clong }}>
              <div>
                <p className="margin0">{d.title}</p>
                <Link style={{ color: "#0094de" }} to={"/webapp/gf/edit?tid=" + d.idx}>Edit</Link>
              </div>
            </InfoWindow>
          )
        })
        : null
    }
  </GoogleMap>
))

const InfoWindowData = (props) => {
  var isDevicePing = props.data.devid ? true : false;
  if (props.pingData) {
    return (
      <div>
        <p className="margin0"><span className="online-offline" style={{ background: 'rgb(199, 76, 17)' }}></span>{props.vehicleNum + " GPS Not Fixed"}</p>
        {props.pingData && props.pingData.ts ? <p className="margin0">Last Ping Sent At: <b>{StampTODate(props.pingData.ts * 1000) + " " + timeFormat(props.pingData.ts * 1000, true)}</b></p> : null}
        {props.data && props.data.ts ? <p className="margin0">Last Location Updated At: <b>{StampTODate(props.data.ts * 1000) + " " + timeFormat(props.data.ts * 1000, true)}</b></p> : null}
        <p className="margin0">{"Ignition: " + (props.pingData.ignition ? "Ignition On" : "Ignition Off")}</p>
        <p className="margin0">{props.data.alt !== null ? "Altitude: " + props.data.alt.toFixed(2) + "m" : "-"}</p>
        <p className="margin0">{props.pingData.relayState ? "Status of Bike : Locked" : "Status of Bike : Unlocked"}</p>
      </div>
    )
  }
  else {
    return (
      <div style={{ fontSize: "12px" }}>
        <p className="margin0"><b><span className="online-offline" style={{ background: (props.data.rideid !== -1 ? 'rgb(66, 183, 42)' : '#fddc33') }}></span>{props.vehicleNum}</b></p>
        <p className="margin0"><b>{(props.data.rideid !== -1 ? " Online:" : " Last Seen : ")}</b></p>
        <p className="margin0"><b>{props.data.ts ? StampTODate(props.data.ts * 1000) + " " + timeFormat(props.data.ts * 1000, true) : "-"}</b></p>
        <p className="margin0">{props.data.spd !== null ? "Speed: " + props.data.spd.toFixed(2) + " km/hr" : "-"}</p>
        <p className="margin0">{props.data.hasOwnProperty('alt') && props.data.alt !== null ? "Altitude: " + props.data.alt.toFixed(2) + " m" : " "}</p>
        {isDevicePing ? <p className="margin0">{props.data.ignition ? "Ignition:   On" : "Ignition:  Off"}</p> : null}
      </div>
    )
  }
}
